import { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { ChooseSignatureSolutions } from "../modules/Fixiter/pages/Onboard/components/SelectSolutions/index";
import { Modal } from "../components/Modal";
import api from "../services/api";
import { useAuth } from "./AuthContext";
import { roles, solutionTypes } from "../utils/constants";
import { TermsOfUseModal } from "../components/TermsOfUseModal";


export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

interface ILicensee{
    id: string;
    name: string;
    completed_onboard: boolean;
    signature?: ISignature;
    country: string;
    state: string;
    city: string;
    contract: string;
    user_term: ITermosUso;
}

interface ISignature {
  id: string;
  plan_name: string;
  orders_quantity: number;
  solutions_quantity: number;
  avaliable_solutions: string[];
  custom_orders: boolean;
  standard_orders: boolean;
  trial_period: number;
  created_at: Date;
}

const OnboardContext = createContext({} as any);

const OnboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [licensee, setLicensee] = useState<ILicensee>();
  const [openModal, setOpenModal] = useState(false);
  const [solutionsWithAccess, setSolutionsWithAccess] = useState<string[]>([])
  const [planHasExpired, setPlanHasExpired] = useState(false)
  const { user, signOut } = useAuth();
  const [userTerm, setUserTerm] = useState<ITermosUso>();
  
  const checkBlocked = (pathname: string) => {
    let pathnameArr = pathname.split('/');
    const solution_type = pathnameArr.pop();
    const solution_key = pathnameArr.pop();

    if (!licensee) {
      return {
        hasAccess: true
      }
    }

    if (!licensee?.signature) {
      return {
        message: "",
        hasAccess: false
      }
    }

    if (solution_type && solution_type === solutionTypes.custom && !licensee.signature?.custom_orders) {
      return {
        message: "",
        hasAccess: false
      }
    }
    
    if (solution_type && solution_type === solutionTypes.standard && !licensee.signature?.standard_orders) {
      console.log("can't standard orders")
      return {
        message: "",
        hasAccess: false
      }
    }
    
    if (solution_key && !solutionsWithAccess.includes(solution_key)) {
      return {
        message: "",
        hasAccess: false
      }
    }

    return {
      hasAccess: true
    };
  }

  const loadLicensee = () => {
    if (user && (user?.role === "FIXITER" || "LICENSEE")) {
      api.get(`/licensees/user/onboard`).then((response) => {
        setLicensee(response.data);
        // Consultar as name_key das soluções que o licenciado pode utilizar
      });
    }
  };

  useEffect(() => {
    if (licensee) {
      // fazer a cha
      api.get(`/signatures/solutions`).then((response)=>{
        setSolutionsWithAccess(
          response.data.map((s: any) => s.name_key)
        );
      })

      api.get(`/user_terms/${licensee?.contract}/active`).then((response) => {
        const user_term = response.data;
        setUserTerm(user_term)
      });
    }
  }, [licensee])

  const getEndDate = () => {
    const licenseeCreatedAt = licensee?.signature?.created_at
    const activeDays =  licensee?.signature?.trial_period

    if(licenseeCreatedAt && activeDays){
      const endDate = new Date(licenseeCreatedAt)
      endDate.setDate(endDate.getDate() + activeDays)
      
      if(new Date() >= endDate){
        setPlanHasExpired(true)
      }
      else setPlanHasExpired(false)
    }
  }
  

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  async function handleChangedPlan(signature: ISignature) {
    if (!signature?.avaliable_solutions &&
          signature?.solutions_quantity) {
      setOpenModal(true);
    }
  }

  useEffect(() => {
    if (user) {
      loadLicensee();
    }
  }, [user]);

  useEffect(() => {
    if (user &&
        licensee?.signature &&
        licensee?.completed_onboard) {
          if (user?.role === roles.fixiter) {
            handleChangedPlan(licensee?.signature);
          }
          getEndDate();
    }
  }, [user, licensee?.signature, licensee?.completed_onboard]);

  return (
    <OnboardContext.Provider
      value={{
        licensee,
        setLicensee: (licensee: any) => {
          setLicensee(licensee);
        },
        loadLicensee,
        solutionsWithAccess,
        setSolutionsWithAccess: (solutionsNameKey: any) => {
          setSolutionsWithAccess([...solutionsWithAccess,solutionsNameKey]);
        },
        checkBlocked,
        planHasExpired,
      }}
    >
      {openModal && (
        <Modal open={openModal}>
          <ChooseSignatureSolutions closeModal={() => toggleModal()} />
        </Modal>
      )}
      {
        (!!user && !!licensee && userTerm && (!licensee.user_term || licensee.user_term.id !== userTerm.id)) && (
          <TermsOfUseModal
            user={user}
            userTerm={userTerm}
            signOut={signOut}
            loadLicensee={loadLicensee}
          />
        )
      }

      {children}
    </OnboardContext.Provider>
  );
};

function useOnboard() {
  const context = useContext(OnboardContext);

  if (!context) {
    throw new Error("useOnboard must be used within an AuthProvider");
  }

  return context;
}

export { useOnboard, OnboardProvider };
