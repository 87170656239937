import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { i18n } from "../../../../../../localization/i18n";
import { Container, Footer } from "./styles";

interface PageTipWarningProps {
  closeModal: () => void;
  changeTip: () => void;
  loading: boolean;
}

export function PageTipWarning({
  closeModal,
  changeTip,
  loading,
}: PageTipWarningProps) {
  return (
    <Container>
      <div className="title">{`${i18n.t("tips.activateTip")}`}</div>
      <div className="description">{`${i18n.t("tips.pageWarning")}`}</div>

      <Footer>
        <OutlinedButton text={i18n.t("tips.cancel")} onClick={closeModal} />
        <SolidButton
          text={i18n.t("tips.confirm")}
          onClick={changeTip}
          loading={loading}
        />
      </Footer>
    </Container>
  );
}
