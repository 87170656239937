import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  h2 {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 30px;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableContainer = styled.div`
  padding: 6px 0px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  margin-top: 20px;
  overflow-y: auto;

  table {
    width: 100%;
    min-width: 430px;
    border-spacing: 0 7px;

    th {
      font-size: 20px;
      font-weight: bold;
      color: var(--dark-gray);
      border-bottom: 1px solid var(--light-gray);
      font-weight: 400;
      padding: 6px 28px 16px;
      text-align: left;
    }

    .name {
      color: var(--fixit);
    }
  }

  @media (max-width: 400px) {
    table {
      th {
        font-size: 18px;
        padding: 6px 20px 16px;
      }
    }
  }
`;

export const Td = styled.td`
  padding: 16px 28px;
  border: 0;
  border-bottom: 1px solid var(--light-gray);
  color: #000;
  cursor: pointer;
  font-size: 16px;
  color: var(--dark-gray);

  @media (max-width: 400px) {
    padding: 12px 24px;
  }
`;
