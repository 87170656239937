import styled from "styled-components";
import global from "../../../../global";

export const Container = styled.div`
  position: relative;
`;

export const NewTipButton = styled.button`
  position: fixed;
  top: calc(100vh - 80px);
  right: 40px;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    box-shadow: 0px 8px 16px 0px ${"var(--fixit)"};
  }

  svg {
    width: 26px;
    height: 26px;
    color: var(--white);
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 26px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    gap: 10px;
  }
`;

export const ListItem = styled.div``;
