import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import Checkbox from "../../components/Checkbox";

import { RiDeleteBin7Line } from "react-icons/ri";

import {
  Container,
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  CheckContainer,
  PrintersForm,
  SelectedPrintersContainer,
  Footer,
  Printer,
} from "./styles";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { AxiosError } from "axios";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";

interface IOption {
  optionText: string;
  value: string;
}

interface ILocationState {
  state: {
      from: {
        pathname: string;
      };
    licensee_id: string;
    licensee_name: string;
  }
}

interface IFormData {
  user: {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
  };
  address: any;
  manager_name: string;
  phone: string;
  situation: string;
  instagram: string;
  person_type: string;
  document_number: string;
  is_public: boolean;
  contract?: {
    value: string | number;
    optionText: string;
  };
}

export default function EditLicensee() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();

  const location = useLocation() as ILocationState;
  const { licensee_id } = location.state || {};
  if (!licensee_id) {
    navigate("/users/licensees", { replace: true });
  }

  const contractOptions = [
    { optionText: "Licença Anual - 1.0", value: "1.0" },
    { optionText: "Licença Mensal - 2.0", value: "2.0" },
  ];

  const situationOptions = [
    { optionText: "Venda efetivada", value: "effected" },
    { optionText: "Em implantação", value: "implementation" },
    { optionText: "Em treinamento", value: "training" },
    { optionText: "Em operação", value: "operational" },
  ];
  const personTypeOptions = [
    { optionText: "Física", value: "Natural" },
    { optionText: "Jurídica", value: "Legal" },
  ];

  const [data, setData] = useState<IFormData>({
    user: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    address: {
      zipcode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    person_type: "",
    document_number: "",
    is_public: false,
    manager_name: "",
    phone: "",
    situation: "",
    instagram: "",
  });
  const [printers, setPrinters] = useState<IOption[]>([]);
  const [ufs, setUfs] = useState<IOption[]>([]);
  const [selectedPrinters, setSelectedPrinters] = useState([] as any);

  async function handleUpdateLicensee() {
    await api
      .patch(`/licensees/${licensee_id}`, {
        ...data,
        printers: selectedPrinters.map((printer: any) => printer.value),
        contract: data.contract?.value,
      })
      .then(() => {
        showToast({
          type: "success",
          message: "Licenciado atualizada com sucesso!",
        });
        navigate(-1);
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao atualizar o licenciado. Detalhes: ${error.message}`,
        });
      });
  }

  async function getPrinters() {
    try {
      const response = await api.get("/printers");

      const mappedPrinters = response.data.map((printer: any) => {
        return {
          value: printer.id,
          optionText: printer.name,
        };
      });

      setPrinters(mappedPrinters);
    } catch (err) {
      showToast({
        type: "error",
        message: "Ocorreu um erro  ao tentar carregar as impressoras.",
      });
    }
  }

  async function handleGetStates() {
    const ufs = await fetch(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    ).then((response) => response.json());

    let array: any = [];

    ufs.forEach((element: any) => {
      let obj = {
        value: element.sigla,
        optionText: element.sigla,
      };
      array.push(obj);
    });

    setUfs(array);
  }

  async function getLicenseeData() {
    try {
      api.get(`/licensees/${licensee_id}`).then((response) => {
        setData({
          ...response.data,
          user: {
            ...response.data.user,
            password: "",
          },
          contract: contractOptions.find(
            (c) => c.value === response.data.contract
          ),
        });

        setSelectedPrinters(
          response.data.printers.map((printer: any) => {
            return {
              value: printer.id,
              optionText: printer.name,
            };
          })
        );
      });
    } catch (err) {
      navigate("/users/licensees", { replace: true });
    }
  }

  async function getInitialData() {
    await Promise.all([getLicenseeData(), getPrinters(), handleGetStates()]);
  }

  function handleDeletePrinter(index: number) {
    let printers = [...selectedPrinters];

    printers.splice(index, 1);

    setSelectedPrinters(printers);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.licensees.edit"),
    });
  }, []);

  return (
    <Container>
      <Form>
        <FormContainer>
          <div className="form_title">Informações do Licenciado</div>

          <FormRow>
            <CheckContainer>
              <label>Visível</label>
              <div className="checks">
                <div className="check">
                  <span>Sim</span>
                  <Checkbox
                    check={data.is_public}
                    handleChange={() => {
                      setData((prevState) => ({
                        ...prevState,
                        is_public: true,
                      }));
                    }}
                  />
                </div>

                <div className="check">
                  <span>Não</span>
                  <Checkbox
                    check={!data.is_public}
                    handleChange={() => {
                      setData((prevState) => ({
                        ...prevState,
                        is_public: false,
                      }));
                    }}
                  />
                </div>
              </div>
            </CheckContainer>
            <InputContainer>
              <label>Tipo de pessoa</label>
              <Dropdown
                options={personTypeOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    person_type: selected.optionText,
                  }));
                }}
                selectedOption={data.person_type}
                placeholder="Selecione o tipo de pessoa..."
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>CPF / CNPJ</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    document_number: event,
                  }));
                }}
                value={data.document_number}
              />
            </InputContainer>

            <InputContainer>
              <label>Contrato</label>
              <Dropdown
                options={contractOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    contract: selected,
                  }));
                }}
                selectedOption={data.contract?.optionText}
                placeholder="Selecione um contrato..."
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Nome</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      name: event,
                    },
                  }));
                }}
                value={data.user.name}
              />
            </InputContainer>

            <InputContainer>
              <label>E-mail</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      email: event,
                    },
                  }));
                }}
                value={data.user.email}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      password: event,
                    },
                  }));
                }}
                value={data.user.password}
              />
            </InputContainer>

            <InputContainer>
              <label>Confirmação da senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      password_confirmation: event,
                    },
                  }));
                }}
                value={data.user.password_confirmation}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Gerente</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    manager_name: event,
                  }));
                }}
                value={data.manager_name}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Telefone</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    phone: event,
                  }));
                }}
                value={data.phone}
              />
            </InputContainer>

            <InputContainer>
              <label>Instagram</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    instagram: event,
                  }));
                }}
                value={data.instagram}
              />
            </InputContainer>
          </FormRow>

          {data.address && (
            <>
              <FormRow>
                <InputContainer>
                  <label>CEP</label>
                  <Input
                    type="text"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          zipcode: event,
                        },
                      }));
                    }}
                    value={data.address.zipcode}
                    maxLength={8}
                  />
                </InputContainer>

                <InputContainer>
                  <label>Rua</label>
                  <Input
                    type="text"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          street: event,
                        },
                      }));
                    }}
                    value={data.address.street}
                  />
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <label>Estado</label>
                  <Dropdown
                    options={ufs}
                    placeholder="Selecione um estado..."
                    selectedOption={data.address.state}
                    handleSelect={(selected) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          state: selected.optionText,
                        },
                      }));
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <label>Cidade</label>
                  <Input
                    type="text"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          city: event,
                        },
                      }));
                    }}
                    value={data.address.city}
                  />
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <label>Número</label>
                  <Input
                    type="text"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          number: event,
                        },
                      }));
                    }}
                    value={data.address.number}
                  />
                </InputContainer>

                <InputContainer>
                  <label>Complemento</label>
                  <Input
                    type="text"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        address: {
                          ...prevState.address,
                          complement: event,
                        },
                      }));
                    }}
                    value={data.address.complement}
                  />
                </InputContainer>
              </FormRow>
            </>
          )}

          <FormRow>
            <InputContainer>
              <label>Situação</label>
              <Dropdown
                options={situationOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    situation: selected.optionText,
                  }));
                }}
                selectedOption={data.situation}
                placeholder="Selecione uma situação..."
              />
            </InputContainer>
          </FormRow>

          <PrintersForm>
            <FormRow>
              <InputContainer>
                <label>Impressoras</label>
                <Dropdown
                  options={printers}
                  handleSelect={(selected) => {
                    let data = selectedPrinters;
                    setSelectedPrinters([...data, selected]);
                  }}
                  placeholder="Selecione uma impressora..."
                />
              </InputContainer>
            </FormRow>

            <SelectedPrintersContainer>
              {selectedPrinters.length > 0 && (
                <div className="sub_title">Impressoras selecionadas</div>
              )}
              {selectedPrinters.map((printer: IOption, index: number) => {
                return (
                  <Printer>
                    <span>{printer.optionText}</span>
                    <RiDeleteBin7Line
                      onClick={() => handleDeletePrinter(index)}
                      size={20}
                      color="var(--fixit)"
                    />
                  </Printer>
                );
              })}
            </SelectedPrintersContainer>
          </PrintersForm>

          <Footer>
            <button type="button" onClick={() => handleUpdateLicensee()}>
              Salvar alterações
            </button>
          </Footer>
        </FormContainer>
      </Form>
    </Container>
  );
}
