import { useEffect, useState } from "react";

import { i18n } from "../../../../localization/i18n";

import { Modal } from "../../../../components/Modal";
import { showToast } from "../../../../components/CustomToast";

import api from "../../../../services/api";

import { RiCloseLine, RiWhatsappFill } from "react-icons/ri";

import {
  InputContainer,
  CloseButton,
  JustifyInput,
  InputHeader,
  Language,
  LicenseeButton,
  CardName,
  Text,
  RowFarContainer,
  RowNearContainer,
  LicenseeListContainer,
  LicenseeSelectedText,
  NewFixPointContainer,
  GeneralContainer,
} from "./styles";

interface IProvisionerList {
  handleProviderSelected?: () => void;
  orderIdentification: string;
  trigger: boolean;
  onClose: () => any;
  order?: any;
}
export interface IOption {
  optionText?: string;
  value: string | number;
  currency?: string;
}

interface ISolution {
  name: string;
  is_standard: boolean;
  is_custom: boolean;
}

interface IProviderData {
  id: string;
  manager_name: string;
  companyName: string;
  phone: string;
  name: string;
  city: string;
  state: string;
}
interface ILicenseeData {
  id: string;
  manager_name: string;
  situation: string;
  is_blocked: boolean;
  state: string;
  country: string;
  city: string;
}

export function OrderProviderModal({
  orderIdentification,
  trigger,
  handleProviderSelected = () => {},
  onClose = () => {},
  order,
}: IProvisionerList) {

  // const [licensees, setLicensees] = useState<ILicenseeData[]>([]);
  const [provider, setProvider] = useState<IProviderData>()
  // const [loading, setLoading] = useState(false);

  async function getLicenseeByUserId (userId: string) {
    try {
      await api.get(`/licensees/user/${userId}`).then((response) => {
        setProvider(response.data)
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  // Por que pegava TODOS os licenciacos?
  /* async function getLicensees() {
    try {
      const response = await api.get(`/licensees`);
      setLicensees(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados",
      });
    }
  } */

  useEffect(() => {
    // carrega todos os licenciados na tela? Como assim?
    // getLicensees();
    trigger && getLicenseeByUserId(order.user_id);
  }, [trigger]);
  
    return (
      <Modal open={trigger} modalWidth={500}>
        <InputHeader>
          {`${i18n.t('orders.order_provider.requester')}`}
        </InputHeader>
        <CloseButton
          onClick={() => {
            onClose();
          }}
        >
          <RiCloseLine size={24} />
        </CloseButton>
          <GeneralContainer>
            <LicenseeSelectedText>{`${i18n.t('orders.order_provider.order_data')}`}</LicenseeSelectedText>
              <RowFarContainer>
                <CardName>
                {`${i18n.t('orders.order_provider.company_name')}`}
                  <label>{provider?.companyName}</label>
                </CardName>
                <CardName>
                {`${i18n.t('orders.order_provider.fantasy_name')}`}
                  <label>{provider?.name}</label>
                </CardName>
              </RowFarContainer>
                <CardName>
                {`${i18n.t('userProfile.personal_data.phone')}:`}
                  <label>{provider?.phone}</label>
                </CardName>
                <CardName>
                {`${i18n.t('orders.order_provider.responsible')}`}
                  <label>{provider?.manager_name}</label>
                </CardName>
                <CardName>
                {`${i18n.t('orders.order_provider.location')}`}
                  <label>{provider?.city}-{provider?.state}</label>
                </CardName>
          </GeneralContainer>
      </Modal>
    );
}
  
