import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const TableContainer = styled.div`
  padding: 6px 0px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  margin-top: 20px;
  overflow-y: auto;

  table {
    width: 100%;
    min-width: 430px;
    border-spacing: 0 7px;
    background-color: var(--white);

    th {
      font-size: 20px;
      font-weight: bold;
      color: var(--dark-gray);
      border-bottom: 1px solid var(--light-gray);
      font-weight: 400;
      padding: 6px 28px 16px;
      text-align: left;
    }

    .name {
      color: var(--fixit);
    }
  }
`;

export const Td = styled.td`
  padding: 16px 28px;
  border: 0;
  border-bottom: 1px solid var(--light-gray);
  color: #000;
  cursor: pointer;
  font-size: 16px;
  color: var(--dark-gray);
`;

export const Button = styled.button`
  position: fixed;
  right: 5%;

  height: 50px;
  width: 50px;
  margin-top: 10px;

  background-color: #9835d1;
  border-radius: 50%;

  color: #fff;

  cursor: pointer;

  transition: border-radius 0.2s, background-color 0.2s;

  &.active,
  &:hover {
    border-radius: 16px;
    background-color: var(--gray);
  }
`;

export const StandardTag = styled.div`
  width: 88px;
  height: 34px;
  border-radius: 24px;
  background: var(--light-gray);
  font-size: 14px;
  line-height: 14px;
  color: var(--dark-grey);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const InputsContainer = styled.div`
  width: 170px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  color: var(--fixit);
  height: 10px;

  display: flex;
  justify-content: center;

`;

export const IconContainer = styled.div`
  width: 50px;

  svg {
    :hover {
      cursor: pointer;
      color: var(--fixit);
    }
  }
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 20px;

    background: red;
`;