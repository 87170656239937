import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  RiLogoutCircleRLine,
  RiChatSmile3Line,
  RiArrowLeftLine,
  RiUser2Fill,
} from "react-icons/ri";

import { HiPencilAlt } from "react-icons/hi";

import { FaDollarSign, FaRegAddressCard, FaUsers } from "react-icons/fa";
import { RiKey2Line } from "react-icons/ri";
import { MdPersonAddAlt1, MdPersonalInjury } from "react-icons/md";
import { Bill, useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";

import {
  ItemText,
  Container,
  BoxNotifications,
  BoxProfile,
  Item,
  BoxNotificationsText,
  Overlay,
  NavigationAction,
  TopBarOptions,
  Separator,
  ItemTextGroup,
  ItemPrimaryText,
  ItemSecondaryText,
  MobileOptions,
  ActionBadge,
  SmallAvatar,
  ProfileAvatar,
} from "./styles";
import { roles } from "../../utils/constants";
import { useOnboard } from "../../hooks/OnboardContext";
import { PrinterControl } from "../PrinterControl";
import ChangePasswordPopup from "../ChangePasswordPopup";
import global from "../../global";
import { Modal } from "../Modal";
import { PersonModal } from "../PersonModal";
import api from "../../services/api";
import { IPerson } from "../PersonModal/dtos";
import { BsFillCreditCardFill } from "react-icons/bs";
import { BiBell } from "react-icons/bi";

const Topbar = () => {
  const { signOut, user, navigationAction } = useAuth();
  const { licensee, loadLicensee } = useOnboard();
  const navigate = useNavigate();

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCreatePersonOpen, setisCreatePersonOpen] = useState(false);
  const [person, setPerson] = useState<IPerson>();
  const [licenseeBill, setLicenseeBill] = useState<Bill>();

  const notification = "0";

  function toggleNotifications() {
    setIsNotificationsOpen(!isNotificationsOpen);
  }

  function toggleProfile() {
    setIsProfileOpen(!isProfileOpen);
  }

  function togglePopup() {
    if (user.id) {
      setIsPopupOpen(!isPopupOpen);
    }
  }

  async function insertUserIntoPerson(person_id: string, user_id: string) {
    try {
      const body = {
        person_id: person_id,
        user_id: user_id,
      };

      await api.patch(`/person/update-person`, body);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!user.person && person) {
      insertUserIntoPerson(person.id, user.id);
    }
  }, [person]);

  function checkDevice() {
    if (
      window.matchMedia(
        `(min-width:${global.responsive.small.width.value}${global.responsive.small.width.unit})`
      ).matches
    ) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }

  useEffect(() => {
    checkDevice();
  }, [toggleProfile]);

  useEffect(() => {
    if (user.role === roles.fixiter || user.role === roles.licensee) {
      setLicenseeBill(
        user.person.licensee.bills.find(
          (bill) =>
            bill.referring_period ===
            `${new Date().getMonth()}/${new Date().getFullYear()}`
        )
      );
    }
  }, [user.person]);

  return (
    <Container>
      <ChangePasswordPopup
        user_id={user.id}
        setOpen={togglePopup}
        open={isPopupOpen}
      />
      {isCreatePersonOpen && (
        <Modal open={isCreatePersonOpen}>
          <PersonModal
            licensee={licensee}
            closeModal={() => {
              setisCreatePersonOpen(false);
            }}
            setPerson={setPerson}
            withoutStep
            onCreate={() => {
              window.location.reload();
            }}
          />
        </Modal>
      )}
      <NavigationAction>
        {!!navigationAction ? (
          <>
            {!!navigationAction.hasButton && (
              <>
                {navigationAction.action === "back" && (
                  <RiArrowLeftLine
                    onClick={() => navigate(-1)}
                    size={30}
                    color="var(--fixit)"
                  />
                )}
              </>
            )}
            <span>{navigationAction.description}</span>
          </>
        ) : (
          <span>Fix it</span>
        )}
      </NavigationAction>
      <TopBarOptions>
        {/* <Credits>
          <RiMoneyDollarCircleFill size={32} color="var(--fixit)" />
          <CreditsText>
            <span>{i18n.t('topbar.coinFix.start')} <b>{coin}</b> {i18n.t('topbar.coinFix.end')}</span>
          </CreditsText>
        </Credits> */}
        {(user.role === roles.licensee ||
          user.role === roles.employee ||
          user.role === roles.creator) && <PrinterControl />}

        {/* <ButtonIcon onClick={toggleNotifications}>
          <RiNotification2Line size={24} color="var(--fixit)" />
        </ButtonIcon> */}

        {isNotificationsOpen ? (
          <>
            <BoxNotifications>
              <RiChatSmile3Line size={50} color="var(--fixit)" />
              <BoxNotificationsText>
                {`${i18n.t("topbar.notification", { quantity: notification })}`}
              </BoxNotificationsText>
            </BoxNotifications>
            <Overlay onClick={toggleNotifications} />
          </>
        ) : null}

        {/* <NameLabel>{!!user ? user.name : ""}</NameLabel> */}
        <ActionBadge
          hasNotification={
            !licenseeBill &&
            (user.role === roles.licensee || user.role === roles.fixiter)
          }
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <SmallAvatar
              hasNotification={
                !licenseeBill &&
                (user.role === roles.licensee || user.role === roles.fixiter)
              }
            >
              <BiBell size={13} onClick={() => {}} />
            </SmallAvatar>
          }
        >
          <ProfileAvatar onClick={toggleProfile}>
            {!!user?.person
              ? user.person.name.substring(0, 2).toUpperCase()
              : user.name.substring(0, 2).toUpperCase()}
            {isProfileOpen ? (
              <>
                <BoxProfile>
                  {/* <Link style={{ textDecoration: 'none' }} to="/">
                <Item>
                    <ItemText>{i18n.t('topbar.editProfile')}</ItemText>
                  <RiUser3Line size={22} color="var(--fixit)" />
                </Item>s
              </Link> */}
                  <Item
                    hasNotification={
                      !licenseeBill &&
                      (user.role === roles.licensee ||
                        user.role === roles.fixiter)
                    }
                    onClick={() => {
                      (user.role === roles.licensee ||
                        user.role === roles.fixiter) &&
                        navigate(
                          `/${user.role.toLocaleLowerCase()}/profile/my_profile`
                        );
                    }}
                  >
                    <ItemTextGroup>
                      <ItemPrimaryText>
                        {!!user?.person ? user.person.name : user.name}
                      </ItemPrimaryText>
                      <ItemSecondaryText>
                        {licensee ? licensee.name : user.role}
                      </ItemSecondaryText>
                    </ItemTextGroup>
                    <RiUser2Fill size={20} />
                  </Item>
                  <Separator />
                  <MobileOptions>
                    {(user.role === roles.fixiter ||
                      user.role === roles.licensee) && (
                      <>
                        <Item
                          isMobile
                          hasNotification={
                            !licenseeBill &&
                            (user.role === roles.licensee ||
                              user.role === roles.fixiter)
                          }
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/closure`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.closure"
                          )}`}</ItemText>
                          <FaDollarSign size={20} />
                        </Item>
                        <Separator />
                        <Item
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/payments`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.paymentInfos"
                          )}`}</ItemText>
                          <BsFillCreditCardFill size={20} />
                        </Item>
                        <Separator />
                        <Item
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/address`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.addressLabel"
                          )}`}</ItemText>
                          <FaRegAddressCard size={20} />
                        </Item>
                        <Separator />
                        <Item
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/patients`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.patientLabel"
                          )}`}</ItemText>
                          <MdPersonalInjury size={20} />
                        </Item>
                        <Separator />
                      </>
                    )}
                    {user.role === roles.licensee && (
                      <>
                        <Item
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/partners`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.partnerLabel"
                          )}`}</ItemText>
                          <FaUsers size={20} />
                        </Item>
                        <Separator />
                        <Item
                          onClick={() => {
                            navigate(
                              `/${user.role.toLocaleLowerCase()}/profile/users`
                            );
                          }}
                        >
                          <ItemText>{`${i18n.t(
                            "userProfile.userLabel"
                          )}`}</ItemText>
                          <MdPersonAddAlt1 size={20} />
                        </Item>
                        <Separator />
                      </>
                    )}
                  </MobileOptions>
                  {user.role !== roles.licensee && user.role !== roles.fixiter && (
                    <>
                      <Item onClick={togglePopup}>
                        <ItemText>{`${i18n.t(
                          "topbar.change_password"
                        )}`}</ItemText>
                        <RiKey2Line size={20} />
                      </Item>
                      <Separator />
                    </>
                  )}
                  {!user.person && (
                    <Item onClick={() => setisCreatePersonOpen(true)}>
                      <ItemText>{`${i18n.t("topbar.addPerson")}`}</ItemText>
                      <HiPencilAlt size={20} />
                    </Item>
                  )}
                  <Separator />
                  {/*
              { user.role === roles.licensee &&
                <>
                <Item
                onClick={() => {
                  history.push({
                    pathname: `/orders/as_provider`
                  });
                } }>
                    <ItemText>{`${i18n.t('topbar.fixpoint')}`}</ItemText>
                    <MdOutlinePersonPinCircle size={20} />
                  </Item>
                <Separator />
                </>
              } */}
                  <Item onClick={signOut}>
                    <ItemText>{`${i18n.t("topbar.signout")}`}</ItemText>
                    <RiLogoutCircleRLine size={20} />
                  </Item>
                </BoxProfile>
                <Overlay onClick={toggleProfile} />
              </>
            ) : null}
          </ProfileAvatar>
        </ActionBadge>
      </TopBarOptions>
    </Container>
  );
};

export default Topbar;
