import styled from "styled-components";

interface ButtonProps{
    delete: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;

  svg{
      position: relative;
      top: -25px;
      left: 170px;
      width: fit-content;
      cursor: pointer;
  }

  span{
      position: relative;
      top: -15px;
      font-weight: 500;
      color: var(--fixit);
  }
`

export const OptionsButton = styled.button<ButtonProps>`
width: 100px;
height: 2.5rem;
background: inherit;
border: 1px solid var(--gray);
font-weight: 500;
border-radius: 10px;
cursor: pointer;
color: var(--fixit);

:hover{
    border-color: ${(ButtonProps) => ButtonProps.delete ? 'var(--success)' : 'var(--error)'};
    color: ${(ButtonProps) => ButtonProps.delete ? 'var(--success)' : 'var(--error)'};
}
`

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`
