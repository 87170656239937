import { Container } from "./styles";
import {
  AccordionContainer,
  AccordionContentContainer,
  AlertMessage,
  Divider,
  Footer,
  FooterButton,
  InputBlock,
  InputContainer,
  InputLine,
  LinkButton,
  MeasuresInputLine,
  SectionFormTitle,
  SelectContainer,
} from "../../styles";
import { AccordionOrder } from "../../../../../components/AccordionOrder";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import {
  CustomSolutionForm,
  IExamData,
  IPrice,
  ISolutionData,
  IStandardFile,
  Solution,
} from "../../dtos";
import CustomImg from "../../../../../assets/order_steps/custom.svg";
import { OutlinedSelect } from "../../../../../components/OutlinedSelect";
import { colors } from "../../mockOptions";

import { Tab, Tabs } from "@mui/material";
import { OutlinedCheckbox } from "../../../../../components/OutlinedCheckbox";
import api from "../../../../../services/api";
import { usePrinters } from "../../../../../hooks/PrintersContext";
import { i18n } from "../../../../../localization/i18n";
import { useAuth } from "../../../../../hooks/AuthContext";
import { roles } from "../../../../../utils/constants";
import { ISimpleFileChoice, IStandard } from "../../../dtos";
import { useEffect } from "react";

interface FinalOrderProps {
  solution_type?: string;
  metricType: string;
  solutionComplete: boolean;
  tmpSolutionData: ISolutionData;
  solutionFormErrors: any;
  solution: Solution;
  orderData: CustomSolutionForm;
  filePrice?: IPrice;
  standard?: IStandard;
  setMetricType: (value: React.SetStateAction<string>) => void;
  setTmpSolutionData: (value: React.SetStateAction<ISolutionData>) => void;
  setStandard: (value: React.SetStateAction<IStandard | undefined>) => void;
  verifySubmit: () => boolean;
  setOrderData: (value: React.SetStateAction<CustomSolutionForm>) => void;
  validateSolutionForm: () => Promise<boolean>;
  handleSubmit: () => void;
}

export default function FinalOrderSection({
  solution_type,
  metricType,
  solutionComplete,
  solution,
  tmpSolutionData,
  solutionFormErrors,
  orderData,
  filePrice,
  standard,
  setMetricType,
  setTmpSolutionData,
  setStandard,
  verifySubmit,
  setOrderData,
  validateSolutionForm,
  handleSubmit,
}: FinalOrderProps) {
  const { defaultPrinter } = usePrinters();
  const { user } = useAuth();

  function getSolutionSubtitle() {
    if (orderData.color) {
      return `${orderData.color}`;
    }

    return "";
  }

  return (
    <Container>
      <AccordionContainer>
        <AccordionOrder
          open={!verifySubmit()}
          emptyTitle={i18n.t("orders.solution_section.empty_title")}
          emptySubtitle={i18n.t("orders.solution_section.empty_subtitle")}
          title=""
          subTitle={getSolutionSubtitle()}
          icon={CustomImg}
          pendingInfos={!solutionComplete}
          handleChange={() => {}}
        >
          <AccordionContentContainer>
            {solution_type === "custom" && (
              <>
                <Tabs
                  value={"custom"}
                  onChange={(event, newValue) => {}}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="custom" label="Medidas extraídas" />
                  {/* <Tab value="standard" label="Tamanho padrão" disabled={!solution?.standards?.length || !orderData.exam?.id} /> */}
                </Tabs>

                {solution_type === "custom" && orderData?.exam && (
                  <MeasuresInputLine>
                    {orderData?.exam?.measures?.map((item, index) => (
                      <InputContainer key={index} containerWidth="100px">
                        <OutlinedInput
                          inputName={`measure${index + 1}`}
                          value={item}
                          readOnly={true}
                          autoComplete="off"
                          label={`Medida ${index + 1}`}
                          handleChange={(event) => {}}
                        />
                      </InputContainer>
                    ))}
                  </MeasuresInputLine>
                )}
                {/*
                    Trecho de escolha de padronizada, vai ficar oculta por enquanto, pois a seleção será feita após a compra, antes da impressão.
                    ( user.role === roles.fixiter || metricType === 'standard' ) && (
                      <>
                        <Tabs
                          value={"standard"}
                          onChange={(event, newValue) => {}}
                          textColor="secondary"
                          indicatorColor="secondary"
                          aria-label="secondary tabs example"
                        >
                          <Tab value="standard" label="Tamanhos padronizados" />
                        </Tabs>
                        <MeasuresInputLine>
                          {solution?.standards?.map((item, index) => (
                            <InputContainer containerWidth="100px">
                              <OutlinedCheckbox label={item.size} checked={!!standard?.id && standard.id === item.id} handleChange={() => {
                                setStandard(item);
                              }}></OutlinedCheckbox>
                            </InputContainer>
                          ))}
                        </MeasuresInputLine>
                      </>
                    )*/}
                <Divider />
              </>
            )}

            {orderData?.exam?.side && (
              <InputLine>
                {solution_type === "custom" && (
                  <SelectContainer>
                    <OutlinedSelect
                      options={solution.sides?.map((side) => ({
                        value: side,
                        optionText: i18n.t(`global.sides.${side}`),
                      }
                      ))}
                      label="Membro"
                      readOnly={true}
                      handleSelect={() => {}}
                      selectedOption={i18n
                        .t(`global.sides.${orderData?.exam?.side?.value}`)
                        .toString()}
                    />
                  </SelectContainer>
                )}
                <SelectContainer>
                  <OutlinedSelect
                    options={colors}
                    label="Cor do filamento"
                    handleSelect={(selected) => {
                      setTmpSolutionData((prevState) => ({
                        ...prevState,
                        color: {
                          optionText: selected.optionText as string,
                          value: selected.value as string
                        },
                      }));
                    }}
                    selectedOption={tmpSolutionData.color.optionText}
                    error={solutionFormErrors.color}
                  />
                </SelectContainer>
              </InputLine>
            )}

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="notes"
                  textArea={true}
                  value={tmpSolutionData.notes}
                  label="Observações"
                  handleChange={(event) => {
                    setTmpSolutionData((prevState) => ({
                      ...prevState,
                      notes: event,
                    }));
                  }}
                />
              </InputContainer>
            </InputLine>

            <InputBlock>
              {user.role !== roles.employee ?  
              <InputContainer containerWidth="120px">
                <OutlinedInput
                  inputName="solutionPrice"
                  disabled={!filePrice}
                  readOnly={true}
                  value={
                    filePrice
                      ? new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(filePrice.file_price)
                      : ""
                  }
                  label="Preço"
                  handleChange={(event) => {}}
                />
              </InputContainer>
              : null
              }

              <InputContainer containerWidth="120px">
                <OutlinedInput
                  disabled={!filePrice}
                  readOnly={
                    solution_type === "custom" ||
                    solution_type === "plan" ||
                    !filePrice
                  }
                  inputName="Qtd."
                  value={filePrice ? orderData.quantity : ""}
                  label="Quantidade"
                  handleChange={(event) => {
                    setOrderData((prevState) => ({
                      ...prevState,
                      quantity: event,
                    }));
                  }}
                />
              </InputContainer>

              {user.role !== roles.employee ? 
              <InputContainer containerWidth="120px">
                <OutlinedInput
                  inputName="total"
                  disabled={!filePrice}
                  readOnly={true}
                  value={
                    filePrice
                      ? new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(orderData.quantity * filePrice.file_price)
                      : ""
                  }
                  label="Total"
                  handleChange={(event) => {}}
                />
              </InputContainer>
              :
              null
              }
            </InputBlock>

            {/* Mais duplicação de código */}
            <Footer>
              {(solution_type === "custom" &&
                metricType === "standard" &&
                !standard?.id) ||
              (user.role === roles.licensee && !defaultPrinter) ||
              !filePrice ? (
                <AlertMessage>
                  {!filePrice
                    ? `${i18n.t("customSolution.noFilePrice")}`
                    : user.role === roles.licensee && !defaultPrinter
                    ? `${i18n.t("customSolution.noSelectedPrinter")}`
                    : `${i18n.t("customSolution.noStandardFile")}`}
                </AlertMessage>
              ) : (
                <FooterButton
                  type="button"
                  disabled={verifySubmit()}
                  onClick={() => {
                    if (!verifySubmit()) {
                      validateSolutionForm().then((valid) => {
                        valid && handleSubmit();
                      });
                    }
                  }}
                >
                  {`${i18n.t("customSolution.sendOrder")}`}
                </FooterButton>
              )}
            </Footer>
          </AccordionContentContainer>
        </AccordionOrder>
      </AccordionContainer>
    </Container>
  );
}
