import styled, { css } from "styled-components";

type ButtonProps = {
    disabled?: boolean;
  };
  type InputProps = {
    disabled?: boolean;
  };
  type IDisabled = {
    disabled?: boolean;
  }

export const CloseButton = styled.div`
width: 100%;
height: 1px;
display: flex;
justify-content: flex-end;
position: relative;
bottom: 1.6rem;
left: 1.6rem;

svg{
  cursor: pointer;
}
`
export const Header = styled.header`
margin: 0 0 1rem 0;
font-size: 1.4rem;
color: var(--fixit);
font-weight: 500;
`

export const InputContainer = styled.div`
width: 100%;
`;

export const Label = styled.span<IDisabled>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--gray);
  margin-left: 20px;
  transition: all 0.6s ease;
  position: absolute;
  top: -7px;
  left: 0px;
  background: var(--white);
  padding: 0 6px;

  ${({ disabled }) =>
    (disabled) &&
    css`
      position: absolute;
      top: -16px;
      left: 0px;
      background: var(--white);
      padding: 0 6px;
      color: var(--fixit);
    `}
`;

export const SolutionsContainer = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 20px;
`;

export const SelectedSolutions = styled.div<InputProps>`
  width: 100%;
  min-height: 10rem;
  border: 2px solid #989898;
  border-radius: 10px;
  transition: all 0.4s ease;
  background: ${(InputProps) => InputProps.disabled ? `var(--light-gray)` : `var(--white)`};
  display: flex;
  flex-wrap: wrap;
  padding: 10px ;
  font-size: 14px;
  color: var(--dark-gray);
  gap: 5px;
  `;

export const Tag = styled.div`
display: flex;
width: fit-content;
max-width: 332px;
height: 18px;
align-items: center;
gap: 2px;
padding: 5px;
border-radius: 25px;
background: #eae8e8;
background: var(--white);
border: 1px solid var(--fixit);

span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: var(--fixit);
  width: 100%;
  height: fit-content;
}

svg {
  margin-left: 2px;
  cursor: pointer;
  color: var(--fixit);
}
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Conclude = styled.span<ButtonProps>`
  font-family: Roboto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--fixit)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100%;
  border: 1px solid ${({ disabled }) =>
  disabled ? "var(--light-gray)" : "var(--fixit)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--fixit);
        color: var(--white);
        background: var(--fixit);
      }
    `}
`;
