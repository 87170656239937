import { useRoutes } from "react-router-dom";

import { Documents } from "../../modules/noAuth/Pages/Documents";
import { UserRegistration } from "../../modules/noAuth/Pages/UserRegistration";

function RegistrationRoutes() {
  const openRoutes = useRoutes([
    { path: "/registration", element: (<UserRegistration />) },
    { path: "/docs/:type", element: (<Documents />) },
  ])

  return openRoutes;
}

export default RegistrationRoutes;
