import { i18n } from "../../../../../../../localization/i18n";

import { OutlinedButton } from "../../../../../../../components/OutlinedButton";

import { RiCheckFill } from "react-icons/ri";

import {
  Container,
  Price,
  Item,
  PlanDescription,
  PlanFooter,
  PlanItemsWrapper,
  PlanTitle,
} from "./styles";

interface PlanProps {
  id: string;
  name: string;
  price: string;
  description: string;
  advantages: string[];
  planSelected: boolean;
  moneyProp: string;
  onPlanSelect: (planId: string) => void;
}

export function Plan({
  id,
  name,
  price,
  description,
  advantages,
  onPlanSelect,
  moneyProp,
  planSelected,
}: PlanProps) {
  return (
    <Container selected={planSelected}>
      <PlanTitle>{name}</PlanTitle>
      <Price>
        {moneyProp}
        {price}
      </Price>
      <PlanDescription>{description}</PlanDescription>
      <PlanItemsWrapper>
        {advantages?.map((advantage) => (
          <Item key={advantage}>
            <RiCheckFill />
            <div className="plan_item">
              <p className="advantage">{advantage}</p>
            </div>
          </Item>
        ))}
      </PlanItemsWrapper>

      <PlanFooter>
        <OutlinedButton
          buttonColor={planSelected ? "var(--active-green)" : "var(--fixit)"}
          text={i18n.t(
            planSelected
              ? "userRegistration.choosedPlan"
              : "userRegistration.choosePlan"
          )}
          onClick={() => onPlanSelect(id)}
        />
      </PlanFooter>
    </Container>
  );
}
