import { RiDownloadCloud2Line, RiLineChartLine, RiMenuLine, RiUserStarFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { i18n } from '../../../localization/i18n';
import { RouteButton } from '../styles';

interface MenuListProps {
  extendedMode: boolean;
}

export const EmployeeMenuList = ({extendedMode} : MenuListProps) => (
    <>
      <Link to="/employee/dashboard">
        {" "}
        {/* Go to Dashboard */} {/* 6 because of 12px margin */}
        <RouteButton extendedMode={extendedMode}>
          <RiLineChartLine size={28} />

          {extendedMode && (
            <span>{`${i18n.t("sidebar.reports")}`}</span>
          )}
        </RouteButton>
      </Link>

      <Link to="/employee/solutions">
        {" "}
        {/* Go to Solutions */}
        <RouteButton extendedMode={extendedMode}>
          <RiDownloadCloud2Line size={28} />
          {extendedMode && (
            <span>{`${i18n.t("sidebar.solutions")}`}</span>
          )}
        </RouteButton>
      </Link>

      <Link to="/employee/orders">
        {" "}
        {/* Go to Dashboard */} {/* 6 because of 12px margin */}
        <RouteButton extendedMode={extendedMode}>
          <RiMenuLine size={28} />

          {extendedMode && (
            <span>{`${i18n.t("sidebar.requests")}`}</span>
          )}
        </RouteButton>
      </Link>
     
    </>
)