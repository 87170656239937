import api from "../../services/api";
import { roles } from "../../utils/constants";

interface IQuery {
    queryList: string[]
}

const getUserOrders = async ({queryList} : IQuery) => {
    const { data } = await api.get(`/orders/user?${queryList.join("&")}`);
    return data;
}

const getOrders = async ({queryList} : IQuery) => {
    const { data } = await api.get(`/orders?${queryList.join("&")}`);
    return data;
}

export const getApiGetOrdersFunctionByRole = (role : string) => {
    if (role === roles.fixiter || role === roles.employee || role === roles.licensee) { return getUserOrders }
    else { return getOrders }
}