import {
  RiBillLine,
  RiLineChartLine,
  RiMenuLine,
  RiPriceTag2Line,
  RiShoppingCart2Line,
  RiUserStarFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { RouteButton } from "../styles";

interface MenuListProps {
  extendedMode: boolean;
}

export const FinancialMenuList = ({ extendedMode }: MenuListProps) => (
  <>
    <Link to="/financial/dashboard">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiLineChartLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.reports")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/financial/orders">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiMenuLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.requests")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/financial/licensees">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiUserStarFill size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.licensees")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/financial/bills">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiBillLine size={28} />

        {extendedMode && <span>{`${i18n.t("bills.bills")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/financial/prices">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiPriceTag2Line size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.prices")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/financial/plans">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiShoppingCart2Line size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.plans")}`}</span>}
      </RouteButton>
    </Link>
  </>
);
