import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { i18n } from "../../../../localization/i18n";

import CustomToast, { showToast } from "../../../../components/CustomToast";

import api from "../../../../services/api";

import { getActiveLanguage } from "../../../../utils/getActiveLanguage";

import LogoImg from "../../../../assets/logo.svg";

import {
  ChangeLanguageContainer,
  Container,
  Header,
  LanguageSelect,
  Loading,
} from "./styles";

interface Params {
  type: string;
}

export function Documents() {
  const { type } = useParams<keyof Params>();

  const [isLoadingDocument, setIsLoadingDocument] = useState(true);
  const [document, setDocument] = useState("");

  async function getDocument() {
    try {
      const response = await api.get(
        `/user_terms/2.0/active?type=${type}&locale=${i18n.language}`
      );

      fetch(
        `${process.env.REACT_APP_STORAGE_BASE_URL}${response.data.document_link}`
      )
        .then((response) => response.text())
        .then((html) => {
          setDocument(html);
        });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.termsError"),
      });
    } finally {
      setIsLoadingDocument(false);
    }
  }

  function handleLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();

    window.localStorage.setItem("i18nextLng", e.target.value);
    window.location = window.location
  }

  useEffect(() => {
    getDocument();
  }, []);

  return (
    <Container>
      <CustomToast />

      <Header>
        <img src={LogoImg} alt="Logo Fixit" />
        <span className="title">
          {type === "privacy_policy"
            ? `${i18n.t("userRegistration.policy")}`
            : `${i18n.t("userRegistration.terms")}`}
        </span>
      </Header>

      {isLoadingDocument ? (
        <Loading />
      ) : (
        <iframe
          title="Document"
          srcDoc={document}
          width="800px"
          height="700px"
          id="docFrame"
          style={{ overflow: "hidden", marginTop: "20px" }}
        ></iframe>
      )}

      <ChangeLanguageContainer>
        <LanguageSelect
          onChange={(e) => handleLanguageChange(e)}
          value={getActiveLanguage()}
        >
          <option value="pt-BR">PT 🇧🇷</option>
          <option value="es">ES 🇪🇸</option>
          <option value="en">EN 🇬🇧</option>
        </LanguageSelect>
      </ChangeLanguageContainer>
    </Container>
  );
}
