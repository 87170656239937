import { useEffect, useState } from "react";

import { RiFilter3Fill } from "react-icons/ri";

import "react-datepicker/dist/react-datepicker.css";

import {
  Container,
  FilterContainer,
  FilterSectionContainer,
  SectionHeader,
  SectionName,
  SelectContainer,
  Footer,
  ApplyButton,
  InputLine,
  InputContainer,
  CheckboxContainer,
} from "./styles";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { i18n } from "../../../../../../localization/i18n";
import { showToast } from "../../../../../../components/CustomToast";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { nullOption, orderTypeOptions, solutionTypeOptions } from "../../mocks";
import { CheckboxNullable } from "../../../../../../components/CheckboxNullable";
import {
  ICurrencyOption,
  IFilter,
  IOption,
  ISearchableSolution,
  ISolution,
} from "../../dtos";
import { countries } from "../../../../../../localization/data/mocks";

interface FilterProps {
  onChange: (filterData: IFilter) => void;
  filter?: IFilter;
}

export function Filter({ onChange, filter }: FilterProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState<ISolution>();
  const [selectedSolutionType, setSelectedSolutionType] = useState<IOption>();
  const [selectedOrderType, setSelectedOrderType] = useState<IOption>();
  const [selectedCountry, setSelectedCountry] = useState<ICurrencyOption>();
  const [selectedIsActive, setSelectedIsActive] = useState<boolean>();

  const [solutions, setSolutions] = useState<ISearchableSolution[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [solutionSearchValue, setSolutionSearchValue] = useState("");

  function applyFilterData() {
    const filterData: IFilter = {
      order_type: selectedOrderType,
      solution_type: selectedSolutionType,
      country: selectedCountry,
      solution: selectedSolution,
      is_active: selectedIsActive,
    };

    onChange(filterData);
    setFilterOpen(false);
  }

  useEffect(() => {
    setSelectedSolution(filter?.solution);
    setSelectedOrderType(filter?.order_type);
    setSelectedSolutionType(filter?.solution_type);
    setSelectedCountry(filter?.country);
    setSelectedIsActive(filter?.is_active);
  }, [filter]);

  async function searchSolutions(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(
        `/solutions/search?term=${solutionSearchValue}`,
        { signal }
      );
      setSolutions(
        data.map((p: any) => ({
          id: p.id,
          label: p.name ?? p.company_name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar as soluções.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (solutionSearchValue != "") {
      searchSolutions(signal);
    }

    return () => abortController.abort();
  }, [solutionSearchValue]);

  return (
    <Container>
      <RiFilter3Fill
        size={34}
        color={filterOpen ? "var(--fixit)" : "var(--gray)"}
        onClick={() => setFilterOpen((prevState) => !prevState)}
      />

      <FilterContainer open={filterOpen}>
        <InputLine>
          <InputContainer>
            <SearchableSelect
              label={`${i18n.t("financial.prices.field.solution")}`}
              options={solutions}
              defaultValue={solutionSearchValue}
              loading={selectLoading}
              onSelect={(selected) => {
                if (typeof selected === "string") {
                  setSelectedSolution(undefined);
                  return;
                }

                setSelectedSolution(
                  solutions?.find((s) => s.model.id === selected.id)?.model
                );
              }}
              onChange={(value) => {
                setSolutionSearchValue(value);
              }}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.country")}`}
              options={[nullOption, ...countries]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedCountry(undefined);
                  return;
                }
                setSelectedCountry(
                  countries.find((t) => t.value === selected.value)
                );
              }}
              selectedOption={selectedCountry?.optionText || ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.order_type")}`}
              options={[nullOption, ...orderTypeOptions]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedOrderType(undefined);
                  return;
                }
                setSelectedOrderType(selected);
              }}
              selectedOption={selectedOrderType?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.solution_type")}`}
              options={[nullOption, ...solutionTypeOptions]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedSolutionType(undefined);
                  return;
                }
                setSelectedSolutionType(selected);
              }}
              selectedOption={selectedSolutionType?.optionText || ""}
            />
          </InputContainer>
        </InputLine>

        <Footer>
          <CheckboxContainer checked={selectedIsActive}>
            <CheckboxNullable
              checked={selectedIsActive}
              handleChange={() => {
                if (selectedIsActive) setSelectedIsActive(false);
                else if (selectedIsActive === false)
                  setSelectedIsActive(undefined);
                else setSelectedIsActive(true);
              }}
            />
            <span>{`${i18n.t("global.status.label")}`}</span>
          </CheckboxContainer>
          <ApplyButton type="button" onClick={applyFilterData}>
            Aplicar
          </ApplyButton>
        </Footer>
      </FilterContainer>
    </Container>
  );
}
