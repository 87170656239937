import { useEffect, useState } from "react";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";

import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { Tabs } from "../../../../../../components/Tabs";
import { TipLanguageForm } from "./components/TIpLanguageForm";
import CustomToast, {
  showToast,
} from "../../../../../../components/CustomToast";

import api from "../../../../../../services/api";

import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../../../utils/validateFormFields";

import { IFile } from "../../../../../Orders/CreateOrder/dtos";

import {
  LanguageTipData,
  LanguageValues,
  NewTipModalProps,
  TipData,
} from "./dtos";

import { TipPageOptions, TipRoleOptions, TipTypesOptions } from "./mocks";

import { RiCloseLine } from "react-icons/ri";

import { Container, Header, Content, Footer } from "./styles";

export function NewTipModal({
  closeModal,
  getAllTips,
  isEdition,
  tipForEdition,
}: NewTipModalProps) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [languageTipData, setLanguageTipData] = useState({
    "pt-BR": {
      description: "",
    },
    en: {
      description: "",
    },
    es: {
      description: "",
    },
  });
  const [tipData, setTipData] = useState<TipData>({
    name: "",
    code: "",
    role: {
      optionText: "",
      value: "",
    },
    type: {
      optionText: "",
      value: "",
    },
    page: {
      optionText: "",
      value: "",
    },
    specificUrl: "",
    files: [],
    gif_time: '',
  });

  function EditTip() {
    if (tipForEdition) {
      // console.log(JSON.parse(tipForEdition?.images_link));
      const typeLabel = TipTypesOptions.find(
        (t) => t.value === tipForEdition.type
      );
      const roleLabel =
        tipForEdition.type !== "specific"
          ? tipForEdition.type === "global"
            ? { optionText: "Todos usuários" }
            : { optionText: "Licenciado" }
          : TipRoleOptions.find((t) => t.value === tipForEdition.role);

      const pageLabel = TipPageOptions[
        tipForEdition.role === "" ? "ALL" : tipForEdition.role
      ].find((page: any) => page.value === tipForEdition.route);

      const description = JSON.parse(tipForEdition?.description);

      setTipData({
        name: tipForEdition.name,
        code: tipForEdition.code,
        role: {
          optionText: roleLabel?.optionText as string,
          value: tipForEdition.role,
        },
        type: {
          optionText: typeLabel?.optionText as string,
          value: tipForEdition.type,
        },
        page: {
          optionText: pageLabel?.optionText
            ? pageLabel.optionText
            : tipForEdition.route,
          value: tipForEdition.route,
        },
        specificUrl: (pageLabel?.optionText
          ? ""
          : tipForEdition.route.split("/").pop()) as string,
        files: tipForEdition.images_link.map((item) => ({
          name: item.split("/")[2] ?? "",
        })),
        gif_time: tipForEdition.gif_time
      });
      setLanguageTipData({
        "pt-BR": {
          description: description["pt-BR"],
        },
        en: {
          description: description.en,
        },
        es: {
          description: description.es,
        },
      });
    }
  }

  useEffect(() => {
    if (isEdition) {
      EditTip();
    }
  }, [isEdition]);

  const tabsComponents = [
    {
      tabName: "PT-BR",
      component: (
        <TipLanguageForm
          language="pt-BR"
          changeLanguageTipData={changeLanguageTipData}
          languageTipData={languageTipData}
          changeTipData={changeTipData}
          tipData={tipData}
          errors={errors}
          editingTip={isEdition}
        />
      ),
    },
    {
      tabName: "EN",
      component: (
        <TipLanguageForm
          language="en"
          changeLanguageTipData={changeLanguageTipData}
          languageTipData={languageTipData}
          changeTipData={changeTipData}
          tipData={tipData}
          errors={errors}
          editingTip={isEdition}
        />
      ),
    },
    {
      tabName: "ES",
      component: (
        <TipLanguageForm
          language="es"
          changeLanguageTipData={changeLanguageTipData}
          languageTipData={languageTipData}
          changeTipData={changeTipData}
          tipData={tipData}
          errors={errors}
          editingTip={isEdition}
        />
      ),
    },
  ];

  async function validateForm(event: React.FormEvent) {
    event.preventDefault();

    const body = {
      role: tipData.role.value,
      type: tipData.type.value,
      page: tipData.page.value,
      name: tipData.name,
      specificUrl: tipData.specificUrl,
      "pt-BR": {
        description: languageTipData["pt-BR"].description,
      },
      en: {
        description: languageTipData.en.description,
      },
      es: {
        description: languageTipData.es.description,
      },
    };

    const tipSchema = Yup.object().shape({
      type: Yup.string().required(typesErrors.required),
      name: Yup.string().required(typesErrors.required),
      role: Yup.string().when("type", () => {
        return tipData.type.value === "specific"
          ? Yup.string().required(typesErrors.required)
          : Yup.string();
      }),
      page: Yup.string().when("role", () => {
        return tipData.role.value !== ""
          ? Yup.string().required(typesErrors.required)
          : Yup.string();
      }),
      specificUrl: Yup.string().when("page", () => {
        return (tipData.page.value as string).includes(":")
          ? Yup.string().required(typesErrors.required)
          : Yup.string();
      }),
      "pt-BR": Yup.object().shape({
        description: Yup.string().required(typesErrors.required),
      }),
      en: Yup.object().shape({
        description: Yup.string().required(typesErrors.required),
      }),
      es: Yup.object().shape({
        description: Yup.string().required(typesErrors.required),
      }),
    });

    const validation = await validateFormFields(body, tipSchema, {
      role: "",
      type: "",
      page: "",
      name: "",
      specificUrl: "",
      "pt-BR": {
        description: "",
      },
      en: {
        description: "",
      },
      es: {
        description: "",
      },
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });
      return;
    }

    setErrors({});
    handleSubmit().then(() => {
      getAllTips();
      clearModalData();
      closeModal();
    });
  }

  async function handleSubmit() {
    setLoading(true);

    try {
      const description = {
        "pt-BR": languageTipData["pt-BR"].description,
        en: languageTipData.en.description,
        es: languageTipData.es.description,
      };

      let formData = new FormData();
      formData.append("name", tipData.name);
      formData.append("description", JSON.stringify(description));
      formData.append("type", tipData.type.value.toString());
      formData.append("code", tipData?.files[0]?.name?.split(".")[0]);
      formData.append("role", tipData.role.value.toString());
      formData.append('gif_time', tipData.gif_time || '5000');
      tipData.files
        .filter((item) => item?.file_data)
        .forEach((file) => formData.append("files", file?.file_data as File));
      if (tipData.type.value !== "specific") {
        tipData.type.value === "global"
          ? formData.append("route", "/dashboard")
          : formData.append("route", "/printing");
      } else {
        if (tipData.specificUrl === "") {
          formData.append("route", tipData.page.value.toString());
        } else {
          if ((tipData.role.value as string).includes(":")) {
            const replaceKey = (tipData.page.value as string)
              .split("/:")[1]
              .split("/")[0];
            formData.append(
              "route",
              (tipData.page.value as string).replace(
                `:${replaceKey}`,
                `${tipData.specificUrl}`
              )
            );
          }
          const replaceKey = (tipData.page.value as string).split("/").pop();
          formData.append(
            "route",
            (tipData.page.value as string).replace(
              `${replaceKey}`,
              `${tipData.specificUrl}`
            )
          );
        }
      }
      if (!isEdition) {
        await api.post("/tips", formData);
        showToast({
          type: "success",
          message: i18n.t("tips.success"),
        });
      } else {
        await api.put(`/tips/${tipForEdition?.id}`, formData);
        showToast({
          type: "success",
          message: "A tip foi atualizado com sucesso",
        });
      }
    } catch (error) {
      isEdition
        ? showToast({
            type: "error",
            message: i18n.t("tips.updateError"),
          })
        : showToast({
            type: "error",
            message: i18n.t("tips.error"),
          });
    } finally {
      setLoading(false);
    }
  }

  function changeTipData(data: Partial<TipData>) {
    setTipData((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  function changeLanguageTipData(
    data: Partial<LanguageValues>,
    language: keyof LanguageTipData
  ) {
    setLanguageTipData((prevState) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        ...data,
      },
    }));
  }

  function clearModalData() {
    setTipData({
      name: "",
      code: "",
      role: {
        optionText: "",
        value: "",
      },
      type: {
        optionText: "",
        value: "",
      },
      page: {
        optionText: "",
        value: "",
      },
      specificUrl: "",
      files: [],
      gif_time: "",
    });
    setLanguageTipData({
      "pt-BR": {
        description: "",
      },
      en: {
        description: "",
      },
      es: {
        description: "",
      },
    });
    setErrors({});
  }

  return (
    <Container>
      <Header>
        <div className="title">{`${
          isEdition ? i18n.t("tips.edit") : i18n.t("tips.create")
        }`}</div>
        <RiCloseLine
          onClick={() => {
            closeModal();
            clearModalData();
          }}
        />
      </Header>

      <Content onSubmit={validateForm}>
        <Tabs tabs={tabsComponents} />

        <Footer>
          <OutlinedButton
            type="submit"
            text={
              isEdition
                ? i18n.t("tips.updateButton")
                : i18n.t("tips.createButton")
            }
            fullWidth={true}
            buttonColor="var(--finished)"
            loading={loading}
          />
        </Footer>
      </Content>
    </Container>
  );
}
