import { Skeleton } from "@mui/material";
import { Container, LeftContent, RightContent } from "./styles";

export function TipsSkeleton() {
  return (
    <Container>
      <LeftContent>
        <Skeleton variant="circular" width={50} height={50} />
        <div>
          <Skeleton variant="text" width={110} height={20} />
          <Skeleton variant="text" width={50} height={18} />
        </div>

        <div>
          <Skeleton variant="text" width={30} height={18} />
          <Skeleton variant="text" width={60} height={20} />
        </div>
        <div>
          <Skeleton variant="text" width={30} height={18} />
          <Skeleton variant="text" width={60} height={20} />
        </div>
      </LeftContent>

      <RightContent>
        <Skeleton variant="text" width={80} height={36} />
      </RightContent>
    </Container>
  );
}
