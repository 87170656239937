import styled from "styled-components";

import global from '../../global';
interface IconsProps{
  selected?: boolean;
  warning?: boolean;
}

export const Container = styled.div`
`;


export const SelectPrinterButton = styled.div<IconsProps>`
  position: relative;
  cursor: pointer;
  height: 25px;
  font-weight: 500;
  font-size: 12px;
  width: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;

  border-radius: 15px;
  border-width: 1px;
  border-style: solid;

  background-color: ${props => props.selected ? (props.warning ?  'var(--warning)' : 'var(--white)') : 'var(--error)'};
  border-color: ${props => props.selected ? 'var(--gray)' : 'var(--error)'};
  color: ${props => props.selected ? 'var(--gray)' : 'var(--white)'};
  
  transition: background-color .2s, border .2s, color .2s;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-right: 10px;
  }  
`;

export const BoxPrinter = styled.div`
    position: absolute;
    right: 0px;
    top: 30px;
    width: max-content;

    border: 1px solid var(--light-gray);
    border-radius: 10px;

    padding: 10px 0px 5px 0;

    background-color: var(--white);
    box-shadow: 4px 3px 8px var(--light-gray);

    list-style-type: none;
    z-index: 5;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  align-items: end;

  font-size: 16px;
  padding: 10px 10px 10px 20px;
  margin: 5px;
  
  border-radius: 10px;

  cursor: pointer;

  transition: background-color .5s;

  svg {
    color: var(--fixit);
    margin-left: 2px;
  }

  :hover {
    background-color: var(--fixit);
    transition: background-color .5s;
    p, svg {
      color: var(--white);
    }
  }
`;

export const ItemText = styled.p`
  margin-right: 5px;
  color: var(--dark-gray);
  user-select: none;
`;


export const ItemTextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemPrimaryText = styled.p`
  margin-right: 5px;
  color: var(--dark-gray);
  user-select: none;
  font-size: 0.8em;
  line-height: 9px;
  text-align: end;
`;

export const ItemSecondaryText = styled.p<IconsProps>`
  margin-top: 5px;
  margin-right: 5px;
  color:  ${props => props.warning ? 'var(--error)' : 'var(--dark-gray)'};
  user-select: none;
  font-size: 0.6em;
  line-height: 9px;
  text-align: end;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(--light-gray);
`;