import { i18n } from "../../../../localization/i18n";

export const paymentType = [
  { optionText: "GCode", value: "gcode" },
  { optionText: "Software", value: "software" },
];

export const paymentMethod = [
  { optionText: "Crédito", value: "credit" },
  { optionText: "Débito", value: "debit" },
  { optionText: "PIX", value: "pix" },
  { optionText: "Boleto", value: "boleto" },
];

export const currencyOptions = [
  { value: "BRL", optionText: "Real" },
  { value: "USD", optionText: "Dólar" },
];

export const currencyLabel = {
  BRL: "Real",
  USD: "Dólar",
};

export const paymentSituationOptions = [
  {
    optionText: "Pago",
    value: "pago",
  },
  {
    optionText: "Aguardando",
    value: "aguardando",
  },
  {
    optionText: "Cancelado",
    value: "cancelado",
  },
  {
    optionText: "Vencido",
    value: "vencido",
  },
  {
    optionText: "Dispute",
    value: "dispute",
  },
  {
    optionText: "Estorno parcial",
    value: "estorno_parcial",
  },
  {
    optionText: "Estorno",
    value: "estorno",
  },
  {
    optionText: "Chargeback",
    value: "chargeback",
  },
  {
    optionText: "Crédito",
    value: "credito",
  },
];

export const nullOption = {
  optionText: i18n.t("global.novalue"),
  value: "",
};

export const paymentMethodLabel = {
  card: "Cartão",
  credit: "Crédito",
  debit: "Débito",
  pix: "PIX",
  boleto: "Boleto",
};

export const paymentSituationLabel = {
  aguardando: "Aguardando",
  estorno: "Estornado",
  cancelado: "Cancelado",
  pago: "Pago",
  vencido: "Vencido",
  dispute: "Dispute",
  estorno_parcial: "Estorno parcial",
  chargeback: "Chargeback",
  credito: "Crédito",
};

export const typeLabel = {
  gcode: "GCode",
  software: "Software",
};
