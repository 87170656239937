import styled, { css } from "styled-components";
import global from "../../../../../../global";

interface CheckboxProps {
  checked?: boolean;
}

export const Container = styled.div`
  position: relative;

  svg {
    cursor: pointer;
    transition: all 0.3s;
  }
`;

export const FilterContent = styled.div`
  position: absolute;
  top: 40px;
  right: 0px;
  width: 450px;
  padding: 20px 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 4px 3px 8px var(--gray);
  z-index: 10;

  .filter_title {
    font-size: 18px;
    color: var(--dark-gray);
    margin-top: 10px;
    margin-bottom: 26px;
  }

  @media (max-width: ${global.breakpoints.mobile}) {
    width: 90vw;
    padding: 20px;
  }
`;

export const InputLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 18px;

  @media (max-width: ${global.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  flex: 1;
`;

export const CheckboxContainer = styled.div<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;

    ${({ checked }) =>
      checked === undefined &&
      css`
        text-decoration: line-through;
      `}
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 18px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
