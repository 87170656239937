import { useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { useOnboard } from "../../hooks/OnboardContext";
import { FaUserEdit } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { SolidButton } from "../SolidButton";
import { UplaodProfileImageModal } from "./components/UploadProfileImageModal";
import { services } from "./services/services";
import {
  Avatar,
  AvatarOptionsContainer,
  Container,
  MainLicenseeData,
  PictureContainer,
  ProfileCard,
  Username,
  UserCard,
  UserDivContent,
  Separator,
  StatusContainer,
  StatusGrayText,
  StatusFixitText,
  StatusDivColumn,
  StatusDivRow,
  UserCardTitle,
  DivColumnLicenseeAndUserCards,
  IconsContainer,
  LicenseeCard,
  EditUserDiv,
} from "./styles";
import { PersonModal } from "../PersonModal";
import { Modal } from "../Modal";
import { IPerson } from "../PersonModal/dtos";
import ChangePasswordPopup from "../ChangePasswordPopup";
import { LicenseeModal } from "../LicenseeModal";
import { UserModal } from "../UserModal";
import Popover from "../Popover";
import { roles } from "../../utils/constants";
import { showToast } from "../CustomToast";
import { i18n } from "../../localization/i18n";

export function UserProfileOptions() {
  const { licensee } = useOnboard();
  const { user, reloadUser } = useAuth();
  const [person, setPerson] = useState<IPerson>();

  const [avatarOpen, setAvatarOpen] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openPersonModal, setOpenPersonModal] = useState(false);
  const [openLicenseeModal, setOpenLicenseeModal] = useState(false);

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  async function getPersonById() {
    const response = await services.getPersonById(user.person.id);
    setPerson(response);
  }

  const togglePersonModal = async () => {
    await getPersonById();
    setOpenPersonModal(!openPersonModal);
  };

  const toggleUserModal = async () => {
    setOpenUserModal(!openUserModal);
  };

  const togglePasswordModal = async () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
  };

  const toggleLicenseeModal = async () => {
    setOpenLicenseeModal(!openLicenseeModal);
  };

  const toggleAvatarModal = () => {
    setAvatarOpen(!avatarOpen);
  };

  return (
    <>
      <UserModal
        open={openUserModal}
        closeModal={toggleUserModal}
        reload={reloadUser}
      />
      <LicenseeModal
        licensee={licensee}
        closeModal={toggleLicenseeModal}
        open={openLicenseeModal}
        getLicensees={reloadUser}
        readOnly={false}
        userVisualization={true}
      />
      <ChangePasswordPopup
        user_id={user.id}
        open={openChangePasswordModal}
        setOpen={togglePasswordModal}
      />
      <Modal open={openPersonModal} onRequestClose={togglePersonModal}>
        <PersonModal
          person_id={user.person.id}
          editPerson={person}
          licensee={licensee}
          closeModal={togglePersonModal}
          onUpdate={reloadUser}
          isEditOpen
          withoutStep
        />
      </Modal>
      <UplaodProfileImageModal
        open={avatarOpen}
        close={toggleAvatarModal}
        setReloadUser={reloadUser}
      />
      <Container>
        <ProfileCard>
          <PictureContainer>
            <Avatar>
              {user.profile_picture_link ? (
                <img
                  alt="user-profile"
                  src={`${process.env.REACT_APP_STORAGE_BASE_URL}${user?.profile_picture_link}`}
                />
              ) : null}
            </Avatar>
            <Username>{user.person.name}</Username>
            <MainLicenseeData>{user.person.expertise}</MainLicenseeData>
          </PictureContainer>
          <AvatarOptionsContainer>
            <SolidButton
              fontSize={12}
              buttonHeight={16}
              text={`${i18n.t("userProfile.profile_picture.register")}`}
              onClick={() => {
                toggleAvatarModal();
              }}
            />
          </AvatarOptionsContainer>
          <Separator />
          <StatusDivRow>
            <Username>{`${i18n.t("userProfile.profile_data")}`}</Username>
            <Popover label={`${i18n.t("userProfile.edit_profile_data")}`}>
              <FaUserEdit
                size={18}
                onClick={() => {
                  togglePersonModal();
                }}
              />
            </Popover>
          </StatusDivRow>
          <StatusContainer>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.personal_data.document"
              )}`}</StatusGrayText>
              <StatusFixitText>{user.person.cpf}</StatusFixitText>
            </StatusDivColumn>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.personal_data.phone"
              )}`}</StatusGrayText>
              <StatusFixitText>{user.person.cellphone}</StatusFixitText>
            </StatusDivColumn>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.personal_data.country"
              )}`}</StatusGrayText>
              <StatusFixitText>
                {services.licenseeCountry(user.person.country)}
              </StatusFixitText>
            </StatusDivColumn>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.personal_data.state"
              )}`}</StatusGrayText>
              <StatusFixitText>{user.person.state}</StatusFixitText>
            </StatusDivColumn>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.personal_data.city"
              )}`}</StatusGrayText>
              <StatusFixitText>{user.person.city}</StatusFixitText>
            </StatusDivColumn>
          </StatusContainer>
        </ProfileCard>

        <DivColumnLicenseeAndUserCards>
          <UserCard>
            <StatusDivRow>
              <UserCardTitle>{`${i18n.t(
                "userProfile.user_data"
              )}`}</UserCardTitle>
              <IconsContainer>
                <Popover label={`${i18n.t("userProfile.edit_user_data")}`}>
                  <FaUserEdit
                    size={18}
                    onClick={() => {
                      toggleUserModal();
                    }}
                  />
                </Popover>
                <Popover label={`${i18n.t("profile.changePassword")}`}>
                  <RiLockPasswordLine
                    size={18}
                    onClick={() => {
                      togglePasswordModal();
                    }}
                  />
                </Popover>
              </IconsContainer>
            </StatusDivRow>
            <UserDivContent>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_user.email"
                )}`}</StatusGrayText>
                <EditUserDiv>
                  <StatusFixitText>{user.email}</StatusFixitText>
                </EditUserDiv>
              </StatusDivColumn>
            </UserDivContent>
            <StatusDivColumn>
              <StatusGrayText>{`${i18n.t(
                "userProfile.data_user.name"
              )}`}</StatusGrayText>
              <EditUserDiv>
                <StatusFixitText>{user.name}</StatusFixitText>
              </EditUserDiv>
            </StatusDivColumn>
          </UserCard>
          <LicenseeCard>
            <StatusDivRow>
              <UserCardTitle>{`${i18n.t(
                "userProfile.licensee_data"
              )}`}</UserCardTitle>
              <IconsContainer>
                <Popover label={`${i18n.t("userProfile.edit_licensee_data")}`}>
                  <FaUserEdit
                    size={18}
                    onClick={() => {
                      user.role !== roles.employee
                        ? toggleLicenseeModal()
                        : showToast({
                            type: "error",
                            message: `${i18n.t("userProfile.no_permission")}`,
                          });
                    }}
                  />
                </Popover>
              </IconsContainer>
            </StatusDivRow>
            <StatusContainer>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.enterprise_name"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {user.person.licensee.companyName}
                </StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.document"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {user.person.licensee.document_number}
                </StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.situation"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {services.licenseeSituation(user.person.licensee.situation)}
                </StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.phone"
                )}`}</StatusGrayText>
                <StatusFixitText>{user.person.licensee.phone}</StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.contract"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {user.person.licensee.contract}
                </StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.printer"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {services.getLicenseePrinters(user.person.licensee)}
                </StatusFixitText>
              </StatusDivColumn>
              <StatusDivColumn>
                <StatusGrayText>{`${i18n.t(
                  "userProfile.data_licensee.fixpoint"
                )}`}</StatusGrayText>
                <StatusFixitText>
                  {user.person.licensee.is_fixpoint ? "Sim" : "Não"}
                </StatusFixitText>
              </StatusDivColumn>
            </StatusContainer>
          </LicenseeCard>
        </DivColumnLicenseeAndUserCards>
      </Container>
    </>
  );
}
