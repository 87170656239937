import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";

import { RiDeleteBin7Line } from "react-icons/ri";

import {
  Container,
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  PrintersForm,
  SelectedPrintersContainer,
  Footer,
  Printer,
} from "./styles";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";

interface IOption {
  optionText: string;
  value: string;
}

interface IFormData {
  user: {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
  };
  manager: string;
  phone: string;
  situation: string;
  instagram: string;
  contract?: {
    value: string | number;
    optionText: string;
  };
}

export default function CreateLicensee() {
  const { setNavigationAction } = useAuth();
  const navigate = useNavigate();
  const situationOptions = [
    { optionText: "Venda efetivada", value: "effected" },
    { optionText: "Em implantação", value: "implementation" },
    { optionText: "Em treinamento", value: "training" },
    { optionText: "Em operação", value: "operational" },
  ];

  const contractOptions = [
    { optionText: "Licença Anual", value: "1.0" },
    { optionText: "Licença Mensal", value: "2.0" },
  ];

  const [data, setData] = useState<IFormData>({
    user: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    manager: "",
    phone: "",
    situation: "",
    instagram: "",
  });
  const [printers, setPrinters] = useState<IOption[]>([]);
  const [selectedPrinters, setSelectedPrinters] = useState([] as any);

  async function handleCreateLicensee() {
    try {
      if (data.user.password !== data.user.password_confirmation) {
        showToast({ type: "error", message: "As senhas não conhecidem" });
        return;
      }

      const body = {
        user: {
          name: data.user.name,
          email: data.user.email,
          password: data.user.password,
          role: "LICENSEE",
        },
        printers: selectedPrinters.map((printer: IOption) => printer.value),
        situation: data.situation,
        manager_name: data.manager,
        phone: data.phone,
        instagram: data.instagram,
        contract: data.contract?.value,
      };

      await api.post("/licensees", body);
      showToast({ type: "success", message: "Licenciado criado com sucesso" });
      navigate(-1);
    } catch (err) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro ao tentar criar um licenciado, tente novamente.",
      });
    }
  }

  async function getPrinters() {
    try {
      const response = await api.get("/printers");

      const mappedPrinters = response.data.map((printer: any) => {
        return {
          value: printer.id,
          optionText: printer.name,
        };
      });

      setPrinters(mappedPrinters);
    } catch (err) {
      showToast({
        type: "error",
        message: "Ocorreu um erro  ao tentar carregar as impressoras.",
      });
    }
  }

  function handleDeletePrinter(index: number) {
    let printers = [...selectedPrinters];

    printers.splice(index, 1);

    setSelectedPrinters(printers);
  }

  useEffect(() => {
    getPrinters();
  }, []);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.licensees.create"),
    });
  }, []);

  return (
    <Container>
      <Form>
        <FormContainer>
          <div className="form_title">Informações do Licenciado</div>

          <FormRow>
            <InputContainer>
              <label>Nome</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      name: event,
                    },
                  }));
                }}
                value={data.user.name}
              />
            </InputContainer>

            <InputContainer>
              <label>E-mail</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      email: event,
                    },
                  }));
                }}
                value={data.user.email}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      password: event,
                    },
                  }));
                }}
                value={data.user.password}
              />
            </InputContainer>

            <InputContainer>
              <label>Confirmação da senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      password_confirmation: event,
                    },
                  }));
                }}
                value={data.user.password_confirmation}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Gerente</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    manager: event,
                  }));
                }}
                value={data.manager}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Telefone</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    phone: event,
                  }));
                }}
                value={data.phone}
              />
            </InputContainer>

            <InputContainer>
              <label>Instagram</label>
              <Input
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    instagram: event,
                  }));
                }}
                value={data.instagram}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Situação</label>
              <Dropdown
                options={situationOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    situation: selected.optionText,
                  }));
                }}
                selectedOption={data.situation}
                placeholder="Selecione uma situação..."
              />
            </InputContainer>

            <InputContainer>
              <label>Contrato</label>
              <Dropdown
                options={contractOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    contract: selected,
                  }));
                }}
                selectedOption={data.contract?.optionText}
                placeholder="Selecione um contrato..."
              />
            </InputContainer>
          </FormRow>

          <PrintersForm>
            <FormRow>
              <InputContainer>
                <label>Impressoras</label>
                <Dropdown
                  options={printers}
                  handleSelect={(selected) => {
                    let data = selectedPrinters;
                    setSelectedPrinters([...data, selected]);
                  }}
                  placeholder="Selecione uma impressora..."
                />
              </InputContainer>
            </FormRow>

            <SelectedPrintersContainer>
              {selectedPrinters.length > 0 && (
                <div className="sub_title">Impressoras selecionadas</div>
              )}
              {selectedPrinters.map((printer: IOption, index: number) => {
                return (
                  <Printer>
                    <span>{printer.optionText}</span>
                    <RiDeleteBin7Line
                      onClick={() => handleDeletePrinter(index)}
                      size={20}
                      color="var(--fixit)"
                    />
                  </Printer>
                );
              })}
            </SelectedPrintersContainer>
          </PrintersForm>

          <Footer>
            <button type="button" onClick={handleCreateLicensee}>
              Salvar
            </button>
          </Footer>
        </FormContainer>
      </Form>
    </Container>
  );
}
