import {
  RiBillLine,
  RiLineChartLine,
  RiMenuLine,
  RiPriceTag2Line,
  RiShoppingCart2Line,
  RiUserStarFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { LinkItem, LinkTitle, RouteButton } from "../styles";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FinancialMenuList = ({ toggleMenu }: MenuListProps) => (
  <>
    <LinkItem>
      <Link to="/financial/dashboard" onClick={toggleMenu}>
        <RouteButton>
          <RiLineChartLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.reports")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
      <Link to="/financial/orders" onClick={toggleMenu}>
        <RouteButton>
          <RiMenuLine size={26} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.requests")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
      <Link to="/financial/licensees" onClick={toggleMenu}>
        <RouteButton>
          <RiUserStarFill size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.licensees")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
      <Link to="/financial/bills" onClick={toggleMenu}>
        <RouteButton>
          <RiBillLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("bills.bills")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
      <Link to="/financial/prices" onClick={toggleMenu}>
        <RouteButton>
          <RiPriceTag2Line size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.prices")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
      <Link to="/financial/plans" onClick={toggleMenu}>
        <RouteButton>
          <RiShoppingCart2Line size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.plans")}`}</LinkTitle>
      </Link>
    </LinkItem>
  </>
);
