import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";
import { roles } from "../../../../../../utils/constants";

import { ClickAwayListener } from "@mui/material";
import { showToast } from "../../../../../../components/CustomToast";

import { FiMoreVertical } from "react-icons/fi";
import api from "../../../../../../services/api";
import { IAddress, IPlans, ISignature, Licensee } from "../../../../dtos";

import {
  Container,
  LeftContent,
  ImageContainer,
  LicenseeInfos,
  RightContent,
  Header,
  MoreOptionsContainer,
  CenterContent,
  ContentInfos,
  MobileContent,
  StatusDesktopContent,
} from "./styles";
import { useAuth } from "../../../../../../hooks/AuthContext";
import { FinancialActions } from "../../../../actions/FinancialActions";
import { AdminActions } from "../../../../actions/AdminActions";
import { SAFActions } from "../../../../actions/SAFActions";

interface LicenseeItemProps {
  onClick?: () => void;
  onDetailsIconClick?: () => void;
  onChangeLicenseeStatus?: () => void;
  licensee: Licensee;
  handleRender: () => void;
  setSelectedAddress?: (address: IAddress) => void;
  toggleAddressModal?: () => void;
}

export function LicenseeItem({
  licensee,
  onClick = () => {},
  onDetailsIconClick = () => {},
  setSelectedAddress = () => {},
  onChangeLicenseeStatus = () => {},
  handleRender = () => {},
  toggleAddressModal = () => {},
}: LicenseeItemProps) {
  const { user } = useAuth();

  const [mobileActionsOpen, setMobileActionsOpen] = useState(false);
  const [signatureOptions, setSignatureOptions] = useState(false);
  const [statusOptions, setStatusOptions] = useState(false);
  const [licenseeSignature, setSignatureLicensee] = useState<ISignature>();
  const [activePlans, setActivePlans] = useState<IPlans[]>([]);
  const [addressOptions, setAddressOptions] = useState(false);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const [contractTypeOptions, setContractTypeOptions] = useState(false);

  const toggleStatusOptions = () => {
    setStatusOptions(!statusOptions);
    setSignatureOptions(false);
    setAddressOptions(false);
    setAdvancedOptions(false);
    setContractTypeOptions(false);
  };

  const togglePlansOptions = () => {
    setSignatureOptions(!signatureOptions);
    setStatusOptions(false);
    setAddressOptions(false);
    setAdvancedOptions(false);
    setContractTypeOptions(false);
  };

  const toggleAddressOptions = () => {
    setAddressOptions(!addressOptions);
    setStatusOptions(false);
    setSignatureOptions(false);
    setAdvancedOptions(false);
    setContractTypeOptions(false);
  };

  const toggleAdvancedOptions = () => {
    setAdvancedOptions(!advancedOptions);
    setStatusOptions(false);
    setSignatureOptions(false);
    setAddressOptions(false);
    setContractTypeOptions(false);
  };

  const toggleContractTypeOptions = () => {
    setContractTypeOptions(!contractTypeOptions);
    setStatusOptions(false);
    setSignatureOptions(false);
    setAddressOptions(false);
    setAdvancedOptions(false);
  };

  async function handleGetLicenseeSignature(id: string) {
    try {
      await api.get(`signatures/get-plan/${id}`).then((response) => {
        setSignatureLicensee(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function getPlans(currency: string, is_active?: boolean) {
    const queryList = [];

    queryList.push(`currency=${currency}`);
    queryList.push(`is_active=${is_active}`);

    try {
      await api.get(`/plans?${queryList.join("&")}`).then((response) => {
        setActivePlans(response.data);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os planos",
      });
    }
  }

  async function handleLicenseeCancellation(churn_date: Date) {
    if (window.confirm("Deseja realizar o distrato?")) {
      const body = {
        churn_date,
      };
      try {
        await api.patch(`/licensees/cancellation/${licensee.id}`, body);
        showToast({
          type: "success",
          message: "Distrato efetuado com sucesso",
        });
        onChangeLicenseeStatus();
      } catch (error) {
        showToast({
          type: "error",
          message: "Não foi possível realizar o distrato do usuário",
        });
      }
    }
  }

  async function handleLicenseeRestore() {
    if (window.confirm("Deseja desfazer o distrato?")) {
      try {
        await api.patch(`/licensees/restore/${licensee.id}`);
        showToast({
          type: "success",
          message: "Distrato desfeito com sucesso",
        });
        onChangeLicenseeStatus();
      } catch (error) {
        showToast({
          type: "error",
          message: "Não foi possível desfazer o distrato do usuário",
        });
      }
    }
  }

  async function handleChangeLiceseeStatus() {
    if (
      window.confirm(
        licensee.is_blocked
          ? "Deseja desbloquear o licenciado?"
          : "Deseja bloquear o licenciado?"
      )
    ) {
      try {
        await api.put(
          `/licensees/${licensee.id}/block?block=${!licensee.is_blocked}`
        );

        showToast({
          type: "success",
          message: licensee.is_blocked
            ? `Licenciado ${licensee.name} ativado com sucesso.`
            : `Licenciado ${licensee.name} bloqueado com sucesso.`,
        });
        onChangeLicenseeStatus();
      } catch (error) {
        showToast({
          type: "error",
          message: licensee.is_blocked
            ? `Ocorreu um erro ao ativar o licenciado ${licensee.name}.`
            : `Ocorreu um erro ao tentar bloquear o licenciado ${licensee.name}.`,
        });
      }
    }
  }

  async function handleChangeLicenseeSituation(id: string, situation: string) {
    try {
      await api.patch(`/licensees/${id}/situation?situation=${situation}`);
      showToast({
        type: "success",
        message: "Situação do licenciado modificada!",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível modificar a situação do licenciado!",
      });
    } finally {
      handleRender();
      setTimeout(toggleStatusOptions, 2000);
    }
  }

  async function handleChangeLicenseePlan(
    licensee_id: string,
    plan_id: string | number
  ) {
    const body = {
      licensee_id: licensee_id,
      plan_id: plan_id,
    };

    try {
      await api.patch(`/signatures/change-plan`, body);
      showToast({
        type: "success",
        message: "Plano do licenciado modificado! Favor ativar.",
      });
      handleRender();
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(togglePlansOptions, 2000);
    }
  }

  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);
      setAddresses(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleActivateSignature(id: string) {
    if (window.confirm("Deseja modificar o status do plano do licenciado?")) {
      try {
        await api
          .put(`/signatures/activate-plan`, {
            licensee_id: id,
          })
          .then((response) => {
            showToast({
              type: "success",
              message: `Licenciado Ativado - Novo Plano: ${response.data?.plan_name}`,
            });
          });
        onChangeLicenseeStatus();
      } catch (error) {
        showToast({
          type: "error",
          message: "Não foi possível modificar o status do licenciado!",
        });
      }
    }
  }

  async function getAddressById(id: string) {
    try {
      const response = await api.get(`/address/${id}`);
      setSelectedAddress(response.data);
      toggleAddressModal();
    } catch (error) {}
  }

  async function handleChangeLicenseeContractType(
    licensee_id: string,
    contract_type: string
  ) {
    try {
      await api.patch(`/licensees/${licensee_id}/${contract_type}`).then(() => {
        showToast({
          type: "success",
          message: "Tipo de contrato alterado com sucesso",
        });
        handleRender();
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel alterar o tipo de contrato do licenciado",
      });
    } finally {
      setTimeout(toggleContractTypeOptions, 2000);
    }
  }

  function renderActions() {
    switch (user.role) {
      case roles.financial:
        return (
          <FinancialActions
            licensee={licensee}
            addresses={addresses}
            licenseeSignature={licenseeSignature}
            activePlans={activePlans}
            addressOptions={addressOptions}
            statusOptions={statusOptions}
            advancedOptions={advancedOptions}
            contractTypeOptions={contractTypeOptions}
            signatureOptions={signatureOptions}
            getPlans={getPlans}
            onDetailsIconClick={onDetailsIconClick}
            handleActivateSignature={handleActivateSignature}
            handleRender={handleRender}
            handleChangeLicenseeSituation={handleChangeLicenseeSituation}
            toggleStatusOptions={toggleStatusOptions}
            togglePlansOptions={togglePlansOptions}
            toggleAddressOptions={toggleAddressOptions}
            toggleAdvancedOptions={toggleAdvancedOptions}
            handleGetLicenseeSignature={handleGetLicenseeSignature}
            handleChangeLicenseePlan={handleChangeLicenseePlan}
            handleBlockLicensee={handleChangeLiceseeStatus}
            handleChurnLicensee={handleLicenseeCancellation}
            handleRestoreLicensee={handleLicenseeRestore}
            handleRenderAddressOptions={getLicenseeAddresses}
            handleRenderAddress={getAddressById}
            handleChangeLicenseeContractType={handleChangeLicenseeContractType}
            toggleContractTypeOptions={toggleContractTypeOptions}
          />
        );
      case roles.saf:
        return (
          <SAFActions
            licensee={licensee}
            licenseeSignature={licenseeSignature}
            activePlans={activePlans}
            statusOptions={statusOptions}
            signatureOptions={signatureOptions}
            getPlans={getPlans}
            handleActivateSignature={handleActivateSignature}
            onDetailsIconClick={onDetailsIconClick}
            handleRender={handleRender}
            handleChangeLicenseeSituation={handleChangeLicenseeSituation}
            toggleStatusOptions={toggleStatusOptions}
            togglePlansOptions={togglePlansOptions}
            handleGetLicenseeSignature={handleGetLicenseeSignature}
          />
        );
      case roles.admin:
        return (
          <AdminActions
            licensee={licensee}
            addresses={addresses}
            licenseeSignature={licenseeSignature}
            activePlans={activePlans}
            addressOptions={addressOptions}
            statusOptions={statusOptions}
            advancedOptions={advancedOptions}
            signatureOptions={signatureOptions}
            getPlans={getPlans}
            onDetailsIconClick={onDetailsIconClick}
            handleActivateSignature={handleActivateSignature}
            handleRender={handleRender}
            handleChangeLicenseeSituation={handleChangeLicenseeSituation}
            toggleStatusOptions={toggleStatusOptions}
            togglePlansOptions={togglePlansOptions}
            toggleAddressOptions={toggleAddressOptions}
            toggleAdvancedOptions={toggleAdvancedOptions}
            handleGetLicenseeSignature={handleGetLicenseeSignature}
            handleChangeLicenseePlan={handleChangeLicenseePlan}
            handleBlockLicensee={handleChangeLiceseeStatus}
            handleChurnLicensee={handleLicenseeCancellation}
            handleRestoreLicensee={handleLicenseeRestore}
            handleRenderAddressOptions={getLicenseeAddresses}
            handleRenderAddress={getAddressById}
          />
        );
    }
  }

  return (
    <>
      <Container
        situation={
          !!licensee.churned_at
            ? "black"
            : licensee.is_blocked
            ? "red"
            : licensee?.situation
        }
        onClick={() => {
          onClick();
        }}
      >
        <Header>
          <LeftContent>
            <ImageContainer>
              <span>{licensee.name?.charAt(0)}</span>
            </ImageContainer>
            <LicenseeInfos
              is_activated={Boolean(licensee?.signature?.is_activated)}
            >
              <span className="licensee_name">{licensee?.name}</span>
              <span className="licensee_location">
                ({licensee.state}) {licensee.city}
              </span>
            </LicenseeInfos>
          </LeftContent>

          <CenterContent>
            <ContentInfos className="document">
              <span className="data_label">
                {`${i18n?.t("global.licensees.field.document_number")}`}
              </span>
              <span className="data_value">{licensee.document_number}</span>
            </ContentInfos>
            <ContentInfos className="manager">
              <span className="data_label">
                {`${i18n?.t("global.licensees.field.manager_name")}`}
              </span>
              <span className="data_value">
                {licensee?.manager_name || licensee?.name}
              </span>
            </ContentInfos>
            <ContentInfos className="phone">
              <span className="data_label">
                {`${i18n?.t("global.licensees.field.phone")}`}
              </span>
              <span className="data_value">{licensee.phone}</span>
            </ContentInfos>
          </CenterContent>

          <RightContent>
            {(user.role === roles.financial || user.role === roles.admin) && (
              <StatusDesktopContent></StatusDesktopContent>
            )}

            <ClickAwayListener
              onClickAway={(event) => {
                setMobileActionsOpen(false);
                event.stopPropagation();
              }}
            >
              <MoreOptionsContainer>
                <FiMoreVertical
                  size={20}
                  color={mobileActionsOpen ? "var(--fixit)" : "var(--primary)"}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMobileActionsOpen((prevState) => !prevState);
                    getLicenseeAddresses(licensee.id);
                    if (mobileActionsOpen) {
                      getLicenseeAddresses(licensee.id);
                    }
                  }}
                />
                {mobileActionsOpen && renderActions()}
              </MoreOptionsContainer>
            </ClickAwayListener>
          </RightContent>
        </Header>
        <MobileContent>
          <LeftContent>
            <ContentInfos>
              <span className="data_value">{licensee.document_number}</span>
            </ContentInfos>
            <ContentInfos>
              <span className="data_value">{licensee.phone}</span>
            </ContentInfos>
          </LeftContent>
        </MobileContent>
      </Container>
    </>
  );
}
