import { useNavigate } from "react-router-dom";
import { roles } from "../../utils/constants";

interface IUser {
    role: string;
    person: {
        licensee: {
            is_blocked: boolean;
            signature: string;
            completed_onboard: boolean;
            situation: string;
        }
    }
}

class Services {    
    async getUserRoleAndRedirect (path: string, user: IUser, user_role: string, planHasExpired: boolean) {
        const block_status = user?.person?.licensee?.is_blocked;
        const completeUserRegistation = !!user?.person?.licensee?.signature;
        const onboard_completed = user?.person?.licensee?.completed_onboard;
        switch (user_role) {
            case roles.licensee:
                if (block_status === true) {
                    return "/payment";
                } else {
                return  "/dashboard";
                }
            case roles.admin:
                return "/admin/dashboard";
            case roles.financial:
                return "/financial/dashboard";
            case "DISABLED":
                return
            case roles.fixiter:
                if (!completeUserRegistation) {
                    return "/fixiter/registration";
                }
                if (
                    !onboard_completed ??
                    user?.person?.licensee?.situation === "onboarding"
                ) {
                    return "/fixiter/onboard";
                }
                if (planHasExpired === true){
                    return "/payment";
                }
                    return "/fixiter/dashboard";
            case roles.creator:
                return "/creators/dashboard";
            case roles.saf:
                return "/saf/dashboard";
            case roles.employee:
                return "/employee/dashboard";
        }
    }
}

export const services = new Services();

