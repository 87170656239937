import styled from "styled-components";
import global from "../../../../../../global";

export const Container = styled.div`
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  transition: all 0.3s;
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px var(--light-gray);
  background: #ccc;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;



export const PlanInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 100px;

  .plan_name {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .plan_price {
    font-size: 14px;
    line-height: 10px;
    color: var(--active-green);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    display: none;
  }
`;

export const StatusDesktopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  
  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    display: none;
  }
`

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.order_type {
    min-width: 100px;
  }

  &.orders_per_month {
    min-width: 100px;
  }

  &.trial_period {
    min-width: 50px;
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }
`;