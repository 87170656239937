import { Container } from "./styles";
import { RiCheckDoubleFill, RiCloseFill, RiEditLine, RiEyeLine, RiSaveLine } from "react-icons/ri";
import { AccordionActionsContainer, AccordionContainer, AccordionContentContainer, CircleButton, InputContainer, InputLine, LinkButton } from "../../styles";
import { AccordionOrder } from "../../../../../components/AccordionOrder";
import { SearchableSelect } from "../../../../../components/SearchableSelect";
import api, { isAxiosCancel } from "../../../../../services/api";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { CustomSolutionForm, IPatientData, ISearchData } from "../../dtos";
import PatientImg from "../../../../../assets/order_steps/patient.svg";
import { FaSearchPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { showToast } from "../../../../../components/CustomToast";
import { i18n } from "../../../../../localization/i18n";
import { AxiosResponse } from "axios";
import { orderTypes } from "../../../../../utils/constants";

interface PatientOrderProps {
  orderType: string;
  patientAccordion: boolean;
  patientEdited: Date;
  patientComplete: boolean;
  patientEditable: boolean;
  patientSearchable: boolean;
  tmpPatientData: IPatientData;
  patientFormErrors: any;
  setTmpPatientData: (value: React.SetStateAction<IPatientData>) => void
  validatePatientForm: (data: IPatientData) => Promise<boolean>;
  setPatientEdited: (value: React.SetStateAction<Date>) => void;
  setPatientAccordion: (value: React.SetStateAction<boolean>) => void;
  setPatientEditable: (value: React.SetStateAction<boolean>) => void;
  setPatientSearchable: (value: React.SetStateAction<boolean>) => void;
  setPatientComplete: (value: React.SetStateAction<boolean>) => void;
  orderData: CustomSolutionForm;
  setOrderData: (value: React.SetStateAction<CustomSolutionForm>) => void;
  handleDiscard?: () => void;
}

export default function PatientOrderSection({
  orderType,
  patientAccordion,
  patientEdited, 
  patientComplete,
  patientEditable,
  patientSearchable,
  patientFormErrors,
  setPatientAccordion,
  setPatientSearchable,
  setPatientEditable,
  setPatientEdited,
  setPatientComplete,
  validatePatientForm,
  tmpPatientData,
  setTmpPatientData,
  orderData,
  setOrderData,
  handleDiscard }: PatientOrderProps) {

  const [patients, setPatients] = useState<ISearchData[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [patientSearchValue, setPatientSearchValue] = useState("");
  const [existingPatient, setExistingPatient] = useState(false)
  const [loading, setLoading] = useState(false);

  function ClearInputs(){
    if (handleDiscard) {
      handleDiscard()
    }
    setExistingPatient(false);
    setPatientEditable(true)
  }

  useEffect(()=>{
    const actualOrder : any = JSON.parse(localStorage.getItem('currentOrder') || "null");
    if(actualOrder?.patient){
      setExistingPatient(true)
    }
  },[])


  async function updatePatientData() {
    setLoading(true);
    const abortController = new AbortController();
    let signal = abortController.signal;  

    try {
      let response : AxiosResponse
      if (tmpPatientData.id) {
        response = await api.patch(`/patients/${tmpPatientData.id}`, tmpPatientData, { signal })
      } else {
        response = await api.post('/patients', tmpPatientData, { signal })
        setTmpPatientData((prevState) => ({
          ...prevState,
          id: response.data.id,
        }))
      }

      setOrderData((prevState) => ({
        ...prevState,
        patient: response.data,
      }));

      setPatientEdited(new Date());
      setPatientEditable(false);
      setPatientSearchable(false);
      setPatientAccordion(false);
      setPatientComplete(true);
    } catch (error) {
      showToast({
        type: "error",
        message:  "Não foi possível salvar os dados do paciente",
      });
    } finally {
      setLoading(false);
    }

    return () => abortController.abort();
  }

  async function searchPatients( signal: AbortSignal ) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(`/patients/search?term=${patientSearchValue}`, { signal })
      setPatients(data.map((p: any) => ({
        id: p.id,
        label: p.name
      })))
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
       }
      showToast({
        type: "error",
        message: i18n.t('orders.patient_section.search.error'),
      });
    } finally {
      setSelectedLoading(false);
    }
  }
  
  function getPatientSubtitle() {
    if (orderData.patient.age && orderData.patient.phone) {
      return `${orderData.patient.age} anos, ${orderData.patient.phone}`;
    }

    return "";
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;  

    if (patientSearchValue != "") {
      searchPatients(signal);
    }

    return () => abortController.abort();
  }, [patientSearchValue]);

  return (
    <Container>
      <AccordionContainer>
        <AccordionOrder
          open={patientAccordion}
          emptyTitle={i18n.t('orders.patient_section.empty_title')}
          emptySubtitle={i18n.t('orders.patient_section.empty_subtitle')}
          title={orderData?.patient.name}
          subTitle={getPatientSubtitle()}
          icon={PatientImg}
          lastUpdate={patientEdited}
          pendingInfos={!patientComplete}
          handleDiscard={handleDiscard}
          handleChange={() => {}}
          toogleComponent={
            <AccordionActionsContainer>
              {
                !patientAccordion ? 
                  (
                    orderData?.patient?.id ?
                      (
                        <CircleButton onClick={() => { 
                          setPatientAccordion(true)}
                        } >
                          <RiEyeLine size={20} color="#989898"/>
                        </CircleButton>
                      ) :
                      (
                        <LinkButton onClick={() => { 
                            setPatientAccordion(true)
                            setPatientSearchable(true)
                          }
                          } >
                          {`${i18n.t('orders.actions.new')}`}
                          <FaSearchPlus size={20} color="#989898"/>
                        </LinkButton>
                      )
                  )
                :
                (
                  <>
                      {
                        !patientEditable && !patientSearchable ? 
                            <>
                              <LinkButton onClick={() => existingPatient ?  ClearInputs() : setPatientEditable(true)} >
                              {existingPatient ? 
                              <>
                                {`${i18n.t('orders.actions.discart')}`}
                                <RiCloseFill size={25} color="#989898" />
                              </>
                              :
                              <>
                                {`${i18n.t('orders.actions.edit')}`}
                                <RiEditLine size={20} color="#989898" />
                              </>
                            }
                              </LinkButton> 
                              <CircleButton >
                                <RiCheckDoubleFill size={25} color="#989898" onClick={() => {
                                  setPatientAccordion(false)
                                  setPatientEditable(false)
                                  setPatientSearchable(false)
                                }} />
                              </CircleButton>
                            </>
                          :
                        <>
                          <LinkButton onClick={() => {
                            validatePatientForm(tmpPatientData).then(valid => {
                              valid && updatePatientData()
                            });
                          }} >
                            {`${i18n.t('orders.actions.save')}`}
                            <RiSaveLine size={20} color="#989898" />
                          </LinkButton>
                          <CircleButton >
                            <RiCloseFill size={25} color="#989898" onClick={() => {
                              setTmpPatientData({...orderData.patient});
                              setPatientAccordion(false)
                              setPatientEditable(false)
                              setPatientSearchable(false)
                            }} />
                          </CircleButton>
                        </>
                      }
                  </>
                )
              }
            </AccordionActionsContainer>
            
          }>
          <AccordionContentContainer>
            <InputLine>
              <InputContainer>
                <SearchableSelect
                  label={`${i18n.t('customSolution.fullName')}`}
                  defaultValue={tmpPatientData.name}
                  readOnly={!patientEditable && !patientSearchable}
                  options={patients}
                  loading={selectLoading}
                  onSelect={(selected) => {
                    if (typeof selected === "string") {
                      setTmpPatientData(() => {
                        return ({
                          name: selected,
                          age: "",
                          months: "",
                          weight: "",
                          height: "",
                          responsible: "",
                          phone: "",
                          email: ""
                        })
                      });
                      return;
                    }

                    const abortController = new AbortController();
                    let signal = abortController.signal;  

                    api.get(`/patients/${selected.id}`, { signal }).then((response) => {
                      setPatientEditable(false);
                      setPatientSearchable(false);
                      setExistingPatient(true);
                      setTmpPatientData(response.data)
                      setOrderData((prevState) => ({
                        ...prevState,
                        patient: response.data,
                      }))
                      validatePatientForm(response.data).then(valid => {
                        if (valid) {
                          setPatientEdited(new Date());
                          setPatientComplete(true);
                        }
                      });
                    })


                    return () => abortController.abort();
                  }}
                  onChange={(value) => {
                    setPatientSearchValue(value);
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      name: value,
                    }));
                  }}
                  error={patientFormErrors?.name}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="age"
                  value={`${tmpPatientData.age}`}
                  label={`${i18n.t('customSolution.age')}`}
                  mask="999"
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      age: event,
                    }));
                  }}
                  error={patientFormErrors.age}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="months"
                  value={`${tmpPatientData.months || ''}`}
                  label={`${i18n.t('customSolution.months')}`}
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      months: event,
                    }));
                  }}
                  onDisabled={() => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      months: "",
                    }));
                  }}
                  disabled={
                    Number(tmpPatientData.age) > 0 || !tmpPatientData.age
                  }
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="weight"
                  value={tmpPatientData.weight}
                  label={`${i18n.t('customSolution.weight')}`}
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      weight: event,
                    }));
                  }}
                  error={patientFormErrors.weight}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="height"
                  value={tmpPatientData.height}
                  label={`${i18n.t('customSolution.height')}`}
                  mask="999"
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      height: event
                    }));
                  }}
                  error={patientFormErrors.height}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="responsible"
                  value={tmpPatientData.responsible}
                  label={`${i18n.t('customSolution.responsibleName')}`}
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      responsible: event,
                    }));
                  }}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer containerWidth="280px">
                <OutlinedInput
                  inputName="patient_cell"
                  value={tmpPatientData.phone}
                  label={`${i18n.t('customSolution.phone')}`}
                  mask={`${i18n.t('customSolution.contactMask')}`}
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      phone: event,
                    }));
                  }}
                  error={patientFormErrors.phone}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="patient_email"
                  value={tmpPatientData.email}
                  label={`${i18n.t('customSolution.email')}`}
                  readOnly={!patientEditable && !patientSearchable}
                  handleChange={(event) => {
                    setTmpPatientData((prevState) => ({
                      ...prevState,
                      email: event,
                    }));
                  }}
                  error={patientFormErrors.email}
                />
              </InputContainer>
            </InputLine>
          </AccordionContentContainer>
        </AccordionOrder>
      </AccordionContainer>
    </Container>
  );
}
