import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { showToast } from "../../../../components/CustomToast";
import { Modal } from "../../../../components/Modal";
import { UploadingFileModal } from "../../components/UploadingFileModal";

import Head from "../../../../components/Head";
import DropZone from "../../../../components/DropZone";
import FileList from "../../../../components/FileList";

import api from "../../../../services/api";

import {
  Container,
  Flex,
  SubContainer,
  FlexContainer,
  Input,
  InputLabel,
  InputContainer,
  FlexButtons,
  Submit,
  Cancel,
  EvaluationWrapper,
  Section,
  Field,
  FilesWrapper,
  LabelLink,
} from "./styles";
import { i18n } from "../../../../localization/i18n";
import { format } from "date-fns";

export interface IOrder {
  id: string;
  incremental_id: number;
  notes: string;
  files: Array<{
    id: string;
    link: string;
  }>;
  patient: {
    name: string;
    weight: string;
    height: string;
    age: number;
    months: number;
  };
  partner: {
    name: string;
  };
  exam: {
    patient_name: string;
    age: string;
    months: string;
    weight: string;
    height: string;
    phone: string;
    email: string;
    measures: string[];
    professional_name: string;
    professional_job: string;
    diagnosis: string;
    prior_use: boolean;
    any_indication: boolean;
    side: string;
    note: string;
  };
  planit: {
    patient_name: string;
    age: string;
    months: string;
    weight: string;
    height: string;
    phone: string;
    email: string;
    doctor_name: string;
    doctor_contact: string;
    body_part: string;
    target: string;
    injury_type: string;
    surgery_date: string;
    files_links: string[];
    any_indication: boolean;
    responsible_name: string;
    note: string;
  };
  solution: {
    name: string;
    link_guide: string;
  };
  licensee: {
    name: string;
  };
  printer: {
    name: string;
  };
}

interface ILocationState {
  state: {
    from: {
      pathname: string;
    };
    order_id: string;
    addFile: boolean;
  }
}

interface IFile {
  print_time: string;
  material_weight: string;
  material_cost: string; 
  type: string;
}

interface IPartConfirmation {
  ETag: string;
  PartNumber: number;
} 

const chunkSize = 1024 * 1024 * 5;

export default function MakeSolution() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({ mode: "all" });

  const location = useLocation() as ILocationState;
  const { order_id, addFile } = location.state || { from: { pathname: "/" } };

  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [openModal, setOpenModal] = useState(false);
  const [canCloseModal, setCanCloseModal] = useState(false);

  const [currentChunkIndex, setCurrentChunkIndex] = useState<number | null>(null);

  const [fileData, setFileData] = useState<IFile>();
  const [UploadId, setUploadId] = useState<string>()
  const [randomFileId, setRandomFileId] = useState<string>()

  useEffect(() => {
    api.get(`/orders/${order_id}`).then((response) => {
      setOrder(response.data);
    });
  }, [order_id]);

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy");
  }

  const mock = [
    {
      title: "Paciente",
      fields: [
        {
          name: "Nome",
          data: order.patient ? order.patient.name : (order.exam
            ? order.exam.patient_name
            : order.planit?.patient_name),
        },
        {
          name: "Idade",
          data: order.patient ? order.patient.age : (order.exam ? order.exam.age : order.planit?.age),
        },
        {
          name: "Meses",
          data: order.patient ? order.patient.months : (order.exam ? order.exam.months : order.planit?.months),
        },
        {
          name: "Peso",
          data: `${order.patient ? order.patient.weight : (order.exam ? order.exam.weight : order.planit?.weight)}kg`,
        },
        {
          name: "Altura",
          data: `${order.patient ? order.patient.height : (order.exam ? order.exam.height : order.planit?.height)}cm`,
        },
      ],
    },
    {
      title: "Avaliação",
      fields: [
        { name: "Diagnóstico", data: order.exam?.diagnosis},
        { name: "Uso previo de órtese", data: (order.exam && order.exam.prior_use != null) && (order.exam.prior_use ? 'Sim': 'Não')},
        { name: "Indicação", data: ( order.exam ? order.exam.any_indication : order.planit?.any_indication ) ? 'Sim': 'Não'},
        { name: "Parte do corpo", data: order.planit?.body_part},
        { name: "Segmentação", data: order.planit?.target},
        { name: "Tipo de lesão", data: order.planit?.injury_type},
        { name: "Data da cirurgia", data: order.planit && formatDate(order.planit.surgery_date)},
        { name: "Observações", data: order.notes},
      ]
    },
  ];

  const [uploadedFile, setUploadedFile] = useState<any>();
  const [partsConfirmation, setPartsConfirmation] = useState<Array<IPartConfirmation>>([]);

  function readAndUploadCurrentChunk() {
    if (currentChunkIndex == null) {
      return
    }

    const reader = new FileReader();
    const file = uploadedFile;
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadChunk(readerEvent: ProgressEvent<FileReader>) {
    if (currentChunkIndex == null || !readerEvent.target || !fileData) {
      return
    }
    setOpenModal(true);

    const file = uploadedFile;
    const filesize = uploadedFile.size;
    const chunks = Math.ceil(filesize / chunkSize) - 1;
    const isLastChunk = currentChunkIndex === chunks;

    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('type', fileData.type);
    params.set("print_time", fileData.print_time);
    params.set("material_weight", fileData.material_weight);
    params.set("material_cost", fileData.material_cost);
    params.set('currentChunkIndex', currentChunkIndex.toString());
    params.set('totalChunks', Math.ceil(file.size / chunkSize).toString());
    if (UploadId) {
      params.set('UploadId', UploadId);  
    }
    if (randomFileId) {
      params.set('randomFileId', randomFileId);  
    }
    if (isLastChunk) {
      params.set('Parts', JSON.stringify(partsConfirmation));
    }
    const headers = {'Content-Type': 'application/octet-stream'};
    api.post(`/files/${order_id}?${params.toString()}`, data, {headers})
      .then(response => {

        setPartsConfirmation((prevState) => [...prevState, { ETag: response.data.ETag, PartNumber: response.data.PartNumber }]);

        if (currentChunkIndex === 0) {
          setUploadId(response.data.UploadId)
          setRandomFileId(response.data.randomFileId)
        }

        if (isLastChunk) {
          setCurrentChunkIndex(null);

          setCanCloseModal(true);
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      }).catch(e => {
        setUploadId(undefined);
        setRandomFileId(undefined);
        setCurrentChunkIndex(null);
        setOpenModal(false);
        setPartsConfirmation([]);
      })
  }

  const handleUpload = (files: File[]) => {
    setUploadedFile(files[0]);
  };

  const handleDeleteFile = (index: number) => {
    setUploadedFile(undefined);
  };

  const submitChunckedForm = async (values: IFile): Promise<void> => {
    if (!uploadedFile) {
      return;
    }

    setCurrentChunkIndex(0);
    setFileData({...values, type: order.planit ? 'planit' : 'custom'});
  }

  useEffect(() => {
    if (currentChunkIndex !== null && fileData) {
      if (currentChunkIndex == 0 && !UploadId && !randomFileId) {
        readAndUploadCurrentChunk()
      } else if (currentChunkIndex > 0 && !!UploadId && !!randomFileId) {
        readAndUploadCurrentChunk()
      }
    }

  }, [currentChunkIndex, fileData, UploadId, randomFileId])

  let measures = [];
  if (order.exam?.measures) {
    for (var i = 0; i < order.exam?.measures.length; i++) {
      measures.push(
        <Field key={`measure_${i}`}>
          <strong>{`Medida ${i + 1}: `}</strong>
          {`${order.exam?.measures[i]}cm`}
        </Field>
      );
    }
  }

  async function cancelMake() {
    if (!order.files.length) {
      await api.patch(`/orders/${order_id}`, {
        status: i18n.t("orders.status.processing.value"),
      });
    }

    navigate({
      pathname: "/creators/orders/",
    });
  }

  function getTitle() {
    if (addFile) {
      return `#${order.incremental_id} - Adicionar arquivo de ${order.solution?.name} para ${order.licensee?.name}`;
    }

    return `#${order.incremental_id} - Produzir ${order.solution?.name} para ${order.licensee?.name}`;
  }

  return (
    <>
      <Modal open={openModal} modalWidth={500}>
        <UploadingFileModal
          percentage={!currentChunkIndex ? '100%' : `${currentChunkIndex ? Math.ceil(((currentChunkIndex ?? 0)/ (Math.ceil(uploadedFile.size / chunkSize) - 1))*100) : 0}%`}
          closeModal={() => setOpenModal(false)}
          filesUploaded={canCloseModal}
        />
      </Modal>
      <Head title={getTitle()} />
      <Container>
        <EvaluationWrapper>
          {(order.exam || order.planit) &&
            mock.map((section, index) => {
              return (
                <Section key={`${index}_${order.id}`}>
                  <h2>{section.title}</h2>
                  {section.fields.map((field, i) => {
                    return field.data != null ? (
                      <Field key={`${i}_${index}_${field.name}`}>
                        <strong>{field.name}:</strong> {field.data}
                      </Field>
                    ) : (
                      ""
                    );
                  })}
                </Section>
              );
            })}
          <Section>
            <h2>Requisitos</h2>
            <Field>
              <strong>Impressora: </strong>
              {order.printer?.name}
            </Field>
            <Field>
              <strong>Lado: </strong>
              {order.exam?.side}
            </Field>
            {measures}
            {order.solution?.link_guide ? (
              <div style={{ marginTop: "12px" }}>
                <LabelLink
                  href={order.solution?.link_guide}
                  target="_blank"
                  rel="noreferrer"
                >
                  Consultar guia de medida
                </LabelLink>
              </div>
            ) : (
              <></>
            )}
          </Section>
        </EvaluationWrapper>

        <form onSubmit={handleSubmit(submitChunckedForm)}>
          <Flex>
            <SubContainer>
              <FlexContainer>
                <InputContainer>
                  <InputLabel>Tempo de impressão</InputLabel>
                  <Input
                    type="text"
                    id="print_time"
                    defaultValue="00 hora(s) 00 minuto(s)"
                    name="print_time"
                    inputWidth="260px"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputContainer>
                <SubContainer>
                  <InputContainer>
                    <InputLabel>Gramas</InputLabel>
                    <Input
                      type="number"
                      id="material_weight"
                      name="material_weight"
                      defaultValue="1.11"
                      step="0.01"
                      min="0.01"
                      inputWidth="150px"
                      ref={register({
                        required: true,
                      })}
                    />
                  </InputContainer>

                  <InputContainer>
                    <InputLabel>Custo</InputLabel>
                    <Input
                      type="number"
                      id="material_cost"
                      name="material_cost"
                      defaultValue="0.11"
                      step="0.01"
                      min="0.01"
                      inputWidth="150px"
                      ref={register({
                        required: true,
                      })}
                    />
                  </InputContainer>
                </SubContainer>
              </FlexContainer>

              <FilesWrapper>
                <DropZone multiple={false} onUpload={handleUpload} />
                {!!uploadedFile && (
                  <FileList
                    files={[uploadedFile]}
                    handleDelete={handleDeleteFile}
                  />
                )}
              </FilesWrapper>
            </SubContainer>
            <FlexButtons>
              <Submit type="submit">Enviar</Submit>
              <Cancel onClick={cancelMake} type="button">
                Cancelar
              </Cancel>
            </FlexButtons>
          </Flex>
        </form>
      </Container>
    </>
  );
}
