import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../../localization/i18n";

import Popover from "../../../components/Popover";
import { ActionsPopup } from "../components/ActionsPopup";
import { showToast } from "../../../components/CustomToast";
import api from "../../../services/api";
import { IOrder } from "../dtos";
import { RiEyeLine, RiMoneyDollarCircleLine, RiMore2Fill } from "react-icons/ri";
import { FiGift } from "react-icons/fi";
import {
  MdPublishedWithChanges,
  MdOutlineMoneyOff,
  MdAttachMoney,
} from "react-icons/md";

import {
  ActionItem,
  ActionsContainer,
  IconButtonContainer,
} from "../components/OrderItem/styles";

interface ActionsProps {
  order: IOrder;
}

export const FinancialActions = ({ order }: ActionsProps) => {
  const navigate = useNavigate();

  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [statusActionsOpen, setStatusActionsOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState(order?.financial_status);

  const actions = [
    {
      text: i18n.t("orders.financial_status.order_status"),
      icon: <MdPublishedWithChanges />,
      action: () => setStatusActionsOpen((prevState) => !prevState),
    },
  ];

  const orderStatusActions = [
    {
      text: i18n.t(`financial.situation.paid`),
      icon: <MdAttachMoney />,
      active: orderStatus === "paid",
      action: () => {
        handleChangeLicenseeStatus("paid");
      },
    },
    {
      text: i18n.t(`financial.situation.not_paid`),
      icon: <MdOutlineMoneyOff />,
      active: orderStatus === "not_paid",
      action: () => {
        handleChangeLicenseeStatus("not_paid");
      },
    },
    {
      text: i18n.t(`financial.situation.courtesy`),
      icon: <FiGift />,
      active: orderStatus === "courtesy",
      action: () => {
        handleChangeLicenseeStatus("courtesy");
      }
    },
    {
      text: i18n.t(`topbar.coinFix.end`),
      icon: <RiMoneyDollarCircleLine />,
      active: orderStatus === "fixcoin_paid",
      action: () => {
        handleChangeLicenseeStatus("fixcoin_paid");
      },
    },
  ];

  async function handleChangeLicenseeStatus(
    status: IOrder["financial_status"]
  ) {
    try {
      await api.put(
        `/orders/${order.id}/financial_status?financial_status=${status}`
      );

      setOrderStatus(status);

      showToast({
        type: "success",
        message: i18n.t("orders.financial_status.success"),
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("orders.financial_status.error"),
      });
    }
  }

  function getFinancialStatusInfos() {
    switch (orderStatus) {
      case "courtesy":
        return {
          label: i18n.t(`financial.situation.courtesy`),
          icon: <FiGift size={18} />,
        };
      case "paid":
        return {
          label: i18n.t(`financial.situation.paid`),
          icon: <MdAttachMoney size={18} />,
        };
      case "not_paid":
        return {
          label: i18n.t(`financial.situation.not_paid`),
          icon: <MdOutlineMoneyOff size={18} />,
        };
      case "fixcoin_paid":
      return {
        label: i18n.t(`topbar.coinFix.end`),
        icon: <RiMoneyDollarCircleLine size={18} />,
      };
    }
  }

  return (
    <ActionsContainer>
      <ActionItem>
        <Popover position="left" label={`${i18n.t("orders.view")}`}>
          <IconButtonContainer>
            <RiEyeLine
              size={18}
              onClick={() => {
                navigate(
                  "/financial/orders/show", {
                    state: {
                      order_id: order.id,
                  },
                  });
                }}
            />
          </IconButtonContainer>
        </Popover>
      </ActionItem>

      <ActionItem>
        <Popover position="left" label={getFinancialStatusInfos().label}>
          <IconButtonContainer>
            {getFinancialStatusInfos().icon}
          </IconButtonContainer>
        </Popover>
      </ActionItem>

      <ActionItem>
        <IconButtonContainer>
          <RiMore2Fill
            size={18}
            onClick={() => setMoreActionsOpen(true)}
            color={moreActionsOpen ? "var(--fixit)" : undefined}
          />

          {moreActionsOpen && (
            <ActionsPopup
              options={actions}
              onClickAway={() => setMoreActionsOpen(false)}
            />
          )}

          {statusActionsOpen && (
            <ActionsPopup
              options={orderStatusActions}
              onClickAway={() => setStatusActionsOpen(false)}
            />
          )}
        </IconButtonContainer>
      </ActionItem>
    </ActionsContainer>
  );
};
