import ReactDOM from "react-dom";
import { ButtonHTMLAttributes } from "react";
import { Button } from "./styles";
import { RiAddFill } from "react-icons/ri";

interface AbsoluteButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  mobile?: boolean;
  absolute: boolean;
  onClick: () => any;
}

export default function AbsoluteButton({
  text,
  mobile,
  absolute,
  onClick,
}: AbsoluteButtonProps) {
  return ReactDOM.createPortal(
    <Button onClick={onClick} absolute={absolute}>
      <RiAddFill />
      <div className="button_text">{text}</div>
    </Button>,
    document.body
  );
}
