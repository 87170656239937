import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import Head from "../../../../components/Head";
import { Loading } from "../../../../components/Loading";

import api from "../../../../services/api";

import { i18n } from "../../../../localization/i18n";

import {
  Container,
  SubContainer,
  ResultContainer,
  ChartArea,
  ResultArea,
  ResultLabel,
  ChartTitle,
  ChartAreaOverlay,
  Soon,
} from "./styles";
import { useAuth } from "../../../../hooks/AuthContext";
import { Modal } from "../../../../components/Modal";

export interface IReport {
  day: number;
  month: number;
  yearByMonth: number[];
  lastSevenDays: number[];
}

export default function Dashboard() {
  const [report, setReport] = useState<IReport>({} as IReport);
  const [language, setLanguage] = useState("pt-br");
  const { setNavigationAction } = useAuth();
  const [open, setIsOpen] = useState(true);

  useEffect(() => {
    api.get(`/reports`).then((response) => {
      setReport(response.data);
    });

    const currentLanguage = localStorage.getItem("i18nextLng");
    if (currentLanguage === null) {
      return;
    } else if (currentLanguage.startsWith("pt")) {
      setLanguage("pt-br");
    } else if (currentLanguage.startsWith("es")) {
      setLanguage("es");
    }
  }, []);

  let lastSevenDaysLabel = [] as string[];

  for (
    let removeDaysQuantity = 1;
    removeDaysQuantity <= 7;
    removeDaysQuantity++
  ) {
    const date = new Date(
      new Date().setDate(new Date().getDate() - removeDaysQuantity)
    ); // Subtracting the date
    const dateFormatted = date.toLocaleDateString(language, {
      day: "2-digit",
      month: "2-digit",
    });
    lastSevenDaysLabel.push(dateFormatted);
  }

  const optionsLastSevenDays = {
    colors: ["#9835D1"],
    labels: lastSevenDaysLabel,
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "var(--gray)",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined,
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false,
        },
      },
    },
    yaxis: {
      show: true,
    },
  };

  const seriesLastSevenDays = [
    { name: "Pedidos", data: report?.lastSevenDays },
  ];

  const optionsYearByMonth = {
    colors: ["#9835D1"],
    labels: [
      i18n.t("dashboard.months.jan"),
      i18n.t("dashboard.months.feb"),
      i18n.t("dashboard.months.mar"),
      i18n.t("dashboard.months.apr"),
      i18n.t("dashboard.months.may"),
      i18n.t("dashboard.months.jun"),
      i18n.t("dashboard.months.jul"),
      i18n.t("dashboard.months.aug"),
      i18n.t("dashboard.months.sep"),
      i18n.t("dashboard.months.oct"),
      i18n.t("dashboard.months.nov"),
      i18n.t("dashboard.months.dec"),
    ],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "var(--gray)",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined,
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false,
        },
      },
    },
    yaxis: {
      show: true,
    },
  };

  const seriesYearByMonth = [{ name: "Pedidos", data: report?.yearByMonth }];

  // NPS chart
  const seriesNps = [44, 55, 41];

  const optionsNps = {
    colors: ["var(--fixit)", "var(--mention-detail)", "var(--notification)"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("dashboard.header")
    });
  }, []);

  const toggleModal = () => {
    setIsOpen(!open);
  }

  return (
    <>
      <Container>
        <SubContainer>
          <ResultContainer>
            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>{`${i18n.t("dashboard.requestsDay")}`}</ChartTitle>
                <ResultLabel>{report?.day}</ResultLabel>
              </ResultArea>
            </ChartArea>

            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>{`${i18n.t("dashboard.requestsMonth")}`}</ChartTitle>
                <ResultLabel>{report?.month}</ResultLabel>
              </ResultArea>
            </ChartArea>
          </ResultContainer>
          <ChartArea maxWidth={360}>
            <ChartTitle>{`${i18n.t("dashboard.requestsSevenDays")}`}</ChartTitle>
            <Chart
              options={optionsLastSevenDays}
              series={seriesLastSevenDays}
              type="area"
              height={160}
            />
          </ChartArea>

          <ChartArea maxWidth={540}>
            <ChartTitle>
              {`${i18n.t("dashboard.requestsYear")}`} {currentYear}
            </ChartTitle>
            <Chart
              options={optionsYearByMonth}
              series={seriesYearByMonth}
              type="bar"
              height={160}
            />
          </ChartArea>
        </SubContainer>

        <SubContainer>
          <ChartArea maxWidth={190}>
            <Soon>{`${i18n.t("dashboard.soon")}`}</Soon>
            <ChartAreaOverlay style={{ opacity: 0.5 }} />
            <ChartTitle>{`${i18n.t("dashboard.nps")}`}</ChartTitle>
            <Chart
              options={optionsNps}
              series={seriesNps}
              type="donut"
              height={196}
            />
          </ChartArea>

          <ChartArea maxWidth={500}>
            <Soon>{`${i18n.t("dashboard.soon")}`}</Soon>
            <ChartAreaOverlay style={{ opacity: 0.5 }} />
            <ChartTitle>
              {`${i18n.t("dashboard.revenueYear")}`} {currentYear}
            </ChartTitle>
            <Chart
              options={optionsYearByMonth}
              series={seriesYearByMonth}
              type="bar"
              height={160}
            />
          </ChartArea>

          <ChartArea maxWidth={360}>
            <Soon>{`${i18n.t("dashboard.soon")}`}</Soon>
            <ChartAreaOverlay style={{ opacity: 0.5 }} />
            <ChartTitle>{`${i18n.t("dashboard.clickSevenDays")}`}</ChartTitle>
            <Chart
              options={optionsLastSevenDays}
              series={seriesLastSevenDays}
              type="area"
              height={160}
            />
          </ChartArea>
        </SubContainer>
      </Container>
    </>
  );
}
