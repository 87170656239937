export const languages = {
    ptBr: {
        description:'Descreva o plano',
        advantages: ['Vantagem 1', 'Vantagem 2'],
        price:0,
    },
    Es: {
        description:'Describir el plan',
        advantages: ['Ventaja 1', 'Ventaja 2'],
        price:0,
    },
    En: {
        description:'Describe the plan',
        advantages: ['Advantage 1', 'Advantage 2'],
        price:0,
    },
}