import { useState } from "react";

import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { Modal } from "../../../../../../components/Modal";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";

import api from "../../../../../../services/api";

import { RiCloseLine } from "react-icons/ri";

import { Container, Header, InputContainer, InputLine, Footer } from "./styles";

interface CreateDiagnosisModalProps {
  open: boolean;
  handleCloseModal: () => void;
  getDiagnosis: (search: string) => Promise<any>;
  changeExamDiagnosis: (diagnosis: any) => void;
}

export function CreateDiagnosisModal({
  open,
  handleCloseModal,
  getDiagnosis,
  changeExamDiagnosis,
}: CreateDiagnosisModalProps) {
  const [name, setName] = useState("");
  const [cid, setCid] = useState("");
  const [loading, setLoading] = useState(false);

  async function createDiagnoses() {
    setLoading(true);
    try {
      const body = {
        name: {
          "pt-BR": name,
          en: name,
          es: name,
        },
        description: {
          "pt-BR": "",
          en: "",
          es: "",
        },
        cid,
        verified: false,
      };

      const response = await api.post("/diagnosis", body);
      const diagnosis = await getDiagnosis("");

      const selectedDiagnosis = diagnosis.find(
        (item: any) => item.id === response.data.id
      );

      changeExamDiagnosis(selectedDiagnosis);
      setName("");
      setCid("");
      handleCloseModal();
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao registrar o diagnóstico.",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal open={open}>
      <Container>
        <Header>
          <span className="title">Registrar Diagnóstico</span>
          <RiCloseLine
            onClick={() => {
              handleCloseModal();
            }}
          />
        </Header>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              inputName="name"
              label="Nome"
              handleChange={(event) => setName(event)}
              value={name}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              inputName="cid"
              label="Cid"
              handleChange={(event) => setCid(event)}
              value={cid}
            />
          </InputContainer>
        </InputLine>

        <Footer>
          <OutlinedButton
            text="Registrar"
            buttonColor="var(--finished)"
            onClick={createDiagnoses}
            loading={loading}
          />
        </Footer>
      </Container>
    </Modal>
  );
}
