import { createContext, useState, useEffect, useContext, Dispatch, SetStateAction, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import api from "../services/api";
import { roles } from "../utils/constants";
import { useAuth } from "./AuthContext";
interface IPrinter {
  name: string;
  optionText: string;
  value: string;
  xyz: string[];
  port?: SerialPort;
  device_vendor_id: string;
  device_product_id: string;
}

interface IPrinterContextState {
  printers: IPrinter[];
  setDefaultPrinter: (printer?: IPrinter) => void;
  defaultPrinter?: IPrinter;
  setPrinting: (printing?: IPrinting) => void;
}

interface IPrinting {
  id: string;
  progress: number;
  sync: boolean;
}

const PrinterContext = createContext({} as IPrinterContextState)

const PrinterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [printers, setPrinters] = useState<IPrinter[]>([]);
    const [defaultPrinter, setDefaultPrinter] = useState<IPrinter>();
    const { user } = useAuth();

    const [printing, setPrinting] = useState<IPrinting>();
    const location = useLocation();
    
    const submitProgress = (data: IPrinting) => {
      api.put(`/printings/${data?.id}/progress`, {
        progress: data.progress.toFixed(0)
      }).then(() => {
        localStorage.setItem("printing", JSON.stringify({...data, sync: true}))
      })
      .catch((err) => {
        console.log(err);
      })
    }

    useEffect(()=>{
      if (printing?.id) {
        localStorage.setItem("printing", JSON.stringify(printing))
        if (printing.sync === false) {
          try {
            submitProgress(printing)
          } catch (err) {
            console.log(err);
          }
        }
      }
    }, [printing])

    useEffect(() => {
      const storedPrinting =
        JSON.parse(localStorage.getItem("printing") ?? "null")
      if (storedPrinting?.id) {
        if (!storedPrinting.sync) {
          setPrinting(storedPrinting)
        } else if (storedPrinting.progress > 95) {
          localStorage.removeItem("printing")
        }
      }
    }, [location])

    useEffect(()=>{
      if ((user?.role === roles.licensee) || (user?.role ===roles.employee)) {
        api.get(`/licensees/user`)
        .then(
          (response) => {
            if (response.data.printers) {
              const printerList = response.data.printers.map((printer : any) => {
                return{
                  name: printer.name,
                  optionText: printer.name, 
                  value: printer.id,
                  xyz: printer.xyz,
                  device_vendor_id: printer.device_vendor_id,
                  device_product_id: printer.device_product_id,
                }
              })
              setPrinters(printerList)
            }
          })
        } else if (user?.role === roles.creator || user?.role === roles.admin) {
          api.get(`/printers`).then(
            (response) => {
              if (response.data) {
                const printerList = response.data.map((printer : any) => {
                  return{
                    name: printer.name,
                    optionText: printer.name, 
                    value: printer.id,
                    xyz: printer.xyz,
                    device_vendor_id: printer.device_vendor_id,
                    device_product_id: printer.device_product_id,
                  }
                })
                setPrinters(printerList)
              }
            })
        } else {
          setPrinters([])
          setDefaultPrinter(undefined)
        }
      }, [user])

    useEffect(()=>{
      if (!!printers?.length) {
        const defaultPrinterId = localStorage.getItem('defaultPrinter')
        if (defaultPrinterId) {
          setDefaultPrinter(printers.find((printer: any) => printer.value === defaultPrinterId))
        }
      }
    }, [printers])

    return(
        <PrinterContext.Provider value ={ {
            printers,
            setDefaultPrinter: (printer?: IPrinter) => {
              setDefaultPrinter(printer)
              if (!printer) {
                localStorage.removeItem('defaultPrinter')
                localStorage.removeItem('printerConfigSign')
                return;
              }
              localStorage.setItem('defaultPrinter', printer.value)
            },
            defaultPrinter,
            setPrinting
          } }>
            {children}
        </PrinterContext.Provider>
    )
}

function usePrinters(){

    const context = useContext(PrinterContext);
  
    if (!context) {
      throw new Error("usePrinters must be used within an AuthProvider");
    }
  
    return context;
  }

export {usePrinters, PrinterProvider}