import { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import ReactLoading from "react-loading";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { showToast } from "../../../../components/CustomToast";
import { Modal } from "../../../../components/Modal";
import { Bill, useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { IconContainer } from "../../../Licensee/styles";
import { Footer, LoadMore, Tag } from "../../../Orders/styles";
import { BillItem } from "./components/BillItem";
import { CreateBillForm } from "./components/CreateBillForm";
import { EditBillForm } from "./components/EditBillForm";
import { Filter } from "./components/Filter";
import { IFilter } from "./dtos";
import {
  Container,
  FilterContainer,
  Form,
  Header,
  ListContainer,
  Legend,
  PaidColor,
  CanceledColor,
  WaitingColor,
  ContentContainer,
  ReversalColor,
  TagsContainer,
} from "./styles";

export default function Bills() {
  const { setNavigationAction } = useAuth();
  const [bills, setBills] = useState<Bill[]>([]);
  const [filter, setFilter] = useState<IFilter>({
    referring_period: undefined,
  });
  const [openLegend, setOpenLegend] = useState(false);
  const [openCreateBillModal, setOpenCreateBillModal] = useState(false);

  const [openEditBillModal, setOpenEditBillModal] = useState(false);
  const [billToEdit, setBillToEdit] = useState<Bill>();

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  const [loading, setLoading] = useState(false);

  function loadMore() {
    setPage(page + 1);
  }

  async function GetBills(reload: boolean) {
    setLoading(true);

    const queryList = [];

    filter?.licensee && queryList.push(`licensee_id=${filter.licensee.id}`);

    filter?.paymentMethod &&
      queryList.push(`payment_method=${filter.paymentMethod.value}`);

    filter?.recurrency && queryList.push(`is_recurrence=${filter.recurrency}`);

    filter?.referring_period !== undefined &&
      queryList.push(`referring_period=${filter.referring_period}`);

    filter?.situation &&
      queryList.push(`payment_situation=${filter.situation.value}`);

    filter?.type && queryList.push(`type=${filter.type.value}`);

    queryList.push(`limit=${limit}`);

    !reload && queryList.push(`page=${page}`);

    try {
      await api.get(`bills/all?${queryList.join("&")}`).then((response) => {
        setBills(
          reload ? response.data.data : [...bills, ...response.data.data]
        );
        setTotalPage(response.data.total / limit);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as faturas",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!!filter) {
      setPage(1);
      GetBills(true);
    }
  }, [filter]);

  useEffect(() => {
    if (page > 1) {
      GetBills(false);
    }
  }, [page]);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("bills.bills"),
    });
  }, []);

  return (
    <Container>
      <Modal
        open={openCreateBillModal}
        onRequestClose={() => setOpenCreateBillModal(false)}
        modalWidth={450}
      >
        <CreateBillForm
          onHandleCreate={() => {
            GetBills(true);
            setOpenCreateBillModal(false);
          }}
        />
      </Modal>
      <Modal
        open={openEditBillModal}
        onRequestClose={() => setOpenEditBillModal(false)}
        modalWidth={450}
      >
        <EditBillForm
          onHandleEdit={() => {
            GetBills(true);
            setOpenEditBillModal(false);
          }}
          bill={billToEdit}
        />
      </Modal>
      <Legend active={openLegend}>
        <ContentContainer>
          <PaidColor />
          <span>Pago</span>
        </ContentContainer>
        <ContentContainer>
          <WaitingColor />
          <span>Aguardando</span>
        </ContentContainer>
        <ContentContainer>
          <ReversalColor />
          <span>Estornado</span>
        </ContentContainer>
        <ContentContainer>
          <CanceledColor />
          <span>Cancelado</span>
        </ContentContainer>
      </Legend>
      <Header>
        <Form>
          <TagsContainer>
            {filter?.referring_period && (
              <Tag key="referring_period">
                <span>
                  {/* {`${i18n.t(
                    `orders.status.${filter?.status.value}.optionText`
                  )}`} */}
                  {filter.referring_period}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      referring_period: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.paymentMethod && (
              <Tag key="payment_method">
                <span>
                  {/* {`${i18n.t(
                    `orders.status.${filter?.status.value}.optionText`
                  )}`} */}
                  {filter.paymentMethod.optionText}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      paymentMethod: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.licensee?.name && (
              <Tag key="licensee">
                <span>
                  {/* {`${i18n.t(
                    `orders.status.${filter?.status.value}.optionText`
                  )}`} */}
                  {filter.licensee?.name}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      licensee: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.recurrency && (
              <Tag key="recurrency">
                <span>{filter.recurrency && "Recorrente"}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      recurrency: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.situation && (
              <Tag key="situation">
                <span>{filter.situation.optionText}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      situation: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.type && (
              <Tag key="type">
                <span>{filter.type.optionText}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      type: undefined,
                    }))
                  }
                />
              </Tag>
            )}
          </TagsContainer>
          <FilterContainer>
            <IconContainer
              onMouseEnter={(event) => {
                setOpenLegend(true);
              }}
              onMouseLeave={(event) => {
                setOpenLegend(false);
              }}
            >
              <FiInfo />
            </IconContainer>
            <Filter
              onChange={(filterData) => {
                setFilter(filterData);
              }}
              filter={filter}
            />
          </FilterContainer>
        </Form>
      </Header>
      <ListContainer>
        {bills?.map((bill) => {
          return (
            <BillItem
              bill={bill}
              handleEdit={() => {
                setBillToEdit(bill);
                setOpenEditBillModal(true);
              }}
            />
          );
        })}
      </ListContainer>
      <AbsoluteButton
        absolute={true}
        onClick={() => setOpenCreateBillModal(true)}
      />
      <Footer>
        {loading ? (
          <ReactLoading
            type={"bubbles"}
            color={"var(--fixit)"}
            height={64}
            width={64}
          />
        ) : page < totalPage ? (
          <LoadMore onClick={loadMore}>Carregar mais...</LoadMore>
        ) : (
          ""
        )}
      </Footer>
    </Container>
  );
}
