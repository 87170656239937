import { useEffect, useState } from "react";
import validateFormFields from "../../utils/validateFormFields";

import { i18n } from "../../localization/i18n";
import * as Yup from "yup";
import { OutlinedInput } from "../OutlinedInput";
import { OutlinedSelect } from "../OutlinedSelect";
import { showToast } from "../CustomToast";
import { OutlinedButton } from "../OutlinedButton";
import { SolidButton } from "../SolidButton";

import { countries } from "../../utils/countries";

import api from "../../services/api";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  Text,
  InputNear,
} from "./styles";
import { SearchableSelect } from "../SearchableSelect";
import { ProfileTypes } from "./mocks";
import { IFormData, IPerson, Licensee, User } from "./dtos";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";
import { profileTypes } from "../../utils/constants";

interface PersonModalProps {
  licensee: Licensee;
  withoutStep?: boolean;
  editPerson?: IPerson;
  isEditOpen?: boolean;
  person_id?: string;
  readOnly?: boolean;
  setPerson?: (person: IPerson) => void;
  closeModal: () => void;
  getLicenseePersons?: (id: string) => void;
  nextStep?: () => void;
  onUpdate?: () => void;
  onCreate?: () => void;
}

export function PersonModal({
  person_id,
  withoutStep,
  licensee,
  isEditOpen,
  editPerson,
  readOnly,
  setPerson = () => {},
  closeModal,
  nextStep = () => {},
  getLicenseePersons = () => {},
  onUpdate = () => {},
  onCreate = () => {},
}: PersonModalProps) {
  const [errors, setErrors] = useState({} as any);

  const formattedProfileTypes = ProfileTypes.map((profile) => ({
    optionText: profile.label,
    value: profile.id,
  }));

  const formattedCountries = countries.map((country) => ({
    label: country.label,
    id: country.code,
  }));

  const [data, setData] = useState<IFormData>({
    name: "",
    cpf: "",
    cellphone: "",
    expertise: "",
    country: {
      id: "",
      label: "",
    },
    profile: formattedProfileTypes.find(
      (t) => t.value === profileTypes.admin
    ) ?? {
      optionText: "",
      value: "",
    },
    state: "",
    city: "",
  });

  const [country, setCountry] = useState("");

  useEffect(() => {
    if(licensee){
      const editCountry = formattedCountries.find((p) => p.id === licensee.country);

      editCountry && setCountry(editCountry.label);

      countries.map((item) => {
        if (item.code === licensee.country) {
          setData(() => ({
            ...data,
            city: licensee.city,
            state: licensee.state,
            country: {
              id: editCountry ? editCountry?.id : "",
              label: editCountry ? editCountry?.label : "",
            }
          }))
        }
      });

      getLicenseePersons(licensee.id);
    }
  }, [licensee]);

  useEffect(() => {
    if (isEditOpen && editPerson){

      const editCountry = formattedCountries.find((p) => p.id === editPerson.country);
      editCountry && setCountry(editCountry.label);

      setData({
        ...data,
        name: editPerson.name,
        cpf: editPerson.cpf,
        cellphone: editPerson.cellphone,
        expertise: editPerson.expertise,
        city: editPerson.city,
        state: editPerson.state,
        country: formattedCountries.find(
          (t) => t.id === editPerson.country
        ) ?? {
          label: "",
          id: "",
        },
      })
    }
  
  }, [isEditOpen, editPerson])

  async function handleUpdatePerson(person_id: string) {
    
    try {
      const body = {
        licensee_id: licensee.id,
        name: data.name,
        cpf: data.cpf,
        cellphone: data.cellphone,
        expertise: data.expertise,
        city: data.city,
        state: data.state,
        country: data.country.id,
      };

      const yup_validation = Yup.object().shape({
        name: Yup.string().required(typesErrors.required),
        cpf: Yup.string().required(typesErrors.required),
        cellphone: Yup.string().required(typesErrors.required),
        expertise: Yup.string().required(typesErrors.required),
        city: Yup.string().required(typesErrors.required),
        state: Yup.string().required(typesErrors.required),
        country: Yup.string().required(typesErrors.required),
      });
  
      const validation = await validateFormFields(body, yup_validation, {
        expertise: "",
        cep: "",
        cellphone: "",
        address: "",
        number: "",
        state: "",
        city: "",
        country: "",
      });
  
      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });
  
        return;
      }
      
      await api.put(`/person/update/${person_id}`, body);
      
      showToast({ 
        type: 'success',
        message: `${i18n.t('userProfile.personal_data.sucess')}`,
      })
      
      setErrors({});
      nextStep();
      clearDataState();
      onUpdate();

    } catch (error) {
      console.log(error);
    } 
  }

  async function handleCreatePerson() {
    try {
      const body = {
        ...data,
        licensee: licensee?.id,
        name: data.name,
        cpf: data.cpf,
        cellphone: data.cellphone,
        expertise: data.expertise,
        city: data.city,
        state: data.state,
        country: data.country.id,
        termsAccepted: true,
      };

      const yup_validation = Yup.object().shape({
        name: Yup.string().required(typesErrors.required),
        cpf: Yup.string().required(typesErrors.required),
        cellphone: Yup.string().required(typesErrors.required),
        expertise: Yup.string().required(typesErrors.required),
        city: Yup.string().required(typesErrors.required),
        state: Yup.string().required(typesErrors.required),
        country: Yup.string().required(typesErrors.required),
      });
  
      const validation = await validateFormFields(body, yup_validation, {
        expertise: "",
        cep: "",
        cellphone: "",
        address: "",
        number: "",
        state: "",
        city: "",
        country: "",
      });
  
      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });
  
        return;
      }
      
      setErrors({});
      const response = await api.post(`/person`, body);
      setPerson(response.data);
      nextStep();
      clearDataState();
      onCreate()

    } catch (err: any) {
      showToast({
        type: "error",
        message: i18n.t("person.error.user_registration_error"),
      });
    }
  }

  function clearDataState() {
    setData({
      name: "",
      cpf: "",
      cellphone: "",
      expertise: "",
      country: {
        id: "",
        label: "",
      },
      profile: {
        value: "",
        optionText: "",
      },
      state: "",
      city: "",
    });
  }

    return (
    <Container>
    <Header>
      <span>
        { readOnly ? (`${i18n.t("person.person_data")}`) :(isEditOpen ? (`${i18n.t("person.edit_personal_data")}`) : (`${i18n.t("person.create_person")}`))}
      </span>
    </Header>

    <FormContainer>
      <InputLine>
        <InputContainer>
          <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.name")}`}
            inputName="person_name"
            handleChange={(event) =>
              setData((prevState) => ({
                ...prevState,
                name: event,
              }))
            }
            value={data.name || ""}
            error={errors?.name}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.cpf")}`}
            inputName="cpf"
            handleChange={(event) =>
              setData((prevState) => ({
                ...prevState,
                cpf: event,
              }))
            }
            value={data.cpf || ""}
            error={errors?.cpf}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.cellphone")}`}
            inputName="cellphone"
            handleChange={(event: any) =>
              setData((prevState) => ({
                ...prevState,
                cellphone: event,
              }))
            }
            value={ data.cellphone || "" }
            error={errors?.cellphone}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
        <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.expertise")}`}
            inputName="expertise"
            handleChange={(event: any) =>
              setData((prevState) => ({
                ...prevState,
                expertise: event,
              }))
            }
            value={ data.expertise || "" }
            error={errors?.expertise}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.city")}`}
            inputName="city"
            handleChange={(event) =>
              setData((prevState) => ({
                ...prevState,
                city: event,
              }))
            }
            value={data?.city}
            defaultValue={licensee?.city || ""}
            error={errors?.city}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            disabled={readOnly}
            label={`${i18n.t("person.state")}`}
            inputName="state"
            handleChange={(event) =>
              setData((prevState) => ({
                ...prevState,
                state: event,
              }))
            }
            value={data?.state}
            defaultValue={licensee?.state || ""}
            error={errors?.state}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
        <SearchableSelect
          label={`${i18n.t("person.country")}`}
          options={formattedCountries}
          onChange={(value: any) => setCountry(value)}
          onSelect={(selected) => {
            if (typeof selected === "string" || !selected?.id) {
              setData((prevState: IFormData) => ({
                ...prevState,
                country: {
                  id: '',
                  label: '',
                },
              }));

              return;
            }
            setData((prevState) => ({
              ...prevState,
              country: selected,
            }));
          }}
          defaultValue={ country ?? '' }
          error={errors?.country}
        />
        </InputContainer>
      </InputLine>
    </FormContainer>

      <Footer>
        { readOnly ? 
          <OutlinedButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.close")}`}
            onClick={() => {
              clearDataState();
              closeModal();
            }}
          />
        : 
          <OutlinedButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.cancel")}`}
            onClick={() => {
              clearDataState();
              closeModal();
            }}
        />
        }
        { readOnly ? null :
          isEditOpen ? (
            <SolidButton
              buttonWidth={200}
              text={`${i18n.t("person.save")}`}
              onClick={async () => {
                person_id && await handleUpdatePerson(person_id);
                closeModal();
              }}
            />
          ) :
          (
            <SolidButton
            buttonWidth={200}
            text={withoutStep ?`${i18n.t("person.save")}` : `${i18n.t("person.create_person")}`}
            onClick={async () => {
              await handleCreatePerson();
              withoutStep && closeModal();
            }}
            />
          )
        }
      </Footer>
    </Container>
    )
}
