import { useState, useEffect } from "react";
import { Modal } from "../../../../components/Modal";

import { EditPrice } from "./components/EditPrice";
import { PriceItem } from "./components/PriceItem";

import api from "../../../../services/api";
import { showToast } from "../../../../components/CustomToast";

import {
  Container,
  Header,
  ListContainer,
  Form,
  TagsContainer,
  FilterContainer,
  Tag,
} from "./styles";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { CreatePrice } from "./components/CreatePrice";
import { RiCloseFill } from "react-icons/ri";
import { Filter } from "./components/Filter";
import { IFilter, IPrice } from "./dtos";

export function Prices() {
  const { setNavigationAction } = useAuth();
  const [loading, setLoading] = useState(true);
  const [priceInfosModal, setPriceInfosModal] = useState(false);
  const [priceCreateModal, setPriceCreateModal] = useState(false);
  const [prices, setPrices] = useState<IPrice[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<IPrice>();

  const [filter, setFilter] = useState<IFilter>();

  async function getPrices() {
    setLoading(true);

    const queryList = [];
    filter?.is_active !== undefined &&
      queryList.push(`is_active=${filter.is_active}`);
    filter?.country && queryList.push(`country=${filter.country.value}`);
    filter?.order_type &&
      queryList.push(`order_type=${filter.order_type.value}`);
    filter?.solution_type &&
      queryList.push(`solution_type=${filter.solution_type.value}`);
    filter?.solution && queryList.push(`solution_id=${filter.solution.id}`);

    console.log(queryList);

    try {
      const response = await api.get(`/prices?${queryList.join("&")}`);
      setPrices(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os preços",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPrices();
  }, [filter]);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("financial.prices.header"),
    });
  }, []);

  return (
    <Container>
      {selectedPrice && (
        <Modal inheritOverflow open={priceInfosModal}>
          <EditPrice
            price={selectedPrice}
            closeModal={(updatedPrice?: IPrice) => {
              if (updatedPrice) {
                setPrices((prevState) => {
                  return prevState.map((p) => {
                    if (p.id === updatedPrice.id) {
                      return updatedPrice;
                    }
                    return p;
                  });
                });
              }
              setSelectedPrice(undefined);
              setPriceInfosModal(false);
            }}
          />
        </Modal>
      )}

      <Modal inheritOverflow open={priceCreateModal}>
        <CreatePrice
          closeModal={async () => {
            await getPrices();
            setPriceCreateModal(false);
          }}
        />
      </Modal>

      <Header>
        <AbsoluteButton
          absolute={false}
          onClick={() => setPriceCreateModal(true)}
        />
        <div></div>
        <Form>
          <TagsContainer>
            {filter?.is_active !== undefined && (
              <Tag key="is_active">
                <span>
                  {filter?.is_active
                    ? `${i18n.t("global.status.active")}`
                    : filter && filter.is_active === false
                    ? `${i18n.t("global.status.deactive")}`
                    : `${i18n.t("global.status.registered")}`}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      is_active: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.solution && (
              <Tag key="solution">
                <span>{filter.solution.name}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      solution: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.order_type && (
              <Tag key="order_type">
                <span>
                  {`${i18n.t(
                    `financial.prices.order_type.${filter?.order_type.value}`
                  )}`}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      order_type: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.solution_type && (
              <Tag key="solution_type">
                <span>
                  {`${i18n.t(
                    `financial.prices.solution_type.${filter?.solution_type.value}`
                  )}`}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      solution_type: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.country && (
              <Tag key="country">
                <span>{filter?.country.value}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      country: undefined,
                    }))
                  }
                />
              </Tag>
            )}
          </TagsContainer>
          <FilterContainer>
            <Filter
              onChange={(filterData) => {
                setFilter(filterData);
              }}
              filter={filter}
            />
          </FilterContainer>
        </Form>
      </Header>

      <ListContainer>
        {prices.map((price) => {
          return (
            <PriceItem
              onUpdate={(updatedPrice) => {
                setPrices((prevState) => {
                  return prevState.map((p) => {
                    if (p.id === updatedPrice.id) {
                      return updatedPrice;
                    }

                    return p;
                  });
                });
              }}
              key={price.id}
              price={price}
              onEditIconClick={() => {
                console.log(price);
                setSelectedPrice(price);
                setPriceInfosModal(true);
              }}
            />
          );
        })}
      </ListContainer>
    </Container>
  );
}
