import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import ReactDynamicFont from "react-dynamic-font";
import { BiCopy } from "react-icons/bi";
import { Bill, useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";
import { IAddress, Licensee } from "../../modules/Licensee/dtos";
import api from "../../services/api";
import { AddressModal } from "../AddressModal";
import { showToast } from "../CustomToast";
import { OutlinedButton } from "../OutlinedButton";
import { OutlinedSelect } from "../OutlinedSelect";
import { SolidButton } from "../SolidButton";
import { Separator } from "../Topbar/styles";
import { CreatePaymentInfosModal } from "../UsersProfile/components/PaymentInfos/components/CreatePaymentInfosModal";
import * as Yup from "yup";

import {
  Container,
  Footer,
  Header,
  Label,
  CopyPaste,
  QrCodeContainer,
  RadioContent,
  TotalValue,
  SentToMailTextContainer,
  DueDateInformation,
} from "./styles";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../utils/validateFormFields";
import { currencyValue } from "../UsersProfile/mock";

interface ICard {
  card_last_four_digits?: string;
  card_flag?: string;
  id?: string;
}
interface IOptions {
  optionText: string;
  value: string;
}

interface IPaymentDetails {
  onHandleCancel: () => void;
  totalBillValue: string | number;
  reloadUser: () => void;
  closure_date: Date;
  bill?: Bill;
  currency: string;
}

export function BillPaymentDetails({
  onHandleCancel,
  totalBillValue,
  reloadUser,
  closure_date,
  bill,
  currency,
}: IPaymentDetails) {
  const { user } = useAuth();
  const [errors, setErrors] = useState({} as any);

  const [loading, setLoading] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState<IOptions[]>([]);
  const [userAddresses, setUserAddresses] = useState<IOptions[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IOptions>();
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<IOptions>();
  const [needCardConfirmation, setNeedCardConfirmation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [pixQrCodeUrl, setPixQrCodeUrl] = useState();
  const [pixQrCodeKey, setPixQrCodeKey] = useState();
  const [boletoUrl, setBoletoUrl] = useState();
  const [boletoKey, setBoletoKey] = useState();

  async function payBill() {
    setLoading(true);
    let cipherInfo = {
      card_holder: "",
      encryptedNumber: "",
      encryptedValidationDate: "",
      encryptedSecurityCode: "",
    };

    if (
      ["card"].includes(paymentMethod) &&
      localStorage.getItem(
        `${selectedPaymentCard?.optionText.slice(
          selectedPaymentCard.optionText.length - 4
        )}`
      )
    ) {
      cipherInfo = JSON.parse(
        localStorage.getItem(
          `${selectedPaymentCard?.optionText.slice(
            selectedPaymentCard.optionText.length - 4
          )}`
        ) ?? ""
      );
    }

    try {
      const body = {
        bill_id: bill ? bill.id : null,
        type: "gcode",
        payment_method: paymentMethod,
        bill_value: totalBillValue,
        referring_period: `${new Date(closure_date).getMonth() + 1}/${new Date(
          closure_date
        ).getFullYear()}`,
        closure_date,
        payment_date: ["card"].includes(paymentMethod) ? new Date() : null,
        is_recurrence: false,
        licensee_id: user.person.licensee.id,
        currency,
        card_id: selectedPaymentCard?.value,
        address_id: selectedAddress?.value,
        encrypted_information: {
          name: cipherInfo.card_holder ?? "",
          number: cipherInfo.encryptedNumber ?? "",
          validationDate: cipherInfo.encryptedValidationDate ?? "",
          securityCode: cipherInfo.encryptedSecurityCode ?? "",
        },
      };

      const yup_validation = Yup.object().shape({
        payment_method: Yup.string(),
        address_id: Yup.string().required(typesErrors.required),
        card_id: Yup.string().when("payment_method", {
          is: "card",
          then: (field) => field.required(typesErrors.required),
          otherwise: (field) => field.nullable().notRequired(),
        }),
      });

      const validation = await validateFormFields(body, yup_validation, {
        payment_method: "",
        address_id: "",
        card_id: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        setLoading(false);
        return;
      }

      setErrors({});

      await api.post(`/bills/pay`, body).then((response) => {
        if (body.payment_method === "pix") {
          setPixQrCodeUrl(response.data.charge.cobrancas[0].pix_qrcode);
          setPixQrCodeKey(response.data.charge.cobrancas[0].pix_chave);
        }
        if (body.payment_method === "boleto") {
          if (response.data.charge.cobrancas[0].linha_digitavel === null) {
            onHandleCancel();
            showToast({
              type: "success",
              message: `${i18n.t("bills.dataSentByMail")}`,
            });
          }
          setBoletoUrl(response.data.charge.cobrancas[0].url);
          setBoletoKey(response.data.charge.cobrancas[0].linha_digitavel);
        }

        if (["card"].includes(paymentMethod)) {
          showToast({
            type: "success",
            message: `${i18n.t("bills.success.payment")}`,
          });
          onHandleCancel();
        }
      });
    } catch (error: any) {
      if (error.response.data.message === "Card not valid") {
        showToast({
          type: "error",
          message: `${i18n.t("bills.error.transaction")}`,
        });
        return;
      }
      showToast({
        type: "error",
        message: `${i18n.t("bills.error.mailBill")}`,
      });
    } finally {
      setLoading(false);
      reloadUser();
    }
  }

  async function BillByMail() {
    setLoading(true);

    try {
      const body = {
        currency,
        transaction_code: "",
        type: "gcode",
        payment_method: paymentMethod,
        payment_situation: "aguardando",
        bill_value: totalBillValue,
        referring_period: `${new Date(closure_date).getMonth() + 1}/${new Date(
          closure_date
        ).getFullYear()}`,
        due_date: new Date(),
        payment_date: null,
        is_recurrence: false,
      };

      await api.post(`/bills/bill-by-mail`, body).then((response) => {
        showToast({
          type: "success",
          message: `${i18n.t("bills.success.mailBill")}`,
        });
        onHandleCancel();
      });
    } catch (error: any) {
      showToast({
        type: "error",
        message: `${i18n.t("bills.error.mailBill")}`,
      });
    } finally {
      setLoading(false);
      reloadUser();
    }
  }

  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);
      setUserAddresses(
        response.data.map((address: IAddress) => {
          if (address.billing_address) {
            setSelectedAddress({
              optionText: `${address?.address}, ${address?.number}`,
              value: address.id,
            });
          }
          return {
            optionText: `${address?.address}, ${address?.number}`,
            value: address.id,
          };
        })
      );
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar seus endereços.",
      });
    }
  }

  async function handleGetUserPaymentInfos() {
    try {
      const response = await api.get(`/payment-infos`);

      if (response.data) {
        const cardsData = response.data.map((card: ICard) => ({
          optionText: `${
            localStorage.getItem(`${card.card_last_four_digits}`)
              ? JSON.parse(
                  localStorage.getItem(`${card.card_last_four_digits}`) ?? ""
                ).card_holder
              : ""
          }  ****${card.card_last_four_digits}`,
          value: card.id,
        }));
        setPaymentInfos(cardsData);
      }
    } catch (error) {
      showToast({
        message: "Ocorreu um erro ao carregar as informações de pagamento",
        type: "error",
      });
    }
  }

  useEffect(() => {
    handleGetUserPaymentInfos();
    getLicenseeAddresses(user.person.licensee.id);
  }, []);

  return (
    <Container>
      <Header>
        <Label>{`${i18n.t("bills.valueToBePaid")}`}</Label>
        <TotalValue>
          <ReactDynamicFont content={`${totalBillValue} ${currency}`} />
        </TotalValue>
      </Header>
      <DueDateInformation>
        <span>{`${i18n.t("bills.latePaymentInfo")}`}</span>
      </DueDateInformation>
      {currency === currencyValue.BRL ? (
        <>
          {!pixQrCodeUrl && !pixQrCodeKey && !boletoKey && !boletoUrl ? (
            <>
              <RadioGroup
                row
                onChange={(event) =>
                  setPaymentMethod(event.target.value as any)
                }
                value={paymentMethod}
              >
                <RadioContent>
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label={`${i18n.t("bills.card")}`}
                  />
                  <FormControlLabel
                    value="boleto"
                    control={<Radio />}
                    label={`${i18n.t("bills.ticket")}`}
                  />
                  <FormControlLabel
                    value="pix"
                    control={<Radio />}
                    label={`${i18n.t("bills.pix")}`}
                  />
                </RadioContent>
              </RadioGroup>
              {!needCardConfirmation && ["card"].includes(paymentMethod) ? (
                <div className="address">
                  <OutlinedSelect
                    error={errors?.card_id}
                    options={paymentInfos}
                    label={`${i18n.t("bills.paymentCard")}`}
                    handleSelect={(event) => {
                      if (
                        !localStorage.getItem(
                          `${event?.optionText.slice(
                            event.optionText.length - 4
                          )}`
                        )
                      ) {
                        setNeedCardConfirmation(true);
                      }
                      setSelectedPaymentCard({
                        optionText: event.optionText,
                        value: String(event.value),
                      });
                    }}
                    selectedOption={selectedPaymentCard?.optionText ?? ""}
                  />
                  <div className="newAddress">
                    <span>{`${i18n.t("bills.didntFindYourCard")}`}</span>
                    <strong onClick={() => setNeedCardConfirmation(true)}>
                      {`${i18n.t("bills.register")}`}
                    </strong>
                  </div>
                </div>
              ) : ["card"].includes(paymentMethod) ? (
                <div className="addAddress">
                  <Separator />
                  <CreatePaymentInfosModal
                    isCardConfirmation={needCardConfirmation}
                    methodId={null}
                    handleCloseModal={() => {
                      setSelectedPaymentCard({
                        optionText: "",
                        value: "",
                      });
                      setNeedCardConfirmation(false);
                    }}
                    onMethodUpsert={handleGetUserPaymentInfos}
                  />
                  <Separator />
                </div>
              ) : (
                <></>
              )}
              {!addNewAddress ? (
                <div className="address">
                  <OutlinedSelect
                    error={errors?.address_id}
                    label={`${i18n.t("bills.billing_address")}`}
                    options={userAddresses}
                    handleSelect={(selected) => {
                      setSelectedAddress({
                        optionText: selected.optionText,
                        value: String(selected.value),
                      });
                    }}
                    selectedOption={selectedAddress?.optionText ?? ""}
                  />
                  <div className="newAddress">
                    <span>{`${i18n.t("bills.didntFindYourAddress")}`}</span>
                    <strong onClick={() => setAddNewAddress(!addNewAddress)}>
                      {`${i18n.t("bills.register")}`}
                    </strong>
                  </div>
                </div>
              ) : (
                <div className="addAddress">
                  <Separator />
                  <AddressModal
                    closeModal={() => setAddNewAddress(!addNewAddress)}
                    licensee={user.person.licensee as unknown as Licensee}
                    getLicenseeAddresses={getLicenseeAddresses}
                  />
                </div>
              )}
              {!addNewAddress && !needCardConfirmation && (
                <Footer>
                  <OutlinedButton text="Cancelar" onClick={onHandleCancel} />
                  <SolidButton
                    text={`${i18n.t("bills.pay")}`}
                    onClick={payBill}
                    loading={loading}
                    disabled={loading}
                  />
                </Footer>
              )}
            </>
          ) : boletoKey && boletoUrl ? (
            <QrCodeContainer>
              <a href={`${boletoUrl}`} target="_blank" rel="noreferrer">
                {`${i18n.t("bills.linkToAccessTicket")}`}
              </a>
              <CopyPaste>
                <div className="Header">
                  <span>{`${i18n.t("bills.barCode")}`}</span>
                  <BiCopy
                    size={20}
                    onClick={() =>
                      navigator.clipboard.writeText(boletoKey ?? "")
                    }
                  />
                </div>
                {boletoKey}
              </CopyPaste>
              <SentToMailTextContainer>
                <span>{`*${i18n.t("bills.dataSentByMail")}*`}</span>
              </SentToMailTextContainer>
            </QrCodeContainer>
          ) : (
            <QrCodeContainer>
              <img src={`${pixQrCodeUrl}`} alt="PIX QRCODE" />
              <CopyPaste>
                <div className="Header">
                  <span>{`${i18n.t("bills.pixCopyAndPaste")}`}</span>
                  <BiCopy
                    size={20}
                    onClick={() =>
                      navigator.clipboard.writeText(pixQrCodeKey ?? "")
                    }
                  />
                </div>
                {pixQrCodeKey}
              </CopyPaste>
              <SentToMailTextContainer>
                <span>{`*${i18n.t("bills.dataSentByMail")}*`}</span>
              </SentToMailTextContainer>
            </QrCodeContainer>
          )}
        </>
      ) : (
        <>
          <Footer>
            <OutlinedButton text="Cancelar" onClick={onHandleCancel} />
            <SolidButton
              text={`${i18n.t("bills.requestBill")}`}
              onClick={BillByMail}
              loading={loading}
              disabled={loading}
            />
          </Footer>
        </>
      )}
    </Container>
  );
}
