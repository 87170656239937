import React, { useEffect } from "react"
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";

import { Iframe } from "./styles"

const Success = () => {
  const { setNavigationAction } = useAuth();

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("success.header")
    });
  }, [ setNavigationAction ]);

  return (
    <Iframe src="https://usefixit.tawk.help/" title="success"/>
  )
}

export default Success;
