import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";

import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { SolidButton } from "../../../../../../components/SolidButton";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";

import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../../../utils/validateFormFields";
import { countries } from "../../../../../../utils/countries";

import {
  Container,
  InputLine,
  InputContainer,
  StepTitle,
  LinkLine,
  Footer,
} from "./styles";
import { ILicenseeFormData } from "../../dtos.d";
import { CheckBoxLine } from "../StepTwo/styles";
import { Checkbox } from "../../../../../../components/Checkbox";
import api from "../../../../../../services/api";

interface StepThreeProps {
  formData: ILicenseeFormData | undefined;
  setFormData: any;
  loading: boolean;
  nextStep: () => void;
  prevStep: () => void;
  createLicensee: (continueAsNatural: boolean) => void;
}

export function StepThree({
  prevStep,
  nextStep,
  formData,
  setFormData,
  createLicensee,
  loading,
}: StepThreeProps) {
  const [errors, setErrors] = useState({} as any);
  const [country, setCountry] = useState(formData?.country?.label ?? "");

  const formattedCountries = countries.map((country) => ({
    label: country.label,
    id: country.code,
    currency: country.currency,
    coin: country.coin,
  }));

  async function validateStepThree() {
    const body = {
      companyNumber: formData?.cnpj,
      companyName: formData?.companyName,
      fantasyName: formData?.fantasyName,
      country: formData?.country?.id,
      state: formData?.state,
      city: formData?.city,
      phone: formData?.phone,
      email: formData?.email,
      termsAccepted: formData?.termsAccepted ?? false,
    };

    const stepThreeSchema = Yup.object().shape({
      companyNumber: Yup.string().required(typesErrors.required).nullable(),
      companyName: Yup.string().required(typesErrors.required).nullable(),
      fantasyName: Yup.string().required(typesErrors.required).nullable(),
      country: Yup.string().required(typesErrors.required).nullable(),
      state: Yup.string().required(typesErrors.required).nullable(),
      city: Yup.string().required(typesErrors.required).nullable(),
      phone: Yup.string().required(typesErrors.required).nullable(),
      email: Yup.string().required(typesErrors.required).nullable(),
      termsAccepted: Yup.bool().oneOf([true], typesErrors.required),
    });

    const validation = await validateFormFields(body, stepThreeSchema, {
      companyNumber: "",
      companyName: "",
      fantasyName: "",
      country: "",
      state: "",
      city: "",
      phone: "",
      email: "",
      termsAccepted: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});
    createLicensee(false);
  }

  async function validateTermsOfUse() {
    const body = {
      termsAccepted: formData?.termsAccepted ?? false,
    };

    const TermsOfUseSchema = Yup.object().shape({
      termsAccepted: Yup.bool().oneOf([true], typesErrors.required),
    });

    const validation = await validateFormFields(body, TermsOfUseSchema, {
      termsAccepted: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});
    createLicensee(true);
  }

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  return (
    <Container>
      <StepTitle>{`${i18n.t("userRegistration.companyData")}`}</StepTitle>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="company_name"
            label={`${i18n.t("userRegistration.companyName")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                companyName: event,
              }));
              clearError("companyName");
            }}
            value={formData?.companyName ?? ""}
            error={errors.companyName}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="fantasy_name"
            label={`${i18n.t("userRegistration.tradingName")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                fantasyName: event,
              }));
              clearError("fantasyName");
            }}
            value={formData?.fantasyName ?? ""}
            error={errors.fantasyName}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="cnpj"
            label={`${i18n.t("userRegistration.companyNumber")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                cnpj: event,
              }));
              clearError("companyNumber");
            }}
            value={formData?.cnpj ?? ""}
            error={errors.companyNumber}
          />
        </InputContainer>

        <InputContainer>
          <SearchableSelect
            label={`${i18n.t("userRegistration.country")}`}
            options={formattedCountries}
            onChange={(value) => setCountry(value)}
            onSelect={(selected) => {
              if (typeof selected === "string" || !selected?.id) {
                setErrors((prevState: any) => ({
                  ...prevState,
                  country: typesErrors.validCountry,
                }));
                setFormData((prevState: ILicenseeFormData) => ({
                  ...prevState,
                  country: undefined,
                }));

                return;
              }

              setErrors((prevState: any) => ({
                ...prevState,
                country: "",
              }));
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                country: selected,
              }));
            }}
            defaultValue={country}
            error={errors?.country}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="state"
            label={`${i18n.t("userRegistration.state")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                state: event,
              }));
              clearError("state");
            }}
            value={formData?.state ?? ""}
            error={errors?.state}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="licenseeCity"
            label={`${i18n.t("userRegistration.city")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                city: event,
              }));
              clearError("city");
            }}
            value={formData?.city ?? ""}
            error={errors?.city}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="phone"
            label={`${i18n.t("userRegistration.cellphone")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                phone: event,
              }));
              clearError("phone");
            }}
            type="number"
            value={formData?.phone ?? ""}
            error={errors?.phone}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="licenseeEmail"
            label={`${i18n.t("userRegistration.email")}`}
            handleChange={(event) => {
              setFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                email: event,
              }));
              clearError("email");
            }}
            value={formData?.email ?? ""}
            error={errors?.email}
          />
        </InputContainer>
      </InputLine>

      <LinkLine>
        <span onClick={validateTermsOfUse}>
          {`${i18n.t("userRegistration.continueAsNaturalPerson")}`}
        </span>
      </LinkLine>

      <CheckBoxLine>
        <Checkbox
          checked={formData?.termsAccepted ?? false}
          handleChange={() => {
            setFormData((prevState: ILicenseeFormData) => ({
              ...prevState,
              termsAccepted: !prevState?.termsAccepted ?? true,
            }));
          }}
          error={errors?.termsAccepted}
        />
        <span>
          {`${i18n.t("userRegistration.termsOfUse")} `}
          <a href="/docs/user_terms" target="_blank">
            {`${i18n.t("userRegistration.terms")}`}
          </a>{" "}
          {`${i18n.t("userRegistration.connector")}`}{" "}
          <a href="/docs/privacy_policy" target="_blank">
            {`${i18n.t("userRegistration.policy")}`}
          </a>
          .
        </span>
      </CheckBoxLine>

      <Footer>
        {/* <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={i18n.t("userRegistration.prevStep")}
          onClick={prevStep}
        /> */}

        <SolidButton
          buttonWidth={220}
          buttonHeight={42}
          text={`${i18n.t("userRegistration.nextStep")}`}
          onClick={validateStepThree}
          loading={loading}
        />
      </Footer>
    </Container>
  );
}
