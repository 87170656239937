import { useState } from "react";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { showToast } from "../../../../../../components/CustomToast";

import {
  solutionTypeOptions,
  orderTypeOptions
} from "../../mocks";

import { RiCloseFill } from "react-icons/ri";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  CheckboxContainer,
  CheckboxLine,
  Footer,
  SaveText
} from "./styles";
import { i18n } from "../../../../../../localization/i18n";
import api from "../../../../../../services/api";
import { IPrice } from "../../dtos";
import { countries } from "../../../../../../localization/data/mocks";

interface PriceInfosProps {
  price: IPrice;
  closeModal: (price?: IPrice) => void;
}

interface IFormData {
  solution: {
    id: string;
    name: string;
  };
  country?: {
    value: string | number;
    optionText: string;
    currency: string;
  };
  order_type?: {
    value: string | number;
    optionText: string;
  };
  solution_type?: {
    value: string | number;
    optionText: string;
  };
  file_price: number
}

export function EditPrice({ price, closeModal }: PriceInfosProps) {
  const [formData, setFormData] = useState<IFormData>({
    ...price,
    order_type: orderTypeOptions.find(t => t.value === price.order_type),
    solution_type: solutionTypeOptions.find(t => t.value === price.solution_type),
    country: countries.find(t => t.value === price.country),
  });

  async function handleUpdatePrice() {
    const body = {
      ...formData,
      solution: undefined,
      order_type: formData.order_type?.value,
      solution_type: formData.solution_type?.value,
      currency: formData.country?.currency,
      country: formData.country?.value,
    }

    try {
      const { data } = await api.patch(`/prices/${price.id}`, body)
      closeModal(data)
    } catch (error) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro  ao tentar atualizar as informações do licenciado.",
      });
    }
  }

  return (
    <Container>
      <RiCloseFill size={24} color="var(--fixit)" onClick={() => closeModal()} />

      <Header>
        <span>{`${i18n.t('financial.prices.edit')}`}</span>
      </Header>

      <FormContainer>
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label={`${i18n.t('financial.prices.field.solution')}`}
              inputName="manager"
              readOnly={true}
              defaultValue={formData.solution?.name || ""}
              handleChange={() => {}}
              value={formData.solution?.name || ""}
            />
          </InputContainer>
          
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.order_type')}`}
              options={orderTypeOptions}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  order_type: selected,
                }));
              }}
              selectedOption={formData.order_type?.optionText || ""}
            />
          </InputContainer>

          {/* <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.solution_type')}`}
              options={solutionTypeOptions}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  solution_type: selected,
                }));
              }}
              selectedOption={formData.solution_type?.optionText  || ""}
            />
          </InputContainer> */}
        </InputLine>


        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.country')}`}
              options={countries}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  country: countries.find(t => t.value === selected.value),
                }));
              }}
              selectedOption={formData.country?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              label={`${i18n.t('financial.prices.field.file_price')}`}
              inputName="file_price"
              type="number"
              handleChange={(event) =>
                {
                  console.log(event)
                  setFormData((prevState) => ({
                    ...prevState,
                    file_price: event,
                  }))
                }
              }
              value={formData.file_price}
            />
          </InputContainer>
        </InputLine>

        {/* <InputLine>
          <InputContainer>
            <OutlinedInput
              label="Nome"
              inputName="name"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  user: {
                    ...prevState.user,
                    name: event,
                  },
                }))
              }
              value={data.user.name}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              label="E-mail"
              inputName="email"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  user: {
                    ...prevState.user,
                    email: event,
                  },
                }))
              }
              value={data.user.email}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="Senha"
              inputName="password"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  user: {
                    ...prevState.user,
                    password: event,
                  },
                }))
              }
              value={data.user.password}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              label="Confirmação da senha"
              inputName="password_confirmation"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  user: {
                    ...prevState.user,
                    password_confirmation: event,
                  },
                }))
              }
              value={data.user.password_confirmation}
            />
          </InputContainer>
        </InputLine> */}
      </FormContainer>

      <Footer>

        <SaveText
          onClick={handleUpdatePrice}
        >
          {`${i18n.t('financial.prices.update')}`}
        </SaveText>
      </Footer>
    </Container>
  );
}
