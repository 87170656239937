import { useState } from "react";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { showToast } from "../../../../../../components/CustomToast";
import { RiCloseFill } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  SaveText,
} from "./styles";
import api from "../../../../../../services/api";
import { AxiosError } from "axios";
import { IUser } from "../..";
import { roles } from "../../../../../../utils/constants";
import { i18n } from "../../../../../../localization/i18n";
import validateFormFields from "../../../../../../utils/validateFormFields";
import * as Yup from "yup";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { IPerson, User } from "../../../../../../components/PersonModal/dtos";
import { ProfileTypes } from "../../../../../../components/PersonModal/mocks";
import { useAuth } from "../../../../../../hooks/AuthContext";

interface UserInfosProps {
  closeModal: () => void;
  hasUser?: IUser;
  
  insertUserIntoPerson?: (person_id: string, user_id: string) => void;
  hasSteps?: boolean;
  nextStep?: () => void;
  person?: IPerson;
  isEdit?: boolean;
}

interface IFormData {
  name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  role?: {
    value: string | number;
    optionText: string;
  };
}

export function EditModal({ person, isEdit, insertUserIntoPerson, closeModal, hasUser, hasSteps = true, }: UserInfosProps) {
  const [errors, setErrors] = useState({} as any);
  const [showPassword, setShowPassword] = useState(true);
  const { user } = useAuth();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }
  
  const [data, setData] = useState<IFormData | undefined>(
    !!hasUser
      ? {
          name: hasUser.name,
          email: hasUser.email,
          role: {
            value: hasUser.role,
            optionText: i18n.t(`global.roles.${hasUser.role}`),
          },
        }
      : undefined
  );

  async function validateData(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    const body = {
      name: data?.name === "" ? person?.name : data?.name,
      email: data?.email,
      role: data?.role?.value,
      password: data?.password ?? '',
      password_confirmation: data?.password_confirmation ?? '',
    };

    const emailAndNameSchema = Yup.object().shape({
      name: (!hasUser && user.role !== roles.admin) ? Yup.string() : Yup.string().required(typesErrors.required),
      email: Yup.string()
        .email(typesErrors.validEmail)
        .required(typesErrors.required),
      role: Yup.string().required(typesErrors.required),
      password: isEdit ? Yup.string() : Yup.string().required(typesErrors.required),
      password_confirmation: isEdit ? Yup.string() : Yup.string()
        .required(typesErrors.required)
        .oneOf([Yup.ref("password"), null], typesErrors.matchPassword),
    });

    const validation = await validateFormFields(body, emailAndNameSchema, {
      name: "",
      email: "",
      role: "",
      password: "",
      password_confirmation: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return false;
    }

    setErrors({});
    return true;
  }

  const submitForm = async (): Promise<void> => {
    if ((!data?.email || !data?.role || !data?.password) && !isEdit) {
      showToast({
        type: "error",
        message: `Dados não informados`,
      });
      return;
    }

    const actionPromise = hasUser?.id
      ? api.put(`/users/${hasUser.id}`, { ...data, role: data?.role?.value })
      : api.post(`/users`, { ...data, role: data?.role?.value });

    actionPromise
      .then((response) => {
        showToast({
          type: "success",
          message: hasUser?.id
            ? "Usuário atualizado com sucesso!"
            : "Usuário adicionado com sucesso!",
        });
        
        (person && insertUserIntoPerson) && insertUserIntoPerson(person.id , response.data.id);
        closeModal();
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao carregar dados do usuário`,
        });
      });

    return;
  };

  return (
    <Container>
      <RiCloseFill
        size={24}
        color="var(--fixit)"
        onClick={() => closeModal()}
      />

      <Header>
        <span>
          {hasUser?.id
            ? `${i18n.t("global.configs.users.edit")}`
            : `${i18n.t("global.configs.users.create")}`}
        </span>
      </Header>

      <FormContainer>
      {((!hasUser && user.role === roles.admin) || isEdit) ? 
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="Nome"
              inputName="name"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  name: event,
                }));
              }}
              value={data?.name || ""}
              error={errors?.name}
            />
          </InputContainer>
        </InputLine>
        : null  
      }
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="E-mail"
              inputName="email"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  email: event,
                }));
              }}
              value={data?.email || ""}
              error={errors?.email}
            />
          </InputContainer>
        </InputLine>

        {
        hasSteps ? 
        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label="Perfil"
              options={Object.values(ProfileTypes)
                .map((v) => ({
                  value: v.role,
                  optionText: v.label,
                }))}
                handleSelect={(selected) => {
                setData((prevState) => ({
                  ...prevState,
                  role: selected,
                }));
              }}
              selectedOption={data?.role?.optionText || ""}
              error={errors?.role}
            />
          </InputContainer>
        </InputLine>
      :  
      <InputLine>
          <InputContainer>
            <OutlinedSelect
              label="Perfil"
              options={Object.values(roles)
                .filter((r) => r !== roles.licensee && r !== roles.fixiter)
                .map((v) => ({
                  value: v,
                  optionText: i18n.t(`global.roles.${v}`),
                }))}
              handleSelect={(selected) => {
                setData((prevState) => ({
                  ...prevState,
                  role: selected,
                }));
              }}
              selectedOption={data?.role?.optionText || ""}
              error={errors?.role}
            />
          </InputContainer>
        </InputLine>
        }
        
        {isEdit ? <></> : 
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="Senha"
              inputName="password"
              type={showPassword ? 'password' : 'text'}
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  password: event,
                }));
              }}
              setType={() => {
                toggleShowPassword();
              }}
              seePassword={true}
              value={data?.password || ""}
              error={errors?.password}
            />
          </InputContainer>
          <InputContainer>
            <OutlinedInput
              label="Confirmar"
              inputName="passwordConfirmation"
              type={showPassword ? 'password' : 'text'}
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  password_confirmation: event,
                }));
              }}
              setType={() => {
                toggleShowPassword();
              }}
              seePassword={true}
              value={data?.password_confirmation || ""}
              error={errors?.password_confirmation}
            />
          </InputContainer>
        </InputLine>
        }
      </FormContainer>

      <Footer>
        {
        hasSteps ?
        <>
        <OutlinedButton
          buttonWidth={200}
          text={'Cancelar'}
          onClick={async () => {
            closeModal();
          }}
        />
        <SolidButton
          buttonWidth={200}
          text={'Avançar'}
          onClick={async () => {
            const valid = await validateData();
            valid && (await submitForm());
          }}
        />
        </>
        :
        <SaveText
          onClick={async () => {
            const valid = await validateData();
            valid && (await submitForm());
          }}
        >
          {hasUser?.id ? "Atualizar" : "Criar"} user
        </SaveText>
        }
      </Footer>
    </Container>
  );
}
