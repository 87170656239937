import React, { useEffect, useState } from "react"
import Chart from 'react-apexcharts';

import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";

import { Container, SubContainer, ResultContainer, ChartArea, ResultArea, ResultLabel, ChartTitle, ChartAreaOverlay, Soon } from "./styles";

export interface IReport {
  day: number;
  month: number;
  yearByMonth: number[];
  lastSevenDays: number[];
};

export default function Dashboard() {
  const [report, setReport] = useState<IReport>({} as IReport);
  const { setNavigationAction } = useAuth();

  let lastSevenDaysLabel = [] as string[];

  for(let removeDaysQuantity = 1; removeDaysQuantity <= 7; removeDaysQuantity++) {
    const date = new Date(new Date().setDate(new Date().getDate()-removeDaysQuantity)); // Subtracting the date
    const dateFormatted = date.toLocaleDateString('pt-br', { day: '2-digit', month: '2-digit' });
    lastSevenDaysLabel.push(dateFormatted);
  }

  const optionsLastSevenDays = {
    colors: ['#9835D1'],
    labels: lastSevenDaysLabel,
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: 'var(--gray)',
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false
        },
      },
    },
    yaxis: {
      show: true,
    }
  };
  
  const seriesLastSevenDays = [
    { name: 'Pedidos', data: report.lastSevenDays }
  ];
  
  const optionsYearByMonth = {
    colors: ['#9835D1'],
    labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: 'var(--gray)',
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false
        },
      },
    },
    yaxis: {
      show: true,
    }
  };
  
  const seriesYearByMonth = [
    { name: 'Pedidos', data: report.yearByMonth }
  ];

  // NPS chart
  const seriesNps = [44, 55, 41];
  
  const optionsNps = {
    colors: ['var(--fixit)', 'var(--mention-detail)', 'var(--notification)'],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    }
  };


  useEffect(() => {
    api.get(`/reports/creators`).then(response => {
      setReport(response.data);
      return response.data
    })
    
    setNavigationAction({
      hasButton: false,
      description: i18n.t("dashboard.header")
    });
  }, []);

  return (
    <>
      <Container>
        <SubContainer>
          <ResultContainer>
            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>Pedidos hoje</ChartTitle>
                <ResultLabel>{report.day}</ResultLabel>
              </ResultArea>
            </ChartArea>

            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>Pedidos no mês</ChartTitle>
                <ResultLabel>{report.month}</ResultLabel>
              </ResultArea>
            </ChartArea>
          </ResultContainer>
          <ChartArea maxWidth={360}>
            <ChartTitle>Pedidos nos últimos 7 dias</ChartTitle>
            <Chart
              options={optionsLastSevenDays}
              series={seriesLastSevenDays}
              type="area"
              height={160}
            />
          </ChartArea>

          <ChartArea maxWidth={540}>
            <ChartTitle>Pedidos em 2021</ChartTitle>
            <Chart
              options={optionsYearByMonth}
              series={seriesYearByMonth}
              type="bar"
              height={160}
            />
          </ChartArea>
        </SubContainer>

        <SubContainer>
          <ChartArea maxWidth={190}>
            <Soon>EM BREVE</Soon>
            <ChartAreaOverlay style={{opacity: 0.5}} />
            <ChartTitle>NPS</ChartTitle>
            <Chart options={optionsNps} series={seriesNps} type="donut" height={196}/>
          </ChartArea>

          <ChartArea maxWidth={500}>
            <Soon>EM BREVE</Soon>
            <ChartAreaOverlay style={{opacity: 0.5}} />
            <ChartTitle>Faturamento em 2021</ChartTitle>
            <Chart options={optionsYearByMonth} series={seriesYearByMonth} type="bar" height={160}/>
          </ChartArea>

          <ChartArea maxWidth={360}>
            <Soon>EM BREVE</Soon>
            <ChartAreaOverlay style={{opacity: 0.5}} />
            <ChartTitle>Cliques site nos últimos 7 dias</ChartTitle>
            <Chart options={optionsLastSevenDays} series={seriesLastSevenDays} type="area" height={160}/>
          </ChartArea>
        </SubContainer>
      </Container>
    </>
  )
}
