import { i18n } from "../../../../../../localization/i18n";

import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { Container, Footer } from "./styles";

interface DeleteTipWarningProps {
  closeModal: () => void;
  deleteTip: (confirmation?: boolean) => void;
  loading: boolean;
}

export function DeleteTipWarning({
  closeModal,
  deleteTip,
  loading,
}: DeleteTipWarningProps) {
  return (
    <Container>
      <div className="title">{`${i18n.t("tips.deleteTip")}`}</div>
      <div className="description">{`${i18n.t("tips.deleteWarning")}`}</div>

      <Footer>
        <OutlinedButton text={i18n.t("tips.cancel")} onClick={closeModal} />
        <SolidButton
          text={i18n.t("tips.confirm")}
          onClick={() => deleteTip(true)}
          loading={loading}
        />
      </Footer>
    </Container>
  );
}
