import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    cursor: pointer;
  }

  h2 {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 30px;
    margin-left: 25px;
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 26px;
      margin-left: 15px;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  margin: 30px 0;
  padding: 30px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_title {
    font-size: 24px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  label {
    font-size: 18px;
    color: var(--dark-gray);
  }

  input {
    margin-top: 6px;
  }

  @media (max-width: 900px) {
    .form_title {
      font-size: 20px;
      margin-left: 2px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 300px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 30px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;

  @media (max-width: 900px) {
    width: 296px;
  }
`;

export const UploadFilesContainer = styled.div`
  margin-top: 30px;

  label {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 8px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const UploadButton = styled.button`
  width: 170px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--white);
  font-size: 16px;
  background: var(--fixit);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #6e00ad;
  }
`;
