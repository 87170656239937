import styled from 'styled-components';
import global from '../../../../global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;  

    svg {
      cursor: pointer;
    }

    h2 {
      color: var(--dark-gray);
      font-weight: bold;
      font-size: 30px;
      margin-left: 25px;
    }
  }

  @media (max-width: 900px) {
    div {
      h2 {
        font-size: 26px;
        margin-left: 15px;
      }
    }
  }
`;

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`

export const Section = styled.section`
  h2 {
    color: var(--dark-gray);
    padding-bottom: 12px;
  }
  position: relative;

  margin-right: 20px;
  margin-bottom: 20px;

  padding: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  min-width: 300px;
  width: calc(33% - 30px);

  @media(max-width: ${global.responsive.medium.width.value}${global.responsive.medium.width.unit}) {
    margin-right: 0;
    width: calc(50% - 30px);
  }
`

export const Field = styled.div`
  color: var(--dark-gray);
  padding-top: 6px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  align-self: flex-end;
  margin-top: 30px;

  button {
    width: 170px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: var(--white);
    font-size: 16px;
    background: var(--fixit);
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: #6e00ad;
    }
  }
`;

export const ButtonIcon = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color .2s;

  &.active, &:hover {
    background-color: var(--light-gray);
  }

  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-right: 18px;
  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-right: 10px;
  }
`;

export const TopRightPosition = styled.div`
  position: absolute;
  right: 0px;
  top: 20px;
`;

export const Image = styled.embed`
  height: 100px;
  border-radius: 15px;
  margin: 0 auto;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-content: center;
  margin: 0 auto;
  border-radius: 20px;
  padding: 5px;
`;

export const Button = styled.button`
  width: 170px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--fixit);
  color: var(--fixit);
  font-size: 16px;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #6e00ad;
    color: var(--white);
  }

  @media (max-width: 500px) {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`;