import { ClickAwayListener } from "@mui/material";
import { ISolution } from "../../dtos";
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { Container, IconButtonContainer } from "./styles";
import AwardImg from "../../../../assets/icons/award_standards_plano.svg";
import { RiDownload2Line } from "react-icons/ri";
import { BoxDownloadFileUpload } from "../BoxDownloadFileUpload";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";

const chunkSize = 1024 * 1024 * 5;

interface IPartConfirmation {
    ETag: string;
    PartNumber: number;
}

interface IUploadProgress {
    currentChunkIndex: number | null;
    currentFileIndex: number;
    UploadId?: string;
    randomFileId?: string;
    partsConfirmation: Array<IPartConfirmation>;
}

interface IImageUploadPopupParams {
    solution: ISolution;
    updateSolution: (link: string | null, enable: boolean | null) => void;
}

interface IUploadFase {
  enable: boolean;
  fase: string;
}

export function DownloadSolutionOptions({ solution, updateSolution } : IImageUploadPopupParams) {
    const [ editSolution, setEditSolution ] = useState<ISolution>();
    const [file, setFile] = useState<File>()
    const [progress, setProgress] = useState<number>(0)
    const [uploadFase, setUploadFase] = useState<IUploadFase>()
    const [uploadProgress, setUploadProgress] = useState<IUploadProgress>({
        currentChunkIndex: null,
        currentFileIndex: 0,
        partsConfirmation: []
      });
    
    const [totalSize, setTotalSize] = useState(0);
    const [totalChunks, setTotalChunks] = useState(0);

    const handleDeleteFile =  () => {
      api.delete(`/solutions/${solution.id}/download_file`)
        .then(() => {
          updateSolution("", null)
        })
    }
    
    const handleClickAway = () => {
        setEditSolution(undefined);
        setUploadFase(undefined)
        setFile(undefined)
        setProgress(0)
        setUploadProgress({
          currentChunkIndex: null,
          currentFileIndex: 0,
          partsConfirmation: []
        })
        setTotalSize(0)
        setTotalChunks(0) 
    }

  function uploadChunk(readerEvent?: ProgressEvent<FileReader>, chunkBlobSize: number = 0) {
    if (
      uploadProgress.currentChunkIndex == null 
      ) {
      showToast({
        type: "error",
        message: `Nenhuma imagem foi adicionado`,
      });
      return;
    }
    
    const chunk = readerEvent?.target?.result;
    const params = new URLSearchParams();
    const filesize = file?.size ?? 0;
    const chunks = file ? Math.ceil(filesize / chunkSize) - 1 : 0;
    const isLastChunk = uploadProgress.currentChunkIndex === chunks;

    if (file) {
      params.set("name", file.name);
      params.set("size", `${file.size}`);
      params.set("type", file.type);
      params.set(
        "currentChunkIndex",
        uploadProgress.currentChunkIndex.toString()
      );
      params.set(
        "totalChunks",
        Math.ceil(
          file.size / chunkSize
        ).toString()
      );
      if (uploadProgress.UploadId) {
        params.set("UploadId", uploadProgress.UploadId);
      }
      if (uploadProgress.randomFileId) {
        params.set("randomFileId", uploadProgress.randomFileId);
      }
      if (isLastChunk) {
        params.set("Parts", JSON.stringify(uploadProgress.partsConfirmation));
      }
    }

    params.set("is_download", `${uploadFase?.enable}`)

    const headers = { "Content-Type": "application/octet-stream" };
    api
      .patch(`/solutions/${solution.id}/activate?${params.toString()}`, chunk , {
        headers,
      })
      .then((response) => {
        if (!file) {
          console.log("adjusts")
          showToast({
            type: "success",
            message: "Arquivo padronizado configurado com sucesso!",
          });
          setUploadProgress(prevState => ({
            ...prevState,
            currentChunkIndex: null
          }));
          setUploadFase(undefined)
          updateSolution(null, uploadFase?.enable ?? null)
          return;
        }

        setTotalChunks((prevState) => prevState + chunkBlobSize);
        
        const nextStage: IUploadProgress = uploadProgress;

        if (uploadProgress.currentChunkIndex === 0) {
          nextStage.UploadId = response.data.UploadId;
          nextStage.randomFileId = response.data.randomFileId;
        }

        if (isLastChunk) {
          nextStage.partsConfirmation = [];
          nextStage.UploadId = undefined;
          nextStage.randomFileId = undefined;
          nextStage.currentChunkIndex = null;
          nextStage.currentFileIndex = 0;
          showToast({
            type: "success",
            message: "Arquivo padronizado configurado com sucesso!",
          });
          updateSolution(response.data.link, uploadFase ? uploadFase.enable : null)
          setUploadFase(undefined)
        } else {
          nextStage.partsConfirmation = [
            ...nextStage.partsConfirmation,
            { ETag: response.data.ETag, PartNumber: response.data.PartNumber },
          ];
          nextStage.currentChunkIndex = (nextStage.currentChunkIndex || 0) + 1;
        }

        setUploadProgress({ ...nextStage });
      })
      .catch((e) => {
        showToast({
          type: "error",
          message: `Erro ao atualizar imagem da solução`,
        });
        setUploadProgress((prevState) => ({
          partsConfirmation: [],
          UploadId: undefined,
          randomFileId: undefined,
          currentChunkIndex: null,
          currentFileIndex: 0
        }));
      });
    }

    useEffect(() => {
      if (totalChunks > 0 && totalSize > 0) {
        setProgress(Math.ceil((totalChunks / totalSize) * 100));
      }
    }, [totalChunks])

    function readAndUploadCurrentChunk() {
        if (uploadProgress.currentChunkIndex == null) {
            return;
        }
        
        if (!file) {
          uploadChunk(undefined, 0)
          return;
        }

        const reader = new FileReader();

        const from = uploadProgress.currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(
            from,
            to
        );
        reader.onload = (e) => uploadChunk(e, blob.size);
        reader.readAsDataURL(blob);
    }

    useEffect(() => {
        if (uploadProgress.currentChunkIndex !== null) {
          readAndUploadCurrentChunk();
        }
    }, [uploadProgress.currentChunkIndex]);

    useEffect(() => {
        if (!!file) {
            setTotalSize(file.size);
        }
    }, [file])

    useEffect(() => {
        if (!!uploadFase && uploadFase.fase === 'prepare') {
            setUploadProgress((prevState) => ({
                ...prevState,
                currentChunkIndex: 0,
                currentFileIndex: 0,
                UploadId: undefined,
                randomFileId: undefined,
                partsConfirmation: [],
                progress: 1
            }));
        }
    }, [uploadFase])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <Popover position="left" label={`${i18n.t("solutions.is_download")}`}>
                  <IconButtonContainer active={solution.is_download} onClick={() => setEditSolution(solution)}>
                      <RiDownload2Line
                          size={18}
                          onClick={() => {}}
                      />
                  </IconButtonContainer>
                </Popover>
                {
                    !!editSolution ?
                    (
                        <BoxDownloadFileUpload
                            deleteFile={handleDeleteFile}
                            solution={solution}
                            progress={progress}
                            file={file}
                            setFile={(f) => setFile(f)}
                            uploadFase={uploadFase?.fase}
                            close={() => handleClickAway()}
                            submit={(enable) => setUploadFase({
                              fase: 'prepare',
                              enable
                            })}/>
                    ) : null
                }
            </Container>
        </ClickAwayListener>
    )
}