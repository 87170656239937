import styled from "styled-components";
import global from "../../../../global";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 50px;
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 30px;
  gap: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: var(--dark-gray);
    font-size: 30px;
  }
`;

export const CardsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 50px;
`;

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 26px;
`;


export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    margin-left: 0px;

    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 0px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 11px 8px 14px;
  border-radius: 25px;
  background: #eae8e8;
  background: var(--white);
  border: 1px solid var(--fixit);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: var(--fixit);
  }

  svg {
    margin-left: 2px;
    cursor: pointer;
    color: var(--fixit);
  }
`;

export const FilterContainer = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-bottom: 10px;
  }
`;

export const PriceInfosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  .price_info {
    display: flex;
    align-items: center;
    gap: 2px;

    p {
      font-weight: 500;
    }
  }
`;
