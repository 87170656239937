import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.form`
  margin-top: 26px;
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom:27px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  span {
    font-size: 18px;
    color: var(--dark-gray);
  }

  svg {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 26px;

  display: flex;
  justify-content: center;
`;
