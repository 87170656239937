import { useEffect, useState } from "react";
import { GiFilmSpool } from 'react-icons/gi';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiEraserLine, RiPrinterLine, RiSettings3Line, RiToolsLine, RiUserSettingsFill } from "react-icons/ri";
import { usePrinters } from "../../hooks/PrintersContext";
import { i18n } from "../../localization/i18n";
import { FilamentConfig } from "../FilamentConfig";
import { Modal } from "../Modal";
import { PrinterConfig } from "../PrinterConfig";

import { BoxPrinter, Container, Item, ItemPrimaryText, ItemSecondaryText, ItemText, ItemTextGroup, Overlay, SelectPrinterButton, Separator } from "./styles";

export function PrinterControl() {
  const [isPrinterOpen, setIsPrinterOpen] = useState(false);
  const [isFilamentConfigured, setFilamentConfigured] = useState(false);
  const [isFilamentModalOpen, setIsFilamentModalOpen] = useState(false);
  const [showPrinterConfig, setShowPrinterConfig] = useState(false);
  const { printers, defaultPrinter, setDefaultPrinter } = usePrinters()

  function togglePrinters(){
    setIsPrinterOpen(!isPrinterOpen)
  }

  const toggleFilamentModal = () => {
    setIsFilamentModalOpen(!isFilamentModalOpen);
  }

  const autoLoadPrinterFromBrowser = async (port: SerialPort) => {
    if (defaultPrinter) {
        if (port.readable?.locked) {
            return;
        }

        if (!port.readable) {
            await port.open({ baudRate: 115200 });
        }

        setDefaultPrinter({
            ...defaultPrinter,
            port
        })
    }
  }

  useEffect(() => {
     if ('serial' in navigator) {
        navigator.serial.addEventListener('disconnect', e => {
            console.log('DISCONNECT PORT');
          // Remove |e.target| from the UI. If the device was open the
          // disconnection can also be observed as a stream error.
        });
    
        navigator.serial.addEventListener("connect", (event) => {
        const port = event.target;
        console.log('CONNECTED PORT', port);
        // TODO: Automatically open port or warn user a port is available.
        });

        if (defaultPrinter && !defaultPrinter.port) {
            const printerConfigSign : any = JSON.parse(localStorage.getItem('printerConfigSign') ?? '{}')            
            if (!!printerConfigSign[defaultPrinter.value]) {
                const signedPort : any = printerConfigSign[defaultPrinter.value];
                navigator.serial.getPorts().then((ports) => {
                    const port = ports.find((p) => {
                        const portInfo = p.getInfo()
                        const usbVendorId = (signedPort.usbVendorId?.toString() ?? defaultPrinter.device_vendor_id)
                        const usbProductId = (signedPort.usbProductId?.toString() ?? defaultPrinter.device_product_id)
                        return portInfo.usbVendorId?.toString() === usbVendorId
                            && portInfo.usbProductId?.toString() === usbProductId
                    })
                    if (port) {
                        autoLoadPrinterFromBrowser(port)
                    }
                }) 
            }
        }
      }

  }, [ defaultPrinter ])

    return (
        <Container>
            <SelectPrinterButton onClick={togglePrinters} selected={!!defaultPrinter} warning={!!defaultPrinter && !defaultPrinter.port}>
                <RiPrinterLine size = {16}/>
                <span>{defaultPrinter?.name || `${i18n.t('topbar.noprinter')}`}</span>
                {isPrinterOpen ?
                <>
                <BoxPrinter>
                    {
                        !defaultPrinter && printers?.map((printer: any) => (
                            <Item onClick={() => setDefaultPrinter({
                                name: printer.optionText,
                                optionText: printer.optionText,
                                value: printer.value,
                                xyz: printer.xyz,
                                device_vendor_id: printer.device_vendor_id,
                                device_product_id: printer.device_product_id
                                })}>
                            <ItemText>{printer.name}</ItemText>
                            <RiCheckboxBlankCircleLine size={20} />
                            </Item>
                        ))
                    }
                    {
                        !!defaultPrinter && <>
                            <Item onClick={(e) => {
                                    setShowPrinterConfig(true)
                                }}>
                                <ItemTextGroup>
                                <ItemPrimaryText>{defaultPrinter.name}</ItemPrimaryText>
                                <ItemSecondaryText warning={!defaultPrinter.port}>{!defaultPrinter.port ? `${i18n.t('printer.setup')}` : `${i18n.t('printer.ready')}`}</ItemSecondaryText>
                                </ItemTextGroup>
                                {
                                    !defaultPrinter.port ? 
                                        <RiSettings3Line color="var(--warning)" size={20}/> :
                                        <RiCheckboxCircleLine size={20} />
                                }
                            </Item>
                            <Separator />
                            <Item onClick={(e) => {
                                    toggleFilamentModal();
                                }}>
                                <ItemTextGroup>
                                <ItemPrimaryText>{`${i18n.t('topbar.filamentData')}`}</ItemPrimaryText>
                                <ItemSecondaryText warning={!isFilamentConfigured}>{`${i18n.t('printer.setup')}`}</ItemSecondaryText>
                                </ItemTextGroup>
                                <GiFilmSpool color="var(--fixit)" size={20}/>
                            </Item>
                            <Separator />
                            <Item onClick={() => setDefaultPrinter(undefined)}>
                                <ItemText>{`${i18n.t('printer.erase')}`}</ItemText>
                                <RiEraserLine size={20} />
                            </Item> 
                        </>
                    }
                </BoxPrinter>
                    <Overlay onClick={togglePrinters}/>
                </>
                : null }
            </SelectPrinterButton>
            {
                showPrinterConfig && (
                    <Modal open={showPrinterConfig}>
                        <PrinterConfig 
                        closeModal={async () => {
                            setShowPrinterConfig(false)
                        }}
                        />
                  </Modal>
                )
            }
            {
                <FilamentConfig 
                    open={isFilamentModalOpen}
                    onRequestClose={toggleFilamentModal}
                    setFilamentConfigured={setFilamentConfigured}
                />
            }
        </Container>
    )
}
