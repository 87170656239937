import { useState } from "react";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { showToast } from "../../../../../../components/CustomToast";
import { RiCloseFill } from "react-icons/ri";
import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  SaveText,
} from "./styles";
import { i18n } from "../../../../../../localization/i18n";
import api from "../../../../../../services/api";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { IPrinter } from "../..";

interface PrinterInfosProps {
  closeModal: () => void;
  printer?: IPrinter;
}

interface IFormData {
  name?: string;
  x?: string;
  y?: string;
  z?: string;
  device_vendor_id?: string;
  device_product_id?: string;
}

interface IParams {
  contract_version: string;
}

export function EditModal({ closeModal, printer }: PrinterInfosProps) {
  const [data, setData] = useState<IFormData | undefined>(
    !!printer
      ? {
          name: printer?.name,
          device_product_id: printer?.device_product_id,
          device_vendor_id: printer?.device_vendor_id,
          x: printer?.xyz[0],
          y: printer?.xyz[1],
          z: printer?.xyz[2],
        }
      : undefined
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const submitForm = async (): Promise<void> => {
    if (
      !data?.name ||
      !data?.x ||
      !data?.y ||
      !data?.z ||
      !data?.device_product_id ||
      !data?.device_vendor_id
    ) {
      showToast({
        type: "error",
        message: `Dados não informados`,
      });
      return;
    }

    const actionPromise = printer?.id
      ? api.put(`/printers/${printer.id}`, {
        name: data?.name,
        device_product_id: data?.device_product_id,
        device_vendor_id: data?.device_vendor_id,
          xyz: [data.x, data.y, data.z],
        })
      : api.post(`/printers`, { ...data, xyz: [data.x, data.y, data.z] });

    actionPromise
      .then(() => {
        showToast({
          type: "success",
          message: printer?.id
            ? "Impressora atualizada com sucesso!"
            : "Impressora adicionada com sucesso!",
        });
        closeModal();
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao carregar dados da impressora`,
        });
      });

    return;
  };

  return (
    <Container>
      <RiCloseFill
        size={24}
        color="var(--fixit)"
        onClick={() => closeModal()}
      />

      <Header>
        <span>
          {printer?.id
            ? `${i18n.t("global.configs.printers.edit")}`
            : `${i18n.t("global.configs.printers.create")}`}
        </span>
      </Header>

      <FormContainer>
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="Nome"
              inputName="name"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  name: event,
                }));
              }}
              value={data?.name || ""}
            />
          </InputContainer>
        </InputLine>
        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="VendorID"
              inputName="vendorId"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  device_vendor_id: event,
                }));
              }}
              value={data?.device_vendor_id || ""}
            />
          </InputContainer>
          <InputContainer>
            <OutlinedInput
              label="ProductId"
              inputName="productId"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  device_product_id: event,
                }));
              }}
              value={data?.device_product_id || ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              label="X"
              inputName="xDimension"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  x: event,
                }));
              }}
              value={data?.x || ""}
            />
          </InputContainer>
          <InputContainer>
            <OutlinedInput
              label="Y"
              inputName="yDimension"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  y: event,
                }));
              }}
              value={data?.y || ""}
            />
          </InputContainer>
          <InputContainer>
            <OutlinedInput
              label="Z"
              inputName="zDimension"
              type="text"
              handleChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  z: event,
                }));
              }}
              value={data?.z || ""}
            />
          </InputContainer>
        </InputLine>
      </FormContainer>

      <Footer>
        <SaveText onClick={submitForm}>
          {printer?.id ? "Atualizar" : "Criar"} impressora
        </SaveText>
      </Footer>
    </Container>
  );
}
