import styled from "styled-components";

interface ButtonProps {
  absolute: boolean;
}

export const Button = styled.button<ButtonProps>`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 170px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  color: var(--white);
  font-size: 16px;
  background: var(--fixit);
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 10;

  &:hover {
    background: #6e00ad;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 720px) {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    svg {
      width: 30px;
      height: 30px;
    }

    .button_text {
      display: none;
    }
  }
`;
