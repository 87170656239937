import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";

import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { showToast } from "../../../../../../components/CustomToast";
import { Plan } from "./Plan";

import { Plans } from "../..";
import { countries } from "../../../../../../utils/countries";

import { ILicenseeFormData, IPersonFormData } from "../../dtos";

import contactIllustration from "../../../../../../assets/contact-us.svg";

import {
  Container,
  StepTitle,
  Footer,
  PlansWrapper,
  IllustrationWrapper,
  StepDescription,
  SAFLinkButton,
} from "./styles";

interface StepTwoProps {
  plans: Plans[];
  moneyProp: string;
  selectedLanguage: string;
  person: IPersonFormData | undefined;
  licensee: ILicenseeFormData | undefined;
  user?: any;
  userLicensee?: any;
  naturalPerson: boolean;
  nextStep: () => void;
  prevStep: () => void;
  asigneePlan: (planId: string) => void;
}

const SAF_WHATSAPP_LINK =
  "https://api.whatsapp.com/send?phone=5511981669461&text=A%20Fix%20it%20ainda%20n%C3%A3o%20opera%20em%20minha%20regi%C3%A3o%2C%20como%20fa%C3%A7o%20para%20trazer%20ao%20meu%20pa%C3%ADs%3F";

export function StepFour({
  nextStep,
  prevStep,
  plans,
  naturalPerson,
  person,
  user,
  userLicensee,
  licensee,
  moneyProp,
  selectedLanguage,
  asigneePlan,
}: StepTwoProps) {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  function getPlansDescription(plan: Plans) {
    try {
      switch (selectedLanguage) {
        case "es":
          return JSON.parse(plan?.description)["Es"];
        case "pt-BR":
          return JSON.parse(plan?.description)["ptBr"];
        default:
          return JSON.parse(plan?.description)["En"];
      }
    } catch {
      return JSON.parse(plan?.description)["ptBr"];
    }
  }

  function getFormStatus() {
    const userPersonCountryOperational = countries.find(
      (country) => country.code === user?.person?.country
    );
    const userLicenseeCountryOperational = countries.find(
      (country) => country.code === userLicensee?.country
    );

    if (licensee?.country?.currency || person?.country?.currency) {
      return true;
    } else if (
      userPersonCountryOperational?.currency ||
      userLicenseeCountryOperational?.currency
    ) {
      return true;
    } else return false;
  }

  return (
    <Container>
      {getFormStatus() ? (
        <>
          <StepTitle>{`${i18n.t("userRegistration.plans")}`}</StepTitle>
          <PlansWrapper>
            {plans?.map((plan) => (
              <Plan
                id={plan.id}
                advantages={getPlansDescription(plan)?.advantages}
                name={plan.plan_name}
                price={plan.price}
                description={getPlansDescription(plan)?.description}
                onPlanSelect={(planId: string) => setSelectedPlanId(planId)}
                planSelected={selectedPlanId === plan.id}
                moneyProp={moneyProp}
              />
            ))}
          </PlansWrapper>

          <Footer>
            {/* <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={i18n.t("userRegistration.prevStep")}
          onClick={prevStep}
        /> */}

            <SolidButton
              text={i18n.t("userRegistration.nextStep")}
              buttonWidth={220}
              buttonHeight={42}
              buttonColor="var(--active-green)"
              buttonHoverColor="var(--active-green-hover)"
              disabled={!selectedPlanId}
              onClick={() => {
                if (!selectedPlanId) {
                  showToast({
                    type: "error",
                    message: i18n.t("userRegistration.errorChoosePlan"),
                  });
                  return;
                }

                asigneePlan(selectedPlanId);
              }}
            />
          </Footer>
        </>
      ) : (
        <>
          <StepTitle>{`${i18n.t("userRegistration.notOperational")}`}</StepTitle>
          <StepDescription>
            {`${i18n.t("userRegistration.noOperationalDescription")}`}
          </StepDescription>

          {/* <IllustrationWrapper>
            <img src={contactIllustration} alt="Building" />
          </IllustrationWrapper> */}

          <Footer>
            <SAFLinkButton href={SAF_WHATSAPP_LINK} target="_blank">
              {`${i18n.t("userRegistration.contactUs")}`}
            </SAFLinkButton>
          </Footer>
        </>
      )}
    </Container>
  );
}
