import styled, { css } from "styled-components";
import global from "../../../../../../global";

interface FilterContainerProps {
  open: boolean;
}

interface CheckboxProps {
  checked?: boolean;
}

export const Container = styled.div`
  position: relative;

  svg {
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div<FilterContainerProps>`
  position: absolute;
  right: 0px;
  top: 36px;

  width: 400px;
  border-radius: 10px;
  box-shadow: 4px 3px 8px var(--gray);
  background: var(--white);
  z-index: 10;

  padding: 32px;

  display: ${({ open }) => (open ? "block" : "none")};
`;

export const FilterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 20px;
  cursor: pointer;
`;

export const SectionName = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
  color: #9e9e9e;
`;

export const SelectContainer = styled.div`
  margin-top: 10px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;


export const CheckboxContainer = styled.div<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;

    ${({ checked }) =>
      checked === undefined &&
      css`
        text-decoration: line-through;
      `}
  }
`;

export const ApplyButton = styled.button`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100px;
  border: 1px solid ${({ disabled }) =>
                      disabled ? "var(--light-gray)" : "var(--finished)"};
  padding: 10px 10px;
  border-radius: 10px;
  background-color: var(--white);

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;
