import styled from 'styled-components'
import global from '../../../../../global';

interface IOverlayProps {
  isMenuOpen: boolean;
}

interface IContainerProps {
  isMenuOpen: boolean;
}

export const Container = styled.nav<IContainerProps>`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--fixit);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15%;

  a {
    text-decoration: none;
    color: var(--white);
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  @media(min-width: ${global.responsive.small.width.value + 1}${global.responsive.small.width.unit}) {
    display: none;
  }
`

export const LinkList = styled.ul`
  list-style-type: none
`

export const LinkItem = styled.li`
  flex-direction: row;
`

export const LinkTitle = styled.h1`
  margin-left: 16px;
`

export const RouteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  border-radius: 50%;

  background-color: rgba(255,255,255,0.3);

  cursor: pointer;
  transition: border-radius .2s, background-color .2s;

  &:hover {
    border-radius: 16px;
    background-color: rgba(255,255,255,0.6);
  }
`

export const Separator = styled.div`
  width: 128px;
  border-bottom: 1px solid rgba(255,255,255,0.3);

  margin: 14px 0;
`;

export const Overlay = styled.div<IOverlayProps>`
  position: absolute;
  width: 25vw;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  ${props => props.isMenuOpen ? "" : "display: none;"}
`
