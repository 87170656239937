import { useEffect, useState } from "react";

import { i18n } from "../../../../../../localization/i18n";

import { ClickAwayListener } from "@mui/material";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { CheckboxNullable } from "../../../../../../components/CheckboxNullable";
import { SolidButton } from "../../../../../../components/SolidButton";

import { nullOption } from "./mocks";

import { RiFilter3Fill } from "react-icons/ri";

import {
  Container,
  FilterContent,
  InputContainer,
  InputLine,
  Footer,
  CheckboxContainer,
} from "./styles";
import { countries } from "../../../../../../localization/data/mocks";
import { IFilterData, IOption } from "../../../../dtos";
import { licenseesContractTypes } from "../../../../../../utils/constants";
interface FilterProps {
  onFilterApply: (filterData: IFilterData) => void;
  filter?: IFilterData;
}

export function Filter({ filter, onFilterApply }: FilterProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [licenseeName, setLicenseeName] = useState<string | undefined>();
  const [licenseesStatus, setLicenseesStatus] = useState<boolean | undefined>();
  const [licenseeContractType, setLicenseeContractType] = useState<IOption>()
  const [licenseeSituation, setLicenseeSituation] = useState<IOption>()
  const [selectedCountry, setSelectedCountry] = useState<IOption>();

  const situationOptions = [
    {
        value:"operational",
        optionText:"Em operação",
    },
    {
        value: "training",
        optionText:"Em treinamento",
    },
    {
        value:"blocked",
        optionText:"Bloqueado",
    },
]

  function applyFilterData() {
    const filterData: IFilterData = {
      licensee_name: licenseeName,
      licensee_status: licenseesStatus,
      country: selectedCountry,
      contract_type: licenseeContractType,
      situation: licenseeSituation
    };

    setFilterOpen(false);
    onFilterApply(filterData);
  }

  useEffect(() => {
    setLicenseeName(filter?.licensee_name);
    setSelectedCountry(filter?.country);
    setLicenseesStatus(filter?.licensee_status);
    setLicenseeContractType(filter?.contract_type);
    setLicenseeSituation(filter?.situation);
  }, [filter]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFilterOpen(false);
      }}
    >
      <Container>
        <RiFilter3Fill
          size={34}
          color={filterOpen ? "var(--fixit)" : "var(--gray)"}
          onClick={() => setFilterOpen((prevState) => !prevState)}
        />

        {filterOpen && (
          <FilterContent>
            <div className="filter_title">Filtrar licenciados</div>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  label="Nome do licenciado"
                  inputName="name"
                  handleChange={(event) => setLicenseeName(event)}
                  value={licenseeName ?? ""}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedSelect
                  label={`${i18n.t("financial.prices.field.country")}`}
                  options={[nullOption, ...countries]}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setSelectedCountry(undefined);
                      return;
                    }
                    setSelectedCountry(selected);
                  }}
                  selectedOption={selectedCountry?.optionText ?? ""}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedSelect
                  label={"Tipo de contrato"}
                  options={[{optionText: "Anual", value: licenseesContractTypes.yearly}, {optionText: "Mensal", value: licenseesContractTypes.monthly}]}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setLicenseeContractType(undefined);
                      return;
                    }
                    setLicenseeContractType(selected);
                  }}
                  selectedOption={licenseeContractType?.optionText ?? ""}
                />
              </InputContainer>
              <InputContainer>
                <OutlinedSelect
                  label={"Situação"}
                  options={situationOptions}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setLicenseeSituation(undefined);
                      return;
                    }
                    setLicenseeSituation(selected);
                  }}
                  selectedOption={licenseeSituation?.optionText ?? ""}
                />
              </InputContainer>
            </InputLine>

            <Footer>
              <CheckboxContainer checked={licenseesStatus}>
                <CheckboxNullable
                  checked={licenseesStatus}
                  handleChange={() => {
                    if (licenseesStatus) setLicenseesStatus(false);
                    else if (licenseesStatus === false)
                      setLicenseesStatus(undefined);
                    else setLicenseesStatus(true);
                  }}
                />
                <span>{`${i18n.t("global.status.label")}`}</span>
              </CheckboxContainer>

              <SolidButton text="Aplicar" onClick={applyFilterData} />
            </Footer>
          </FilterContent>
        )}
      </Container>
    </ClickAwayListener>
  );
}
