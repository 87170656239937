import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import Input from "../../components/Input";
import ReactLoading from "react-loading";

import {
  Container,
  Header,
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  Footer,
} from "./styles";
import { useEffect } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";

export default function EditCreator() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  async function handleGetCreatorData() {
    try {
    } catch (err) {}
  }

  async function handleUpdateCreator() {
    try {
    } catch (err) {}
  }

  useEffect(() => {
    handleGetCreatorData();
  }, []);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.designers.edit"),
    });
  }, []);

  return (
    <>
      {loading ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--fixit)"}
          height={64}
          width={64}
        />
      ) : (
        <Container>
          <Header>
            <RiArrowLeftLine
              onClick={() => navigate(-1)}
              size={32}
              color="var(--fixit)"
            />
            <h2>Editar Designer</h2>
          </Header>

          <Form>
            <FormContainer>
              <div className="form_title">Informações do Designer</div>

              <FormRow>
                <InputContainer>
                  <label>Nome</label>
                  <Input
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        name: event,
                      }));
                    }}
                    value={data.name}
                  />
                </InputContainer>

                <InputContainer>
                  <label>E-mail</label>
                  <Input
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        email: event,
                      }));
                    }}
                    value={data.email}
                  />
                </InputContainer>
              </FormRow>

              <FormRow>
                <InputContainer>
                  <label>Senha</label>
                  <Input
                    type="password"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        password: event,
                      }));
                    }}
                    value={data.password}
                  />
                </InputContainer>

                <InputContainer>
                  <label>Confirmação da senha</label>
                  <Input
                    type="password"
                    handleChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        password_confirmation: event,
                      }));
                    }}
                    value={data.password_confirmation}
                  />
                </InputContainer>
              </FormRow>

              <Footer>
                <button type="button" onClick={handleUpdateCreator}>
                  Salvar alterações
                </button>
              </Footer>
            </FormContainer>
          </Form>
        </Container>
      )}
    </>
  );
}
