import { useEffect } from "react";

import { i18n } from "../../../../localization/i18n";

import { useAuth } from "../../../../hooks/AuthContext";

import { ConfigItem } from "../../../../components/ConfigItem";

import {
  RiPagesLine,
  RiPriceTag2Line,
  RiPrinterCloudLine,
  RiUser3Line,
  RiLightbulbLine,
  RiFirstAidKitLine,
} from "react-icons/ri";

import { Container, EvaluationWrapper } from "./styles";

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

export default function Configurations() {
  const { setNavigationAction } = useAuth();

  const mock = [
    {
      title: "Usuários internos",
      label: `Clique para configurar`,
      help: "Cadastre novos colaboradores para utilizar os recursos do APP",
      icon: <RiUser3Line />,
      ativo: true,
      action: "/configs/users",
    },
    {
      title: "Impressoras",
      label: `Clique para configurar`,
      help: "Acesse o controle das impressoras aceitas pelo software",
      icon: <RiPrinterCloudLine />,
      ativo: true,
      action: "/configs/printers",
    },
    {
      title: "Decumentos - Anual",
      label: "Clique para configurar",
      help: "Configure os termos de uso e polítia de privacidade para os cliente do modelo anual",
      ativo: true,
      action: "/configs/docs/1.0",
      icon: <RiPagesLine />,
    },
    {
      title: "Documentos - Mensal",
      label: `Clique para configurar`,
      help: "Configure os termos de uso e polítia de privacidade para os cliente do modelo mensal",
      ativo: true,
      action: "/configs/docs/2.0",
      icon: <RiPagesLine />,
    },
    {
      title: "Categorização das soluções",
      label: `Classificação em dois níveis`,
      help: "Organize como as soluções aparecerão para os clientes",
      icon: <RiPriceTag2Line />,
      ativo: true,
      action: "/configs/fields",
    },
    {
      title: "Dicas",
      label: `Clique para configurar`,
      help: "Configure as novas funcionalidades",
      icon: <RiLightbulbLine />,
      ativo: true,
      action: "/configs/tips",
    },
    {
      title: "Diagnósticos",
      label: `Clique para configurar`,
      help: "Configure os dianósticos disponíveis para os pedidos.",
      icon: <RiFirstAidKitLine />,
      ativo: true,
      action: "/configs/diagnosis",
    },
  ];

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.header"),
    });
  }, []);

  return (
    <>
      <Container>
        <EvaluationWrapper>
          {mock.map((section) => {
            return (
              <ConfigItem
                action={section.action}
                icon={section.icon}
                section={section.title}
                label={section.label}
                help={section.help}
              />
            );
          })}
        </EvaluationWrapper>
      </Container>
    </>
  );
}
