import { RiDownloadLine, RiDeleteBinLine } from "react-icons/ri";
import api from "../../../../services/api";

import { Container, Header, Title, Subtitle } from "./styles";

interface DownloadFileCard {
  fileLink: string;
  originalName: string;
  fileSize: number;
  onDelete: () => void;
}

export function DownloadCard({
  fileLink,
  fileSize,
  originalName,
  onDelete,
}: DownloadFileCard) {

  return (
    <Container>
      <Header>
        <Title>{originalName}</Title>
      </Header>

      <div className="icons">
        <a
          href={fileLink}
          target="_blank"
          rel="noreferrer"
          download
          className="download"
        >
          <RiDownloadLine size={24} color="var(--fixit)" />
        </a>

        <div className="exclude">
          <RiDeleteBinLine onClick={onDelete} size={24} color="var(--fixit)" />
        </div>
      </div>
    </Container>
  );
}
