import { RiDownload2Line, RiEyeLine, RiFileUploadLine, RiHistoryLine, RiPrinterLine } from "react-icons/ri";
import { MdOutlinePersonPinCircle } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import Popover from "../../../components/Popover";
import { i18n } from "../../../localization/i18n";
import { ActionItem, ActionsContainer, BoxFileList, IconButtonContainer, Item, ItemText, Overlay, ProvisionerContainer } from "../components/OrderItem/styles";
import { IOrder, IPopupData } from "../dtos";
import { useEffect, useState } from "react";
import { OrderProviderModal } from "../components/OrderProviderModal";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/AuthContext";
import { useOnboard } from "../../../hooks/OnboardContext";
import { solutionTypes } from "../../../utils/constants";


interface ActionsProps {
    printOrder: (
      order: any
    ) => void;
    openPopup: (
      {
        order,
        printTime,
        cost,
        weight,
        link,
        printer,
        type
      } : IPopupData
    ) => void;
    printFile: (
      {
        order,
        printTime,
        cost,
        weight,
        printer,
        type
      } :  IPopupData
    ) => void;
    openPrintHistory: ({
      orderId,
      quantity
    }: { orderId: string, quantity: number }) => void;
    isPrintOptionDropdownOpen: boolean;
    setIsPrintOptionDropdownOpen: (value: React.SetStateAction<boolean>) => void;
    isFileListDropdownOpen: boolean;
    setIsFileListDropdownOpen: (value: React.SetStateAction<boolean>) => void;
    order: IOrder;
    openPlanitFileUploadPopup: (planit_id: string) => void;
}

export const LicenseeActions = ({order, printOrder, printFile, openPopup, openPrintHistory, isFileListDropdownOpen, setIsFileListDropdownOpen, isPrintOptionDropdownOpen, setIsPrintOptionDropdownOpen, openPlanitFileUploadPopup} : ActionsProps) => {
    const navigate = useNavigate();
    const { licensee } = useOnboard();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    }
    
    return (
    <>
    <OrderProviderModal
        // handleProviderSelected={}
        trigger={isModalOpen}
        orderIdentification={order.id}
        onClose={() => {
          toggleModal();
        }}
        order={order}
    />
    <ActionsContainer>
        { ( order.solution_type === 'standard') && (
            <ActionItem className="print">
            <Popover position="left" label={`${i18n.t('orders.print')}`}>
                <IconButtonContainer disabled={order.status !== i18n.t("orders.status.completed.value")} >
                <RiPrinterLine size={18} onClick={() => {
                    order.status === i18n.t("orders.status.completed.value") && 
                    setIsPrintOptionDropdownOpen(true)
                }}/>                  
                {isPrintOptionDropdownOpen &&
                    (
                    <>
                        <BoxFileList>
                        {
                            <Item onClick={() => {            
                                setIsPrintOptionDropdownOpen(false)
                                printOrder(order)
                            }}>
                                <ItemText>{`${i18n.t('orders.printChoose.header')}`}</ItemText>
                                <RiPrinterLine size={18} />
                            </Item>
                        }
                        <Item onClick={() => {            
                            setIsPrintOptionDropdownOpen(false)
                            openPrintHistory({
                                orderId: order.id,
                                quantity: order.quantity
                            })
                        }}>
                            <ItemText>{`${i18n.t('orders.printHistory')}`}</ItemText>
                            <RiHistoryLine size={18} />
                        </Item>
                        </BoxFileList>
                        <Overlay onClick={() => {                           
                        setIsPrintOptionDropdownOpen(false)
                        }}/>
                    </>
                ) }
                </IconButtonContainer>
            </Popover>
            </ActionItem>
        )}

        { order.solution_type === solutionTypes.plan && (
            <ActionItem className="download">
                <Popover position="left" label={`Upload`}>
                    <IconButtonContainer disabled={order.status === i18n.t("orders.status.completed.value")}>
                        <RiFileUploadLine size={18} onClick={() => {
                            order.status !== i18n.t("orders.status.completed.value") && 
                            openPlanitFileUploadPopup(order.planit_id)
                        }}/>
                    </IconButtonContainer>
                </Popover>
            </ActionItem>
        )}

        { order.solution_type !== 'standard' && (
            <ActionItem className="download">
            <Popover position="left" label={`${i18n.t('orders.download')}`}>
                <IconButtonContainer disabled={order.status !== i18n.t("orders.status.completed.value")}>
                <RiDownload2Line size={18} onClick={() => {
                    (order.status === i18n.t("orders.status.completed.value") && order.files?.length) && 
                    setIsFileListDropdownOpen(true)
                }}/>
                {isFileListDropdownOpen &&
                    (
                    <>
                        <BoxFileList>
                        {
                            order.files?.map((file: any, index) => (
                            <Item
                                href={`${process.env.REACT_APP_STORAGE_BASE_URL}${file.link}`}
                                key={index} onClick={() => { 
                                setIsFileListDropdownOpen(false)
                                openPopup(
                                    {
                                        order,
                                        printTime: file.print_time,
                                        cost: file.material_cost,
                                        weight: file.material_weight,
                                        link: file.link,
                                        type: 'download',
                                        printer: order.printer
                                    }
                                )
                                }} download>
                                <ItemText>{file.original_name}</ItemText>
                                <RiDownload2Line size={18} />
                            </Item>
                            ))
                        }
                        </BoxFileList>
                        <Overlay onClick={() => { 
                        setIsFileListDropdownOpen(false)
                        }}/>
                    </>
                ) }
                </IconButtonContainer>
            </Popover>
            </ActionItem>
        )}
        <ActionItem>
            <Popover position="left" label={`${i18n.t('orders.view')}`}>
                <IconButtonContainer disabled={order.status === i18n.t("orders.status.cancelled.value")} >
                <RiEyeLine size={18} onClick={() => {
                    order.status !== i18n.t("orders.status.cancelled.value") && 
                    navigate({
                        pathname: `/evaluation/${order.id}`
                    });
                }}/>
                </IconButtonContainer>
            </Popover>
        </ActionItem>
        {(!order.printer && order.solution_type === 'standard') &&
            <ActionItem>
            <Popover position="left" label={"FixPoint"}>
            <ProvisionerContainer
            //   providerSelected={!!order?.provider?.id ?? providerSelected}
            >
                <IconButtonContainer>
                    <MdOutlinePersonPinCircle
                    size={18}
                    onClick={() => {
                        toggleModal();
                    }}
                    />
                </IconButtonContainer>
            </ProvisionerContainer>
            </Popover>
        </ActionItem>
        }
        
    </ActionsContainer>
    </>
)}