import styled, { css } from "styled-components";

interface ContainerProps {
  open: boolean;
}

interface AcceptSectionProps {
  showTerms: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  transition: visibility 225ms;
  visibility: hidden;
  opacity: 0;
  font-size: 14px;
  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  .modal_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 954px;
    min-width: 400px;
    width: 600px;
    max-height: calc(100% - 64px);
    color: #575757;
    background: #ffffff;
    border-radius: 4px;
    padding: 30px;
    // margin: 32px 0;
    position: relative;
    overflow-y: auto;
  }

  @media (max-width: 650px) {
    .modal_wrapper {
      min-width: 350px;
      width: 350px;
    }
  }
`;

export const ModalContent = styled.div<AcceptSectionProps>`
  .header {
    display: flex;
    justify-content: center;

    font-size: 16px;
    font-weight: bold;
  }

  .terms_of_use {
    margin-top: 20px;

    transition: 0.3s;
  }

  .accept_section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    margin-top: 30px;

    transition: 0.3s;

    strong {
      color: var(--fixit);
      cursor: pointer;
    }

    ${({ showTerms }) =>
      !showTerms &&
      css`
        align-items: center;
      `}
  }

  .accept_check {
    display: flex;
    align-items: flex-start;
    gap: 6px;
  }

  @media (max-width: 650px) {
    .accept_section {
      align-items: center;
    }
  }

  @media (min-width: 650px) {
    .terms_of_use {
      max-height: 500px;
      overflow-y: auto;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  color: var(--white);
  font-weight: 500;

  width: 130px;
  height: 42px;
  border-radius: 10px;
  background: var(--fixit);

  cursor: pointer;

  &:disabled {
    background: var(--gray);
    cursor: auto;
  }
`;
