import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
} from "./styles";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { UserItem } from "./components/UserItem";
import { Modal } from "../../../../components/Modal";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { EditModal } from "./components/EditModal";
import { showToast } from "../../../../components/CustomToast";
import { PersonModal } from "../../../../components/PersonModal";
import { IPerson } from "../../../../components/PersonModal/dtos";
import { useOnboard } from "../../../../hooks/OnboardContext";


export interface IUser {
  id: string;
  name: string;
  role: string;
  email: string;
}

export default function Users() {
  const { licensee } = useOnboard();
  const { user } = useAuth();
  const { setNavigationAction } = useAuth();
  const [ users, setUsers ] = useState<IUser[]>();
  const [person, setPerson] = useState<IPerson>();
  const [formSteps, setFormSteps] = useState(1);
  const [ isCreatePersonOpen, setIsCreatePersonOpen ] = useState(false);

  const getUsers = async () => {
    try{
      const {data} = await api.get(`/users`)
      setUsers(data);
    } catch(e) {
      console.log(e);
    };
  }

  useEffect(() => {
    getUsers();

    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.users.header")
    });

  }, []);

  async function deleteUser (id: string) {
    if(user.id !== id){
    await api.delete(`/users/${id}`).then(() => {
      showToast({
        type: 'success',
        message: `${i18n.t("person.success.user_deleted_success")}`
      })
      getUsers();
    }).catch((err) => {
      showToast({
        type: 'error',
        message: `${i18n.t("person.success.user_deleted_success")}`
      })
    })
  }
  else{
    showToast({
      type: 'error',
      message: 'Não é possivel deletar usuário.'
    })
  }
  }

  const nextStep = () => {
    setFormSteps((prevState) => prevState + 1);
  }

  async function insertUserIntoPerson(person_id: string, user_id: string) {
    try {
      const body = {
        person_id: person_id,
        user_id: user_id,
      }

      await api.patch(`/person/update-person`, body);

    } catch (error) {
      console.log(error);
    }
  }

  const toggleModal = () => {
    setIsCreatePersonOpen(!isCreatePersonOpen);
    setFormSteps(1);
  }

  const steps = [
    {
      step: 2,
      component: (
        <EditModal 
          person={person}
          closeModal={async () => {
            await getUsers();
            toggleModal()
          }}
          insertUserIntoPerson={insertUserIntoPerson}
          hasSteps={false}
        />
      )
    },
    {
      step: 1,
      component: (
        <PersonModal
          licensee={licensee}
          closeModal={() => {
            setIsCreatePersonOpen(false);
          }}
          setPerson = {setPerson}
          nextStep={nextStep}
        />
      )
    }
  ]

  return (
    <Container>
       {
        isCreatePersonOpen && (
          <Modal open={isCreatePersonOpen}>
            {steps.map((step) => {
              if (step.step === formSteps) {
                return step.component;
              }
            })}
          </Modal>
        )
      }
      <EvaluationWrapper>
        {
          users?.map((user) => {
            return (
              <UserItem 
                user={user} 
                getUsers={getUsers}
                deleteUser={deleteUser}
                />
            )
          })
        }
      </EvaluationWrapper>
      <AbsoluteButton
        absolute
        onClick={() => {
          setIsCreatePersonOpen(true);
        }}
      />
    </Container>
    )
  }

