/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { useOnboard } from "../../hooks/OnboardContext";
import api from "../../services/api";
import { roles } from "../../utils/constants";
import { Checkbox } from "../Checkbox";

import { CloseButton, CancelButton, Container, ModalContent, Footer } from "./styles";

interface ModalProps {
  open?: boolean;
  closeModal?: (any: any) => any;
}

interface Person {
  id: string;
  licensee: {
    contract: string;
    user_term: ITermosUso;
  };
}

interface IUserData {
  name: string;
  email: string;
  role: string;
  id: string;
  person: Person;
}

interface IUserTermsProps {
  user: IUserData;
  signOut: () => void;
  userTerm: ITermosUso;
  loadLicensee: () => void;
}

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

export function TermsOfUseModal({ user, userTerm, signOut, loadLicensee }: IUserTermsProps) {
  const [terms, setTerms] = useState<string>();
  const [open, setOpen] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [reCaptcha, setReCaptcha] = useState<any>()

  useEffect(() => {
    if (showTerms && !!userTerm) {
      fetch(`${process.env.REACT_APP_STORAGE_BASE_URL}${userTerm.document_link}`)
        .then(response => response.text())
        .then(html => {
          setTerms(html);
          // var parser = new DOMParser();
          // var doc = parser.parseFromString(html, "text/html");
        })
        .catch(err => {
          // writer.abort({reason: 'Problema na impressão'});
          console.error(err);
        })
    }
  }, [showTerms]);

  const acceptUserTerm = () => {
    if (!!userTerm && accepted) {
      api.post("/users/verify", {
        user_term_id: userTerm.id,
        token: reCaptcha
      }).then((response) => 
        loadLicensee()
      )
      .catch((err) => console.error("err", err));
    }
  };

  function getButtonStatus() {
    if (accepted && reCaptcha && userTerm) {
      return false;
    } else return true;
  }

  return ReactDOM.createPortal(
    <Container open={open}>
      <div className="modal_wrapper">
        <ModalContent showTerms={!!terms}>
          <div className="header">
            <div className="title">Termos de uso</div>
          </div>

          {!!terms && (
              <iframe srcDoc={terms} width="100%" id="docFrame" scrolling="no" style={{overflow: 'hidden'}}
              onLoad={() => {
                var obj = document.getElementById( 'docFrame' ) as HTMLIFrameElement;
                if (obj && obj.contentWindow) {
                  obj.style.height = (obj.contentWindow.document.body.scrollHeight + 60) + 'px';
                }
              }}></iframe>
          )}

          <div className="accept_section">
            <div className="accept_check">
              <Checkbox
                checked={accepted}
                handleChange={() => setAccepted((prevState) => !prevState)}
              />
              <span>
                Li e estou de acordo com os{" "}
                <strong onClick={() => setShowTerms(true)}>
                  Termos de uso
                </strong>
              </span>
            </div>
            { process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(value) => setReCaptcha(value)}
              />
             ) }
          </div>

          <Footer>
            <CancelButton
              type="button"
              onClick={() => {
                signOut()
                setOpen(false)
              }}
            >
              Sair do aplicativo
            </CancelButton>
            <CloseButton
              type="button"
              disabled={getButtonStatus()}
              onClick={() => acceptUserTerm()}
            >
              Confirmar
            </CloseButton>
          </Footer>
        </ModalContent>
      </div>
    </Container>,
    document.body
  );
}
