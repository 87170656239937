import { i18n } from "../../../../localization/i18n";

export const orderTypeOptions = [
  { optionText: "B2B", value: "is_partner" },
  { optionText: "B2C", value: "is_patient" },
  { optionText: "FIXITER", value: "is_fixiter" },
];

export const solutionTypeOptions = [
  {
    optionText: i18n.t("financial.prices.solution_type.standard"),
    value: "standard",
  },
  {
    optionText: i18n.t("financial.prices.solution_type.custom"),
    value: "custom",
  },
];

export const nullOption = {
  optionText: i18n.t("global.novalue"),
  value: "",
};
