import styled, { css } from "styled-components";

type PlanProps = {
  selected: boolean;
};

export const Container = styled.div<PlanProps>`
  width: 100%;
  min-height: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid var(--fixit);
  background: var(--white);

  display: flex;
  flex-direction: column;

  transition: 0.3s all;

  svg {
    min-width: 20px;
    min-height: 20px;
    color: var(--fixit);
  }

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 450px) {
    width: 100%;
    min-height: 450px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: var(--active-green);
      transform: translateY(-10px);

      svg {
        color: var(--active-green);
      }

      &:hover {
      }
    `};
`;

export const PlanTitle = styled.span`
  font-size: 20px;
  color: var(--dark-gray);
`;

export const Price = styled.span`
  font-size: 22px;
  font-weight: 700;
  color: var(--dark-gray);
  margin-top: 10px;
`;

export const PlanDescription = styled.div`
  font-size: 16px;
  color: var(--dark-gray);
  margin-top: 10px;
`;

export const PlanItemsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .plan_item {
    flex: 1;
  }

  .advantage {
    width: 100%;
    font-size: 16px;
    color: var(--dark-gray);
    /* white-space: word-wrap; */
  }
`;

export const PlanFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  padding-top: 28px;
  margin-top: auto;
`;
