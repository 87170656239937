import { useEffect, useState } from "react";

import * as Yup from "yup";

import { Modal } from "../../../../../../components/Modal";
import { Tabs } from "../../../../../../components/Tabs";
import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { DiagnosisLanguageForm } from "./components/DiagnosisLanguageForm";

import api from "../../../../../../services/api";

import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../../../utils/validateFormFields";

import {
  Diagnosis,
  DiagnosisData,
  LanguageDiagnosisData,
  LanguageValues,
} from "../../dtos";

import { RiCloseLine } from "react-icons/ri";

import { Container, Header, Content, Footer } from "./styles";

interface DiagnosisModalProps {
  open: boolean;
  diagnosisToEdit?: Diagnosis;
  handleCloseModal: () => void;
  getDiagnosis: () => void;
}

export function DiagnosisModal({
  open,
  handleCloseModal,
  getDiagnosis,
  diagnosisToEdit,
}: DiagnosisModalProps) {
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [diagnosisData, setDiagnosisData] = useState<DiagnosisData>({
    cid: "",
    verified: true,
  });
  const [languageDiagnosisData, setLanguageDiagnosisData] = useState({
    "pt-BR": {
      name: "",
      description: "",
    },
    en: {
      name: "",
      description: "",
    },
    es: {
      name: "",
      description: "",
    },
  });

  const tabsComponents = [
    {
      tabName: "PT-BR",
      component: (
        <DiagnosisLanguageForm
          language="pt-BR"
          changeLanguageDiagnosisData={changeLanguageDiagnosisData}
          languageDiagnosisData={languageDiagnosisData}
          diagnosisData={diagnosisData}
          changeDianosisData={changeDiagnosisData}
          errors={errors}
        />
      ),
    },
    {
      tabName: "EN",
      component: (
        <DiagnosisLanguageForm
          language="en"
          changeLanguageDiagnosisData={changeLanguageDiagnosisData}
          languageDiagnosisData={languageDiagnosisData}
          diagnosisData={diagnosisData}
          changeDianosisData={changeDiagnosisData}
          errors={errors}
        />
      ),
    },
    {
      tabName: "ES",
      component: (
        <DiagnosisLanguageForm
          language="es"
          changeLanguageDiagnosisData={changeLanguageDiagnosisData}
          languageDiagnosisData={languageDiagnosisData}
          diagnosisData={diagnosisData}
          changeDianosisData={changeDiagnosisData}
          errors={errors}
        />
      ),
    },
  ];

  async function handleCreateDiagnosis() {
    setLoading(true);

    try {
      const name = {
        "pt-BR": languageDiagnosisData["pt-BR"].name,
        en: languageDiagnosisData.en.name,
        es: languageDiagnosisData.es.name,
      };

      const description = {
        "pt-BR": languageDiagnosisData["pt-BR"].description,
        en: languageDiagnosisData.en.description,
        es: languageDiagnosisData.es.description,
      };

      const body = {
        name: name,
        description: description,
        cid: diagnosisData.cid,
        verified: true,
      };

      await api.post("/diagnosis", body);

      handleCloseModal();
      clearModalData();
      getDiagnosis();
      showToast({
        type: "success",
        message: "Diagnóstico cadastrado com sucesso",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao registrar o diagnóstico.",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateDiagnosis() {
    setLoading(true);

    try {
      const name = {
        "pt-BR": languageDiagnosisData["pt-BR"].name,
        en: languageDiagnosisData.en.name,
        es: languageDiagnosisData.es.name,
      };

      const description = {
        "pt-BR": languageDiagnosisData["pt-BR"].description,
        en: languageDiagnosisData.en.description,
        es: languageDiagnosisData.es.description,
      };

      const body = {
        name: name,
        description: description,
        cid: diagnosisData.cid,
        verified: diagnosisData.verified,
      };

      await api.put(`/diagnosis/${diagnosisToEdit?.id}`, body);

      handleCloseModal();
      clearModalData();
      getDiagnosis();
      showToast({
        type: "success",
        message: "Diagnóstico cadastrado com sucesso",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao atualizar o diagnóstico.",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleValidateForm() {
    const body = {
      cid: diagnosisData.cid,
      "pt-BR": {
        name: languageDiagnosisData["pt-BR"].name,
        description: languageDiagnosisData["pt-BR"].description,
      },
      en: {
        name: languageDiagnosisData.en.name,
        description: languageDiagnosisData.en.description,
      },
      es: {
        name: languageDiagnosisData.es.name,
        description: languageDiagnosisData.es.description,
      },
    };

    const diagnosisSchema = Yup.object().shape({
      cid: Yup.string().required(typesErrors.required),
      "pt-BR": Yup.object().shape({
        name: Yup.string().required(typesErrors.required),
      }),
      en: Yup.object().shape({
        name: Yup.string().required(typesErrors.required),
      }),
      es: Yup.object().shape({
        name: Yup.string().required(typesErrors.required),
      }),
    });

    const validation = await validateFormFields(body, diagnosisSchema, {
      cid: "",
      "pt-BR": {
        name: "",
      },
      en: {
        name: "",
      },
      es: {
        name: "",
      },
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: "Verifique os campos com erro",
      });
      return;
    }

    setErrors({});
    if (diagnosisToEdit) handleUpdateDiagnosis();
    else handleCreateDiagnosis();
  }

  function changeLanguageDiagnosisData(
    data: Partial<LanguageValues>,
    language: keyof LanguageDiagnosisData
  ) {
    setLanguageDiagnosisData((prevState) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        ...data,
      },
    }));
  }

  function changeDiagnosisData(data: Partial<DiagnosisData>) {
    setDiagnosisData((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  function clearModalData() {
    setErrors({});
    setDiagnosisData({
      cid: "",
      verified: true,
    });
    setLanguageDiagnosisData({
      "pt-BR": {
        name: "",
        description: "",
      },
      en: {
        name: "",
        description: "",
      },
      es: {
        name: "",
        description: "",
      },
    });
  }

  function getDiagnosisToEditInfos() {
    try {
      if (diagnosisToEdit) {
        setDiagnosisData({
          cid: diagnosisToEdit.cid,
          verified: diagnosisToEdit.verified,
        });
        setLanguageDiagnosisData({
          "pt-BR": {
            name: diagnosisToEdit.name["pt-BR"],
            description: diagnosisToEdit.description["pt-BR"],
          },
          en: {
            name: diagnosisToEdit.name.en,
            description: diagnosisToEdit.description.en,
          },
          es: {
            name: diagnosisToEdit.name.es,
            description: diagnosisToEdit.description.es,
          },
        });
      }
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao carregar os dados do diagnóstico",
      });
    }
  }

  useEffect(() => {
    if (diagnosisToEdit) {
      getDiagnosisToEditInfos();
    }
  }, [diagnosisToEdit]);

  return (
    <Modal open={open}>
      <Container>
        <Header>
          <span className="title">Novo diagnóstico</span>
          <RiCloseLine
            onClick={() => {
              handleCloseModal();
              clearModalData();
            }}
          />
        </Header>

        <Content>
          <Tabs tabs={tabsComponents} />
        </Content>

        <Footer>
          <OutlinedButton
            text={
              diagnosisToEdit
                ? "Atualizar diagnóstico"
                : "Registrar diagnóstico"
            }
            buttonColor="var(--finished)"
            onClick={() => handleValidateForm()}
            loading={loading}
          />
        </Footer>
      </Container>
    </Modal>
  );
}
