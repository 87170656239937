import { messages as portugueseMessages } from './ptBR'
import { messages as spanishMessages } from './es'
import { messages as englishMessages} from './en'

const messages = {
  ...portugueseMessages,
  ...spanishMessages,
  ...englishMessages
}

export { messages }
