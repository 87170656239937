import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
  ButtonIcon,
} from "./styles";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { PrinterItem } from "./components/PrinterItem";
import { Modal } from "../../../../components/Modal";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { EditModal } from "./components/EditModal";


export interface IPrinter {
  id: string;
  name: string;
  device_vendor_id: string;
  device_product_id: string;
  xyz: string[];
}

export default function Printers() {
  const { setNavigationAction } = useAuth();
  const [ printers, setPrinters ] = useState<IPrinter[]>();
  const [ isCreateModalOpen, setIsCreateModalOpen ] = useState(false);

  const getPrinters = async () => {
    try {
      const { data } = await api.get(`/printers`)
      setPrinters(data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPrinters();

    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.printers.header")
    });

  }, []);

  return (
    <Container>
      {
        isCreateModalOpen && (
          <Modal open={isCreateModalOpen}>
            <EditModal 
              closeModal={async () => {
                await getPrinters();
                setIsCreateModalOpen(false)
              }}
            />
          </Modal>
        )
      }
      <EvaluationWrapper>
        {
          printers?.map((printer) => {
            return (
              <PrinterItem 
                printer={printer} 
                getPrinters={getPrinters}
                />
            )
          })
        }
      </EvaluationWrapper>
      <AbsoluteButton
        absolute
        onClick={() => {
          setIsCreateModalOpen(true);
        }}
      />
    </Container>
    )
  }

