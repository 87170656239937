import { ILanguagesProps } from "../../dtos";

export const languages: ILanguagesProps = {
  ptBr: {
    description: "",
    advantages: [""],
    price: 0,
  },
  Es: {
    description: "",
    advantages: [""],
    price: 0,
  },
  En: {
    description: "",
    advantages: [""],
    price: 0,
  },
};
