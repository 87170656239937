import { useState, useEffect } from "react";

import api from "../../../../services/api";
import { Header, InputsContainer, Inputs, Container } from "./styles";
import { useAuth } from "../../../../hooks/AuthContext";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { IPrinterOption, ISolution, ISolutionOptions } from "./dtos";
import { SolutionsItem } from "../../components/SolutionsItem";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { StandardConfigModal } from "../../components/StandardConfigModal";
import { usePrinters } from "../../../../hooks/PrintersContext";

export default function StandardSolutionsList() {
  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const [solution, setSolution] = useState<ISolution>();
  const [solutionsOptions, setSolutionsOptions] = useState<ISolutionOptions[]>([]);
  const [solutionsOption, setSolutionsOption] = useState<ISolutionOptions>();
  const [openStandardConfigModal, setOpenStandardConfigModal] = useState(false);
  const { defaultPrinter } = usePrinters();

  const { setNavigationAction } = useAuth();
  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: 'Gerenciar Soluções Padronizadas',
    });
  }, [])

  useEffect(() => {
    if(solutionsOption?.id) {
      getSolutionById(solutionsOption.id)
    }
  }, [solutionsOption, openStandardConfigModal])

  const toggleStandardConfigModal = () => {
    setOpenStandardConfigModal(!openStandardConfigModal);
  }

  useEffect(() => {
      reloadFiles();
  }, [openStandardConfigModal]);

  useEffect(() => {
    reloadFiles();
}, []);

  async function reloadFiles() {
    try {
      const abortController = new AbortController();
      let signal = abortController.signal;
    
      let queryList = [];
      queryList.push(`is_standard=${true}`)

      await api.get(`/solutions?${queryList.join('&')}`, { signal }).then((response) => {
      
        setSolutionsOptions(response.data.map((solution: any) => {
          return {
            id: solution.id,
            value: solution.name_key,
            optionText: solution.name,
          };
        }));

        setSolutions(response.data);
      });

    } catch (err) {
      console.log(err);
    }
  }

  async function getStandardFiles (standard_id: string, printer_id: string, side: string, part: string) {
    try {
      const response = await api.get(`standard-files/standard/${standard_id}/${printer_id}/${side}/${part}`);

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async function getSolutionById(id: string) {
    try {
      await api.get(`/solutions/${id}`).then((response) => {
        setSolution(response.data);
      })
    } catch (err) {
      console.log(err);
    }
  }
 
  const updateStandard = async function (standardId: string, size: string, order: string) {
    try {
      await api
        .patch(`/solutions/standard/${standardId}`,
          {
            size,
            order
          });
      // loadSolutions();
      showToast({ type: "success", message: "Tamanho atualizado com sucesso!" });
    } catch {
      showToast({
        type: "error",
        message: "Erro ao atualizar o tamanho!"
      });
    }
  }

  return (
    <>
      { solutionsOption?.id ?
      <StandardConfigModal 
        open={openStandardConfigModal}
        solutionId={solutionsOption?.id}
        solution={solution}
        closeModal={toggleStandardConfigModal}
        onSave={toggleStandardConfigModal}
        reloadFiles={reloadFiles}
      />
      : null
      }
      <Header>
        <Inputs>
          <InputsContainer>
            <OutlinedSelect 
              label={'Solução'}
              options={solutionsOptions}
              handleSelect={(selected) => setSolutionsOption({
                ...selected,
                value: selected.value.toString(),
                id: selected?.id?.toString(),
              })} 
              selectedOption={solutionsOption?.optionText || ""}        
            />
          </InputsContainer>
        </Inputs>
      </Header>

      { (defaultPrinter && solutions) &&
        <Container>
          {solutions.map((solution) => {
          if (solutionsOption?.value === solution.name_key) {
            return (
              solution.sides.map((sides, index) => {
                return (
                  solution.standards.map((standard) => {
                    return (
                      solution.parts.map((part) => {
                        return (
                          <SolutionsItem
                            key={index}
                            standard={standard}
                            side={sides}
                            part={part}
                            printer={defaultPrinter} 
                            updateStandard={updateStandard} 
                            solution={solution}
                            reloadFiles={reloadFiles}
                            getStandardFiles={getStandardFiles}
                          />
                        )
                      })
                    )
                  })
                )
              })
            )
          }
        })
        }
        </Container>
      }
    </>
  );
}
