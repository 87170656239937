import styled from "styled-components";
import global from "../../../../../../global";

type ButtonProps = {
  disabled?: boolean;
  providerSelected?: boolean;
};

export const Container = styled.div`
  width: 350px;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    padding: 16px 20px 16px 22px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;


export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    gap: 15px;
  }
`;

export const ImageContainer = styled.div`
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    filter: invert(100%) sepia(0%) saturate(7455%) hue-rotate(293deg) brightness(108%) contrast(106%);
  }
`;



export const PrincipalInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  min-width: 180px;
  max-width: 180px;

  .identity {
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    font-weight: bolder;
  }

  .date {
    font-size: 10px;
    line-height: 10px;
    color: var(--gray);
    font-weight: 500;
    margin-top: 5px;
  }

  .solution {
    font-size: 14px;
    line-height: 14px;
    color: var(--active-green);
    font-weight: bolder;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    min-width: 180px;
    max-width: 180px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }
`;

export const ActionItem = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    &.print {
      display: none;
    }

    &.download {
      display: none;
    }
  }
`

export const IconButtonContainer = styled.div<ButtonProps>`
  position: relative;
  display: flex;
  gap: 10px;

  svg {
    color: ${props => props.disabled ? 'var(--light-gray)' : 'var(--gray)'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }
`