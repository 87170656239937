import { useEffect, useState } from "react";

import * as Yup from "yup";
import { i18n } from "../../../../../../localization/i18n";

import { Checkbox } from "../../../../../../components/Checkbox";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { showToast } from "../../../../../../components/CustomToast";
import DropZone from "../DropZone";

import api from "../../../../../../services/api";

import validateFormFields from "../../../../../../utils/validateFormFields";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import { planTypes } from "../../../../../../utils/constants";

import { IAdvantages, IFile, IFormData, IOption, ISolutions } from "../../dtos";

import { languages } from "./mockOptions";

import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import {
  RiCloseFill,
  RiDeleteBin7Line,
  RiSaveLine,
  RiCheckFill,
} from "react-icons/ri";

import {
  Container,
  Header,
  FormContainer,
  CheckboxContainer,
  InputLine,
  InputContainer,
  FlexContainer,
  FlexContainerButton,
  Footer,
  SaveText,
  SolutionsContainer,
  SelectedSolutions,
  Label,
  LinkButton,
  Tag,
  DropzoneContainer,
  File,
  NextStep,
  BackText,
  TextArea,
  DescriptionContainer,
  UploadFilesContainer,
  ScrollContainer,
} from "./styles";

interface ICloseModal {
  closeModal: () => void;
  onCreatePlan: () => void;
}

export function CreatePlan({ closeModal, onCreatePlan }: ICloseModal) {
  const currencyOptions = [
    { value: "BRL", optionText: "Real" },
    { value: "USD", optionText: "Dólar" },
  ];

  const solutionTypesOptions = [
    { value: "all", optionText: "Todas" },
    { value: "standard", optionText: "Padronizadas" },
    { value: "custom", optionText: "Personalizadas" },
  ];

  const formattedPlanTypes = Object.entries(planTypes).map((item) => ({
    optionText: i18n.t(`financial.prices.plan_type.${item[0]}`),
    value: item[1],
  }));

  const [file, setFile] = useState({} as IFile);
  const [formData, setFormData] = useState<IFormData>({
    pricePerMonth: 0,
    OrdersPerMonth: 0,
    numberOfSolutions: 0,
    link_image: "",
    avaliableSolutions: [],
    trial_period: 0,
    plan_name: "",
    solutionsType: {
      optionText: "",
      value: "",
    },
    plan_type: {
      optionText: "",
      value: "",
    },
    currency: {
      value: "",
      optionText: "",
    },
    description: "",
  });

  const [solutions, setSolutions] = useState<any[]>([]);
  const [availableSolutions, setAvailableSolutions] = useState<IOption[]>([]);
  const [errors, setErrors] = useState({} as any);
  const [formSteps, setFormSteps] = useState(1);

  const [ptBr, setIsptBr] = useState(true);
  const [Es, setIsEs] = useState(false);
  const [En, setIsEn] = useState(false);

  const [descriptionBr, setDescriptionBr] = useState("");
  const [advantagesBr, setAdvantagesBr] = useState<IAdvantages[]>([
    {
      advantages: "",
      key: Date.now(),
    },
  ]);

  const [descriptionEs, setDescriptionEs] = useState("");
  const [advantagesEs, setAdvantagesEs] = useState<IAdvantages[]>([
    {
      advantages: "",
      key: Date.now(),
    },
  ]);

  const [descriptionEn, setDescriptionEn] = useState("");
  const [advantagesEn, setAdvantagesEn] = useState<IAdvantages[]>([
    {
      advantages: "",
      key: Date.now(),
    },
  ]);

  const [saveBr, setSaveBr] = useState(false);
  const [saveEn, setSaveEn] = useState(false);
  const [saveEs, setSaveEs] = useState(false);

  function handleLanguageBr() {
    setIsEs(false);
    setIsEn(false);
    setIsptBr(!ptBr);
  }

  function handleLanguageEs() {
    setIsEs(!Es);
    setIsptBr(false);
    setIsEn(false);
  }

  function handleLanguageEn() {
    setIsEn(!En);
    setIsEs(false);
    setIsptBr(false);
  }

  const completeFormStep = () => {
    setFormSteps((step) => step + 1);
  };

  const backFormStep = () => {
    setFormSteps((step) => step - 1);
  };

  async function validateForm(event: any) {
    event.preventDefault();

    const body = {
      ...formData,
      price: formData?.pricePerMonth,
      orders_quantity:
        Number(formData?.OrdersPerMonth) === 0 ||
        formData?.OrdersPerMonth === ""
          ? null
          : formData?.OrdersPerMonth,
      solutions_quantity:
      Number(formData?.numberOfSolutions) === 0 ||
      formData?.numberOfSolutions === "" ? null : formData?.numberOfSolutions,
      link_image: formData?.link_image,
      avaliable_solutions: formData?.avaliableSolutions?.length
        ? formData?.avaliableSolutions?.map((item) => item.id)
        : null,
      trial_period: formData?.trial_period,
      plan_name: formData?.plan_name,
      plan_type: formData?.plan_type.value,
      custom_orders:
        (formData?.solutionsType?.value === "all" ||
          formData?.solutionsType?.value === "custom") &&
        true,
      standard_orders:
        (formData?.solutionsType?.value === "all" ||
          formData?.solutionsType?.value === "standard") &&
        true,
      currency: formData?.currency.value,
      description: JSON.stringify(languages),
    };

    const validationSchema = Yup.object().shape({
      price: Yup.number().positive().min(0).required(typesErrors.required),
      orders_quantity: Yup.number().min(0).nullable(),
      solutions_quantity: Yup.number().min(0).nullable(),
      trial_period: Yup.number()
        .positive()
        .min(0)
        .required(typesErrors.required),
      plan_name: Yup.string().required(typesErrors.required),
      plan_type: Yup.string().required(typesErrors.required),
      currency: Yup.string(),
      description: Yup.string(),
    });

    const validation = await validateFormFields(body, validationSchema, {
      plan_name: "",
      orders_quantity: "",
      solutions_quantity: "",
      trial_period: "",
      price: "",
      description: "",
      currency: {
        value: "",
        optionText: "",
      },
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: "Verifique os campos com erro.",
      });
      console.log(validation.errors);

      return;
    }

    handleSubmitPlan(body);
  }

  function clearInputs() {
    setFormData({
      pricePerMonth: undefined,
      OrdersPerMonth: undefined,
      avaliableSolutions: [],
      trial_period: undefined,
      link_image: "",
      numberOfSolutions: 0,
      plan_name: "",
      solutionsType: {
        optionText: "",
        value: "",
      },
      currency: {
        value: "",
        optionText: "",
      },
      plan_type: {
        optionText: "",
        value: "",
      },
      description: "",
    });
    setSaveBr(false);
    setSaveEn(false);
    setSaveEs(false);
  }

  async function handleSubmitPlan(body: any) {
    try {
      await api.post(`/plans`, body);

      onCreatePlan();

      showToast({
        type: "success",
        message: "Plano criado com sucesso!",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro  ao tentar criar o plano.",
      });
    } finally {
      closeModal();
      clearInputs();
    }
  }

  async function getSolutions() {
    try {
      await api.get("/solutions").then((response) => {
        const formattedSolutions = response.data.map((solution: any) => ({
          value: solution.id,
          optionText: solution.name,
        }));

        setSolutions(response.data);
        setAvailableSolutions(formattedSolutions);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar atualizar as soluções disponíveis",
      });
    }
  }

  function handleAddNewAdvantage() {
    setAdvantagesBr((prevState) => [
      ...prevState,
      {
        advantages: "",
        key: Date.now(),
      },
    ]);
  }

  function handleAddNewEsAdvantage() {
    setAdvantagesEs((prevState) => [
      ...prevState,
      {
        advantages: "",
        key: Date.now(),
      },
    ]);
  }

  function handleAddNewEnAdvantage() {
    setAdvantagesEn((prevState) => [
      ...prevState,
      {
        advantages: "",
        key: Date.now(),
      },
    ]);
  }

  function handleSetDescriptionBrLanguage() {
    languages.ptBr.description = descriptionBr;
  }

  function handleSetDescriptionEsLanguage() {
    languages.Es.description = descriptionEs;
  }

  function handleSetDescriptionEnLanguage() {
    languages.En.description = descriptionEn;
  }

  function handleSetAdvantagesBrLanguage(
    key: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setAdvantagesBr((prevState) => {
      const newState = prevState.map((advantage) => {
        if (advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          };
        }
        return advantage;
      });
      return newState;
    });
    setSaveBr(false);
  }

  function handleSetAdvantagesEsLanguage(
    key: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setAdvantagesEs((prevState) => {
      const newState = prevState.map((advantage, index) => {
        if (advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          };
        }
        return advantage;
      });
      return newState;
    });
    setSaveEs(false);
  }

  function handleSetAdvantagesEnLanguage(
    key: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setAdvantagesEn((prevState) => {
      const newState = prevState.map((advantage) => {
        if (advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          };
        }
        return advantage;
      });
      return newState;
    });
    setSaveEn(false);
  }

  function handleSaveBrLanguage() {
    advantagesBr.map((advantage, index) => {
      return (languages.ptBr.advantages[index] = advantage.advantages);
    });
  }

  function handleSaveEsLanguage() {
    advantagesEs.map((advantage, index) => {
      return (languages.Es.advantages[index] = advantage.advantages);
    });
  }

  function handleSaveEnLanguage() {
    advantagesEn.map((advantage, index) => {
      return (languages.En.advantages[index] = advantage.advantages);
    });
  }

  function handleRemoveTaskById(id: string | number) {
    const deletedTag = formData.avaliableSolutions?.filter(
      (as) => as.id !== id
    );
    setFormData((prevState) => ({
      ...prevState,
      avaliableSolutions: [...deletedTag],
    }));
  }

  useEffect(() => {
    if (formData.solutionsType.value === "all") {
      const formattedSolutions = solutions.map((solution: any) => ({
        value: solution.id,
        optionText: solution.name,
      }));
      setAvailableSolutions([...formattedSolutions]);
    } else if (formData.solutionsType.value === "custom") {
      const filteredSolutions: any = solutions
        .filter((solution) => solution.is_custom)
        .map((solution: any) => ({
          value: solution.id,
          optionText: solution.name,
        }));

      setAvailableSolutions([...filteredSolutions]);
    } else if (formData.solutionsType.value === "standard") {
      const filteredSolutions: any = solutions
        .filter((solution) => solution.is_standard)
        .map((solution: any) => ({
          value: solution.id,
          optionText: solution.name,
        }));

      setAvailableSolutions([...filteredSolutions]);
    }

    setFormData((prevState) => ({
      ...prevState,
      avaliableSolutions: [],
    }));
  }, [formData.solutionsType.value]);

  useEffect(() => {
    setFormSteps(1);
  }, [closeModal]);

  useEffect(() => {
    getSolutions();
  }, []);

  if (formSteps === 1) {
    return (
      <Container>
        <RiCloseFill
          size={24}
          color="var(--fixit)"
          onClick={() => {
            backFormStep();
            closeModal();
            clearInputs();
          }}
        />

        <Header>
          <span>Criar novo plano</span>
        </Header>

        <FormContainer>
          <InputContainer>
            <OutlinedInput
              label={`${i18n.t("financial.prices.field.plan_name")}`}
              inputName="plan_name"
              type="text"
              handleChange={(event) => {
                setFormData((prevState) => ({
                  ...prevState,
                  plan_name: event,
                }));
              }}
              value={formData?.plan_name || ""}
              autoComplete="off"
            />
          </InputContainer>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Tipo do plano"
                options={formattedPlanTypes}
                handleSelect={(selected) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    plan_type: selected,
                  }));
                }}
                selectedOption={formData?.plan_type?.optionText}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("financial.prices.field.file_price")}`}
                inputName="file_price"
                type="number"
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    pricePerMonth: event,
                  }));
                }}
                value={formData?.pricePerMonth || ""}
                min={0}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedSelect
                selectedOption={formData.currency?.optionText}
                label={"Moeda"}
                options={currencyOptions}
                handleSelect={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    currency: {
                      value: event.value.toString(),
                      optionText: event.optionText,
                    },
                  }));
                }}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("financial.prices.field.trial_period")}`}
                inputName="trial_period"
                type="number"
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    trial_period: event,
                  }));
                }}
                value={formData?.trial_period || ""}
                min={0}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    OrdersPerMonth: event,
                  }));
                }}
                type="number"
                inputName={"plans_ordersPerMonth"}
                label={"Pedidos por mês"}
                value={formData?.OrdersPerMonth || ""}
                min={0}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Tipo de soluções"
                options={solutionTypesOptions}
                handleSelect={(selected) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    solutionsType: selected,
                  }));
                }}
                selectedOption={formData?.solutionsType?.optionText}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedInput
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    numberOfSolutions: event,
                    avaliableSolutions: [],
                  }));
                }}
                type="number"
                inputName={"plans_numbersOfSolutions"}
                label={`${i18n.t("financial.prices.field.numberOfSolutions")}`}
                value={formData?.numberOfSolutions || ""}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label={"Soluções disponíveis"}
                options={availableSolutions}
                handleSelect={(event) => {
                  const solutionsLimit =
                    formData.numberOfSolutions >
                    formData.avaliableSolutions.length;
                  const solutionAlreadySelected =
                    formData.avaliableSolutions.find(
                      (item) => item.id === event.value
                    );
                  if (!solutionAlreadySelected && solutionsLimit) {
                    setFormData((prevState) => ({
                      ...prevState,
                      avaliableSolutions: [
                        ...prevState.avaliableSolutions,
                        {
                          id: event.value,
                          optionText: event.optionText,
                        },
                      ],
                    }));
                  }
                }}
                selectedOption={""}
              />
            </InputContainer>
          </InputLine>
        </FormContainer>
        <SolutionsContainer>
          <Label>Soluções selecionadas</Label>
          <SelectedSolutions>
            {formData.avaliableSolutions.map((s) => {
              return (
                <Tag key={s.id}>
                  <span>{s.optionText}</span>
                  <RiCloseFill
                    size={14}
                    onClick={() => {
                      handleRemoveTaskById(s.id);
                    }}
                  />
                </Tag>
              );
            })}
          </SelectedSolutions>
        </SolutionsContainer>
        <Footer>
          <NextStep onClick={completeFormStep}>
            Próximo
            <FiArrowRight size="1rem" />
          </NextStep>
        </Footer>
      </Container>
    );
  }
  if (formSteps === 2) {
    return (
      <Container>
        <RiCloseFill
          size={24}
          color="var(--fixit)"
          onClick={() => {
            closeModal();
            clearInputs();
          }}
        />

        <Header>
          <span>Criar novo plano</span>
        </Header>
        <FlexContainer>
          <CheckboxContainer>
            <Checkbox checked={ptBr} handleChange={() => handleLanguageBr()} />
            <span>pt-BR</span>
          </CheckboxContainer>

          <CheckboxContainer>
            <Checkbox checked={Es} handleChange={() => handleLanguageEs()} />
            <span>ES</span>
          </CheckboxContainer>

          <CheckboxContainer>
            <Checkbox checked={En} handleChange={() => handleLanguageEn()} />
            <span>EN</span>
          </CheckboxContainer>
        </FlexContainer>

        {ptBr && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Português</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionBr(event.target.value);
                  setSaveBr(false);
                }}
                placeholder="Escreva uma descrição do plano"
                defaultValue={languages.ptBr.description}
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesBr.map((advantage, index) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Português</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        handleSetAdvantagesBrLanguage(advantage.key, event)
                      }
                      placeholder={`Vantagem ${index}`}
                      defaultValue={advantage.advantages}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveBr ? (
                <LinkButton
                  onClick={() => {
                    setSaveBr(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionBrLanguage();
                    handleSaveBrLanguage();
                    setSaveBr(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}
        {Es && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Espanhol</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionEs(event.target.value);
                  setSaveEs(false);
                }}
                placeholder="Escribe una descripción del plan"
                defaultValue={languages.Es.description}
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesEs.map((advantage, index) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Espanhol</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        handleSetAdvantagesEsLanguage(advantage.key, event)
                      }
                      placeholder={`Ventaja ${index}`}
                      defaultValue={advantage.advantages}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewEsAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveEs ? (
                <LinkButton
                  onClick={() => {
                    setSaveEs(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionEsLanguage();
                    handleSaveEsLanguage();
                    setSaveEs(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}

        {En && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Inglês</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionEn(event.target.value);
                  setSaveEn(false);
                }}
                placeholder="Write a description for the Plan"
                defaultValue={languages.En.description}
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesEn.map((advantage, index) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Inglês</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        handleSetAdvantagesEnLanguage(advantage.key, event)
                      }
                      placeholder={`Advantage ${index}`}
                      defaultValue={advantage.advantages}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewEnAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveEn ? (
                <LinkButton
                  onClick={() => {
                    setSaveEn(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionEnLanguage();
                    handleSaveEnLanguage();
                    setSaveEn(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}

        <UploadFilesContainer>
          <DropzoneContainer>
            <span>Adicione uma foto</span>
            <DropZone
              accept={{'image/*': [".gif", ".jpeg",".jpg",".png"]}}
              onUpload={(file) => {
                const formattedFile = {
                  name: file[0].name,
                  size: file[0].size,
                  type: file[0].type,
                  file_data: file[0],
                };
                setFile(formattedFile);
              }}
            />
          </DropzoneContainer>

          {file.name && (
            <File>
              <span>{file.name}</span>
              <RiDeleteBin7Line
                onClick={() => setFile({} as IFile)}
                size={20}
                color="var(--fixit)"
              />
            </File>
          )}
        </UploadFilesContainer>
        <Footer>
          <BackText onClick={backFormStep}>
            <FiArrowLeft size="1rem" />
            Voltar
          </BackText>
          <SaveText
            onClick={(event: any) => {
              saveBr && saveEs && saveEn
                ? validateForm(event)
                : showToast({
                    type: "error",
                    message: "Confira se todos os campos estão salvos!",
                  });
            }}
          >
            Criar Plano
          </SaveText>
        </Footer>
      </Container>
    );
  } else return null;
}
