import React, { useEffect, useState } from "react";

import { i18n } from "../../localization/i18n";

import { showToast } from "../../components/CustomToast";
import { ProgressBar } from "../../components/ProgressBar";

import {
  Container,
  DropdownContainer,
  PrintButton,
  CloseButton,
  FindPrinterContainer,
  FindPrinter,
  CrtButtons,
  PrintingActionButton,
  PrintingProblemButton,
  SelectPrinterContainer,
  PrinterDisplay,
  TemperatureDisplay,
  Field
} from "./styles";
import api from "../../services/api";
import { RiErrorWarningLine, RiPauseCircleLine, RiPlayCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { IFileStream, IPrinter } from "../../modules/Orders/dtos";
import LineBreakTransformer from "../../utils/stream/LineBreakTransformer";
import { usePrinters } from "../../hooks/PrintersContext";
import ByteSliceTransformer from "../../utils/stream/ByteSliceTransformer";
import DecoderTransformer from "../../utils/stream/DecoderTransformer";
import { ALGO } from "../../utils/constants";
import { EncryptionFactory } from "../../utils/crypto/GenerateEncryptionKey";
interface ISendToPrinterProps {
  fileStream: IFileStream;
  printingId: string;
  progress: number;
  printer: IPrinter;
  setContentLength: (value: React.SetStateAction<number>) => void;
  setReceived: (value: React.SetStateAction<number>) => void;
  resumePrinting: () => void;
  printStart: () => void;
  printEnd: () => void;
}

const encoder = new TextEncoder();

interface IPorts {
  index: number;
  optionText: string;
  value: string;
  port: SerialPort;
  status: string;
}

const filters: any = {
  "4292|60000": {
    name: "Impressora Sethi3D S4X",
    usbVendorId: 4292,
    usbProductId: 60000,
  },
};

/* Pegar o máximo X da impressora */
const commands = {
  'changeFilament': 'M600',
  'pause': 'M226', // testando .... o pause é o M25, e o pause M226 é safety, executa o que estiver na fila.
  'resume': 'M24',
  'autoReportTemperature': 'M155 S10',
  'bedTemperature': 'M190',
  'extruderTemperature': 'M109',
  'temperatureStatus': 'M105 X0',
  'relativePositioning': 'G91',
  'absolutePositioning': 'G90',
  'extruderCleanMove': 'G0 X150.0', // Movimento com a mesma velocidade 
  'retractExtruder': 'G1 E-5 F500', // Não deveria mexer no feedrate
  'swtichOffFan': 'M106 S0',
  'switchOffExtruder': 'M104 S0',
  'switchOffBed': 'M140 S0',
  'stopInconditional': 'M0',
  'sendMessage': (message: string) => `M117 ${message}`,
  'extruderOff': 'G0 X15.0 Y15.0',
  'restoreBedTemperature': (bedPrintingTemperature: number) => `M190 S${bedPrintingTemperature} T0`,
  'restoreExtruderTemperature': (extruderPrintingTemperature: number) => `M109 S${extruderPrintingTemperature} T0`
};

interface Blob {
  readonly size: number;
  readonly type: string;
  arrayBuffer(): Promise<ArrayBuffer>;
  slice(start?: number, end?: number, contentType?: string): Blob;
  stream(): ReadableStream<Uint8Array>;
  text(): Promise<string>;
}

const SendToPrinter = ({
  progress,
  printingId,
  printer,
  setContentLength,
  setReceived,
  resumePrinting,
  printStart,
  printEnd,
  fileStream,
}: ISendToPrinterProps) => {
  const [gCodeControlCommand, setGCodeControlCommand] = useState<string>('ready');
  const [control, setControl] = useState<string>('human');
  const [printerMessage, setPrinterMessage] = useState<string>();
  const [gcodeStreamReader, setGcodeStreamReader] = useState<ReadableStreamDefaultReader<string>>();
  const [printerReader, setPrinterReader] = useState<ReadableStreamDefaultReader<string>>();
  const [printerWriter, setPrinterWriter] = useState<WritableStreamDefaultWriter<string>>();

  const [extruderTemperature, setExtruderTemperature] = useState<number>(0);
  const [extruderTemperatureGoal, setExtruderTemperatureGoal] = useState<number>(0);
  const [bedTemperature, setBedTemperature] = useState<number>(0);
  const [bedTemperatureGoal, setBedTemperatureGoal] = useState<number>(0);

  const [displayMessage, setDisplayMessage] = useState<string>();
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel>();
  const { defaultPrinter, setPrinting } = usePrinters();

  const [ printingFase, setPrintingFase ] = useState<string>();

  const [ cryptoFileContentLength, setCryptoFileContentLength ] = useState(0);
  const [ cryptoFileReadableStream, setCryptoFileReadableStream ] = useState<ReadableStream<Uint8Array>>();
  const [ downloadedData, setDownloadedData ] = useState(0);

  /*
  Apresentar que está aquecendo a mesa.
  "TargetBed:65"
  "T:26.93 /0 B:27.33 /65"
  */

  /*
  Apresentar que está aquecendo o Extrusor
  TargetExtr0:210
  T:131.57 /210 B:65.72
  */

  // cryptos/9db19c7ab8c3_MioFix_MIOFIX_KIT.fixit
  // 3319273


  async function interrumptPrinting() {
    setDisplayMessage(commands['sendMessage']('Stream ERROR'));
    try {
      await api.delete(`/printings/${printingId}`);
    } catch (err) {
      console.log(err);
    }
  }

  async function sendProgressToServerAndBlockPrinter() {
    setPrinting({
      id: printingId,
      progress: progress,
      sync: false,
    })
  }

  const printStep = (step: string) => {
    setPrintingFase(step);
  }

  useEffect(() => {
    if (cryptoFileContentLength && cryptoFileReadableStream) {
      (async () => {
        const root = await navigator.storage.getDirectory();

        const dirHandle = await root.getDirectoryHandle('tmpFixitFolder', { create: true });
        const fileHandle = await dirHandle.getFileHandle('gcode.fixit', { create: true });

        const writable = await fileHandle.createWritable({keepExistingData: false});
        const writer = writable.getWriter()
  
        await cryptoFileReadableStream
          .pipeTo(new WritableStream({
            write(chunk) {
              setDownloadedData((prevState) => prevState + chunk.length);
              writer.write(chunk)
            }
          }))
        
        await writer.close()
        printStep('print')
        console.log('fechou o writer aqui')
      })()
    }
  }, [cryptoFileContentLength, cryptoFileReadableStream])

  useEffect(() => {
    if (cryptoFileContentLength && printingFase === 'print' && downloadedData > 0 && downloadedData === cryptoFileContentLength) {
      (async () => {
        const root = await navigator.storage.getDirectory();
        const dirHandle = await root.getDirectoryHandle('tmpFixitFolder', { create: false });
        const fileHandle = await dirHandle.getFileHandle('gcode.fixit', { create: false });
        const file : Blob = await fileHandle.getFile();

        const stream = file.stream();

        console.log(file.size);

        if (!process.env.REACT_APP_FIXIT_FILE_KEY) {
          throw new Error("Cannot find ")
        }
  
        const chunk_size = +(process.env.REACT_APP_CHUNK_SIZE || '1024')
        const counter_size = +(process.env.REACT_APP_COUNTER_SIZE || '16')
  
        const textStream = stream
          ?.pipeThrough(new TransformStream(new ByteSliceTransformer(chunk_size + counter_size)))
          ?.pipeThrough(
            new TransformStream(
              new DecoderTransformer(
                ALGO,
                counter_size,
                await new EncryptionFactory().getEncryptionKey(ALGO, process.env.REACT_APP_FIXIT_FILE_KEY),
                (received: number) => {
                  setReceived((prevState) => prevState + received)
                }
              )
            ))
          .pipeThrough(new TextDecoderStream())
  
        const lineBreakStream = textStream.pipeThrough(
          new TransformStream(
            new LineBreakTransformer()
          )
        );
  
        // await sendToPrinterTest(lineBreakStream.getReader())
        await sendToPrinter(lineBreakStream.getReader())
      })()
    }
  }, [downloadedData, cryptoFileContentLength, printingFase])

  const startPrinting = async (response: Response) => {
    if (!response.ok || !response.body) {
      throw response.statusText;
    }

   /* const length = response.headers.get("Content-Length");
    if (!length) {
      throw new Error("Missing Content-Length data");
    }

    const numberLength : number = +length

    setCryptoFileContentLength(numberLength);*/
    setCryptoFileReadableStream(response.body);
  }

  const preparePrint = async (link: string) => {
    try {
      // printStart()
      const token = localStorage.getItem("@FixitApp:token");

      const response = await fetch(link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      if (response.status === 200) {
        console.log("new print")
        await startPrinting(response)
      } else {
        console.log("old print")
        printStart()
        printStep('print')
        const length = response.headers.get("Content-Length");
        if (!length) {
          throw new Error("Wrong response data");
        }

        // const numberLength : number = +length
        // setContentLength(numberLength);

        const stream = response.body;
        const slicedStream = stream?.pipeThrough(new TransformStream(new ByteSliceTransformer(1024)))
        // const [streamPrinter, streamLogger] = slicedStream.tee()
        const logStream = slicedStream.pipeThrough(new TransformStream({
          transform(chunk, controller) {
            setReceived((prevState) => prevState + chunk.length)
            controller.enqueue(chunk)
          }
        }))
        const textStream = logStream.pipeThrough(new TextDecoderStream());

        const lineBreakStream = textStream.pipeThrough(
          new TransformStream(
            new LineBreakTransformer()
          )
        );

        // await sendToPrinterTest(lineBreakStream.getReader())
        await sendToPrinter(lineBreakStream.getReader())
      }
    } catch (err) {
      console.log(err);
      if (printingId) {
        interrumptPrinting();
      }
      showToast({
        type: "error",
        message: "Impressão indisponível",
      });
      return;
    }
  };

  function cancelPrinting () {
    setControl('stop')
  }

  function tooglePauseStopPrinting () {
    if (control === 'paused') {
      printerWriting(commands['restoreBedTemperature'](bedTemperatureGoal)).then(() => {
        setControl('restoreExtruderTemperature')
      })
    } else {
      setControl('pause');
    }
  };

  async function fixPort () {
    if(window.confirm('Tem certeza que deseja reiníciar a porta? Verifique se não existe algum procedimento em execução')) {
      const printerPort = defaultPrinter?.port;
      if (printerPort?.readable && printerPort.readable.locked) {
        try {
          await printerPort.close()
          await printerPort.open({ baudRate: 115200 })

          const newPort = {
            ...printerPort,
            status: 'OK'
          }
        } catch (err) {
          window.alert('Não foi possível liberar a impressora.')
        }
      }
    }
  }

  async function printerReading () {
    if (!printerReader) {
      return;
    }
    try {
      const { value, done } = await printerReader.read();
      if (done) {
        setPrinterReader(undefined)
        setPrinterWriter(undefined)
        return;     
      }
      if (!value) {
        printerReading()
        return
      }
      setPrinterMessage((prevState) => (prevState !== value ? value : `${value} odd`));
    } catch (e) {
      console.log(e)
    }
  }

  async function printerWriting (printerInstruction: string) : Promise<void> {
    if (!printerWriter) {
      return;
    }
    return printerWriter.write(`${printerInstruction}\n`);
  }

  async function sendMessageToDisplay () : Promise<void> {
    if (displayMessage) {
      // await printerWriting(displayMessage)
    }
  }

  async function readGCodeFromServer () : Promise<string | undefined> {
    const { value } : ReadableStreamDefaultReadResult<string> = await gcodeStreamReader!.read()
    if (value) {
          if (value.charAt(0) === ";" || value.charAt(0) === "(") {
            return await readGCodeFromServer()
          }
    
          const commandArray = value.split(' ')
          if (commandArray[0] === commands['bedTemperature']) {
            setBedTemperatureGoal(+commandArray[1].substring(1))
          }
          if (commandArray[0] === commands['extruderTemperature']) {
            setExtruderTemperatureGoal(+commandArray[1].substring(1))
          }
    
          return value;
    }
  }

  useEffect(() => {
    if (printerMessage && (printerMessage === "ok" || printerMessage.split(" ")[0] === "ok")) {
      if (control === 'human' &&  gCodeControlCommand !== 'ready') {
        if (gCodeControlCommand === 'ready') {
          setControl('printer')
        } else {
          try {
            const value = gCodeControlCommand;
            setGCodeControlCommand('ready');
            printerWriting(value).finally(() => {
              // incluir aqui para se tiver mensagem para exibir, o controle  para o 
              setControl('printer')
            }).catch(err => console.log(err))
          } catch (err) {
            console.log(err)
          }
        }
      } else if (control === 'printer') {
        readGCodeFromServer().then((value) => {
          if (value) {
            printerWriting(`${value}`)
              .finally(() => {
                sendMessageToDisplay().then(printerReading)
              })
          }
        }).catch(err => console.log(err))
      } else if (control === 'pause') {
        printerWriting(commands['pause']).then(() => {
          setControl('extruderOff')
        })
      } else if (control == 'extruderOff') {
        printerWriting(commands['extruderOff']).finally(() => {
          setControl('paused')
        })
      } else if (control === 'restoreExtruderTemperature') {
        printerWriting(commands['restoreExtruderTemperature'](extruderTemperatureGoal)).then(() => {
          setControl('extruderCleanMove')
        })  
      } else if (control === 'extruderCleanMove') {
        printerWriting(commands['extruderCleanMove']).then(() => {
          setGCodeControlCommand(commands['resume']);
        })  
      } else if (control === 'stop') {
        printerWriting(commands['switchOffBed']).finally(() => {
          setControl('switchOffExtruder');
        })
      } else if (control === 'switchOffExtruder') {
        printerWriting(commands['switchOffExtruder']).finally(() => {
          setControl('swtichOffFan');
        })
      } else if (control === 'swtichOffFan') {
        printerWriting(commands['swtichOffFan']).finally(() => {
          setControl('stopExtruder');
        })
      } else if (control == 'stopExtruder') {
        printerWriting(commands['relativePositioning']).finally(() => {
          setControl('stoppingRetractExtruder')
        })
      } else if (control == 'stoppingRetractExtruder') {
        printerWriting(commands['retractExtruder']).finally(() => {
          setControl('stopAbsolutePositioning')
        })
      } else if (control == 'stopAbsolutePositioning') {
        printerWriting(commands['absolutePositioning']).finally(() => {
          setControl('stoppingExtruderOff')
        })
      } 
      else if (control == 'stoppingExtruderOff') {
        printerWriting(commands['extruderOff']).finally(() => {
          setControl('stopInconditional')
        })
      } else if (control === 'stopInconditional') {
        printerWriting(commands['stopInconditional']).finally(() => {
          console.log('STOPED')
          setControl('stopped');
          printerReader?.releaseLock();
          // interrumptPrinting();
        })
      }
    } else {
      if (printerMessage && printerMessage.startsWith("T:")) {
        const printerMessageArray = printerMessage.split(' ')
        const extruderTemperatureCode = printerMessageArray[0]
        const bedTemperatureCode = printerMessageArray[2]
        if (extruderTemperatureCode) {
          setExtruderTemperature(parseInt(extruderTemperatureCode.split(':')[1]))
        }
        if (bedTemperatureCode) {
          setBedTemperature(parseInt(bedTemperatureCode.split(':')[1]))
        }
      }
      printerReading()
    }
  }, [printerMessage]);

  useEffect(() => {
    console.log('control', control)
    if (control === 'printer' ||
      control === 'pause' ||
      control === 'paused' ||
      control == 'extruderCleanMove' ||
      control == 'relativePositioning' ||
      control == 'absolutePositioning' ||
      control == 'retractExtruder' || 
      control == 'extruderOff' || 
      control == 'stopAbsolutePositioning' ||
      control === 'restoreExtruderTemperature' || 
      control === 'restoreBedTemperature' ||
      control === 'stop' ||
      control === 'switchOffExtruder' || 
      control === 'swtichOffFan' || 
      control == 'stopExtruder' || 
      control == 'stoppingRetractExtruder' ||
      control == 'stoppingExtruderOff' || 
      control === 'stopInconditional' ) {
      printerReading()
    }
  }, [control]);

  useEffect(() => {
    const isPaused = (control === 'restoreBedTemperature');
    if (gCodeControlCommand !== 'ready') {
      setControl('human')
      if (isPaused) {
        setTimeout(printerReading, 1000)
      }
    }
  }, [gCodeControlCommand]);
  
  useEffect(() => {
    if (gcodeStreamReader && printerReader && printerWriter) {
      console.log('start')
      printerWriting(commands['sendMessage']("FIX IT STREAM")).finally(() => {
        setControl('printer')
      })
    }
  }, [gcodeStreamReader, printerReader, printerWriter]);

  async function sendToPrinterTest (
    chunksReader: ReadableStreamDefaultReader<string>
  ) {
    setGcodeStreamReader(chunksReader)
    setPrinterReader({
      read: async () => {
        const result : ReadableStreamDefaultReadValueResult<string> = { done: false, value: "ok" };
        return result;
      },
      releaseLock: () => {},
      cancel: async (reason: string) => {},
      closed: Promise.resolve(undefined)
    });

    const textEncoder = new TextEncoderStream();
    setPrinterWriter({
        closed: Promise.resolve(undefined),
        desiredSize: 1000,
        abort: async () => {},
        close: async () => {},
        ready: Promise.resolve(undefined),
        releaseLock: async () => {},
        write: async (chunk) => {} //console.log(chunk)
    })
  }

  async function sendToPrinter (
    chunksReader: ReadableStreamDefaultReader<string>
  ) {
    if (!defaultPrinter?.port) {
      showToast({
        type: "error",
        message: "Ops...Você não possui dispositivos conectados",
      });
      return;
    }

    setGcodeStreamReader(chunksReader)

    const serialPort: SerialPort = defaultPrinter?.port;

    if (serialPort && serialPort.writable && serialPort.readable) {
      const lineReader = serialPort.readable
        .pipeThrough(new TextDecoderStream())
        .pipeThrough(new TransformStream(
          new LineBreakTransformer()
        ))
        .getReader();

      setPrinterReader(lineReader);

      const textEncoder = new TextEncoderStream();
      const writableStreamClosed = textEncoder.readable.pipeTo(
        serialPort.writable
      );
      setPrinterWriter(textEncoder.writable.getWriter())
    } else {
      showToast({
        type: "error",
        message: "Ops...Impossível se comunicar com a impressora",
      });
      return;
    }
  };

  function verifyProgress() {
    !!printingId &&
      sendProgressToServerAndBlockPrinter();
  }

  useEffect(() => {
      if ('wakeLock' in navigator) {
        navigator.wakeLock.request('screen').then((wl:  WakeLockSentinel) => setWakeLock(wl));
      }

      setCryptoFileContentLength(fileStream.standard_file.crypto_length);
      setContentLength(fileStream.standard_file.content_length);
  }, []);

  useEffect(() => {
    verifyProgress();
  }, [progress]);

  useEffect(() => {
    if (process.env.REACT_APP_BASE_URL &&
        fileStream?.id  &&
        printingFase === 'prepare') {
      preparePrint(
        `${process.env.REACT_APP_BASE_URL}/printings/${fileStream.id}/print`
      )
    }
  }, [printingFase])


  const controlBlock : Boolean =
    control === 'pause'||
    control == 'extruderCleanMove' ||
    control == 'relativePositioning' ||
    control == 'absolutePositioning' ||
    control == 'retractExtruder' || 
    control == 'extruderOff' || 
    control == 'stopAbsolutePositioning' ||
    control === 'restoreExtruderTemperature' || 
    control === 'restoreBedTemperature' ||
    control === 'stop' ||
    control === 'switchOffExtruder' || 
    control === 'swtichOffFan' || 
    control == 'stopExtruder' || 
    control == 'stoppingRetractExtruder' ||
    control == 'stoppingExtruderOff' || 
    control === 'stopInconditional'

  return (
    <Container>

      <PrinterDisplay>
        <TemperatureDisplay>
          <Field>
            <strong>Ext: {extruderTemperature}° / </strong>
            {extruderTemperatureGoal}°
          </Field>
          <Field>
            <strong>Bed: {bedTemperature}° / </strong>
            {bedTemperatureGoal}°
          </Field>
        </TemperatureDisplay>
        <CrtButtons>
          <PrintingProblemButton disabled={defaultPrinter?.port && !defaultPrinter.port.readable?.locked && !printerReader} onClick={fixPort}>
            <RiErrorWarningLine size={28} />
          </PrintingProblemButton>
          <PrintingActionButton disabled={
              gCodeControlCommand !== 'ready' 
              || !!controlBlock
              || progress >= 100} onClick={tooglePauseStopPrinting}>
                    {
              control === 'paused' ? 
              (
                <RiPlayCircleLine size={28} />
              ) : 
              (
                <RiPauseCircleLine size={28} />
              )
            }
          </PrintingActionButton>
          <PrintingActionButton disabled={gCodeControlCommand !== 'ready' 
              || !!controlBlock
              || progress >= 90} onClick={cancelPrinting}>
            {
              <RiCloseCircleLine size={28} />
            }
          </PrintingActionButton>
        </CrtButtons>
      </PrinterDisplay>
    
      {!printingFase ? (
        <PrintButton
          disabled={!defaultPrinter?.port?.readable && process.env.NODE_ENV !== 'development'}
          type="button"
          onClick={() => 
            setPrintingFase('prepare')
            /* preparePrint(
              `${process.env.REACT_APP_BASE_URL}/printings/${fileStream.id}/print`
            )*/ 
          }
        >
          {`${i18n.t("orders.sendToPrinter")}`}
        </PrintButton>
      ) : (progress >= 100 || control === 'stopped') ? (
        <CloseButton type="button" onClick={() => printEnd()}>
          {`${i18n.t("orders.conclude")}`}
        </CloseButton>
      ) : (
        <>
          { printingFase === 'test' && (
            <>
            </>
          )}
          { printingFase === 'prepare' && (
            <>
              <ProgressBar
                text={`Preparando ${((downloadedData / cryptoFileContentLength) * 100).toFixed(0)}%`}
                percentage={(downloadedData / cryptoFileContentLength) * 100}
              />
            </>
          )}
          { printingFase === 'print' && (
            <>
              <ProgressBar
                text={`Imprimindo ${progress.toFixed(0)}%`}
                percentage={progress}
              />

              {progress >= 95 && (
                <FindPrinterContainer onClick={resumePrinting}>
                  <FindPrinter>{`${i18n.t("orders.printComplete")}`}</FindPrinter>
                </FindPrinterContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default SendToPrinter;