import { ReactNode, useEffect, useState } from "react";
import Popover from "../../../../components/Popover";

import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  PrincipalInfos,
  PriceInfos,
  RightContent,
  Header,
  MobileContent,
  QuantityInfos,
  IconButtonContainer,
} from "./styles";
import { IOrder, IPlanit, IPopupData } from "../../dtos";

import AwardImg from "../../../../assets/icons/award_standards_plano.svg";
import PenImg from "../../../../assets/icons/pen_custom_plano.svg";
import { format } from "date-fns";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import {
  orderSituation,
  orderStatus,
  orderUrgencyTypes,
  roles,
} from "../../../../utils/constants";
import { LicenseeActions } from "../../actions/LicenseeActions";
import { CreatorActions } from "../../actions/CreatorActions";
import api from "../../../../services/api";
import { FinancialActions } from "../../actions/FinancialActions";
import { SAFActions } from "../../actions/SAFActions";
import { FixiterActions } from "../../actions/FixiterActions";
import { AdminActions } from "../../actions/AdminActions";
import { abridgedControl } from "../../../../utils/strings";
import { BiTestTube } from "react-icons/bi";
import { FiGift } from "react-icons/fi";
import { MdAttachMoney, MdOutlineMoneyOff } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";

interface PriceItemProps {
  onClick?: () => void;
  onSeeIconClick?: () => void;
  onUpdate?: (order: IOrder) => void;
  getOrders: (reload: boolean) => Promise<void>;
  setIsProviderSelected: (value: React.SetStateAction<boolean>) => void;
  setOrderPrintManagement: (
    value: React.SetStateAction<IOrder | undefined>
  ) => void;
  order: IOrder;
  children: ReactNode;
  openPopup: ({
    order,
    printTime,
    cost,
    weight,
    link,
    printer,
    type,
  }: IPopupData) => void;
  printOrder: (order: any) => void;
  printFile: ({
    order,
    printTime,
    cost,
    weight,
    printer,
    type,
  }: IPopupData) => void;
  openPrintHistory: ({
    orderId,
    quantity,
  }: {
    orderId: string;
    quantity: number;
  }) => void;
  openPlanitFileUploadPopup: (planit_id: string) => void;
}

interface IUser {
  name: string;
}

export function OrderItem({
  order,
  onClick = () => {},
  onSeeIconClick = () => {},
  onUpdate = () => {},
  children,
  getOrders,
  setIsProviderSelected,
  setOrderPrintManagement,
  openPopup,
  printOrder,
  printFile,
  openPrintHistory,
  openPlanitFileUploadPopup,
}: PriceItemProps) {
  const { user } = useAuth();
  const [isFileListDropdownOpen, setIsFileListDropdownOpen] = useState(false);
  const [isPrintOptionDropdownOpen, setIsPrintOptionDropdownOpen] =
    useState(false);
  const [creator, setCreator] = useState<IUser>();

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' 'HH:mm");
  }

  useEffect(() => {
    order?.creator_id &&
      api.get(`/users/${order.creator_id}`).then((response) => {
        setCreator(response.data);
      });
  }, [order]);

  const orderFinancialStatusIcon = {
    paid : {
      label: i18n.t(`financial.situation.paid`),
      icon: <MdAttachMoney size={18}/>,
    },
    not_paid: {
      label: i18n.t(`financial.situation.not_paid`),
      icon: <MdOutlineMoneyOff size={18}/>,
    },
    courtesy: {
      label: i18n.t(`financial.situation.courtesy`),
      icon: <FiGift size={18}/>,
    },
    fixcoin_paid: {
      label:  i18n.t(`topbar.coinFix.end`),
      icon: <RiMoneyDollarCircleLine size={18}/>,
    },
  };

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer className={order.status}>
            <Popover
              position="right"
              label={`${i18n.t(
                `financial.prices.solution_type.${order.solution_type}`
              )} - ${i18n.t(`orders.status.${order.status}.optionText`)}`}
            >
              <img
                alt="standard-solution"
                src={order.solution_type === "standard" ? AwardImg : PenImg}
              />
            </Popover>
          </ImageContainer>
          <PrincipalInfos className={order.status}>
            <span className="solution">
              #{order.incremental_id} {order.solution?.name}{" "}
              {!!order.solution_standard
                ? ` (${order.solution_standard.size}-${order.exam?.side || ""})`
                : ""}
            </span>
            <span className="date">{formatDate(order.created_at)}</span>
          </PrincipalInfos>

          {order.licensee && (
            <ContentInfos className="licensee desktop">
              <span className="data_label">
                {order.licensee.is_test
                  ? `Licenciado teste`
                  : `${i18n.t("orders.licensee")}`}{" "}
                {order.licensee.is_test && <BiTestTube />}
              </span>
              <span className="data_value">
                {abridgedControl(order.licensee.name, 15)}
              </span>
            </ContentInfos>
          )}

          <ContentInfos className="patient desktop">
            <span className="data_label">{`${i18n.t("orders.patient")}`}</span>
            <span className="data_value">
              {order.patient
                ? abridgedControl(order.patient.name, 13)
                : order.exam
                ? abridgedControl(order.exam.patient_name, 13)
                : order.planit
                ? abridgedControl(order.planit.patient_name, 13)
                : `${i18n.t("orders.patientNotSet")}`}
            </span>
          </ContentInfos>

          {creator && (user.role === roles.creator || user.role === roles.saf) && (
            <ContentInfos className="creator desktop">
              <span className="data_label">{`${i18n.t(
                "orders.creator"
              )}`}</span>
              <span className="data_value">
                {abridgedControl(creator.name, 15)}
              </span>
            </ContentInfos>
          )}
        </LeftContent>

        <RightContent>
          {user.role !== roles.creator && (
            <>
              {user.role === roles.employee ? null : (
                <PriceInfos
                  urgencyNonJustified={
                    order.urgency_type === orderUrgencyTypes.requested &&
                    !order.urgency_reason
                  }
                >
                  <span className="price_name">
                    {`${i18n.t(`orders.total`, {
                      order_type: i18n.t(
                        `financial.prices.order_type.${order.category}`
                      ),
                    })}`}
                  </span>
                  {order.financial_status === orderSituation.courtesy &&
                  (user.role === roles.fixiter ||
                    user.role === roles.licensee) ? (
                    <span className="price_location">
                      {`${i18n.t(`financial.situation.courtesy`)}`}
                    </span>
                  ) : order.financial_status === orderSituation.paid &&
                    (user.role === roles.fixiter ||
                      user.role === roles.licensee) ? (
                    <span className="price_location">
                      {`${i18n.t(`financial.situation.paid`)}`}
                    </span>
                  ) : (
                    <>
                      {order.urgency_type === orderUrgencyTypes.requested &&
                      !order.urgency_reason ? (
                        <Popover
                          position="left"
                          label="Valor dobrado por urgência não justificada"
                        >
                          <span className="price_location">
                            {parseFloat(order.total.toString()).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )}
                          </span>
                        </Popover>
                      ) : (
                        <span className="price_location">
                          {parseFloat(order.total.toString()).toLocaleString(
                            "pt-br",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </span>
                      )}
                    </>
                  )}
                </PriceInfos>
              )}
              <QuantityInfos>
                <span className="quanitity_name">
                  {`${i18n.t(`orders.qtd`)}`}
                </span>
                <span className="quanitity_value">{order.quantity}</span>
              </QuantityInfos>
            </>
          )}
          {((user.role === roles.licensee) || (user.role === roles.employee)) && (
            <>
            <LicenseeActions
              order={order}
              isFileListDropdownOpen={isFileListDropdownOpen}
              isPrintOptionDropdownOpen={isPrintOptionDropdownOpen}
              setIsFileListDropdownOpen={setIsFileListDropdownOpen}
              setIsPrintOptionDropdownOpen={setIsPrintOptionDropdownOpen}
              openPopup={openPopup}
              printOrder={printOrder}
              printFile={printFile}
              openPrintHistory={openPrintHistory}
              openPlanitFileUploadPopup={openPlanitFileUploadPopup}
            />
            <IconButtonContainer>
              <Popover label={orderFinancialStatusIcon[order.financial_status].label} position="left">
                {orderFinancialStatusIcon[order.financial_status].icon}
              </Popover>
            </IconButtonContainer>
            </>
          )}
          {user.role === roles.admin && (
            <AdminActions
              onProviderSelect={() => getOrders(true)}
              order={order}
            />
          )}
          {user.role === roles.creator && (
            <CreatorActions order={order} getOrders={() => getOrders(true)} />
          )}
          {user.role === roles.financial && <FinancialActions order={order} />}
          {user.role === roles.saf && (
            <SAFActions
              setOrderPrintManagement={setOrderPrintManagement}
              order={order}
              getOrders={getOrders}
            />
          )}
          {user.role === roles.fixiter && (
            <FixiterActions
              printOrder={printOrder}
              order={order}
              isPrintOptionDropdownOpen={isPrintOptionDropdownOpen}
              setIsPrintOptionDropdownOpen={setIsPrintOptionDropdownOpen}
            />
          )}
        </RightContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_label">{`${i18n.t("orders.patient")}`}</span>
            <span className="data_value">
              {order.patient
                ? order.patient.name
                : order.exam
                ? order.exam.patient_name
                : order.planit
                ? order.planit.patient_name
                : `${i18n.t("orders.patientNotSet")}`}
            </span>
          </ContentInfos>

          {order.partner && (
            <ContentInfos className="partner">
              <span className="data_label">{`${i18n.t(
                "orders.partner"
              )}`}</span>
              <span className="data_value">{order.partner.name}</span>
            </ContentInfos>
          )}
        </LeftContent>
      </MobileContent>
    </Container>
  );
}
