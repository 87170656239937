import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";

import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { SolidButton } from "../../../../../../components/SolidButton";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";

import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../../../utils/validateFormFields";
import { countries } from "../../../../../../utils/countries";

import { IPersonFormData } from "../../dtos.d";

import {
  Container,
  StepTitle,
  InputContainer,
  InputLine,
  Footer,
} from "./styles";

interface StepTwoProps {
  formData: IPersonFormData | undefined;
  setFormData: any;
  createPerson: () => void;
  nextStep: () => void;
  prevStep: () => void;
}

export function StepTwo({
  formData,
  setFormData,
  createPerson,
  nextStep,
  prevStep,
}: StepTwoProps) {
  const [errors, setErrors] = useState({} as any);
  const [country, setCountry] = useState("");

  const formattedCountries = countries.map((country) => ({
    label: country.label,
    id: country.code,
    currency: country.currency,
    coin: country.coin,
  }));

  async function validateStepTwo() {
    const body = {
      name: formData?.name,
      country: formData?.country?.id,
      expertise: formData?.expertise,
      professional_number: formData?.professional_number,
      cpf: formData?.cpf,
      cellphone: formData?.cellphone,
      state: formData?.state,
      city: formData?.city,
    };

    const stepTwoSchema = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      country: Yup.string().required(typesErrors.required),
      cpf: Yup.string().required(typesErrors.required),
      cellphone: Yup.string().required(typesErrors.required),
      expertise: Yup.string().required(typesErrors.required),
      professional_number: Yup.string().required(typesErrors.required),
      state: Yup.string().required(typesErrors.required),
      city: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, stepTwoSchema, {
      name: "",
      country: "",
      cpf: "",
      cellphone: "",
      expertise: "",
      state: "",
      professional_number: "",
      city: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});
    createPerson();
  }

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  return (
    <Container>
      <StepTitle>{`${i18n.t("userRegistration.fillData")}`}</StepTitle>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="name"
            label={`${i18n.t("userRegistration.name")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                name: event,
              }));
              clearError("name");
            }}
            value={formData?.name ?? ""}
            error={errors?.name}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <SearchableSelect
            label={`${i18n.t("userRegistration.country")}`}
            options={formattedCountries}
            onChange={(value) => setCountry(value)}
            onSelect={(selected) => {
              if (typeof selected === "string" || !selected?.id) {
                setErrors((prevState: any) => ({
                  ...prevState,
                  country: typesErrors.validCountry,
                }));
                setFormData((prevState: IPersonFormData) => ({
                  ...prevState,
                  country: undefined,
                }));

                return;
              }

              setErrors((prevState: any) => ({
                ...prevState,
                country: "",
              }));
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                country: selected,
              }));

              console.log(selected);
            }}
            defaultValue={country}
            error={errors?.country}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="expertise"
            label={`${i18n.t("userRegistration.expertise")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                expertise: event,
              }));
              clearError("expertise");
            }}
            value={formData?.expertise ?? ""}
            error={errors?.expertise}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="councilNumber"
            label={`${i18n.t("userRegistration.professional_number")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                professional_number: event,
              }));
              clearError("professional_number");
            }}
            value={formData?.professional_number ?? ""}
            error={errors?.professional_number}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="cpf"
            label={`${i18n.t("userRegistration.cpf")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                cpf: event,
              }));
              clearError("cpf");
            }}
            type="number"
            value={formData?.cpf ?? ""}
            error={errors?.cpf}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="cellphone"
            label={`${i18n.t("userRegistration.cellphone")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                cellphone: event,
              }));
              clearError("cellphone");
            }}
            type="number"
            value={formData?.cellphone ?? ""}
            error={errors?.cellphone}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="state"
            label={`${i18n.t("userRegistration.state")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                state: event,
              }));
              clearError("state");
            }}
            value={formData?.state ?? ""}
            error={errors?.state}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="city"
            label={`${i18n.t("userRegistration.city")}`}
            handleChange={(event) => {
              setFormData((prevState: IPersonFormData) => ({
                ...prevState,
                city: event,
              }));
              clearError("city");
            }}
            value={formData?.city ?? ""}
            error={errors?.city}
          />
        </InputContainer>
      </InputLine>

      {/* <CheckBoxLine>
        <Checkbox
          checked={formData?.termsAccepted ?? false}
          handleChange={() => {
            setFormData((prevState: IPersonFormData) => ({
              ...prevState,
              termsAccepted: !prevState?.termsAccepted ?? true,
            }));
          }}
          error={errors?.termsAccepted}
        />
        <span>
          {`${i18n.t("userRegistration.termsOfUse")} `}
          <a href="/docs/user_terms" target="_blank">
            {`${i18n.t("userRegistration.terms")}`}
          </a>{" "}
          {`${i18n.t("userRegistration.connector")}`}{" "}
          <a href="/docs/privacy_policy" target="_blank">
            {`${i18n.t("userRegistration.policy")}`}
          </a>
          .
        </span>
      </CheckBoxLine> */}

      <Footer>
        {/* <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={i18n.t('userRegistration.prevStep')}
          onClick={prevStep}
        /> */}

        <SolidButton
          text={i18n.t("userRegistration.nextStep")}
          buttonWidth={220}
          buttonHeight={42}
          onClick={validateStepTwo}
        />
      </Footer>
    </Container>
  );
}
