import styled from "styled-components";

export const Container = styled.div``;

export const StepTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 20px;
`;

export const InputLine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;

  @media (max-width: 505px) {
    width: 100%;
    flex: 1;
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const LinkLine = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 16px;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--fixit);
    }
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;
