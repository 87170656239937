import { useEffect, useState } from "react"
import { FiArrowRight } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import { showToast } from "../../../../../../components/CustomToast"
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import api from "../../../../../../services/api"
import { ISolutions } from "../../../../../Financial/pages/Plans/dtos";
import { 
    CloseButton,
    Conclude, 
    Footer, 
    Header, 
    InputContainer, 
    Label, 
    SelectedSolutions, 
    SolutionsContainer, 
    Tag 
} from "./styles";


interface ICloseModal {
  closeModal: () => void;
}

interface IFormData{
  numberOfSolutions: number,
  availableSolutions: ISolutions[]
}

export function ChooseSignatureSolutions({closeModal} : ICloseModal){

    const [solutions, setSolutions] = useState<ISolutions[]>([])
    const [ signature, setSignature] = useState({
        solutions_quantity: 0
    });
    const [formData, setFormData] = useState<IFormData>({
        numberOfSolutions: 0,
        availableSolutions: [],
      });

    async function ChooseSolutions(){
        const body = formData?.availableSolutions?.map((item) => 
        item.id
      )
        try{
            await api.put('/signatures/choosesolution', body)
            showToast({
              type: 'success',
              message: 'Parabéns, você acabou de escolher suas soluções!'
          })
        }catch(error){
            return
        }
    }

    async function getSignature(){
        try{
            await api.get('/signatures/licensee').then((response) => {
                setSignature(response.data)
            })
        } catch(error){
            showToast({
                type: 'error',
                message: 'Você não possui uma assinatura, entre em contato com o SAF'
            })
        }
    }

    async function getSolutions(){
        try{
          await api.get('/solutions').then((response)=> {
            setSolutions(response.data)
          })
        } catch(error){
          showToast({
            type: 'error',
            message: 'Ocorreu um erro ao tentar carregar as soluções'
          })
        }
      }

      useEffect(()=>{
          getSignature()
          getSolutions()
      },[])

      function handleRemoveTaskById(id: string | number) {
        const deletedTag = formData.availableSolutions?.filter(as => as.id !== id)
        setFormData((prevState) => ({
          ...prevState,
          availableSolutions: [...deletedTag]
        }))
      }

      return(
        <>
            <CloseButton>
                <RiCloseFill 
                    size={24} 
                    color="var(--fixit)" 
                    onClick={()=>{
                    closeModal()
                }} />
            </CloseButton>
            <Header>
              Selecione as suas {signature.solutions_quantity} soluções:
            </Header>
            <InputContainer>
            <OutlinedSelect 
            label={'Soluções disponíveis'}
            options={solutions.map((s : any) => ({
            value: s.id,
            optionText: s.name,
            }))}
            handleSelect={(event) => {
            const solutionsLimit = (formData.availableSolutions.length < signature.solutions_quantity)
            const solutionAlreadySelected = formData.availableSolutions.find((item)=> item.id === event.value)
            if((!solutionAlreadySelected) && solutionsLimit){
            return(
            setFormData((prevState) => ({
                ...prevState,
                availableSolutions: [...prevState.availableSolutions,{
                id: event.value,
                optionText: event.optionText
                }]
            }))
            )
            }
            }}
            selectedOption={''}
            />
        </InputContainer>
    <SolutionsContainer>
        <Label>Soluções selecionadas</Label>
        <SelectedSolutions>
        {formData.availableSolutions.map((s)=>{
            return(
            <Tag key={s.id}>
                <span>{s.optionText}</span>
                <RiCloseFill
                size={14}
                onClick={()=>{handleRemoveTaskById(s.id)}}
                />
            </Tag>
            )
        })}
        </SelectedSolutions>
    </SolutionsContainer>
    <Footer>
        <Conclude
          onClick={()=>{
              ChooseSolutions()
              closeModal()
            }
          }
        >
          Começar a usar o App
          <FiArrowRight size="1rem"/>
        </Conclude>
      </Footer>
  </>
)
}