import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ReactLoading from "react-loading";

import api from "../../../../services/api";

import { Container, Header, TableContainer, Td } from "./styles";
import { showToast } from "../../../../components/CustomToast";
import AbsoluteButton from "../../components/AbsoluteButton";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { roles } from "../../../../utils/constants";

interface Creator {
  id: string;
  name: string;
  email: string;
  created_at: string;
  role: string;
}

export default function ListCreators() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();

  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState<Creator[]>([]);

  async function getCreators() {
    try {
      setLoading(true);
      const response = await api.get("/users");
      const creators = response.data.filter(
        (user: Creator) => user.role === roles.creator
      );
      setCreators(creators);
      setLoading(false);
    } catch (err) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro ao tentar carregar os licenciados, tente novamente.",
      });
      setLoading(false);
    }
  }

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }

  useEffect(() => {
    getCreators();
    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.designers.header"),
    });
  }, []);

  return (
    <>
      {loading ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--fixit)"}
          height={64}
          width={64}
        />
      ) : (
        <Container>
          <Header>
            <AbsoluteButton
              text="Criar designers"
              absolute={false}
              onClick={() => navigate("/users/create/creator")}
            />
          </Header>

          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Criado em:</th>
                </tr>
              </thead>

              <tbody>
                {creators.map((creator) => (
                  <tr key={creator.id}>
                    <Td className="name">{creator.name}</Td>
                    <Td>{creator.email}</Td>
                    <Td>{formatDate(creator.created_at)}</Td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
