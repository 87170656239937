import { useState } from 'react';
import { i18n } from '../../../../../../localization/i18n';
import DropZone from '../../../../../../components/DropZone';
import { OutlinedButton } from '../../../../../../components/OutlinedButton';
import { SolidButton } from '../../../../../../components/SolidButton';
import { RiCloseLine } from 'react-icons/ri';
import { Container, FormContainer, Header, Footer } from './styles';
import api from '../../../../../../services/api';
import { useOnboard } from '../../../../../../hooks/OnboardContext';
import { showToast } from '../../../../../../components/CustomToast';

interface SendCertificateModalProps {
  closeModal: () => void;
}

export function SendCertificateModal({ closeModal }: SendCertificateModalProps) {
  const { licensee } = useOnboard();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);


  async function handleSendCertificate(id: string) {
    var formData = new FormData();
    file && formData.append('file', file);

    setLoading(true);
    try {
      await api.patch(`/licensees/docs/${id}`, formData);
      showToast({
        type: 'success',
        message: `${i18n.t('fixiterOnboarding.document_success')}`
      })
    } catch (error) {
      console.log(error);
      showToast({
        type: 'success',
        message: `${i18n.t('fixiterOnboarding.document_failed')}`
      })
    } finally {
      setLoading(false);
      closeModal();
    }
  }

  return (
    <Container>
      <Header>
        <div className="title">{`${i18n.t('fixiterOnboarding.sendDiploma')}`}</div>
        <RiCloseLine size={26} onClick={closeModal} />
      </Header>

      <FormContainer>
        <span className="description">{`${i18n.t('fixiterOnboarding.chooseFile')}`}</span>
        <div className="file_input_container">
          <DropZone
            accept={{'image/*': [".jpeg",".jpg",".png"]}}
            onUpload={
              (files: File[]) => {
                const imageUrl = URL.createObjectURL(files[0]);

                setFile(files[0]);
                setFileUrl(imageUrl);
              }
            }
          />
        </div>

      {(fileUrl && file) && (
           <div className="image_container">
             <img src={fileUrl} alt="" />

             <div className="hover_infos">
               <div className="image_overlay" />
               <span className="image_name">{file.name}</span>
               <RiCloseLine
                 size={22}
                 onClick={() => {
                   setFile(undefined);
                   setFileUrl('');
                 }}
               />
             </div>
           </div>
         )}
       </FormContainer>

      <Footer>
        <OutlinedButton text={`${i18n.t('fixiterOnboarding.cancel')}`} onClick={closeModal} />
        <SolidButton
          text={`${i18n.t('fixiterOnboarding.send')}`}
          loading={loading}
          disabled={!file}
          onClick={async () => {
            await handleSendCertificate(licensee?.id)
          }}
        />
      </Footer>
    </Container>
  )
}
