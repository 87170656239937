import styled, { css } from "styled-components";
import global from "../../global";
import { Avatar, Badge, styled as mui_styled } from "@mui/material";

interface IconsProps {
  selected?: boolean;
}

interface AvatarProps {
  hasNotification?: boolean;
  isMobile?: boolean;
}

export const MobileOptions = styled.div`
  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ActionBadge = mui_styled(Badge)(
  ({ hasNotification }: AvatarProps) => ({
    cursor: hasNotification ? "pointer" : "",
  })
);

export const SmallAvatar = mui_styled(Avatar)(
  ({ hasNotification }: AvatarProps) => ({
    width: 15,
    height: 15,
    backgroundColor: "var(--error)",
    display: hasNotification ? "flex" : "none",
  })
);

export const Container = styled.div`
  padding-right: 12px;
  padding-left: calc(2vw + 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--light-gray);
  height: 50px 20px 20px 20px;
  max-width: 100%;
  background-color: var(--background);
  position: sticky;
  top: 0;
  z-index: 10;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding-left: calc(2vw + 50px);
  }
`;

export const Language = styled.select`
  background: transparent;
  border: 1px solid var(--light-gray);
  color: var(--dark-gray);
  font-size: 16px;
  border-radius: 6px;
  padding: 4px;
`;

export const Credits = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 80px;
  cursor: pointer;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin: 0 24px; // NOTE: this maybe need to be changed
  }
`;

export const CreditsText = styled.p`
  margin-left: 6px;
  font-size: 18px;
  color: var(--dark-gray);
  user-select: none;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    span {
      display: none;
    }
  }
`;

export const NameLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: var(--dark-gray);
  margin-right: 8px;
  user-select: none;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ProfileAvatar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  background-color: var(--gray);
  border-radius: 50%;
  color: var(--white);
  font-weight: 500;
  font-size: 10px;

  margin-right: 12px;

  cursor: pointer;
  :hover {
    background-color: var(--success);
  }
  user-select: none;
`;

export const ButtonIcon = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 45%;

  cursor: pointer;
  transition: background-color 0.2s;
  &.active,
  &:hover {
    background-color: var(--light-gray);
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 18px;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 10px;
  }
`;

export const BoxNotifications = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 250px;
  height: 200px;
  position: absolute;
  right: 220px;
  top: 44px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 6px;
  background-color: var(--white);
  list-style-type: none;
  z-index: 1;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    right: 44px;
  }
`;

export const BoxNotificationsText = styled.p`
  color: var(--gray);
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
  user-select: none;
`;

export const BoxProfile = styled.div`
  position: absolute;
  right: 0px;
  top: 30px;
  width: max-content;

  border: 1px solid var(--light-gray);
  border-radius: 10px;

  padding: 10px 0px 5px 0;

  background-color: var(--white);
  box-shadow: 4px 3px 8px var(--light-gray);

  list-style-type: none;
  z-index: 5;
`;

export const Item = styled.li<AvatarProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  align-items: end;

  font-size: 16px;
  padding: 10px 10px 10px 20px;
  margin: 5px;

  border-radius: 10px;
  border: 1px solid;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    border-color: ${(props) =>
      props.hasNotification && !props.isMobile ? "var(--error)" : ""};
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    border-color: ${(props) =>
      props.hasNotification && props.isMobile ? "var(--error)" : ""};
  }

  cursor: pointer;

  transition: background-color 0.5s;

  svg {
    color: var(--fixit);
    margin-left: 2px;
  }

  :hover {
    background-color: var(--fixit);
    transition: background-color 0.5s;
    p,
    svg {
      color: var(--white);
    }
  }
`;

export const ItemTextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemPrimaryText = styled.p`
  margin-right: 5px;
  color: var(--dark-gray);
  user-select: none;
  font-size: 0.8em;
  line-height: 9px;
  text-align: end;
`;

export const ItemSecondaryText = styled.p`
  margin-top: 5px;
  margin-right: 5px;
  color: var(--dark-gray);
  user-select: none;
  font-size: 0.6em;
  line-height: 9px;
  text-align: end;
`;

export const ItemText = styled.p`
  margin-right: 5px;
  color: var(--dark-gray);
  user-select: none;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const NavigationAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  height: 50px;
  background-color: transparent;

  position: sticky;
  top: 0;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: 23px;
    color: var(--printed);
  }

  svg {
    cursor: pointer;
  }
`;

export const TopBarOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  border-bottom: 1px solid var(--light-gray);

  height: 50px;
  max-width: 100%;
  background-color: var(--background);
  gap: 8px;

  position: sticky;
  top: 0;
  img {
    padding: 8px;
    margin-right: auto;
    margin-left: 42px;
    display: none;

    @media (max-width: ${global.responsive.small.width.value}${global.responsive
        .small.width.unit}) {
      display: initial;
    }
  }
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(--light-gray);
`;
