import { useState } from "react";
import { RiEyeLine, RiPagesLine } from "react-icons/ri";
import { IDoc } from "../..";
import Popover from "../../../../../../components/Popover";
import { ShowDocModal } from "../../../../../../components/ShowDocModal";
import { i18n } from "../../../../../../localization/i18n";
import {
  ActionItem,
  ActionsContainer,
  Container,
  Header,
  IconButtonContainer,
  ImageContainer,
  LeftContent,
  PrincipalInfos,
  RightContent,
} from "./styles";

interface IDocItem {
  doc: IDoc;
}

export function DocItem({ doc }: IDocItem) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer>
            <Popover
              position="right"
              label={`${i18n.t("global.configs.docs.label")}`}
              styles={{
                minWidth: "200px",
                maxWidth: "200px",
                whiteSpace: "normal",
              }}
            >
              <RiPagesLine />
            </Popover>
          </ImageContainer>
          <PrincipalInfos>
            <span className="solution">{doc.name}</span>
            <span className="date">
              {doc.version} - {doc.locale}{" "}
            </span>
          </PrincipalInfos>
        </LeftContent>
        <RightContent>
          <ActionsContainer>
            <ActionItem>
              <Popover
                position="left"
                label={`Ver ${doc.name} - ${doc.version}`}
              >
                <IconButtonContainer>
                  <RiEyeLine size={18} onClick={() => setIsModalOpen(true)} />
                </IconButtonContainer>
              </Popover>
              {isModalOpen && (
                <ShowDocModal
                  name={doc.name}
                  version={doc.version}
                  link={doc.document_link}
                  closeModal={() => setIsModalOpen(false)}
                  open={isModalOpen}
                />
              )}
            </ActionItem>
          </ActionsContainer>
        </RightContent>
      </Header>
    </Container>
  );
}
