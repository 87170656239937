import { RiCheckFill } from "react-icons/ri";

import { Container } from "./styles";

interface ICheckboxProps {
  checked: boolean;
  handleChange: (event: any) => any;
  backgroundCheckedColor?: string;
  svgCheckColor?: string;
  error?: boolean;
  readOnly?: boolean;
}

export function Checkbox({
  checked,
  handleChange,
  backgroundCheckedColor = "var(--fixit)",
  svgCheckColor = "var(--white)",
  readOnly = false,
  error = false,
}: ICheckboxProps) {
  return (
    <Container
      onClick={(e) => { !readOnly && handleChange(e) }}
      checked={checked}
      readOnly={readOnly}
      error={error}
      backgroundCheckedColor={backgroundCheckedColor}
    >
      {checked && <RiCheckFill size={16} color={svgCheckColor} />}
    </Container>
  );
}
