import { useEffect, useState } from "react";
import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { SolidButton } from "../../../../../../components/SolidButton";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { Form, Header, Line } from "./styles";

import * as Yup from "yup";
import validateFormFields from "../../../../../../utils/validateFormFields";

import {
  paymentType,
  paymentMethod,
  paymentSituationOptions,
  paymentSituationLabel,
  typeLabel,
  paymentMethodLabel,
  currencyLabel,
  currencyOptions,
} from "../../mocks";
import { CheckboxContainer } from "../Filter/styles";
import { Checkbox } from "../../../../../../components/Checkbox";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { i18n } from "../../../../../../localization/i18n";
import { ILicensee, ISearchableLicensee } from "../../../../../Orders/dtos";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";

interface ICreateBill {
  transaction_code?: string;
  type?: string;
  payment_method?: string;
  payment_situation?: string;
  bill_value?: string;
  referring_period?: string;
  due_date?: Date;
  payment_date?: Date;
  is_recurrence?: boolean;
  licensee_id?: string;
  currency?: string;
}

interface IHandleCreate {
  onHandleCreate: () => void;
}

export function CreateBillForm({ onHandleCreate }: IHandleCreate) {
  const [inputs, setInputs] = useState<ICreateBill>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const [selectedLicensee, setSelectedLicensee] = useState<ILicensee>();
  const [licensees, setLicensees] = useState<ISearchableLicensee[]>([]);
  const [licenseeSearchValue, setLicenseeSearchValue] = useState("");
  const [selectLoading, setSelectedLoading] = useState(false);

  function clearInputs() {
    setInputs({
      transaction_code: "",
      type: "",
      payment_method: "",
      payment_situation: "",
      bill_value: "",
      referring_period: "",
      is_recurrence: false,
      currency: "",
      licensee_id: "",
      due_date: undefined,
      payment_date: undefined,
    });
    setSelectedLicensee(undefined);
  }

  async function searchLicensees(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      const { data } = await api.get(
        `/licensees/search?term=${licenseeSearchValue}`,
        { signal }
      );
      setLicensees(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (licenseeSearchValue != "") {
      searchLicensees(signal);
    }

    return () => abortController.abort();
  }, [licenseeSearchValue]);

  async function handleCreateBill(event: React.FormEvent) {
    event.preventDefault();

    setLoading(true);

    const body = {
      transaction_code: inputs?.transaction_code,
      type: inputs?.type,
      payment_method: inputs?.payment_method,
      payment_situation: inputs?.payment_situation,
      bill_value: inputs?.bill_value,
      referring_period: inputs?.referring_period,
      due_date: inputs?.due_date,
      payment_date: inputs?.payment_date,
      is_recurrence: inputs?.is_recurrence ?? false,
      currency: inputs?.currency,
      licensee_id: selectedLicensee?.id,
    };

    try {
      const yup_validation = Yup.object().shape({
        transaction_code: Yup.number().required(typesErrors.required),
        type: Yup.string().required(typesErrors.required),
        payment_method: Yup.string().required(typesErrors.required),
        payment_situation: Yup.string().required(typesErrors.required),
        bill_value: Yup.number().required(typesErrors.required),
        referring_period: Yup.string().required(typesErrors.required),
        licensee_id: Yup.string().required(typesErrors.required),
        due_date: Yup.string().required(typesErrors.required),
        currency: Yup.string().required(typesErrors.required),
      });

      const validation = await validateFormFields(body, yup_validation, {
        transaction_code: "",
        type: "",
        payment_method: "",
        payment_situation: "",
        bill_value: "",
        referring_period: "",
        is_recurrence: "",
        licensee_id: "",
        due_date: "",
        currency: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        setLoading(false);
        return;
      }

      setErrors({});

      await api.post("/bills/create", body).then((res) => {
        showToast({
          type: "success",
          message: "Fatura criada com sucesso",
        });
        clearInputs();
        onHandleCreate();
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar criar fatura",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form onSubmit={handleCreateBill}>
        <Line>
          <Header>
            <span>Criar fatura</span>
          </Header>
          <div className="CheckBoxContainer">
            <CheckboxContainer checked={inputs?.is_recurrence}>
              <Checkbox
                checked={inputs?.is_recurrence ?? false}
                handleChange={() =>
                  setInputs((prevState) => ({
                    ...prevState,
                    is_recurrence: !inputs?.is_recurrence,
                  }))
                }
              />
              <span>Recorrente</span>
            </CheckboxContainer>
          </div>
        </Line>
        <OutlinedInput
          error={errors?.bill_value}
          handleChange={(event) =>
            setInputs((prevState) => ({
              ...prevState,
              bill_value: event,
            }))
          }
          inputName={"bill_value"}
          label={"Valor"}
          value={inputs?.bill_value ?? ""}
        />
        <Line>
          <OutlinedInput
            error={errors?.transaction_code}
            type="number"
            handleChange={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                transaction_code: event,
              }))
            }
            inputName={"transaction_code"}
            label={"Código"}
            value={inputs?.transaction_code ?? ""}
          />
          <OutlinedInput
            error={errors?.referring_period}
            mask="99/9999"
            handleChange={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                referring_period: event,
              }))
            }
            inputName={"referring_period"}
            label={"Mês referente"}
            value={inputs?.referring_period ?? ""}
          />
        </Line>
        <Line>
          <OutlinedInput
            error={errors?.due_date}
            type="date"
            handleChange={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                due_date: event,
              }))
            }
            inputName={"due_date"}
            label={"Vencimento"}
            value={String(inputs?.due_date) ?? ""}
          />
          <OutlinedInput
            type="date"
            handleChange={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                payment_date: event,
              }))
            }
            inputName={"payment_date"}
            label={"Pago em"}
            value={String(inputs?.payment_date) ?? ""}
          />
        </Line>
        <Line>
          <OutlinedSelect
            error={errors?.type}
            options={paymentType}
            label={"Tipo"}
            handleSelect={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                type: event.value.toString(),
              }))
            }
            selectedOption={
              typeLabel[inputs?.type as keyof typeof typeLabel] ?? ""
            }
          />
          <OutlinedSelect
            error={errors?.payment_method}
            options={paymentMethod}
            label={"Método"}
            handleSelect={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                payment_method: event.value.toString(),
              }))
            }
            selectedOption={
              paymentMethodLabel[
                inputs?.payment_method as keyof typeof paymentMethodLabel
              ] ?? ""
            }
          />
        </Line>
        <Line>
          <OutlinedSelect
            error={errors?.payment_situation}
            options={paymentSituationOptions}
            label={"Situação"}
            handleSelect={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                payment_situation: event.value.toString(),
              }))
            }
            selectedOption={
              paymentSituationLabel[
                inputs?.payment_situation as keyof typeof paymentSituationLabel
              ] ?? ""
            }
          />
          <OutlinedSelect
            error={errors?.currency}
            options={currencyOptions}
            label={"Moeda"}
            handleSelect={(event) =>
              setInputs((prevState) => ({
                ...prevState,
                currency: event.value.toString(),
              }))
            }
            selectedOption={
              currencyLabel[inputs?.currency as keyof typeof currencyLabel] ??
              ""
            }
          />
        </Line>
        <SearchableSelect
          error={errors?.licensee_id}
          label={`${i18n.t("profile.unit")}`}
          options={licensees}
          defaultValue={licenseeSearchValue}
          loading={selectLoading}
          onSelect={(selected) => {
            if (typeof selected === "string") {
              setSelectedLicensee(undefined);
              return;
            }

            setSelectedLicensee(
              licensees?.find((s) => s.model.id === selected.id)?.model
            );
          }}
          onChange={(value) => {
            setLicenseeSearchValue(value);
          }}
        />

        <SolidButton text={"Criar"} type="submit" loading={loading} />
      </Form>
    </>
  );
}
