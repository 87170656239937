import { useRoutes } from "react-router-dom";
import AdminLayout from "../../modules/Admin/components/AdminLayout";
import Dashboard from "../../modules/Admin/pages/Dashboard";
import ListCreators from "../../modules/Admin/pages/Creators";
import CreateCreator from "../../modules/Admin/pages/CreateCreator";
import UploadTerms from "../../modules/Admin/pages/UploadTerms";
import Configurations from "../../modules/Admin/pages/Configurations";
import Fields from "../../modules/Admin/pages/Fields";
import Categories from "../../modules/Admin/pages/Categories";
import EditField from "../../modules/Admin/pages/EditField";
import EditCategory from "../../modules/Admin/pages/EditCategory";
import CreateField from "../../modules/Admin/pages/CreateField";
import CreateCategory from "../../modules/Admin/pages/CreateCategory";
import EditCreator from "../../modules/Admin/pages/EditCreator";
import { Orders } from "../../modules/Orders";
import ShowOrder from "../../modules/Orders/ShowOrder";
import { LicenseesList } from "../../modules/Licensee";
import CreateLicensee from "../../modules/Licensee/pages/CreateLicensee";
import EditLicensee from "../../modules/Licensee/pages/EditLicensee";
import Docs from "../../modules/Admin/pages/Docs";
import Printers from "../../modules/Admin/pages/Printers";
import Users from "../../modules/Admin/pages/Users";
import { Tips } from "../../modules/Admin/pages/Tips";
import DiagnosisList from "../../modules/Admin/pages/Diagnosis";

function AdminRoutes() {
    const adminRoutes = useRoutes([
      { path: '/admin/dashboard',
        element: (
          <AdminLayout>
            <Dashboard />
          </AdminLayout>
      )},
      { path: '/admin/orders', 
        element: (
          <AdminLayout>
            <Orders />
          </AdminLayout>
      )},
      { path: '/admin/orders/show', 
        element: (
          <AdminLayout>
            <ShowOrder />
          </AdminLayout>
      )},
      { path: '/users/licensees', 
        element: (
          <AdminLayout>
            <LicenseesList />
          </AdminLayout>
      )},
      { path: '/users/create/licensee', 
        element: (
          <AdminLayout>
            <CreateLicensee />
          </AdminLayout>
      )},
      { path: '/users/creators', 
        element: (
          <AdminLayout>
            <ListCreators />
          </AdminLayout>
      )},
      { path: '/users/licensee', 
        element: (
          <AdminLayout>
            <LicenseesList />
          </AdminLayout>
      )},
      { path: '/users/create/creator', 
        element: (
          <AdminLayout>
            <CreateCreator />
          </AdminLayout>
      )},
      { path: '/users/edit/licensee', 
        element: (
          <AdminLayout>
            <EditLicensee />
          </AdminLayout>
      )},
      { path: '/users/edit/creator', 
        element: (
          <AdminLayout>
            <EditCreator />
          </AdminLayout>
      )},
      { path: '/configs', 
        element: (
          <AdminLayout>
            <Configurations />
          </AdminLayout>
      )},
      { path: '/configs/fields', 
        element: (
          <AdminLayout>
            <Fields />
          </AdminLayout>
      )},
      { path: '/configs/fields/:field_key', 
        element: (
          <AdminLayout>
            <Categories />
          </AdminLayout>
      )},
      { path: '/configs/fields/create', 
        element: (
          <AdminLayout>
            <CreateField />
          </AdminLayout>
      )},
      { path: '/configs/fields/:field_key/edit', 
        element: (
          <AdminLayout>
            <EditField />
          </AdminLayout>
      )},
      { path: '/configs/fields/:field_key/create', 
        element: (
          <AdminLayout>
            <CreateCategory />
          </AdminLayout>
      )},
      { path: '/configs/fields/:field_key/:category_key/edit', 
        element: (
          <AdminLayout>
            <EditCategory />
          </AdminLayout>
      )},
      { path: '/configs/docs/:contract_version', 
        element: (
          <AdminLayout>
            <Docs />
          </AdminLayout>
      )},
      { path: '/configs/terms/:contract_version/upload', 
        element: (
          <AdminLayout>
            <UploadTerms />
          </AdminLayout>
      )},
      { path: '/configs/printers', 
        element: (
          <AdminLayout>
            <Printers />
          </AdminLayout>
      )},
      { path: '/configs/users', 
        element: (
          <AdminLayout>
            <Users />
          </AdminLayout>
      )},
      { path: '/configs/tips', 
        element: (
          <AdminLayout>
            <Tips />
          </AdminLayout>
      )},
      { path: '/configs/tips', 
        element: (
          <AdminLayout>
            <Tips />
          </AdminLayout>
      )},
      { path: '/configs/diagnosis', 
        element: (
          <AdminLayout>
            <DiagnosisList />
          </AdminLayout>
      )},
    ]);

    return adminRoutes;
}

export default AdminRoutes;
