import { useEffect, useState } from "react";

import { i18n } from "../../../../localization/i18n";

import { Modal } from "../../../../components/Modal";
import { showToast } from "../../../../components/CustomToast";

import api from "../../../../services/api";

import { RiCloseLine } from "react-icons/ri";

import {
  InputContainer,
  CloseButton,
  JustifyInput,
  InputHeader,
  Language,
  LicenseeButton,
  CardName,
  Text,
  RowFarContainer,
  RowNearContainer,
  LicenseeListContainer,
  LicenseeSelectedText,
  NewFixPointContainer,
} from "./styles";
import { SolidButton } from "../../../../components/SolidButton";

interface IProvisionerList {
  handleProviderSelected?: () => void;
  orderIdentification: string;
  trigger: boolean;
  onClose: () => any;
  order?: any;
}
export interface IOption {
  optionText?: string;
  value: string | number;
  currency?: string;
}
export interface IFilterData {
  licensee_name?: string;
  licensee_status?: boolean | undefined;
  country?: IOption;
  state?: string;
  has_printer?: string;
}
interface ILicenseeData {
  id: string;
  manager_name: string;
  situation: string;
  is_blocked: boolean;
  state: string;
  country: string;
  city: string;
}

export function ProvisionerList({
  orderIdentification,
  trigger,
  handleProviderSelected = () => {},
  onClose = () => {},
  order,
}: IProvisionerList) {
  const [licensees, setLicensees] = useState<ILicenseeData[]>([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [filter, setFilter] = useState<IFilterData>({
    has_printer: 'true'
  });
  const [newFixPoint, setNewFixPoint] = useState(false);

  async function setOrderProvider(id: string) {
    const body = {
      orderId: orderIdentification,
      provider: id,
    };

    try {
      await api.put(`/orders/provider`, body);

      showToast({
        type: "success",
        message: "Provedor do pedido cadastrado!",
      });
      setNewFixPoint(false);
      handleProviderSelected();;
      onClose();
    } catch (error) {
      showToast({
        type: "error",
        message: "Erro no cadastro do provedor!",
      });
    }
  }

  async function getLicensees() {
    try {
      const queryList = [];

      filter?.country && queryList.push(`country=${filter.country.value}`);
      filter?.state && queryList.push(`state=${filter.state}`);
      filter?.has_printer && queryList.push(`has_printer=true`);

    
      const response = await api.get(`/licensees?${queryList.join("&")}`);

      setLicensees(response.data.data);
      
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados",
      });
    }
  }

  useEffect(() => {
    getLicensees();
  }, [filter]);

  return (
    <Modal open={trigger} modalWidth={500}>
      <InputHeader>
        Escolha o licenciado que imprimirá o pedido
      </InputHeader>
      <CloseButton
        onClick={() => {
          onClose();
          setNewFixPoint(false);
        }}
      >
        <RiCloseLine size={24} />
      </CloseButton>

      {order.provider && !newFixPoint ? (
        <>
          <LicenseeSelectedText>Selecionado</LicenseeSelectedText>
          <LicenseeButton
            is_blocked={order.provider.is_blocked}
            onClick={() => {
              setOrderProvider(order.provider.id);
            }}
          >
            <RowFarContainer>
              <CardName>
                Localidade:
                <label>{order.provider.city}-{order.provider.state}</label>
              </CardName>
              {order.provider.country === "BRA" ? <div>🇧🇷</div> : null}
            </RowFarContainer>
            <CardName>
              {" "}
              Responsável:
              <label>{order.provider.manager_name}</label>
            </CardName>
            <CardName>
              {" "}
              Nome da empresa:
              <label>{order.provider.companyName}</label>
            </CardName>
            <CardName>
              {" "}
              Nome-Fantasia:
              <label>{order.provider.name}</label>
            </CardName>
            <CardName>
              {" "}
              Telefone:
              <label>{order.provider.phone}</label>
            </CardName>
          </LicenseeButton>

          <NewFixPointContainer>
            <SolidButton
              text="Modificar FixPoint"
              onClick={() => setNewFixPoint(true)}
            />
          </NewFixPointContainer>
        </>
      ) : (
        <>
          <RowNearContainer>
            <Text>País:</Text>
            <Language
              onChange={(event) => {
                setFilter((prevState) => ({
                  ...prevState,
                  country: {
                    value: event.target.value,
                  },
                }));

                setFilter((prevState) => ({
                  ...prevState,
                  state: undefined,
                }));
              }}
              value={country || undefined}
            >
              <option value="BRA">BR 🇧🇷</option>
              <option value="ES">ES 🇪🇸</option>
              <option value="ES">EN 🇬🇧</option>
            </Language>
          </RowNearContainer>
          <InputContainer>
            <JustifyInput
              placeholder="Digite o estado que deseja buscar o licenciado"
              onChange={(event) => {
                setFilter((prevState) => ({
                  ...prevState,
                  state: event.target.value || undefined,
                }));
              }}
              onClick={() => {
                setLoading(true)
              }}
            />
          </InputContainer>
          <LicenseeListContainer>
            {filter?.state !== undefined && licensees.map((licensee: any) => {
              return (
                <LicenseeButton
                  is_blocked={licensee.is_blocked}
                  onClick={() => {
                    setOrderProvider(licensee.id);
                  }}
                >
                  <RowFarContainer>
                    <CardName>
                      {" "}
                      Localidade:
                      <label>{licensee.city}-{licensee.state}</label>
                    </CardName>
                    {licensee.country === "BRA" ? <div>🇧🇷</div> : null}
                  </RowFarContainer>
                  <CardName>
                    {" "}
                    Responsável:
                    <label>{licensee.manager_name}</label>
                  </CardName>
                  <CardName>
                    {" "}
                    Nome-Fantasia:
                    <label>{licensee.name}</label>
                  </CardName>
                  <CardName>
                    {" "}
                    Nome da empresa:
                    <label>{licensee.companyName}</label>
                  </CardName>
                </LicenseeButton>
              );
            })}
          </LicenseeListContainer>
        </>
      )}
    </Modal>
  );
}
