import styled, { css } from "styled-components";

type ButtonProps = {
  disabled?: boolean;
};

export const Container = styled.div`
  > svg {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  padding-top: 20px;

  .section_title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
  }
`;

export const CheckboxLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const SaveText = styled.span<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100%;
  border: 1px solid ${({ disabled }) =>
                      disabled ? "var(--light-gray)" : "var(--finished)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;