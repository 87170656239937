import { useEffect, useState } from "react";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useForm } from "react-hook-form";

import {
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  UploadFilesContainer,
  DropzoneContainer,
  Footer,
  UploadButton,
  File,
  SolutionName,
  FileContainer,
  ProgressContainer,
} from "./styles";
import api from "../../services/api";
import { showToast } from "../CustomToast";
import { OutlinedInput } from "../OutlinedInput";
import DropZone from "../DropZone";
import { Modal } from "../Modal";
import { Slicers, SlicersVariables } from "../../utils/constants";
import { IFile, IStandardFileData, ModalProps } from "./dtos";
import { GCodeViewer } from "../GCodeViewer";
import { ProgressBar } from "../ProgressBar";

interface IPartConfirmation {
  ETag: string;
  PartNumber: number;
}

const chunkSize = 1024 * 1024 * 5;

export default function UploadStandardFilesModal({ 
  open, 
  editFile,
  hasEditFile,
  standard_id,
  standard_size,
  solution_name,
  part,
  side,
  printer,
  file_id,
  close = () => {},
  reloadFiles = async () => {},
  deleteStandardFile,
  setIconStatus,
  setHasEditFile,
} : ModalProps) {
  const [content, setContent] = useState<any>();
  const [weight, setWeight] = useState("");
  const [time, setTime] = useState("");
  const [materialCost, setMaterialCost] = useState("");
  const [slicer, setSlicer] = useState<any>("");
  const [name, setName] = useState(solution_name);

  const [UploadId, setUploadId] = useState<string>()
  const [randomFileId, setRandomFileId] = useState<string>()
  const [currentChunkIndex, setCurrentChunkIndex] = useState<number | null>(null);
  const [partsConfirmation, setPartsConfirmation] = useState<Array<IPartConfirmation>>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const [file, setFile] = useState({} as IFile);
  const [data, setData] = useState<IStandardFileData>({
    side: "",
    part: "",
    size: "",
    print_time: "",
    material_weight: "",
    material_cost: "",
    printer: {
      id: "",
      name: "",
    },
  });

  useEffect(() => {
    clearStates();
  }, [printer])

  useEffect(() => {
    if (hasEditFile === false) {
      setData({
        ...data,
        material_weight: weight,
        material_cost: materialCost,
        print_time: time,
        size: standard_size,
        side: side,
        part: part,
        printer: {
          id: printer.value,
          name: printer.optionText,
        },
      });
    }
  }, [file, time, weight, materialCost]);

  useEffect(() => {
    if (editFile && hasEditFile === true) {
      setData({
        ...data,
        material_weight: editFile.material_weight,
        material_cost: editFile.material_cost,
        print_time: editFile.print_time,
        size: standard_size,
        side: editFile.side,
        part: editFile.part,
        printer: {
          id: editFile.printer.id,
          name: printer.optionText,
        },
      });
    }
  }, [editFile]);

  useEffect(() => {
    if (file?.file_data) {
      getFileText();
    }
  }, [file.file_data]);

  
  useEffect(() => {
    if (slicer?.length) {
      let readableFile = new FileReader();
      const fileSize = file.size;

      if (slicer === Slicers.cura) {
        readableFile.readAsText(file.file_data.slice(0, 200));
      } else if (slicer === Slicers.prusa) {
        readableFile.readAsText(file.file_data.slice(fileSize - 10000, fileSize));
      } else {
        readableFile.readAsText(file.file_data.slice(fileSize - 200, fileSize));
      }

      readableFile.onload = function () {
        setContent(readableFile.result);
      };
    }
  }, [slicer])

  useEffect(() => {
    if (content !== undefined && slicer === Slicers.cura) {
      let hours;
      let arred_minutes;
      let filament;
      let materialCost_cura;

      let time_position = content && content.indexOf("TIME:");

      let filament_position =
      content && content.indexOf("Filament");

      let time = content.slice(time_position + 5, filament_position - 2);

      filament = content.slice(
        filament_position + 15,
        filament_position + 22
      );

      let weight_cura =
        filament * SlicersVariables.cura.curaFilamentWheightPerMeter;

      materialCost_cura =
        filament *
        ((SlicersVariables.cura.curaFilamentWheightPerMeter / 1000) *
          SlicersVariables.cura.valuePerKilogramBR);

      setWeight(String(weight_cura.toFixed(2)) + "g");
      setMaterialCost(String(materialCost_cura.toFixed(2)) + "");

      if (time > 3600) {
        hours = Math.round(time / 3600);
        arred_minutes = Math.trunc(((time / 3600) - hours) * 60);
      }

      if (time < 3600) {
        arred_minutes = Math.trunc(time / 60);
      }

      if (hours && hours >= 1) {
        content && setTime(`${hours}h, ${arred_minutes}m`);
      } else {
        content && setTime(`${arred_minutes}m`);
      }
    }

    if (content !== undefined && slicer === Slicers.prusa) {
      let filament_weight;
      let time;
      let cost;

      let time_position = content && content.indexOf("time");
      let filament_position = content && content.indexOf("used [g]");
      let cost_position = content.indexOf("filament cost");

      time = content.slice(time_position + 20, time_position + 27);
      filament_weight = content.slice(
        filament_position + 11,
        filament_position + 16
      );
      cost = content.slice(cost_position + 16, cost_position + 20);

      setWeight(filament_weight + "g");
      setTime(time);
      setMaterialCost(cost);
    }

    if (content !== undefined && slicer === Slicers.simplify) {
      let time_position = content && content.indexOf("time:");
      let weight_position = content && content.indexOf("weight:");
      let cost_position = content && content.indexOf("cost:");

      content && setTime(content.slice(time_position + 5, time_position + 20));
      content &&
        setWeight(content.slice(weight_position + 7, weight_position + 13));
      content &&
        setMaterialCost(content.slice(cost_position + 5, cost_position + 20));
    }
  }, [content, slicer]);

  const clearStates = async () => {
    setFile({} as IFile);
    setContent(undefined);
    setTime('');
    setMaterialCost('');
    setWeight('');
    setSlicer('');
    setName('');
    setData({
      ...data,
      printer: {
        id: "",
        name: "",
      },
      side: "",
      part: "",
      size: "",
      print_time: "",
      material_weight: "",
      material_cost: "",
    });
  };

  async function getFileText() {
    let readSlicer = new FileReader();
    const fileSize = file.size;

    try {
      readSlicer.readAsText(file.file_data.slice(0, 200));

      readSlicer.onload = function () {
       const contentSlicer = readSlicer.result?.toString();

       if (!contentSlicer) {
        setSlicer("Falha na leitura do arquivo");
        return;
       }

       let simplifyPosition = contentSlicer.indexOf("Simplify3D(R)");

        if (simplifyPosition !== -1) {
          setSlicer(contentSlicer.slice(simplifyPosition, simplifyPosition + 10));
          return;
        }

        let curaPosition = contentSlicer.indexOf("Cura_SteamEngine");

        if (curaPosition !== -1) {
          setSlicer(contentSlicer.slice(curaPosition, curaPosition + 4));
          return;
        }

        let prusaPosition = contentSlicer.indexOf("PrusaSlicer");

        if (prusaPosition !== -1) {
          setSlicer(contentSlicer.slice(prusaPosition, prusaPosition + 11));
          return;
        }

        if (
          prusaPosition === -1 &&
          simplifyPosition === -1 &&
          curaPosition === -1
        ) {
          setSlicer("Fatiador não reconhecido");
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (currentChunkIndex !== null && file.file_data) {
      if (currentChunkIndex == 0 && !UploadId && !randomFileId) {
        readAndUploadCurrentChunk()
      } else if (currentChunkIndex > 0 && !!UploadId && !!randomFileId) {
        readAndUploadCurrentChunk()
      }
    }

  }, [currentChunkIndex, file.file_data, UploadId, randomFileId])
  

  function readAndUploadCurrentChunk() {
    if (currentChunkIndex == null) {
      return
    }

    const reader = new FileReader();

    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.file_data.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }
  
  function uploadChunk(readerEvent: ProgressEvent<FileReader>) {
    if (currentChunkIndex == null || !readerEvent.target || !file?.file_data) {
      showToast({
        type: "error",
        message: `Nenhum documento foi adicionado`,
      });
      return;
    }

    const filesize = file.size;
    const chunks = Math.ceil(filesize / chunkSize) - 1;
    const isLastChunk = currentChunkIndex === chunks;

    const chunk = readerEvent.target.result;

    const params = new URLSearchParams();
    params.set('name', file.name);
    params.set('size', `${file.size}`);
    params.set('type', file.type);

    
    params.set("printer_id", data.printer.id);
    params.set("standard_id", standard_id);
    if (data.side) params.set("side", data.side);
    if (data.part) params.set("part", data.part);
    params.set("print_time", data.print_time);
    params.set("material_weight", data.material_weight);
    params.set("material_cost", data.material_cost);
    params.set("solution_name", solution_name || "") ;
    params.set('currentChunkIndex', currentChunkIndex.toString());
    params.set('totalChunks', Math.ceil(file.size / chunkSize).toString());
    if (UploadId) {
      params.set('UploadId', UploadId);  
    }
    if (randomFileId) {
      params.set('randomFileId', randomFileId);  
    }
    if (isLastChunk) {
      params.set('Parts', JSON.stringify(partsConfirmation));
    }

    const headers = {'Content-Type': 'application/octet-stream'};
    api.post(`/standard-files?${params.toString()}`, chunk, {headers})
      .then(response => {

        if (currentChunkIndex === 0) {
          setUploadId(response.data.UploadId)
          setRandomFileId(response.data.randomFileId)
        }

        if (isLastChunk) {
          setPartsConfirmation([]);
          setCurrentChunkIndex(null);
          setUploadId(undefined)
          setRandomFileId(undefined)
          showToast({
            type: "success",
            message: "Arquivo padronizado configurado com sucesso!",
          });
          setIconStatus();
          reloadFiles();
          close();
          clearStates();
          setHasEditFile(true);
        } else {
          setPartsConfirmation((prevState) => [...prevState, { ETag: response.data.ETag, PartNumber: response.data.PartNumber }]);
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      }).catch(e => {
        showToast({
          type: "error",
          message: `Erro ao carregar arquivo padronizado`,
        });
        setUploadId(undefined);
        setRandomFileId(undefined);
        setCurrentChunkIndex(null);
        setPartsConfirmation([]);
      })
  }

  const submitChunckedForm = async (): Promise<void> => {
    if (!file?.file_data) {
      return;
    }

    setCurrentChunkIndex(0);
  }

  function getUploadedGcodeURL() {
    try {
      if (file?.file_data) return URL.createObjectURL(file.file_data);

      if (editFile?.link_gcode) return editFile?.link_gcode;

      return "";
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao exibir a visualização 3D do arquivo.",
      });
    }
  }

  return (
    <Modal
      open={open}
      onRequestClose={async () => {
        close();
        await clearStates();
        setHasEditFile(false);
      }}
    >
        <Form onSubmit={handleSubmit(submitChunckedForm)}>
          <FormContainer>
            <div className="form_title">Informações do arquivo</div>
            <SolutionName>{solution_name}</SolutionName>
            {hasEditFile && editFile ? 
            (
              <FileContainer>
                <File>
                  <span>{`${name} ${data.part} ${data.side} - ${data.size}`}</span>
                  <RiDeleteBin7Line
                    onClick={async () => {
                      if (file_id) {
                        if (
                          window.confirm(
                            "Tem certeza que deseja exluir o arquivo?"
                          )
                        ) {
                          await deleteStandardFile(file_id);
                          close();
                          await clearStates();
                          await reloadFiles();
                          await setIconStatus();
                          setHasEditFile(false);
                        }
                      }
                    }}
                    size={20}
                    color="var(--fixit)"
                  />
                </File>
              </FileContainer>
            ) : (
            <UploadFilesContainer>
              {file.name ? (
                <>
                  {/* <GCodeViewer gcodeURL={getUploadedGcodeURL()} /> */}
                </>
              ) : (
                <>
                  <label>Arquivo</label>
                  <DropzoneContainer>
                    <DropZone
                      multiple={false}
                      onUpload={(upFile) => {
                        const formattedFile = {
                          name: upFile[0].name,
                          size: upFile[0].size,
                          type: upFile[0].type,
                          file_data: upFile[0],
                        };
                        setFile(formattedFile);
                      }}
                    />
                  </DropzoneContainer>
                </>
              )}
              {file.name && (
                <File>
                  <span>{file.name}</span>
                  <RiDeleteBin7Line
                    onClick={async () => {
                      setFile({} as IFile);
                      await clearStates();
                    }}
                    size={20}
                    color="var(--fixit)"
                  />
                </File>
              )}
            </UploadFilesContainer>
          )}

          <FormRow>
            {!hasEditFile && 
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label="Fatiador"
                inputName="slicer"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    slicer: value,
                  }));
                }}
                value={slicer}
              />
            </InputContainer>
            }
          </FormRow>

          <FormRow>
            <InputContainer>
              <OutlinedInput
                label="Tempo"
                inputName="print_time"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    print_time: value,
                  }));
                }}
                value={data.print_time || ""}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label="Peso"
                inputName="material_weigth"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    material_weight: value,
                  }));
                }}
                value={data.material_weight || ""}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <OutlinedInput
                label="Custo"
                inputName="material_cost"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    material_cost: value,
                  }));
                }}
                value={data.material_cost || ""}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <OutlinedInput
                label={"Parte"}
                inputName={"part"}
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    part: event.value,
                  }));
                }}
                value={data.part || ""}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label={"Lado"}
                inputName={"side"}
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    side: event.value,
                  }));
                }}
                value={data.side || ""}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label={"Tamanho"}
                inputName={"size"}
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    size: event.value,
                  }));
                }}
                value={data.size || ""}
              />
            </InputContainer>
          </FormRow>

          <ProgressContainer>
            <ProgressBar
              text={!currentChunkIndex ? 'Adicione um arquivo para iniciar o upload' : `Realizando upload ${currentChunkIndex ? Math.ceil(((currentChunkIndex ?? 0)/ (Math.ceil(file.size / chunkSize) - 1))*100) : 0}%`}
              percentage={currentChunkIndex ? ((currentChunkIndex ?? 0)/ (Math.ceil(file.size / chunkSize) - 1))*100 : 0}
            />
          </ProgressContainer>

          <Footer>
            <UploadButton type="submit" disabled={!!currentChunkIndex}>
              Confirmar Dados e Cadastrar
            </UploadButton>
          </Footer>
        </FormContainer>
      </Form>
    </Modal>
  );
}
