import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import api from "../../../../services/api";
import { AxiosError } from "axios";

import Input from "../../components/Input";
import DropZone from "../../../../components/DropZone";

import { RiDeleteBin7Line } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import { useForm } from "react-hook-form";
import Dropdown from "../../components/Dropdown";

import {
  Container,
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  UploadFilesContainer,
  DropzoneContainer,
  Footer,
  UploadButton,
  File,
} from "./styles";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}

interface IFormData {
  name: string;
  version: string;
  contract?: {
    value: string | number;
    optionText: string;
  };
  type: {
    value: string;
    optionText: string;
  };
  locale: {
    value: string;
    optionText: string;
  };
}
interface IParams {
  contract_version: string;
}

export default function UploadTerms() {
  const { setNavigationAction } = useAuth();
  const navigate = useNavigate();

  const contractOptions = [
    { optionText: "Licença Anual - 1.0", value: "1.0" },
    { optionText: "Licença Mensal - 2.0", value: "2.0" },
  ];

  const typeOptions = [
    { optionText: "Política de Privacidade", value: "privacy_policy" },
    { optionText: "Termos de Uso", value: "user_terms" },
  ];

  const localeOptions = [
    { optionText: "Português", value: "pt-BR" },
    { optionText: "Inglês", value: "en" },
    { optionText: "Espanhol", value: "es" },
  ];

  let { contract_version } = useParams<keyof IParams>();

  const [file, setFile] = useState<IFile>();
  const [data, setData] = useState<IFormData>({
    name: "",
    version: "",
    type: {
      optionText: "",
      value: "",
    },
    locale: {
      optionText: "",
      value: "",
    },
    contract: contractOptions.find((c) => c.value === contract_version),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  function handleDeleteFile() {
    setFile(undefined);
  }

  const submitForm = async (): Promise<void> => {
    if (!file) {
      showToast({
        type: "error",
        message: `Nenhum documento foi adicionado`,
      });
      return;
    }

    var formData = new FormData();

    formData.append("file", file.file_data);
    formData.append("name", data.name);
    formData.append("version", data.version);
    formData.append("type", data.type.value);
    formData.append("locale", data.locale.value);

    if (data.contract) {
      formData.append("contract", data.contract.value + "");
    }

    api
      .post(`/user_terms`, formData)
      .then(() => {
        showToast({
          type: "success",
          message: "Documento adicionado com sucesso!",
        });
        navigate(-1);
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao carregar dados do termo de uso`,
        });
      });

    return;
  };

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.configs.createUserTerms"),
    });
  }, []);

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitForm)}>
        <FormContainer>
          <div className="form_title">Informações do termo</div>

          <FormRow>
            <InputContainer>
              <label>Título do documento</label>
              <Input
                type="text"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
                value={data.name}
              />
            </InputContainer>

            <InputContainer>
              <label>Versão</label>
              <Input
                type="text"
                handleChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    version: value,
                  }));
                }}
                value={data.version}
              />
            </InputContainer>

            <InputContainer>
              <label>Contrato</label>
              <Dropdown
                options={contractOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    contract: selected,
                  }));
                }}
                selectedOption={data.contract?.optionText}
                placeholder="Selecione um contrato..."
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Tipo</label>
              <Dropdown
                options={typeOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    type: selected,
                  }));
                }}
                selectedOption={data.type?.optionText}
                placeholder="Selecione o tipo do contrato..."
              />
            </InputContainer>

            <InputContainer>
              <label>Localidade</label>
              <Dropdown
                options={localeOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    locale: selected,
                  }));
                }}
                selectedOption={data.locale?.optionText}
                placeholder="Selecione o país..."
              />
            </InputContainer>
          </FormRow>

          <UploadFilesContainer>
            <label>Arquivo</label>
            <DropzoneContainer>
              <DropZone
                onUpload={(file) => {
                  const formattedFile = {
                    name: file[0].name,
                    size: file[0].size,
                    type: file[0].type,
                    file_data: file[0],
                  };
                  setFile(formattedFile);
                }}
              />
            </DropzoneContainer>

            {file && (
              <File>
                <span>{file.name}</span>
                <RiDeleteBin7Line
                  onClick={() => handleDeleteFile()}
                  size={20}
                  color="var(--fixit)"
                />
              </File>
            )}
          </UploadFilesContainer>

          <Footer>
            <UploadButton type="submit">Cadastrar termos</UploadButton>
          </Footer>
        </FormContainer>
      </Form>
    </Container>
  );
}
