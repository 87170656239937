import styled from "styled-components";

type ContainerProps = {
  textColor: string;
  hoverTextColor: string;
  borderColor: string;
  hoverBorderColor: string;
  buttonBackground: string;
  buttonHoverBackground: string;
  activeBorderColor: string;
  buttonActiveBackground: string;
  activeTextColor: string;
};

export const Container = styled.button<ContainerProps>`
  min-width: 80px;
  min-height: 25px;
  border-radius: 15px;
  border-width: 2px;
  border-color: ${({ borderColor }) => borderColor};
  border-style: solid;
  background: ${({ buttonBackground }) => buttonBackground};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.4s;

  span {
    font-weight: 600;
    font-size: 10px;
    color: ${({ textColor }) => textColor};

    transition: color 0.4s;
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${({ hoverBorderColor }) => hoverBorderColor};
      background: ${({ buttonHoverBackground }) => buttonHoverBackground};

      span {
        color: ${({ hoverTextColor }) => hoverTextColor};
      }
    }
  }

  &:active {
    border-color: ${({ activeBorderColor }) => activeBorderColor};
    background: ${({ buttonActiveBackground }) => buttonActiveBackground};
    zoom: 1.01;

    span {
      color: ${({ activeTextColor }) => activeTextColor};
    }
  }
`;
