import { useState } from "react";
import { RiEdit2Line, RiEyeLine, RiPagesLine } from "react-icons/ri";
import { BiTrashAlt } from "react-icons/bi";
import { IUser } from "../..";
import { Modal } from "../../../../../../components/Modal";
import Popover from "../../../../../../components/Popover";
import { EditModal } from "../EditModal";
import { ActionItem, ActionsContainer, Container, Header, IconButtonContainer, ImageContainer, LeftContent, PrincipalInfos, RightContent } from "./styles";

interface IUserItem {
    user: IUser;
    getUsers:  () => Promise<void>;
    deleteUser: (id: string) => Promise<void>;
}

export function UserItem({ user, getUsers, deleteUser }: IUserItem) {
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    return (
        <Container>
            <Header>
                <LeftContent>
                    <ImageContainer>
                        <Popover
                        position="right"
                        label={`Usuário ${user.role}`}
                        styles={{ minWidth: "200px", maxWidth: "200px", whiteSpace: "normal" }}
                        >
                          <RiPagesLine />
                        </Popover>
                    </ImageContainer>
                    <PrincipalInfos>
                        <span className="solution">
                        { user.name }
                        </span>
                        <span className="date">ROLE: { user.role }</span>
                    </PrincipalInfos>
                </LeftContent>
                <RightContent>
                    <ActionsContainer>
                    <ActionItem>
                    <IconButtonContainer >
                        <Popover position="left" label={`Editar ${user.name}`}>
                            <RiEdit2Line size={18} onClick={() => setIsModalOpen(true)}/>
                        </Popover>
                        <Popover position="left" label={`Deletar ${user.name}`}>
                            <BiTrashAlt size={18} onClick={() => deleteUser(user.id)}/>
                        </Popover>
                    </IconButtonContainer>
                        {
                            isModalOpen && 
                            <Modal open={isModalOpen}>
                                <EditModal
                                    hasUser={user}
                                    closeModal={async () => {
                                        await getUsers();
                                        setIsModalOpen(false)
                                    }}
                                    isEdit
                                    hasSteps={false}
                                    />
                            </Modal>
                        }
                        </ActionItem>
                    </ActionsContainer>
                </RightContent>
            </Header>
        </Container>
    )
}