import axios, { AxiosError } from "axios";
const FormData = require('form-data');

let isRefreshing = false;
let failedRequestsQueue: {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError<any>) => void;
}[] = [];

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8080",
});
export const isAxiosCancel = axios.isCancel;

/*api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@FixitApp:token");
    config.headers.Authorization = token;

    return config;
  }
)*/
axios.interceptors.request.use(config => {
  if (config.data instanceof FormData && !!config.headers) {
    Object.assign(config.headers, config.data.getHeaders());
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response?.data?.message === "Expired token") {
        const refresh_token = localStorage.getItem("@FixitApp:refresh_token");
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post("/sessions/refresh-token", {
              refresh_token,
            })
            .then((response) => {
              const { token, refresh_token } = response.data;

              localStorage.setItem("@FixitApp:token", token);
              localStorage.setItem("@FixitApp:refresh_token", refresh_token);

              api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

              api.get("/users/me").then((response) => {
                const { role } = response.data;

                if (role === "DISABLED") {
                  localStorage.removeItem("@FixitApp:token");
                  localStorage.removeItem("@FixitApp:refresh_token");
                }
              });

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(token)
              );
              failedRequestsQueue = [];
            })
            .catch((error) => {
              failedRequestsQueue.forEach((request) =>
                request.onFailure(error)
              );
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              if (originalConfig?.headers) {
                originalConfig.headers["Authorization"] = `Bearer ${token}`;

                resolve(api(originalConfig));
              }
            },
            onFailure: (error: AxiosError) => {
              reject(error);
            },
          });
        });
      } else {
        localStorage.removeItem("@FixitApp:token");
        localStorage.removeItem("@FixitApp:refresh_token");
        delete api.defaults.headers.common["Authorization"];
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
