import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bill, useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { roles } from "../../../../utils/constants";
import { BillPaymentDetails } from "../../../BillPaymentDetails";
import { ClosureItem } from "../../../ClosureItem";
import { showToast } from "../../../CustomToast";
import { Loader } from "../../../Loader";
import { Modal } from "../../../Modal";
import { currencyValue } from "../../mock";

import { Container, Wrapper } from "./styles";

interface IOrdersClosure {
  sum: string;
  total: number;
  totalCourtesy: string;
  totalNotPaid: string;
  totalPaid: string;
}

export default function MonthClosure() {
  const { user, reloadUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [filterStartDate, setFilterStartDate] = useState<Date>();
  const [filterEndDate, setFilterEndDate] = useState<Date>();
  const [previousFilterStartDate, setPreviousFilterStartDate] =
    useState<Date>();
  const [previousFilterEndDate, setPreviousFilterEndDate] = useState<Date>();
  const [orders, setOrders] = useState<IOrdersClosure>();
  const [previousOrders, setPreviousOrders] = useState<IOrdersClosure>();
  const [ordersAsProvider, setOrdersAsProvider] = useState<IOrdersClosure>();
  const [currency, setCurrency] = useState();
  const [licenseeBill, setLicenseeBill] = useState<Bill>();

  const [openCardSelectModal, setOpenCardSelectModal] = useState(false);

  function toggleCardSelectModal() {
    setOpenCardSelectModal(!openCardSelectModal);
  }

  async function getDate() {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = dataAtual.getMonth();
    if (new Date().getDate() > 25) {
      const filterStartDate = new Date(ano, mes, 26);
      const filterEndDate = new Date(ano, mes + 1, 25);
      const previousFilterStartDate = new Date(ano, mes - 1, 26);
      const previousFilterEndDate = new Date(ano, mes, 25);
      setFilterEndDate(filterEndDate);
      setFilterStartDate(filterStartDate);
      setPreviousFilterEndDate(previousFilterEndDate);
      setPreviousFilterStartDate(previousFilterStartDate);
      return {
        filterEndDate,
        filterStartDate,
        previousFilterEndDate,
        previousFilterStartDate,
      };
    } else {
      const filterStartDate = new Date(ano, mes - 1, 26);
      const filterEndDate = new Date(ano, mes, 25);
      const previousFilterStartDate = new Date(ano, mes - 2, 26);
      const previousFilterEndDate = new Date(ano, mes - 1, 25);
      setFilterEndDate(filterEndDate);
      setFilterStartDate(filterStartDate);
      setPreviousFilterEndDate(previousFilterEndDate);
      setPreviousFilterStartDate(previousFilterStartDate);
      return {
        filterEndDate,
        filterStartDate,
        previousFilterEndDate,
        previousFilterStartDate,
      };
    }
  }

  function formatDate(date: Date) {
    return format(date, "dd/MM/yyyy");
  }

  async function getOrders() {
    try {
      await getDate().then((response) => {
        api
          .get(
            `/orders/user?from=${response.filterStartDate?.toJSON()}&to=${response.filterEndDate?.toJSON()}`
          )
          .then((response) => {
            setOrders({
              sum: response.data.sum,
              total: response.data.total,
              totalCourtesy: response.data.totalCourtesy,
              totalNotPaid: response.data.totalNotPaid,
              totalPaid: response.data.totalPaid,
            });
          });
      });
    } catch (err) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as informações de pedido",
      });
    }
  }

  async function getPreviousOrders() {
    try {
      await getDate().then((response) => {
        api
          .get(
            `/orders/user?from=${response.previousFilterStartDate?.toJSON()}&to=${response.previousFilterEndDate?.toJSON()}`
          )
          .then((response) => {
            setPreviousOrders({
              sum: response.data.sum,
              total: response.data.total,
              totalCourtesy: response.data.totalCourtesy,
              totalNotPaid: response.data.totalNotPaid,
              totalPaid: response.data.totalPaid,
            });
          });
      });
    } catch (err) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as informações de pedido",
      });
    }
  }

  async function getOrdersAsProvider() {
    try {
      await getDate().then((response) => {
        api
          .get(
            `/orders/user?from=${response.filterStartDate?.toJSON()}&to=${response.filterEndDate?.toJSON()}&as_provider=true`
          )
          .then((response) => {
            setOrdersAsProvider({
              sum: response.data.sum,
              total: response.data.total,
              totalCourtesy: response.data.totalCourtesy,
              totalNotPaid: response.data.totalNotPaid,
              totalPaid: response.data.totalPaid,
            });
          });
      });
    } catch (err) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as informações de pedido",
      });
    }
  }

  async function getUserCurrency() {
    try {
      await api.get(`/signatures/licensee`).then((response) => {
        setCurrency(response.data.plan.currency);
      });
    } catch (err) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as informações de moeda",
      });
    }
  }

  useEffect(() => {
    reloadUser();
    Promise.all([
      getOrders(),
      getOrdersAsProvider(),
      getUserCurrency(),
      getPreviousOrders(),
    ]);
  }, []);

  useEffect(() => {
    if (orders && ordersAsProvider && previousOrders) {
      setLoading(false);
    }
  }, [orders, ordersAsProvider, previousOrders]);

  useEffect(() => {
    setLicenseeBill(
      user.person.licensee.bills.find(
        (bill) =>
          bill.referring_period ===
          `${new Date(previousFilterEndDate as Date).getMonth() + 1}/${new Date(
            previousFilterEndDate as Date
          ).getFullYear()}`
      )
    );
  }, [previousFilterEndDate, user]);

  return (
    <>
      <Modal
        open={openCardSelectModal}
        onRequestClose={toggleCardSelectModal}
        modalWidth={500}
      >
        <BillPaymentDetails
          bill={licenseeBill}
          reloadUser={reloadUser}
          onHandleCancel={toggleCardSelectModal}
          totalBillValue={previousOrders?.totalNotPaid ?? 0}
          closure_date={previousFilterEndDate ?? new Date()}
          currency={currency ?? currencyValue.BRL}
        />
      </Modal>
      {!loading ? (
        <Container>
          <Wrapper>
            <ClosureItem
              title={`${i18n.t("userProfile.actualClosure")} (${currency})`}
              description={`${i18n.t("userProfile.descriptions.closure")}`}
              period={`${formatDate(filterStartDate as Date)} - ${formatDate(
                filterEndDate as Date
              )}`}
              help={`${i18n.t("userProfile.descriptions.closurePopover")}`}
              value={orders?.totalNotPaid ?? 0}
              onClick={() => {
                navigate({
                  pathname: `/${user.role.toLocaleLowerCase()}/orders/closure`,
                  search: `?from=${formatDate(
                    filterStartDate as Date
                  )}&to=${formatDate(filterEndDate as Date)}`,
                });
              }}
            />
            <ClosureItem
              currency={currency}
              bill={licenseeBill}
              closurePeriod={previousFilterEndDate}
              acceptPayment={
                !licenseeBill && (previousFilterEndDate as Date) <= new Date()
              }
              onHandlePayBill={toggleCardSelectModal}
              title={`${i18n.t("userProfile.previousClosure")} (${currency})`}
              description={`${i18n.t("userProfile.descriptions.closure")}`}
              period={`${formatDate(
                previousFilterStartDate as Date
              )} - ${formatDate(previousFilterEndDate as Date)}`}
              help={`${i18n.t("userProfile.descriptions.closurePopover")}`}
              value={previousOrders?.totalNotPaid ?? 0}
              onClick={() => {
                navigate({
                  pathname: `/${user.role.toLocaleLowerCase()}/orders/closure`,
                  search: `?from=${formatDate(
                    previousFilterStartDate as Date
                  )}&to=${formatDate(previousFilterEndDate as Date)}`,
                });
              }}
            />
            {user.role === roles.licensee && (
              <ClosureItem
                title={`${i18n.t("orders.as_provider")}`}
                description={`${i18n.t("userProfile.descriptions.fixPoint")}`}
                help={`${i18n.t("userProfile.descriptions.fixPointPopover")}`}
                value={ordersAsProvider?.total ?? 0}
                onClick={() => {
                  navigate({
                    pathname: `/orders/as_provider`,
                  });
                }}
              />
            )}
            <ClosureItem
              title={`${i18n.t("userProfile.ordersMade")}`}
              description={`${i18n.t("userProfile.descriptions.ordersMade")}`}
              value={orders?.total ?? 0}
            />
            <ClosureItem
              title={`${i18n.t("financial.situation.courtesy")} (${currency})`}
              description={`${i18n.t(
                "userProfile.descriptions.ordersCourtesy"
              )}`}
              value={orders?.totalCourtesy ?? 0}
            />
            <ClosureItem
              title={`${i18n.t("userProfile.ordersPaid")} (${currency})`}
              description={`${i18n.t("userProfile.descriptions.ordersPaid")}`}
              value={orders?.totalPaid ?? 0}
            />
          </Wrapper>
        </Container>
      ) : (
        <Container>
          <Loader />
        </Container>
      )}
    </>
  );
}
