import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBtn from '../../../../components/NavBtn';
import NavMenu from './NavMenu';
import Topbar from '../../../../components/Topbar';
import CustomToast from '../../../../components/CustomToast';

import {
  PageContainer,
} from './styles';
import Sidebar from '../../../../components/Sidebar';

interface Props {
  children: ReactNode;
  noPadding?: boolean;
}

function AdminLayout({ children, noPadding }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/saf') {
      setIsIframe(true)
    } else {
      setIsIframe(false);
    }
  }, [location])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <NavMenu toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <NavBtn toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <Sidebar />
      <PageContainer noPadding={noPadding ?? false} isMenuOpen={isMenuOpen} isIframe={isIframe}>
        <Topbar />
        <CustomToast />
        <div className="content-area">
          {children}
        </div>
      </PageContainer>
    </>
  );
};

export default AdminLayout;
