import styled, { css } from "styled-components";

interface CheckboxProps{
  checked?: boolean
}


export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
padding-bottom: 50px;
`;

export const ListContainer = styled.div`
  width: 100%;

  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;