import { BrowserRouter, Routes } from "react-router-dom";
import { AuthProvider } from "./hooks/AuthContext";

import GlobalStyles from "./styles/GlobalStyles";
import { PrinterProvider } from "./hooks/PrintersContext";
import { OnboardProvider } from "./hooks/OnboardContext";
import { TipsProvider } from "./hooks/TipsContext";
import Router from "./routes";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TipsProvider>
          <PrinterProvider>
            <OnboardProvider>
                <Router />
            </OnboardProvider>
          </PrinterProvider>
        </TipsProvider>
      </AuthProvider>
      <GlobalStyles />
    </BrowserRouter>
  );
}

export default App;
