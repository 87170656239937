import styled from "styled-components";
import global from "../../../../../../global";

interface IPaymentSituation {
  paid?: string;
}

export const Container = styled.div<IPaymentSituation>`
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  border-left: 3px solid
    ${(props) =>
      props.paid === "pago"
        ? `var(--active-green)`
        : props.paid === "aguardando"
        ? `var(--warning)`
        : props.paid === "estorno"
        ? `var(--development)`
        : `var(--error)`};

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding: 16px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    gap: 10px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px var(--light-gray);
  background: var(--fixit);
  color: #ffff;
  font-weight: 500;
  font-size: 1.2rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  svg {
    font-size: 30px;
  }
`;

export const PrincipalInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  min-width: 140px;
  max-width: 140px;

  .date {
    font-size: 10px;
    line-height: 10px;
    color: var(--gray);
    font-weight: 500;
    margin-top: 5px;
  }

  .solution {
    font-size: 14px;
    line-height: 14px;
    color: var(--active-green);
    font-weight: bolder;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    min-width: fit-content;
    max-width: fit-content;
  }
`;

export const PriceInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-width: 100px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    &.licensee_desktop {
      display: none;
    }
  }

  .price_name {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .price_location {
    font-size: 14px;
    line-height: 10px;
    color: var(--active-green);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const StatusDesktopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.order_type {
    min-width: 100px;
  }

  &.solution_type {
    min-width: 100px;
  }

  &.country {
    min-width: 50px;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    &.licensee_desktop {
      display: none;
    }
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 20px;
`;

export const EditBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
