import { FiEdit2 } from "react-icons/fi";
import Popover from "../../../../../../components/Popover/";
import { HoverActionButton } from "../../../../../../components/HoverActionButton";
import ImagemCoringa from "../../../../../../assets/imagem_coringa.png";

import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  PriceInfos,
  RightContent,
  ActionsContainer,
  Header,
  CenterContent,
  StatusDesktopContent,
  MobileContent,
} from "./styles";
import { i18n } from "../../../../../../localization/i18n";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { showToast } from "../../../../../../components/CustomToast";
import { IPrice } from "../../dtos";

interface PriceItemProps {
  onClick?: () => void;
  onSeeIconClick?: () => void;
  onEditIconClick?: () => void;
  onUpdate?: (price: IPrice) => void;
  price: IPrice;
}

export function PriceItem({
  price,
  onClick = () => {},
  onSeeIconClick = () => {},
  onEditIconClick = () => {},
  onUpdate = () => {},
}: PriceItemProps) {
  const toogleStatus = async () => {
    if (
      window.confirm(
        i18n.t(
          `financial.prices.confirm.${
            price.is_active ? "deactivate" : "activate"
          }`
        )
      )
    ) {
      const abortController = new AbortController();
      let signal = abortController.signal;

      api
        .put(`/prices/${price.id}/activate?status=${!price.is_active}`, {
          signal,
        })
        .then(({ data }) => {
          onUpdate(data);
        })
        .catch((err) => {
          if (isAxiosCancel(err)) {
            return "axios request cancelled";
          }
          showToast({
            type: "error",
            message: "Não foi possível alterar o status do preço",
          });
        });

      return () => abortController.abort();
    }
  };

  return (
    <Container
      onClick={() => {
        onClick();
      }}
    >
      <Header>
        <LeftContent>
          <ImageContainer>
            <img
              src={
                price.solution?.link_img
                  ? `${process.env.REACT_APP_STORAGE_BASE_URL}${price.solution?.link_img}`
                  : ImagemCoringa
              }
              alt="gif"
            />
          </ImageContainer>
          <PriceInfos>
            <span className="price_name">{price.solution?.name}</span>
            <span className="price_location">
              {price.currency} {price.file_price}
            </span>
          </PriceInfos>
        </LeftContent>

        <CenterContent>
          <ContentInfos className="order_type">
            <span className="data_label">
              {`${i18n.t("financial.prices.field.order_type")}`}
            </span>
            <span className="data_value">
              {`${i18n.t(`financial.prices.order_type.${price.order_type}`)}`}
            </span>
          </ContentInfos>
          <ContentInfos className="solution_type">
            <span className="data_label">
              {`${i18n.t("financial.prices.field.solution_type")}`}
            </span>
            <span className="data_value">
              {`${i18n.t(
                `financial.prices.solution_type.${
                  price.solution_type || "default"
                }`
              )}`}
            </span>
          </ContentInfos>
          <ContentInfos className="country">
            <span className="data_label">
              {`${i18n.t("financial.prices.field.country")}`}
            </span>
            <span className="data_value">
              {price.country || `${i18n.t("financial.prices.country.default")}`}
            </span>
          </ContentInfos>
        </CenterContent>

        <RightContent>
          <StatusDesktopContent>
            <HoverActionButton
              onClick={toogleStatus}
              text={!price.is_active ? "Disabled" : "Enabled"}
              hoverText={!price.is_active ? "Enable" : "Disable"}
              textColor={
                !price.is_active
                  ? "var(--active-orange)"
                  : "var(--active-green)"
              }
              hoverTextColor="var(--white)"
              borderColor={
                !price.is_active
                  ? "var(--active-orange)"
                  : "var(--active-green)"
              }
              hoverBorderColor={
                !price.is_active
                  ? "var(--active-green)"
                  : "var(--active-orange)"
              }
              buttonBackground="var(--white)"
              buttonHoverBackground={
                !price.is_active
                  ? "var(--active-green)"
                  : "var(--active-orange)"
              }
            />
          </StatusDesktopContent>

          <ActionsContainer>
            <Popover position="left" label="Editar">
              <FiEdit2
                size={18}
                onClick={(event) => {
                  event.stopPropagation();
                  onEditIconClick();
                }}
              />
            </Popover>
          </ActionsContainer>
        </RightContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_value">
              {`${i18n.t(`financial.prices.order_type.${price.order_type}`)}`}
            </span>
          </ContentInfos>
          {price.solution_type && (
            <ContentInfos>
              <span className="data_value">
                {`${i18n.t(
                  `financial.prices.solution_type.${price.solution_type}`
                )}`}
              </span>
            </ContentInfos>
          )}
          <ContentInfos>
            <span className="data_value">{price.country}</span>
          </ContentInfos>
        </LeftContent>
        <RightContent>
          <HoverActionButton
            onClick={toogleStatus}
            text={!price.is_active ? "Disabled" : "Enabled"}
            hoverText={!price.is_active ? "Enable" : "Disable"}
            textColor={
              !price.is_active ? "var(--active-orange)" : "var(--active-green)"
            }
            hoverTextColor="var(--white)"
            borderColor={
              !price.is_active ? "var(--active-orange)" : "var(--active-green)"
            }
            hoverBorderColor={
              !price.is_active ? "var(--active-green)" : "var(--active-orange)"
            }
            buttonBackground="var(--white)"
            buttonHoverBackground={
              !price.is_active ? "var(--active-green)" : "var(--active-orange)"
            }
          />
        </RightContent>
      </MobileContent>
    </Container>
  );
}
