import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
  Section,
  Field,
  Footer,
  ButtonIcon,
  TopRightPosition,
  ImageContainer,
  Image,
  Header,
} from "./styles";
import api from "../../../../services/api";
import { useNavigate } from "react-router";
import { RiPencilLine } from "react-icons/ri";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

interface ISolutionField {
  name: I18NTextLanguageObject;
  name_key: string;
  image_link: string;
  description: I18NTextLanguageObject;
}

export default function Fields() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();
  const [fields, setFields] = useState<Array<ISolutionField>>([]);

  useEffect(() => {
    api
      .get(`/categories`)
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.configs.solutionFields"),
    });
  }, []);

  return (
    <>
      <Header>
        <AbsoluteButton
          text="Novo"
          absolute={false}
          onClick={() => navigate("/configs/fields/create")}
        />
      </Header>

      <Container>
        <EvaluationWrapper>
          {fields.map((field) => {
            const language = i18n.language;

            return (
              <Section>
                <TopRightPosition>
                  <ButtonIcon
                    onClick={() =>
                      navigate(`/configs/fields/${field.name_key}/edit`)
                    }
                  >
                    <RiPencilLine size={24} color="var(--fixit)" />
                  </ButtonIcon>
                </TopRightPosition>
                <h2>{field.name[language as keyof I18NTextLanguageObject]}</h2>
                {!!field.image_link && (
                  <ImageContainer>
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_BASE_URL}${field.image_link}`}
                    />
                  </ImageContainer>
                )}
                <Field>
                  <strong>Descrição:</strong> {field.description[language as keyof I18NTextLanguageObject]}
                </Field>
                <Field>
                  <strong>Chave de URL:</strong> {field.name_key}
                </Field>
                <Footer>
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/configs/fields/${field.name_key}`)
                    }
                  >
                    Configurar
                  </button>
                </Footer>
              </Section>
            );
          })}
        </EvaluationWrapper>
      </Container>
    </>
  );
}
