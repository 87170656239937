import styled, { css } from "styled-components";
import global from "../../../../../../global"

type ButtonProps = {
  disabled?: boolean;
};

export const Container = styled.div`
  > svg {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  resize: none;
  padding: 15px;

  border: 2px solid var(--fixit);
  border-radius: 6px;

  font-size: 1rem;
  color: var(--dark-gray);

  input{
  }

  ::placeholder {
    color: var(--gray);
    @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
      padding-left: 0px;
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: relative;
  width: 100%;
  margin-top: 10px;

  span{
    color: var(--fixit);
    position: relative;
    top: 10px;
    right: -25px;
    background-color: var(--white);
    font-weight: 500;
    padding: 0 5px;
  }
`;

export const UploadFilesContainer = styled.div`
  margin-top: 5px;
  
  label {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  padding-top: 25px;

  .section_title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  gap: 10px;
`

export const FlexContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span{
    color: var(--gray);
    position: relative;
    top: 10px;
    right: 70px;
    background-color: var(--white);
  }
`

export const LinkButton = styled.button<ButtonProps>`
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
  border: 2px solid var(--light-gray);
  gap: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => disabled ? '#ccc' : '#fff'};
  color: var(--fixit);
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
  `}

  &:link,
  &:visited {
      text-transform: uppercase;
      text-decoration: none;
      padding: 15px 40px;
      display: inline-block;
      border-radius: 100px;
      transition: all .2s;
      position: absolute;
  }

  &:active {
      transform: translateY(-1px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  :hover{
    border: 2px solid var(--fixit)
  }

  @keyframes moveInBottom {
      0% {
          opacity: 0;
          transform: translateY(30px);
      }

      100% {
          opacity: 1;
          transform: translateY(0px);
      }
  }
`

export const CheckboxLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const SaveText = styled.span<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100%;
  border: 1px solid ${({ disabled }) =>
                      disabled ? "var(--light-gray)" : "var(--finished)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;

export const SolutionsContainer = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 20px;
`;

export const SelectedSolutions = styled.div`
  width: 100%;
  min-height: 90px;
  border: 2px solid #989898;
  border-radius: 10px;
  transition: all 0.4s ease;
  background: var(--white);
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 14px;
  color: var(--dark-gray);
  gap: 5px;
  `;

export const Label = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--gray);
  margin-left: 20px;
  transition: all 0.6s ease;
  position: absolute;
  top: -7px;
  left: 0px;
  background: var(--white);
  padding: 0 6px;
`;

export const Tag = styled.div`
  display: flex;
  width: fit-content;
  max-width: 332px;
  height: 18px;
  align-items: center;
  gap: 2px;
  padding: 5px;
  border-radius: 25px;
  background: #eae8e8;
  background: var(--white);
  border: 1px solid var(--fixit);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: var(--fixit);
    width: 100%;
    height: fit-content;
  }

  svg {
    margin-left: 2px;
    cursor: pointer;
    color: var(--fixit);
  }
`;

export const NextStep = styled.span<ButtonProps>`
  font-family: Roboto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--fixit)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100%;
  border: 1px solid ${({ disabled }) =>
  disabled ? "var(--light-gray)" : "var(--fixit)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--fixit);
        color: var(--white);
        background: var(--fixit);
      }
    `}
`;

export const BackText = styled.span<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 14px;
  line-height: 18px;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--fixit)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 50%;
  border: 1px solid ${({ disabled }) =>
      disabled ? "var(--light-gray)" : "var(--fixit)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--fixit);
        color: var(--white);
        background: var(--fixit);
      }
    `}
`;

