import { useState } from "react";

import { RiEyeLine } from "react-icons/ri";
import { MdOutlinePersonPinCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Popover from "../../../components/Popover";
import { i18n } from "../../../localization/i18n";
import {
  ActionItem,
  ActionsContainer,
  IconButtonContainer,
  ProvisionerContainer,
} from "../components/OrderItem/styles";
import { IOrder } from "../dtos";
import { ProvisionerList } from "../components/ProvisionerList";

interface ActionsProps {
  order: IOrder;
  onProviderSelect: () => void;
}

interface ILicenseeData {
  id: string;
  manager_name: string;
  regiion: string;
  situation: string;
  is_blocked: boolean;
}

export const AdminActions = ({
  order,
  onProviderSelect,
}: ActionsProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [providerSelected, setProviderSelected] = useState(false);

  function toggleProvisionerSelected() {
    setProviderSelected(true);
  }
  function handleToggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  return (
    <>
      <ProvisionerList
        handleProviderSelected={() => {
          onProviderSelect() 
          toggleProvisionerSelected()
        }}
        trigger={isModalOpen}
        orderIdentification={order.id}
        onClose={() => {
          handleToggleModal();
        }}
        order = {order}
      />
      <ActionsContainer>
        <ActionItem>
          <Popover position="left" label={`${i18n.t("orders.view")}`}>
            <IconButtonContainer>
              <RiEyeLine
                size={18}
                onClick={() => {
                  navigate(
                    "/admin/orders/show",{ 
                      state: {
                      order_id: order.id,
                    },
                  });
                }}
              />
            </IconButtonContainer>
          </Popover>
        </ActionItem>

        {(!order.printer && order.category === 'is_fixiter') && 
        <ActionItem>
          <Popover position="left" label={"FixPoint"}>
            <ProvisionerContainer
              providerSelected={!!order?.provider?.id ?? providerSelected}
            >
              <MdOutlinePersonPinCircle
                size={18}
                onClick={() => {
                  handleToggleModal();
                }}
              />
            </ProvisionerContainer>
          </Popover>
        </ActionItem>
        }
        </ActionsContainer>
    </>
  );
};
