import styled from "styled-components";

type StepProps = {
  filled: boolean;
};

export const Container = styled.div``;

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Step = styled.div<StepProps>`
  position: relative;

  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  border: 2px solid var(--fixit);
  background: ${({ filled }) => (filled ? "var(--fixit)" : "transparent")};

  display: flex;
  align-items: center;
  justify-content: center;

  .step_number {
    font-size: 16px;
    font-weight: 600;
    color: ${({ filled }) => (filled ? "var(--white)" : "var(--fixit)")};
  }

  .step_label {
    position: absolute;
    bottom: -24px;

    font-size: 16px;
    font-weight: 500;
    color: var(--dark-gray);
    white-space: nowrap;
  }

  @media (max-width: 1000px) {
    .step_label {
      display: none;
    }
  }
`;

export const StepConnector = styled.div`
  flex: 1;
  height: 1px;
  background: var(--fixit);
`;

export const ComponentContainer = styled.div`
  margin-top: 54px;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`;
