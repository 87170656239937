import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .title {
    font-size: 22px;
    color: var(--secondary);
    font-weight: 500;
  }

  .description {
    font-size: 16px;
    color: var(--primary);
    margin-top: 20px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  button {
    width: 110px;
  }
`;
