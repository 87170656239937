import styled, { css } from "styled-components";
import global from "../../../../global";

interface InputProps {
  error?: string;
  inputWidth?: number;
  inputHeight?: number;
}

export const Container = styled.div<InputProps>`
  position: relative;

  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  height: 100%;

  .input_element {
    min-height: ${({ inputHeight }) => inputHeight ?? "40"}px;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    padding: 0px 12px;
    font-size: 18px;
    border: 2px solid var(--light-gray);
    transition: border 0.3s;

    color: var(--dark-gray);

    &:focus {
      border: 2px solid var(--fixit);
    }

    &::placeholder {
      color: var(--light-gray);
    }

    ${({ error }) =>
      error &&
      css`
        border: 1px solid var(--formError);

        &:focus {
          border: 1px solid var(--formError);
        }
      `}
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
  }
`;

export const Error = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0px;

  font-size: 12px;
  color: var(--formError);
`;
