import { useEffect, useState } from "react";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { showToast } from "../../../../../../components/CustomToast";

import {
  nullOption,
  orderTypeOptions
} from "../../mocks";

import { RiCloseFill } from "react-icons/ri";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  SaveText
} from "./styles";
import { i18n } from "../../../../../../localization/i18n";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { ICurrencyOption, IOption, IPrice, ISearchableSolution, ISolution } from "../../dtos";
import { countries } from "../../../../../../localization/data/mocks";
interface PriceInfosProps {
  closeModal: (price?: IPrice) => void;
}

interface IFormData {
  solution?: ISolution;
  country?: ICurrencyOption;
  order_type?: IOption;
  solution_type?: IOption;
  file_price?: number;
}

export function CreatePrice({ closeModal }: PriceInfosProps) {
  const [formData, setFormData] = useState<IFormData>({
    country: countries.find(t => t.value === 'BRA'),
  });
  const [solutions, setSolutions] = useState<ISearchableSolution[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [solutionSearchValue, setSolutionSearchValue] = useState("");

  async function handleSubmitPrice() {
    const body = {
      ...formData,
      order_type: formData?.order_type?.value,
      solution_type: formData?.solution_type?.value,
      currency: formData?.country?.currency,
      country: formData?.country?.value
    }

    try {
      const { data } = await api.post(`/prices`, body)
      closeModal(data)
    } catch (error) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro  ao tentar atualizar as informações do licenciado.",
      });
    }
  }

  async function searchSolutions( signal: AbortSignal ) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(`/solutions/search?term=${solutionSearchValue}`, { signal })
      setSolutions(data.map((p: any) => ({
        id: p.id,
        label: p.name ?? p.company_name,
        model: p
      })))
      
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
       }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar as soluções.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;  

    if (solutionSearchValue != "") {
      searchSolutions(signal);
    }

    return () => abortController.abort();
  }, [solutionSearchValue]);

  return (
    <Container>
      <RiCloseFill size={24} color="var(--fixit)" onClick={() => closeModal()} />

      <Header>
        <span>{`${i18n.t('financial.prices.create')}`}</span>
      </Header>

      <FormContainer>

        <InputLine>
          <InputContainer>
            <SearchableSelect
              label={`${i18n.t('financial.prices.field.solution')}`}
              options={solutions}
              defaultValue={solutionSearchValue}
              loading={selectLoading}
              onSelect={(selected) => {
                if (typeof selected === "string") {
                  setFormData((prevState) => ({
                    ...prevState,
                    solution: undefined
                  }));
                  return;
                }

                setFormData((prevState) => ({
                  ...prevState,
                  solution: solutions?.find((s) => s.model.id === selected.id)?.model
                }));
              }}
              onChange={(value) => {
                setSolutionSearchValue(value);
              }}
            />
          </InputContainer>
        
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.order_type')}`}
              options={orderTypeOptions}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  order_type: selected,
                }));
              }}
              selectedOption={formData?.order_type?.optionText || ""}
            />
          </InputContainer>

          {/* <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.solution_type')}`}
              options={[nullOption, ...solutionTypeOptions]}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  solution_type: selected,
                }));
              }}
              selectedOption={formData?.solution_type?.optionText  || ""}
            />
          </InputContainer> */}
        </InputLine>


        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t('financial.prices.field.country')}`}
              options={[nullOption, ...countries]}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  country: countries.find(t => t.value === selected.value),
                }));
              }}
              selectedOption={formData?.country?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              label={`${i18n.t('financial.prices.field.file_price')}`}
              inputName="file_price"
              type="number"
              handleChange={(event) =>
                {
                  setFormData((prevState) => ({
                    ...prevState,
                    file_price: event,
                  }))
                }
              }
              value={formData?.file_price || ""}
            />
          </InputContainer>
        </InputLine>
      </FormContainer>

      <Footer>

        <SaveText
          onClick={handleSubmitPrice}
        >
          {`${i18n.t('financial.prices.save')}`}
        </SaveText>
      </Footer>
    </Container>
  );
}
