import { useState, ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

interface HoverActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  textColor?: string;
  hoverText: string;
  hoverTextColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  buttonBackground?: string;
  buttonHoverBackground?: string;
  activeBorderColor?: string;
  buttonActiveBackground?: string;
  activeTextColor?: string;
}

export function HoverActionButton({
  text,
  hoverText,
  textColor = "var(--fixit)",
  borderColor = "var(--fixit)",
  buttonBackground = "var(--white)",
  hoverTextColor = "var(--white)",
  hoverBorderColor = "var(--fixit)",
  buttonHoverBackground = "var(--fixit)",
  activeTextColor = "var(--white)",
  activeBorderColor = "var(--fixit)",
  buttonActiveBackground = "var(--fixit)",
  ...rest
}: HoverActionButtonProps) {
  const [mouseOverButton, setMouseOverButton] = useState(false);

  return (
    <Container
      textColor={textColor}
      hoverTextColor={hoverTextColor}
      activeTextColor={activeTextColor}
      borderColor={borderColor}
      hoverBorderColor={hoverBorderColor}
      activeBorderColor={activeBorderColor}
      buttonBackground={buttonBackground}
      buttonHoverBackground={buttonHoverBackground}
      buttonActiveBackground={buttonActiveBackground}
      onMouseEnter={() => setMouseOverButton(true)}
      onMouseLeave={() => setMouseOverButton(false)}
      {...rest}
    >
      <span>{mouseOverButton ? hoverText : text}</span>
    </Container>
  );
}
