import { useRoutes } from "react-router-dom";
import BracingTypes from "../../modules/Fixiter/pages/BracingTypes";
import { CreateOrder } from "../../modules/Orders/CreateOrder";
import Dashboard from "../../modules/Fixiter/pages/Dashboard";
import { Onboard } from "../../modules/Fixiter/pages/Onboard";
import Layout from "../../components/Layout";
import { Orders } from "../../modules/Orders";
import { SolutionsCategory } from "../../modules/Fixiter/pages/SolutionsCategory";
import { UserRegistration } from "../../modules/noAuth/Pages/UserRegistration";
import Solutions from "../../modules/Orders/Solutions";
import Evaluation from "../../modules/licensees/pages/Evaluation";
import Courses from "../../modules/Fixiter/pages/Courses";
import { useOnboard } from "../../hooks/OnboardContext";
import LayoutIframe from "../../components/LayoutIframe";
import Logon from "../../modules/Logon";
import Payment from "../../modules/Fixiter/pages/Payment";
import UsersProfile from "../../components/UsersProfile";
import AuthGuard from "../AuthGuard";

function FixiterRoutes() {
  const { planHasExpired } = useOnboard();

  const expiredRoutes = useRoutes([
    { path: '/', element: (<Logon />) },
    { path: '/payment',
      element: (
        <LayoutIframe>
          <Payment />
        </LayoutIframe>
    )},
    { path: '/*',
      element: (
        <LayoutIframe>
          <Onboard />
        </LayoutIframe>
    )},
  ]);

  const fixiterRoutes = useRoutes([
    { path: '/fixiter/onboard',
      element: (
        <Layout>
          <Onboard />
        </Layout>
    )},
    { path: '/fixiter/registration',
      element: (
        <UserRegistration />
    )},
    { path: '/fixiter/dashboard',
      element: (
        <Layout>
          <Dashboard />
        </Layout>
    )},
    { path: '/fixiter/dashboard',
      element: (
        <Layout>
          <Dashboard />
        </Layout>
    )},
    { path: '/fixiter/solutions',
      element: (
        <Layout>
          <SolutionsCategory />
        </Layout>
    )},
    { path: '/fixiter/categories/:field_key',
      element: (
        <Layout>
          <BracingTypes />
        </Layout>
    )},
    { path: '/fixiter/solutions/:category_key',
      element: (
        <Layout>
          <Solutions />
        </Layout>
    )},
    { path: '/fixiter/solutions/:category_key',
      element: (
        <Layout>
          <Solutions />
        </Layout>
    )},
    { path: '/fixiter/solutions/:category_key',
      element: (
        <AuthGuard
          redirectTo={'/fixiter/dashboard'}
        >
          <Layout>
            <Solutions />
          </Layout>
        </AuthGuard>
    )},
    { path: '/fixiter/order/:solution_key/:solution_type',
      element: (
        <AuthGuard
          redirectTo={'/fixiter/dashboard'}
        >
          <Layout>
            <CreateOrder />
          </Layout>
        </AuthGuard>
    )},
    { path: '/fixiter/order/:solution_key/:solution_type/:size',
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
    )},
    { path: '/fixiter/orders',
      element: (
        <Layout>
          <Orders />
        </Layout>
    )},
    { path: '/fixiter/orders/:closure',
      element: (
        <Layout>
          <Orders />
        </Layout>
    )},
    { path: '/fixiter/evaluation/:order_id',
      element: (
        <Layout>
          <Evaluation />
        </Layout>
    )},
    { path: '/fixiter/courses',
      element: (
        <Layout>
          <Courses />
        </Layout>
    )},
    { path: '/fixiter/profile/:options',
      element: (
        <Layout>
          <UsersProfile />
        </Layout>
    )},
  ]);

  if (planHasExpired) {
    return expiredRoutes
  } 

  return fixiterRoutes;
}

export default FixiterRoutes;
