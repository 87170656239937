import { RiCloseFill } from "react-icons/ri";
import { Container, Content, OptionsButton } from "./styles";


interface IConfirmations{
    onRequestClose: () => void;
    onRequestDelete: () => void;
}

export function DeleteConfirmation({onRequestClose, onRequestDelete} : IConfirmations){

    return(
        <Container>
            <span>Você realmente deseja excluir este plano?</span>
            <Content>
                <OptionsButton delete={true} onClick={onRequestDelete}>Sim</OptionsButton>
                <OptionsButton delete={false} onClick={onRequestClose}>Não</OptionsButton>
            </Content>
        </Container>
)
}