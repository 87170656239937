import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";

import { useAuth } from "../../../../../../hooks/AuthContext";

import { ActionsPopup } from "../../../../../Orders/components/ActionsPopup";
import { HoverActionButton } from "../../../../../../components/HoverActionButton";
import { showToast } from "../../../../../../components/CustomToast";
import { PageTipWarning } from "../PageTipWarning";
import { Modal } from "../../../../../../components/Modal";
import { GlobalTipWarning } from "../GlobalTipWarning";

import api from "../../../../../../services/api";

import { ITips } from "../../dtos";

import { RiMore2Fill, RiPencilLine, RiDeleteBin5Line } from "react-icons/ri";

import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  PrincipalInfos,
  RightContent,
  Header,
  StatusDesktopContent,
  MobileContent,
  IconButtonContainer,
  MobileMoreActions,
} from "./styles";
import { DeleteTipWarning } from "../DeleteTipWarning";

interface TipsItemProps {
  tip: ITips;
  tips: ITips[];
  onClick: () => void;
  getTips: (skeleton?: boolean) => Promise<void>;
}

export function TipsItem({ tip, getTips, onClick, tips }: TipsItemProps) {
  const { user } = useAuth();

  const [moreActions, setMoreAction] = useState(false);
  const [loadingTipState, setLoadingTipState] = useState(false);
  const [globalTipWarning, setGlobalTipWarning] = useState(false);
  const [pageTipWarning, setPageTipWarning] = useState(false);
  const [deleteTipWarning, setDeleteTipWarning] = useState(false);

  const actions = [
    {
      text: i18n.t("tips.edit"),
      icon: <RiPencilLine />,
      active: false,
      action: () => onClick(),
    },
    {
      text: i18n.t("tips.delete"),
      icon: <RiDeleteBin5Line />,
      active: false,
      action: () => handleDeleteTip(),
    },
  ];

  async function handleDeleteTip(confirmation?: boolean) {
    setLoadingTipState(true);

    try {
      if (tip.is_active && !confirmation) {
        setDeleteTipWarning(true);
        return;
      }

      await api.delete(`/tips/${tip.id}`);

      setDeleteTipWarning(false);
      await getTips(false);
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("tips.deleteError"),
      });
    } finally {
      setLoadingTipState(false);
    }
  }

  async function handleChangeTips() {
    setLoadingTipState(true);

    try {
      await api.patch(`/tips/status/${tip.id}`);
      setGlobalTipWarning(false);
      setPageTipWarning(false);
      await getTips(false);
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("tips.changeStateError"),
      });
    } finally {
      setLoadingTipState(false);
    }
  }

  async function handleChangeTipState() {
    try {
      const tipGlobalAlreadyActivated = tips.find(
        (item) => item.type === "global"
      );
      const pageTipAlreadyActivated = tips.find(
        (item) => item.route === tip.route
      );

      if (
        !tip.is_active &&
        tip.type === "global" &&
        tipGlobalAlreadyActivated &&
        tipGlobalAlreadyActivated.id !== tip.id
      ) {
        setGlobalTipWarning(true);
        return;
      }

      if (
        !tip.is_active &&
        pageTipAlreadyActivated &&
        pageTipAlreadyActivated.id !== tip.id
      ) {
        setPageTipWarning(true);
        return;
      }

      await api.patch(`/tips/status/${tip.id}`);
      await getTips(false);
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("tips.changeStateError"),
      });
    }
  }

  return (
    <Container>
      <Modal open={globalTipWarning} modalWidth={480}>
        <GlobalTipWarning
          closeModal={() => setGlobalTipWarning(false)}
          changeGlobalTips={handleChangeTips}
          loading={loadingTipState}
        />
      </Modal>

      <Modal open={pageTipWarning} modalWidth={480}>
        <PageTipWarning
          closeModal={() => setPageTipWarning(false)}
          changeTip={handleChangeTips}
          loading={loadingTipState}
        />
      </Modal>

      <Modal open={deleteTipWarning} modalWidth={480}>
        <DeleteTipWarning
          closeModal={() => setDeleteTipWarning(false)}
          deleteTip={handleDeleteTip}
          loading={loadingTipState}
        />
      </Modal>

      <Header>
        <LeftContent>
          <ImageContainer>
            <span>{tip.name?.charAt(0)}</span>
          </ImageContainer>

          <PrincipalInfos className={`${tip.name}`}>
            <span className="solution">{tip.name}</span>
            <span className="date">{tip.type}</span>
          </PrincipalInfos>

          <ContentInfos className="licensee desktop">
            <span className="data_label">Role</span>
            <span className="data_value">{tip.role}</span>
          </ContentInfos>

          <ContentInfos className="patient desktop">
            <span className="data_label">Rota</span>
            <span className="data_value">{tip.route}</span>
          </ContentInfos>
        </LeftContent>

        <RightContent>
          <StatusDesktopContent>
            <HoverActionButton
              text={tip.is_active ? "Ativa" : "Inativa"}
              hoverText={tip.is_active ? "Desativar" : "Ativar"}
              textColor={
                tip.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
              hoverTextColor="var(--white)"
              borderColor={
                tip.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
              hoverBorderColor={
                tip.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
              buttonBackground="var(--white)"
              buttonHoverBackground={
                tip.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
              onClick={() => {
                handleChangeTipState();
              }}
            />

            <IconButtonContainer>
              <RiMore2Fill onClick={() => setMoreAction(true)} />

              {moreActions && (
                <ActionsPopup
                  options={actions}
                  onClickAway={() => setMoreAction(false)}
                />
              )}
            </IconButtonContainer>
          </StatusDesktopContent>
        </RightContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_label">{`${i18n.t("orders.patient")}`}</span>
            <span className="data_value">
              {`${i18n.t("orders.patientNotSet")}`}
            </span>
          </ContentInfos>

          <ContentInfos className="partner">
            <span className="data_label">{`${i18n.t("orders.partner")}`}</span>
          </ContentInfos>
        </LeftContent>

        <MobileMoreActions>
          <IconButtonContainer>
            <RiMore2Fill onClick={() => setMoreAction(true)} />

            {moreActions && (
              <ActionsPopup
                options={actions}
                onClickAway={() => setMoreAction(false)}
              />
            )}
          </IconButtonContainer>
        </MobileMoreActions>
      </MobileContent>
    </Container>
  );
}
