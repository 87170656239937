import { useEffect, useState } from "react";

import { HomeCard } from "../../../../components/HomeCard";

import { Container, Actions, CardsContainer } from "./styles";
import api from "../../../../services/api";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import { useOnboard } from "../../../../hooks/OnboardContext";
import { roles } from "../../../../utils/constants";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

interface ISolutionField {
  name: I18NTextLanguageObject;
  name_key: string;
  image_link: string;
  description: I18NTextLanguageObject;
  autoopen: boolean;
  solutions: any[];
}

export function SolutionsCategory() {

  const { user } = useAuth(); 
  const { licensee } = useOnboard();
  const [fields, setFields] = useState<Array<ISolutionField>>([]);
  const { setNavigationAction } = useAuth();

  useEffect(() => {
    api.get(`/categories?is_active=true`).then(response => {
      setFields(response.data);
    }).catch((error) => {
      console.log(error)
    });
    
    setNavigationAction({
      hasButton: false,
      description: i18n.t("solutions.header")
    });
  }, []);

  return (
    <>
      <Container>
        {/* <HomeHeader /> */}
        <Actions>
          <CardsContainer>
            {fields.map((card) => {
              const language = i18n.language;
              var link : string
              
              if (card.solutions.length && card.autoopen) {
                const solution = card.solutions[0]
                link = `${user.role === roles.employee
                  ? '/employee' :
                  ''}/order/${solution.name_key}/${solution.form_type || "custom"}`
              } else if (card.solutions.length && !card.autoopen){
                link = `${user.role === roles.employee
                  ? '/employee' :
                  ''}/solutions/${card.name_key}`
              } else {
                link = `${user.role === roles.employee
                  ? '/employee' :
                  ''}/categories/${card.name_key}`
              }

              return (
                <HomeCard
                  key={card.name_key}
                  title={card.name[language as keyof I18NTextLanguageObject]}
                  icon={card.image_link}
                  description={card.description[language as keyof I18NTextLanguageObject]}
                  pageRoute={link}
                  disabled={!licensee || ((!licensee?.signature || user.role !== roles.licensee) && ((card.name_key !== "talas") && (card.name_key !== "brindeseacessrios")))}
                />
              );
            })}
          </CardsContainer>
        </Actions>
      </Container>
    </>
  );
}