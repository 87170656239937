import { RiDownloadCloud2Line, RiLineChartLine, RiMenuLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { RouteButton } from '../styles';
import { TbFileSettings } from 'react-icons/tb'

interface MenuListProps {
  extendedMode: boolean;
}

export const CreatorMenuList = ({extendedMode} : MenuListProps) => (
    <>
      <Link to="/creators/dashboard"> {/* Go to Dashboard */}
        <RouteButton extendedMode={extendedMode}> 
          <RiLineChartLine size={28} />

          {extendedMode && (
            <span>Relatórios</span>
          )}
        </RouteButton>
      </Link>

      <Link to="/creators/solutions"> {/* Go to Solutions */}
        <RouteButton extendedMode={extendedMode}> 
          <RiDownloadCloud2Line size={28} />

          {extendedMode && (
            <span>Soluções</span>
          )}
        </RouteButton>
      </Link>  

      <Link to="/creators/orders"> {/* Go to Orders */}
        <RouteButton extendedMode={extendedMode}>
          <RiMenuLine size={26} />

          {extendedMode && (
            <span>Pedidos</span>
          )}
        </RouteButton> 
      </Link>

      <Link to="/creators/standard-solutions"> {/* Go to Orders */}
        <RouteButton extendedMode={extendedMode}>
          <TbFileSettings size={26} />

          {extendedMode && (
            <span>Padronizadas</span>
          )}
        </RouteButton> 
      </Link>
    </>
)