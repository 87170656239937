import { useEffect, useState } from "react";

import { RiFilter3Fill } from "react-icons/ri";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import {
  Container,
  FilterContainer,
  SectionHeader,
  SectionName,
  Footer,
  ApplyButton,
  InputLine,
  InputContainer,
  PeriodInputsContainer,
  PeriodInput,
  DatePickerContainer,
  InputSeparator,
  CheckboxContainer,
} from "./styles";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { i18n } from "../../../../../../localization/i18n";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { ILicensee, ISearchableLicensee } from "../../../../../Orders/dtos";
import { IOption } from "../../../Plans/dtos";
import { IFilter } from "../../dtos";
import { Checkbox } from "../../../../../../components/Checkbox";
import {
  nullOption,
  paymentMethod,
  paymentSituationOptions,
  paymentType,
} from "../../mocks";

interface FilterProps {
  onChange: (filterData: IFilter) => void;
  filter?: IFilter;
}

export function Filter({ onChange, filter }: FilterProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedSituation, setSelectedSituation] = useState<IOption>();
  const [selectedType, setSelectedType] = useState<IOption>();
  const [selectedLicensee, setSelectedLicensee] = useState<ILicensee>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IOption>();
  const [recurrency, setRecurrency] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [licensees, setLicensees] = useState<ISearchableLicensee[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [licenseeSearchValue, setLicenseeSearchValue] = useState("");

  function prevDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const prev = selectedMonth.setMonth(selectedMonth.getMonth() - 1);
    setSelectedMonth(new Date(prev));
  }

  function nextDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const next = selectedMonth.setMonth(selectedMonth.getMonth() + 1);
    setSelectedMonth(new Date(next));
  }

  function applyFilterData() {
    const filterData: IFilter = {
      referring_period: formatDate(selectedMonth.toString()),
      situation: selectedSituation,
      licensee: selectedLicensee,
      type: selectedType,
      paymentMethod: selectedPaymentMethod,
      recurrency: recurrency,
    };

    onChange(filterData);
    setFilterOpen(false);
  }

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "MM/yyyy");
  }

  useEffect(() => {
    setSelectedSituation(filter?.situation);
    setSelectedType(filter?.type);
    setRecurrency(filter?.recurrency ?? false);
    setLicenseeSearchValue(filter?.licensee?.name ?? "");
    setSelectedLicensee(filter?.licensee);
    setSelectedPaymentMethod(filter?.paymentMethod);
  }, [filter]);

  async function searchLicensees(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      const { data } = await api.get(
        `/licensees/search?term=${licenseeSearchValue}`,
        { signal }
      );
      setLicensees(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (licenseeSearchValue != "") {
      searchLicensees(signal);
    }

    return () => abortController.abort();
  }, [licenseeSearchValue]);

  return (
    <Container>
      <RiFilter3Fill
        size={34}
        color={filterOpen ? "var(--fixit)" : "var(--gray)"}
        onClick={() => setFilterOpen((prevState) => !prevState)}
      />

      <FilterContainer open={filterOpen}>
        <InputLine>
          <InputContainer>
            <PeriodInputsContainer className={"month"}>
              <SectionHeader onClick={() => {}}>
                <SectionName>{"Mês"}</SectionName>
              </SectionHeader>
              <InputSeparator></InputSeparator>
              <PeriodInput>
                <DatePickerContainer>
                  <FiChevronLeft onClick={(e) => prevDate(e)} size={18} />
                  <DatePicker
                    selected={selectedMonth}
                    onChange={(date: Date) => setSelectedMonth(date)}
                    dateFormat="MM/yyyy"
                    locale={ptBR}
                    showMonthYearPicker
                    showTwoColumnMonthYearPicker
                  />
                  <FiChevronRight onClick={(e) => nextDate(e)} size={18} />
                </DatePickerContainer>
              </PeriodInput>
            </PeriodInputsContainer>
          </InputContainer>
        </InputLine>
        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={"Situação"}
              options={[nullOption, ...paymentSituationOptions]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedSituation(undefined);
                  return;
                }
                setSelectedSituation(selected);
              }}
              selectedOption={selectedSituation?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedSelect
              label={"Tipo"}
              options={[nullOption, ...paymentType]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedType(undefined);
                  return;
                }
                setSelectedType(selected);
              }}
              selectedOption={selectedType?.optionText || ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          {/* <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.solution_type")}`}
              options={[nullOption, ...solutionTypeOptions]}
              handleSelect={(selected) => {}}
              selectedOption={""}
            />
          </InputContainer> */}
          <InputContainer>
            <SearchableSelect
              label={`${i18n.t("profile.unit")}`}
              options={licensees}
              defaultValue={licenseeSearchValue}
              loading={selectLoading}
              onSelect={(selected) => {
                if (typeof selected === "string") {
                  setSelectedLicensee(undefined);
                  return;
                }

                setSelectedLicensee(
                  licensees?.find((s) => s.model.id === selected.id)?.model
                );
              }}
              onChange={(value) => {
                setLicenseeSearchValue(value);
              }}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={"Método"}
              options={[nullOption, ...paymentMethod]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedPaymentMethod(undefined);
                  return;
                }
                setSelectedPaymentMethod(selected);
              }}
              selectedOption={selectedPaymentMethod?.optionText || ""}
            />
          </InputContainer>
          <CheckboxContainer checked={recurrency}>
            <Checkbox
              checked={recurrency}
              handleChange={() => setRecurrency(!recurrency)}
            />
            <span>Recorrente</span>
          </CheckboxContainer>
        </InputLine>
        <Footer>
          <ApplyButton type="button" onClick={applyFilterData}>
            Aplicar
          </ApplyButton>
        </Footer>
      </FilterContainer>
    </Container>
  );
}
