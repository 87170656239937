import { ReactNode, useEffect, useState } from "react";

import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { MdOutlineMoneyOffCsred, MdOutlineAttachMoney } from "react-icons/md";
import Popover from "../../../../../../components/Popover/";

import { IPlan } from "../../dtos";

import ImagemCoringa from "../../../../../../assets/imagem_coringa.png";

import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  RightContent,
  ActionsContainer,
  Header,
  CenterContent,
  MobileContent,
  PlanInfos,
  StatusDesktopContent,
} from "./styles";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { i18n } from "../../../../../../localization/i18n";
import { showToast } from "../../../../../../components/CustomToast";
import { HoverActionButton } from "../../../../../../components/HoverActionButton";

interface PlanItemProps {
  onEditIconClick?: () => void;
  onDeleteIconClick?: () => void;
  onUpdate?: (plan: IPlan) => void;
  plan: IPlan;
  handleRender: () => void;
}

export function PlanItem({
  plan,
  onEditIconClick = () => {},
  onDeleteIconClick = () => {},
  onUpdate = () => {},
  handleRender = () => {},
}: // handleIsActive,
PlanItemProps) {
  const toogleStatus = async () => {
    if (
      window.confirm(
        i18n.t(
          `financial.plans.confirm.${
            plan.is_active ? "deactivate" : "activate"
          }`
        )
      )
    ) {
      const abortController = new AbortController();
      let signal = abortController.signal;

      await api
        .put(
          `/plans/${plan.id}/is_active`,
          { is_active: !plan.is_active },
          { signal }
        )
        .then(({ data }) => {
          onUpdate(data);
          showToast({
            type: "sucsess",
            message: "Status do plano atualizado com sucesso",
          });
          handleRender();
        })
        .catch((err) => {
          if (isAxiosCancel(err)) {
            return "axios request cancelled";
          }
          showToast({
            type: "error",
            message: "Não foi possível alterar o status do plano",
          });
        });
      return () => abortController.abort();
    }
  };

  async function handleChangePlanFinancialStatus() {
    const body = {
      id: plan.id,
      is_free: !plan.is_free,
    };
    if (
      window.confirm(
        i18n.t(`financial.plans.financial.${plan.is_free ? "paid" : "free"}`)
      )
    ) {
      const abortController = new AbortController();
      let signal = abortController.signal;
      try {
        await api
          .put(`/plans/changeStatus`, body, { signal })
          .then(({ data }) => {
            onUpdate(data);
            showToast({
              type: "sucsess",
              message: "Status financeiro do plano atualizado com sucesso!",
            });
          });
        handleRender();
      } catch (error) {
        console.log("Não foi possível modificar o status do plano!");
      }
    }
  }

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer>
            <img
              src={
                plan?.link_image
                  ? `${process.env.REACT_APP_STORAGE_BASE_URL}${plan?.link_image}`
                  : ImagemCoringa
              }
              alt="gif"
            />
          </ImageContainer>
          <PlanInfos>
            <span className="plan_name">{plan.plan_name}</span>
            <span className="plan_price">
              {`${plan.currency} ${plan.price}`}
            </span>
          </PlanInfos>
        </LeftContent>

        <CenterContent>
          <ContentInfos className="order_type">
            <span className="data_label">Numero de órteses</span>
            <span className="data_value">
              {plan.solutions_quantity ? plan.solutions_quantity : "Ilimitado"}
            </span>
          </ContentInfos>
          <ContentInfos className="orders_per_month">
            <span className="data_label">Pedidos por mês</span>
            <span className="data_value">
              {plan.orders_quantity ? plan.orders_quantity : "Ilimitado"}
            </span>
          </ContentInfos>
          <ContentInfos className="trial_period">
            <span className="data_label">Periodo</span>
            <span className="data_value">{plan.trial_period}</span>
          </ContentInfos>
        </CenterContent>

        <RightContent>
          <StatusDesktopContent>
            <HoverActionButton
              onClick={toogleStatus}
              text={
                !plan.is_active
                  ? i18n.t("financial.prices.field.inactive")
                  : i18n.t("financial.prices.field.active")
              }
              hoverText={
                !plan.is_active
                  ? i18n.t("financial.prices.field.inactive")
                  : i18n.t("financial.prices.field.active")
              }
              textColor={
                !plan.is_active ? "var(--active-orange)" : "var(--active-green)"
              }
              hoverTextColor="var(--white)"
              borderColor={
                !plan.is_active ? "var(--active-orange)" : "var(--active-green)"
              }
              hoverBorderColor={
                !plan.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
              buttonBackground="var(--white)"
              buttonHoverBackground={
                !plan.is_active ? "var(--active-green)" : "var(--active-orange)"
              }
            />
          </StatusDesktopContent>
          <ActionsContainer>
            {plan.is_free ? (
              <Popover position="right" label="Marcar como Pago">
                <MdOutlineMoneyOffCsred
                  size={20}
                  onClick={(event) => {
                    handleChangePlanFinancialStatus();
                  }}
                />
              </Popover>
            ) : (
              <Popover position="right" label="Marcar como Free">
                <MdOutlineAttachMoney
                  size={20}
                  onClick={(event) => {
                    handleChangePlanFinancialStatus();
                  }}
                />
              </Popover>
            )}
            <Popover position="left" label="Editar">
              <FiEdit2
                size={18}
                onClick={(event) => {
                  event.stopPropagation();
                  onEditIconClick();
                }}
              />
            </Popover>
            <Popover position="left" label="Excluir">
              <FiTrash2
                size={18}
                onClick={() => {
                  onDeleteIconClick();
                }}
              />
            </Popover>
          </ActionsContainer>
        </RightContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_value">
              {plan.solutions_quantity ? plan.solutions_quantity : "Ilimitado"}
            </span>
          </ContentInfos>
          <ContentInfos>
            <span className="data_value">
              {plan.orders_quantity ? plan.orders_quantity : "Ilimitado"}
            </span>
          </ContentInfos>
          <ContentInfos>
            <span className="data_value">{plan.trial_period}</span>
          </ContentInfos>
        </LeftContent>
        <RightContent>
          <HoverActionButton
            onClick={toogleStatus}
            text={!plan.is_active ? "Disabled" : "Enabled"}
            hoverText={!plan.is_active ? "Enable" : "Disable"}
            textColor={
              !plan.is_active ? "var(--active-orange)" : "var(--active-green)"
            }
            hoverTextColor="var(--white)"
            borderColor={
              !plan.is_active ? "var(--active-orange)" : "var(--active-green)"
            }
            hoverBorderColor={
              !plan.is_active ? "var(--active-green)" : "var(--active-orange)"
            }
            buttonBackground="var(--white)"
            buttonHoverBackground={
              !plan.is_active ? "var(--active-green)" : "var(--active-orange)"
            }
          />
        </RightContent>
      </MobileContent>
    </Container>
  );
}
