import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: nowrap;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  .CheckBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.div`
  margin: 1rem 3rem 1rem 0;
  font-size: 20px;
  color: var(--fixit);
  font-weight: 500;
`;
