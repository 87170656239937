import { useEffect, useState } from "react";

import {
  addressTypes,
  customerSituations,
  planTypes,
} from "../../../utils/constants";
import { IAddress, IPlans, ISignature, Licensee } from "../dtos";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import {
  MdPublishedWithChanges,
  MdOutlineWorkOutline,
  MdOutlineHomeWork,
  MdWorkOutline,
  MdOutlineDeliveryDining,
  MdOutlineNotInterested,
} from "react-icons/md";
import { VscGear } from "react-icons/vsc";
import {
  FaExchangeAlt,
  FaRegAddressCard,
  FaRegMoneyBillAlt,
} from "react-icons/fa";
import { FiEye, FiUserCheck } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { BsBook, BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { BiUserPin, BiMoney } from "react-icons/bi";
import { GoEyeClosed } from "react-icons/go";
import { AiOutlineHome, AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";

import {
  AddressActions,
  AdvancedOptionsAction,
  MobileActions,
  MobileActiveOption,
  MobileOption,
  SignatureActions,
  StatusActions,
} from "../pages/LicenseesList/components/LicenseeItem/styles";
import { Modal } from "../../../components/Modal";
import { TextField } from "@mui/material";
import { DateContainer } from "../styles";

export interface AdminProps {
  licensee: Licensee;
  addresses: IAddress[];
  licenseeSignature?: ISignature;
  activePlans: IPlans[];
  signatureOptions: boolean;
  addressOptions: boolean;
  statusOptions: boolean;
  advancedOptions: boolean;
  handleBlockLicensee: () => void;
  handleChurnLicensee: (churn_date: Date) => void;
  handleRestoreLicensee: () => void;
  handleActivateSignature: (id: string) => void;
  handleChangeLicenseeSituation: (id: string, situation: string) => void;
  handleGetLicenseeSignature: (id: string) => void;
  handleChangeLicenseePlan: (
    licensee_id: string,
    plan_id: string | number
  ) => void;
  handleRenderAddressOptions: (id: string) => void;
  handleRenderAddress: (id: string) => void;
  getPlans: (currency: string, is_active: boolean) => void;
  toggleStatusOptions: () => void;
  toggleAdvancedOptions: () => void;
  togglePlansOptions: () => void;
  toggleAddressOptions: () => void;
  onDetailsIconClick?: () => void;
  handleRender: () => void;
}

export const AdminActions = ({
  licensee,
  addresses,
  licenseeSignature,
  activePlans,
  signatureOptions,
  statusOptions,
  advancedOptions,
  addressOptions,
  handleBlockLicensee = () => {},
  handleChurnLicensee = () => {},
  handleRestoreLicensee = () => {},
  handleRenderAddressOptions = () => {},
  handleActivateSignature = () => {},
  handleChangeLicenseeSituation = () => {},
  handleGetLicenseeSignature = () => {},
  handleChangeLicenseePlan = () => {},
  handleRenderAddress = () => {},
  toggleAdvancedOptions = () => {},
  toggleStatusOptions = () => {},
  togglePlansOptions = () => {},
  toggleAddressOptions = () => {},
  getPlans = () => {},
  onDetailsIconClick = () => {},
}: AdminProps) => {
  const [churnDate, setChurnDate] = useState<Date>(new Date());
  const [openSelectChurnDate, setOpenSelectChurnDate] = useState(false);

  const licenseeStatus = [
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.onboarding
            );
          }}
        >
          <BiUserPin />
          <span>Onboarding</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BiUserPin />
          <span>Onboarding</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "onboarding",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.effected
            );
          }}
        >
          <BiMoney />
          <span>Venda Efetivada</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BiMoney />
          <span>Venda Efetivada</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "effected",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.training
            );
          }}
        >
          <BsBook />
          <span>Em Treinamento</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BsBook />
          <span>Em Treinamento</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "training",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.implementation
            );
          }}
        >
          <MdOutlineHomeWork />
          <span>Em Implantação</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <MdOutlineHomeWork />
          <span>Em Implantação</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "implementation",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.operational
            );
          }}
        >
          <MdOutlineWorkOutline />
          <span>Em Operação</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <MdOutlineWorkOutline />
          <span>Em Operação</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "operational",
    },
  ];

  useEffect(() => {
    if (Boolean(licensee?.signature?.is_activated)) {
      handleGetLicenseeSignature(licensee.id);
    }
  }, [signatureOptions]);

  useEffect(() => {
    if (licensee.country === "BRA") {
      getPlans("BRL", false);
    } else {
      getPlans("USD", false);
    }
  }, []);

  function renderSignatureOptions() {
    return (
      <SignatureActions>
        {activePlans.map((plan) => {
          if (plan.plan_type === planTypes.demo) {
            return (
              <MobileActiveOption
                is_active={plan.is_active}
                onClick={(event) => {
                  handleChangeLicenseePlan(licensee.id, plan.id);
                }}
              >
                <BsStar />
                <span>Plano {plan.plan_name}</span>
                {licenseeSignature?.plan_name === plan.plan_name ? (
                  <RiCheckboxCircleLine className="checked" />
                ) : null}
              </MobileActiveOption>
            );
          }
          if (plan.plan_type === planTypes.basic) {
            return (
              <MobileActiveOption
                is_active={plan.is_active}
                onClick={(event) => {
                  handleChangeLicenseePlan(licensee.id, plan.id);
                }}
              >
                <BsStarHalf />
                <span>Plano {plan.plan_name}</span>
                {licenseeSignature?.plan_name === plan.plan_name ? (
                  <RiCheckboxCircleLine className="checked" />
                ) : null}
              </MobileActiveOption>
            );
          }
          if (plan.plan_type === planTypes.advanced) {
            return (
              <MobileActiveOption
                is_active={plan.is_active}
                onClick={(event) => {
                  handleChangeLicenseePlan(licensee.id, plan.id);
                }}
              >
                <BsStarFill />
                <span>Plano {plan.plan_name}</span>
                {licenseeSignature?.plan_name === plan.plan_name ? (
                  <RiCheckboxCircleLine className="checked" />
                ) : null}
              </MobileActiveOption>
            );
          } else {
            return (
              <MobileActiveOption
                is_active={plan.is_active}
                onClick={(event) => {
                  handleChangeLicenseePlan(licensee.id, plan.id);
                }}
              >
                <GoEyeClosed className="not_visible" />
                <span>Plano {plan.plan_name}</span>
                {licenseeSignature?.plan_name === plan.plan_name ? (
                  <RiCheckboxCircleLine className="checked" />
                ) : null}
              </MobileActiveOption>
            );
          }
        })}
      </SignatureActions>
    );
  }

  function renderStatusOptions() {
    return (
      <StatusActions>
        {licenseeStatus.map((l) => {
          if (licensee.situation === l.value) {
            return l.checkedComponent;
          }
          return l.defaultComponent;
        })}
      </StatusActions>
    );
  }

  function renderAddressOptions() {
    return (
      <AddressActions>
        {addresses &&
          addresses.map((add, index) => {
            if (add.type === addressTypes.other) {
              return (
                <MobileActiveOption
                  onClick={(event) => {
                    handleRenderAddress(add.id);
                  }}
                >
                  <FaRegAddressCard size={16} />
                  <span>Outro {`${index}`} </span>
                  {add.deliver_address === true ? (
                    <MdOutlineDeliveryDining className="checked" />
                  ) : null}
                  {add.billing_address === true ? (
                    <FaRegMoneyBillAlt className="checked" />
                  ) : null}
                </MobileActiveOption>
              );
            }
            if (add.type === addressTypes.home) {
              return (
                <MobileActiveOption
                  onClick={(event) => {
                    handleRenderAddress(add.id);
                  }}
                >
                  <AiOutlineHome size={16} />
                  <span>Casa</span>
                  {add.deliver_address === true ? (
                    <MdOutlineDeliveryDining className="checked" />
                  ) : null}
                  {add.billing_address === true ? (
                    <FaRegMoneyBillAlt className="checked" />
                  ) : null}
                </MobileActiveOption>
              );
            }

            if (add.type === addressTypes.work) {
              return (
                <MobileActiveOption
                  onClick={(event) => {
                    handleRenderAddress(add.id);
                  }}
                >
                  <MdWorkOutline size={16} />
                  <span>Trabalho</span>
                  {add.deliver_address === true ? (
                    <MdOutlineDeliveryDining className="checked" />
                  ) : null}
                  {add.billing_address === true ? (
                    <FaRegMoneyBillAlt className="checked" />
                  ) : null}
                </MobileActiveOption>
              );
            }
          })}
      </AddressActions>
    );
  }

  function renderAdvancedOptions() {
    return (
      <AdvancedOptionsAction>
        <MobileActiveOption
          onClick={() => {
            licensee.churned_at
              ? handleRestoreLicensee()
              : setOpenSelectChurnDate(true);
          }}
        >
          <MdOutlineNotInterested color={"red"} />
          <span>
            {licensee.churned_at ? "Desfazer distrato" : "Realizar distrato"}
          </span>
        </MobileActiveOption>

        <MobileActiveOption
          is_active={true}
          onClick={() => {
            handleBlockLicensee();
          }}
        >
          {licensee?.is_blocked ? (
            <AiOutlineLock color="red" />
          ) : (
            <AiOutlineUnlock color="red" />
          )}
          <span>
            {licensee.is_blocked
              ? "Desbloquear licenciado"
              : "Bloquear licenciado"}
          </span>
        </MobileActiveOption>
      </AdvancedOptionsAction>
    );
  }

  function toggleDateModal() {
    setOpenSelectChurnDate(!openSelectChurnDate);
  }

  return (
    <>
      {signatureOptions && renderSignatureOptions()}
      {statusOptions && renderStatusOptions()}
      {addressOptions && renderAddressOptions()}
      {advancedOptions && renderAdvancedOptions()}
      <Modal
        open={openSelectChurnDate}
        modalWidth={550}
        onRequestClose={toggleDateModal}
      >
        <DateContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              orientation="landscape"
              disableFuture
              onAccept={() => {
                handleChurnLicensee(churnDate);
                setOpenSelectChurnDate(false);
              }}
              value={churnDate}
              onChange={(value) => {
                setChurnDate(value ?? new Date());
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DateContainer>
      </Modal>
      <MobileActions>
        <MobileOption
          onClick={(event: any) => {
            toggleStatusOptions();
            event.stopPropagation();
          }}
        >
          {" "}
          {statusOptions ? <IoIosArrowBack /> : <MdPublishedWithChanges />}
          <span>Modificar Status</span>
        </MobileOption>

        {Boolean(licensee?.signature?.is_activated) ? (
          <MobileOption
            onClick={(event: any) => {
              event.stopPropagation();
              togglePlansOptions();
            }}
          >
            {signatureOptions ? (
              <IoIosArrowBack />
            ) : (
              <FaExchangeAlt className="change" />
            )}
            <span>Modificar Assinatura</span>
          </MobileOption>
        ) : (
          <MobileOption
            onClick={(event: any) => {
              event.stopPropagation();
              handleActivateSignature(licensee.id);
            }}
          >
            <FiUserCheck />
            <span>Ativar Assinatura</span>
          </MobileOption>
        )}

        <MobileOption
          onClick={(event: any) => {
            event.stopPropagation();
            handleRenderAddressOptions(licensee.id);
            toggleAddressOptions();
          }}
          disabled={!addresses.length}
        >
          <FaRegAddressCard
            color={!addresses.length ? "gray" : "var(--fixit)"}
          />
          <span>Endereço</span>
        </MobileOption>

        <MobileOption
          onClick={(event: any) => {
            event.stopPropagation();
            onDetailsIconClick();
          }}
        >
          <FiEye />
          <span>Ver Detalhes</span>
        </MobileOption>
        <MobileOption
          onClick={(event: any) => {
            event.stopPropagation();
            toggleAdvancedOptions();
          }}
        >
          <VscGear />
          <span>Opções avançadas</span>
        </MobileOption>
      </MobileActions>
    </>
  );
};
