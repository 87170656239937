import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  PriceInfos,
  RightContent,
  Header,
  PrincipalInfos,
  EditBtn,
} from "./styles";
import { Bill } from "../../../../../../hooks/AuthContext";
import { format } from "date-fns";
import { RiBillLine } from "react-icons/ri";
import {
  paymentMethodLabel,
  paymentSituationLabel,
  typeLabel,
} from "../../mocks";
import { FiEdit2 } from "react-icons/fi";

interface PriceItemProps {
  bill: Bill;
  handleEdit: () => void;
}

export function BillItem({ bill, handleEdit }: PriceItemProps) {
  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy");
  }

  return (
    <Container paid={bill.payment_situation}>
      <Header>
        <LeftContent>
          <ImageContainer className="concluido">
            <RiBillLine />
          </ImageContainer>
          <PrincipalInfos className="concluido">
            <span className="solution">{bill.licensee.name}</span>
            <span className="date">{bill.referring_period}</span>
          </PrincipalInfos>

          <ContentInfos>
            <span className="data_label">Valor</span>
            <span className="data_value">{bill.bill_value}</span>
          </ContentInfos>

          <ContentInfos>
            <span className="data_label">Situação</span>
            <span className="data_value">
              {
                paymentSituationLabel[
                  bill.payment_situation as keyof typeof paymentSituationLabel
                ]
              }
            </span>
          </ContentInfos>

          <ContentInfos className="licensee_desktop">
            <span className="data_label">Método de pagamento</span>
            <span className="data_value">
              {
                paymentMethodLabel[
                  bill.payment_method as keyof typeof paymentMethodLabel
                ]
              }
            </span>
          </ContentInfos>

          <ContentInfos className="licensee_desktop">
            <span className="data_label">Tipo</span>
            <span className="data_value">
              {typeLabel[bill.type as keyof typeof typeLabel]}
            </span>
          </ContentInfos>

          <ContentInfos className="licensee_desktop">
            <span className="data_label">Recorrente</span>
            <span className="data_value">
              {bill.is_recurrence ? "Sim" : "Não"}
            </span>
          </ContentInfos>
        </LeftContent>

        <RightContent>
          <>
            <PriceInfos>
              <span className="price_name">Código de transação</span>

              <span className="price_location">{bill.transaction_code}</span>
            </PriceInfos>
            <PriceInfos className="licensee_desktop">
              <span className="price_name">Data de pagamento</span>

              <span className="price_location">
                {bill.payment_date
                  ? formatDate(String(bill.payment_date))
                  : "00/00/00"}
              </span>
            </PriceInfos>
          </>
          <EditBtn>
            <FiEdit2 color="var(--fixit)" onClick={handleEdit} size={18} />
          </EditBtn>
        </RightContent>
      </Header>
    </Container>
  );
}
