import { useEffect, useState } from "react";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";

import { Modal } from "../../../../components/Modal";
import { NewTipModal } from "./components/NewTipModal";
import { TipsSkeleton } from "./components/TipsItem/TipsSkeleton";
import { showToast } from "../../../../components/CustomToast";

import api from "../../../../services/api";
import { ITips } from "./dtos";
import { RiAddLine } from "react-icons/ri";
import { Container, ListContainer, NewTipButton } from "./styles";
import { TipsItem } from "./components/TipsItem";

export function Tips() {
  const { setNavigationAction } = useAuth();

  const [tips, setTips] = useState<ITips[]>([]);
  const [loadingTips, setLoadingTips] = useState(false);
  const [isEdition, setIsEdition] = useState(false);
  const [tip, setTip] = useState<ITips>();
  const [newTipModalOpen, setNewTipModalOpen] = useState(false);

  async function getTips(skeleston?: boolean) {
    skeleston && setLoadingTips(true);
    try {
      const response = await api.get(`/tips`);

      setTips(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar as tips",
      });
    } finally {
      skeleston && setLoadingTips(false);
    }
  }

  function renderSkeleton() {
    const skeletonsQuantity = [];

    for (var i = 0; i < 5; i++) {
      skeletonsQuantity.push(<TipsSkeleton />);
    }

    return skeletonsQuantity;
  }

  useEffect(() => {
    getTips(true);

    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.tips.header"),
    });
  }, []);

  return (
    <Container>
      <Modal open={newTipModalOpen} modalWidth={450}>
        <NewTipModal
          getAllTips={getTips}
          closeModal={() => {
            setNewTipModalOpen(false);
            setIsEdition(false);
          }}
          isEdition={isEdition}
          tipForEdition={tip}
        />
      </Modal>
      <ListContainer>
        {loadingTips && <>{renderSkeleton().map((skeleton) => skeleton)}</>}

        {tips.map((tip) => {
          return (
            <TipsItem
              key={tip.id}
              tip={tip}
              tips={tips}
              onClick={() => {
                setIsEdition(true);
                setTip(tip);
                setNewTipModalOpen(true);
              }}
              getTips={getTips}
            />
          );
        })}
      </ListContainer>
      <NewTipButton type="button" onClick={() => setNewTipModalOpen(true)}>
        <RiAddLine />
      </NewTipButton>
    </Container>
  );
}
