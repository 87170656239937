import { useState, useEffect } from "react";
import api from "../../services/api";

import { FiInfo } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";

import { useAuth } from "../../hooks/AuthContext";

import { IAddress, IFilterData, Licensee } from "./dtos";
import { i18n } from "../../localization/i18n";

import { Modal } from "../../components/Modal";
import { showToast } from "../../components/CustomToast";
import { Filter } from "./pages/LicenseesList/components/Filter";
import AbsoluteButton from "../../components/AbsoluteButton";

import { NewLicenseeModal } from "../../components/NewLicenseeModal";
import { LicenseeModal } from "../../components/LicenseeModal";
import { LicenseeItem } from "./pages/LicenseesList/components/LicenseeItem";
import { roles } from "../../utils/constants";
import { AddressModal } from "../../components/AddressModal";

import {
  Container,
  ListContainer,
  FilterContainer,
  FilterTags,
  Tag,
  Legend,
  ContentContainer,
  IconContainer,
  OperationalColor,
  OnboardingColor,
  EffectedColor,
  TrainingColor,
  ImplantationColor,
  BlockColor,
  ChurnColor,
  TotalContainer,
  TotalInfo,
} from "./styles";




export function LicenseesList() {
  const { setNavigationAction, user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [licenseeInfosModal, setLicenseeInfosModal] = useState(false);
  const [newLicenseeModal, setNewLicenseeModal] = useState(false);
  const [licensees, setLicensees] = useState<Licensee[]>([]);
  const [totalLicensees, setTotalLicensees] = useState(0)
  const [selectedLicensee, setSelectedLicensee] = useState({} as Licensee);
  const [selectedAddress, setSelectedAddress] = useState({} as IAddress);

  const [filter, setFilter] = useState<IFilterData>();
  const [openLegend, setOpenLegend] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const toggleAddressModal = () => {
    setOpenAddressModal(!openAddressModal);
  };

  async function getLicensees() {
    setLoading(true);

    try {
      const queryList = [];

      filter?.licensee_status !== undefined &&
        queryList.push(`is_blocked=${!filter.licensee_status}`);
      filter?.licensee_name &&
        queryList.push(`licensee_name=${filter.licensee_name}`);
      filter?.country && 
        queryList.push(`country=${filter.country.value}`);
      filter?.contract_type &&
        queryList.push(`contract_type=${filter?.contract_type?.value}`);
      filter?.situation &&
        queryList.push(`situation=${filter?.situation?.value}`);

      const response = await api.get(`/licensees?${queryList.join("&")}`);

      setLicensees(response.data.data);
      setTotalLicensees(response.data.total);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados",
      });
    } finally {
      setLoading(false);
    }
  }

  function closeModal() {
    setLicenseeInfosModal(false);
  }

  function closeNewLicenseeModal() {
    setNewLicenseeModal(false);
  }

  useEffect(() => {
      getLicensees();
  }, [filter]);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: false,
      description: i18n.t("global.licensees.header"),
    });
  }, []);

  return (
    <Container>
      <Modal open={openAddressModal} modalWidth={600}>
        <AddressModal
          licensee={selectedLicensee}
          readOnly={openAddressModal} 
          isEditOpen={openAddressModal}
          editAddress={selectedAddress}
          address_id={selectedAddress?.id}
          closeModal={toggleAddressModal}
        />
      </Modal>

      <LicenseeModal
        getLicensees={getLicensees}
        open={licenseeInfosModal}
        licensee={selectedLicensee}
        closeModal={closeModal}
      />

      <Modal open={newLicenseeModal} modalWidth={800} noPadding>
        <NewLicenseeModal
          closeModal={closeNewLicenseeModal}
          getLicensees={getLicensees}
        />
      </Modal>
      <div className="Header">
        { user.role !== roles.saf &&
          <TotalContainer>
                  <TotalInfo>
                    <span className="label">Total</span>
                    <span className="value">{totalLicensees}</span>
                  </TotalInfo>
          </TotalContainer>
        }
        <Legend active={openLegend}>
          <ContentContainer>
            <OnboardingColor />
            <span>Onboarding</span>
          </ContentContainer>
          <ContentContainer>
            <EffectedColor />
            <span>Venda Efetivada</span>
          </ContentContainer>
          <ContentContainer>
            <TrainingColor />
            <span>Em Treinamento</span>
          </ContentContainer>
          <ContentContainer>
            <ImplantationColor />
            <span>Em Implantação</span>
          </ContentContainer>
          <ContentContainer>
            <OperationalColor />
            <span>Operacional</span>
          </ContentContainer>
          <ContentContainer>
            <ChurnColor />
            <span>Churn</span>
          </ContentContainer>
          <ContentContainer>
            <BlockColor />
            <span>Bloqueado</span>
          </ContentContainer>
        </Legend>
        <FilterContainer>
          <IconContainer
            onMouseEnter={(event) => {
              setOpenLegend(true);
            }}
            onMouseLeave={(event) => {
              setOpenLegend(false);
            }}
          >
            <FiInfo />
          </IconContainer>
          <FilterTags>
            {filter?.country && (
              <Tag>
                <span>{filter?.country.value}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      country: undefined,
                    }))
                  }
                />
              </Tag>
            )}

            {filter?.licensee_status !== undefined && (
              <Tag key="is_blocked">
                <span>
                  {filter?.licensee_status
                    ? `${i18n.t("global.status.active")}`
                    : filter && filter.licensee_status === false
                    ? `${i18n.t("global.status.deactive")}`
                    : `${i18n.t("global.status.registered")}`}
                </span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      licensee_status: undefined,
                    }))
                  }
                />
              </Tag>
            )}

            {filter?.licensee_name && (
              <Tag>
                <span>{filter?.licensee_name}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      licensee_name: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.contract_type && (
              <Tag>
                <span>{filter?.contract_type.optionText}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      contract_type: undefined,
                    }))
                  }
                />
              </Tag>
            )}
            {filter?.situation && (
              <Tag>
                <span>{filter?.situation.optionText}</span>
                <RiCloseFill
                  size={14}
                  onClick={() =>
                    setFilter((prevState) => ({
                      ...prevState,
                      situation: undefined,
                    }))
                  }
                />
              </Tag>
            )}
          </FilterTags>
          

          <Filter
            onFilterApply={(filterData) => setFilter(filterData)}
            filter={filter}
          />
        </FilterContainer>
        </div>
      <ListContainer>
        {licensees.map((licensee) => {
          return (
            <LicenseeItem
              key={licensee.id}
              licensee={licensee}
              onDetailsIconClick={() => {
                setSelectedLicensee(licensee);
                setLicenseeInfosModal(true);
              }}
              onChangeLicenseeStatus={getLicensees}
              handleRender={getLicensees}
              setSelectedAddress={setSelectedAddress}
              toggleAddressModal={toggleAddressModal}
            />
          );
        })}
      </ListContainer>

      <AbsoluteButton
        absolute
        onClick={() => {
          setNewLicenseeModal(true);
        }}
      />
    </Container>
  );
}
