import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBin7Line } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import DropZone from "../../../../components/DropZone";
import { Modal } from "../../../../components/Modal";
import { ProgressBar } from "../../../../components/ProgressBar";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { IFile } from "../../CreateOrder/dtos";
import { ModalProps } from "./dtos";
import {
  DropzoneContainer,
  File,
  FileContainer,
  Footer,
  Form,
  FormContainer,
  ProgressContainer,
  UploadButton,
} from "./styles";

const chunkSize = 1024 * 1024 * 5;

interface IPartConfirmation {
  ETag: string;
  PartNumber: number;
}

interface IUploadProgress {
  currentChunkIndex: number | null;
  currentFileIndex: number;
  UploadId?: string;
  randomFileId?: string;
  partsConfirmation: Array<IPartConfirmation>;
}

export default function PlanitUploadingPopUpModal({
  open,
  planit_id,
  close = () => {},
}: ModalProps) {
  const [fileLinks, setFileLinks] = useState<string[]>();
  const [uploadedFiles, setUploadedFiles] = useState<Array<IFile>>([]);
  const [deletedFiles, setDeletedFiles] = useState<Array<string>>([]);

  const [totalSize, setTotalSize] = useState(0);
  const [totalChunks, setTotalChunks] = useState(0);

  const [uploadProgress, setUploadProgress] = useState<IUploadProgress>({
    currentChunkIndex: null,
    currentFileIndex: 0,
    partsConfirmation: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const handleUpload = (files: Array<File>) => {
    let totalFilesSize = totalSize;
    files.forEach((file) => {
      totalFilesSize += file.size;
      const newFile = {
      name: file.name,
      size: file.size,
      type: file.type,
      id: Math.random(),
      file_data: file,
    };

      setTotalSize((prevState) => prevState + file.size);
      setUploadedFiles((prevState) => [...prevState, newFile]);
    });
  };

  useEffect(() => {
    if (uploadProgress.currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [uploadProgress.currentChunkIndex]);

  function uploadChunk(readerEvent: ProgressEvent<FileReader>) {
    if (
      uploadProgress.currentChunkIndex == null ||
      !readerEvent.target ||
      !uploadedFiles[uploadProgress.currentFileIndex]?.file_data
    ) {
      showToast({
        type: "error",
        message: `Nenhum documento foi adicionado`,
      });
      return;
    }

    const filesize = uploadedFiles[uploadProgress.currentFileIndex].size;
    const chunks = Math.ceil(filesize / chunkSize) - 1;
    const isLastChunk = uploadProgress.currentChunkIndex === chunks;

    const chunk = readerEvent.target.result;

    const params = new URLSearchParams();
    params.set("name", uploadedFiles[uploadProgress.currentFileIndex].name);
    params.set(
      "size",
      `${uploadedFiles[uploadProgress.currentFileIndex].size}`
    );
    params.set("type", uploadedFiles[uploadProgress.currentFileIndex].type);

    params.set(
      "currentChunkIndex",
      uploadProgress.currentChunkIndex.toString()
    );
    params.set(
      "totalChunks",
      Math.ceil(
        uploadedFiles[uploadProgress.currentFileIndex].size / chunkSize
      ).toString()
    );
    if (uploadProgress.UploadId) {
      params.set("UploadId", uploadProgress.UploadId);
    }
    if (uploadProgress.randomFileId) {
      params.set("randomFileId", uploadProgress.randomFileId);
    }
    if (isLastChunk) {
      params.set("Parts", JSON.stringify(uploadProgress.partsConfirmation));
    }

    const headers = { "Content-Type": "application/octet-stream" };
    api
      .patch(`/planits/${planit_id}/files?${params.toString()}`, chunk, {
        headers,
      })
      .then((response) => {
        setTotalChunks(totalChunks + 1);
        const nextStage: IUploadProgress = uploadProgress;

        if (uploadProgress.currentChunkIndex === 0) {
          nextStage.UploadId = response.data.UploadId;
          nextStage.randomFileId = response.data.randomFileId;
          setTotalChunks(totalChunks + 1);
        }

        if (isLastChunk) {
          setTotalChunks(totalChunks + 1);
          nextStage.partsConfirmation = [];
          nextStage.UploadId = undefined;
          nextStage.randomFileId = undefined;
          if (nextStage.currentFileIndex + 1 < uploadedFiles.length) {
            setTotalChunks(totalChunks + 1);
            nextStage.currentChunkIndex = 0;
            nextStage.currentFileIndex += 1;
          } else {
            setTotalChunks((Math.ceil(totalSize / chunkSize)));
            nextStage.currentChunkIndex = null;
            nextStage.currentFileIndex = 0;
            showToast({
              type: "success",
              message: "Arquivo padronizado configurado com sucesso!",
            });
            close()
          }
        } else {
          setTotalChunks(totalChunks + 1);
          nextStage.partsConfirmation = [
            ...nextStage.partsConfirmation,
            { ETag: response.data.ETag, PartNumber: response.data.PartNumber },
          ];
          nextStage.currentChunkIndex = (nextStage.currentChunkIndex || 0) + 1;
        }

        setUploadProgress({ ...nextStage });
      })
      .catch((e) => {
        showToast({
          type: "error",
          message: `Erro ao carregar arquivo padronizado`,
        });
        setTotalChunks(0);
        setTotalSize(0);
        setUploadProgress({
          partsConfirmation: [],
          UploadId: undefined,
          randomFileId: undefined,
          currentChunkIndex: null,
          currentFileIndex: 0,
        });
      });
  }

  function readAndUploadCurrentChunk() {
    if (uploadProgress.currentChunkIndex == null) {
      return;
    }

    const reader = new FileReader();

    const from = uploadProgress.currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = uploadedFiles[uploadProgress.currentFileIndex].file_data.slice(
      from,
      to
    );
    reader.onload = (e) => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  const submitChunckedForm = async (): Promise<void> => {
    if (!uploadedFiles?.length) {
      return;
    }

    setUploadProgress((prevState) => ({
      ...prevState,
      currentChunkIndex: 0,
      currentFileIndex: 0,
      UploadId: undefined,
      randomFileId: undefined,
      partsConfirmation: [],
    }));
  };

  return (
    <Modal
      open={open}
      onRequestClose={async () => {
        close();
        setTotalChunks(0);
        setTotalSize(0);
        // clearStates();
      }}
    >
      <Form onSubmit={handleSubmit(submitChunckedForm)}>
        <FormContainer>
          <div className="form_title">{`${i18n.t(
            "orders.planit.file_upload"
          )}`}</div>
          <DropzoneContainer>
            <DropZone onUpload={handleUpload} />
            <div className="files">
              {uploadedFiles.map((file: IFile, index: number) => {
                return (
                  <File key={index}>
                    <span>{file.name}</span>
                    <RiDeleteBin7Line
                      onClick={() => {
                        setUploadedFiles((prevState) =>
                          prevState.filter((p, i) => i !== index)
                        );
                        setTotalSize(totalSize - file.size);
                      }}
                      size={20}
                      color="var(--fixit)"
                    />
                  </File>
                );
              })}
              {planit_id &&
                fileLinks?.map((link, index) => {
                  return (
                    <File key={index}>
                      <span
                        className={
                          deletedFiles.find((d) => d === link)
                            ? "deleted"
                            : "active"
                        }
                      >
                        {link}
                      </span>
                      <RiDeleteBin7Line
                        onClick={() => {
                          !deletedFiles.find((d) => d === link) &&
                            setDeletedFiles((prevState) => [
                              ...prevState,
                              link,
                            ]);
                        }}
                        size={20}
                        color="var(--gray)"
                      />
                    </File>
                  );
                })}
            </div>
          </DropzoneContainer>
          <ProgressContainer>
            <ProgressBar
              text={`${totalChunks ? Math.ceil(((totalChunks ?? 0)/ (Math.ceil(totalSize / chunkSize)))*100) : 0}%`}
              percentage={totalChunks ? ((totalChunks ?? 0)/ (Math.ceil(totalSize / chunkSize)))*100 : 0}
            />
          </ProgressContainer>
          <Footer>
            <UploadButton type="submit">
              {`${i18n.t("orders.planit.submit_file_upload")}`}
            </UploadButton>
          </Footer>
        </FormContainer>
      </Form>
    </Modal>
  );
}
