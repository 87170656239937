import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
} from "./styles";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { Modal } from "../../../../components/Modal";
import { useOnboard } from "../../../../hooks/OnboardContext";
import { IPerson } from "./dtos";
import { showToast } from "../../../../components/CustomToast";
import { PersonModal } from "../../../../components/PersonModal";
import { EditModal } from "../../../Admin/pages/Users/components/EditModal";
import { User } from "../../../../components/PersonModal/dtos";
import { PersonItem } from "./components/PersonItem";
import AbsoluteButton from "../../../../components/AbsoluteButton";

export default function Users() {
  const { user } = useAuth();
  const { licensee } = useOnboard();
  const [ isCreatePersonOpen, setIsCreatePersonOpen ] = useState(false);
  const [stepUser, setStepUser] = useState<User>();
  const [formSteps, setFormSteps] = useState(1);
  const [person, setPerson] = useState<IPerson>();
  const [editPerson, setEditPerson] = useState<IPerson>();
  const [persons, setPersons] = useState<IPerson[]>();

  const toggleModal = () => {
    setIsCreatePersonOpen(!isCreatePersonOpen);
    setFormSteps(1);
  }

  const nextStep = () => {
    setFormSteps((prevState) => prevState + 1);
  }

  async function getLicenseePersons(id: string) {
    try {
      const response = await api.get(`/person/all/${id}`);
      setPersons(response.data)
    }
    catch(error) {
      console.log(error);
    }
  }

  async function deletePerson(id: string) {
    
    if (user?.person?.id === id) {
      showToast({
        type: 'error',
        message: i18n.t("person.error.delete_user")
      })
    }
    else {
      try {      
        await api.delete(`/person/${id}`);
        showToast({
          type: 'success',
          message: i18n.t("person.success.user_deleted_success")
        })
      }
      
      catch(error) {
        console.log(error);
      }

      finally {
        getLicenseePersons(licensee?.id);
      }
    }
  }

  async function getPersonById (id: string) {
    try {
      const response = await api.get(`/person/${id}`);
      setEditPerson(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserById (id: string) {
    try {
      const response = await api.get(`/users/${id}`);
      setStepUser(response.data)
    } catch (error) {
      console.log(error);
    }
  }

  async function insertUserIntoPerson(person_id: string, user_id: string) {
    try {
      const body = {
        person_id: person_id,
        user_id: user_id,
      }

      await api.patch(`/person/update-person`, body);

    } catch (error) {
      console.log(error);
    }
    finally {
      getLicenseePersons(licensee?.id);
    }
  }
  
  useEffect(() => {
    if(licensee){
      getLicenseePersons(licensee?.id);
    }
  }, [licensee]);

  const steps = [
    {
      step: 2,
      component: (
        <EditModal
          person={person}
          closeModal={toggleModal}
          insertUserIntoPerson={insertUserIntoPerson} />
      )
    },
    {
      step: 1,
      component:(
        <PersonModal
          getLicenseePersons={getLicenseePersons}
          licensee={licensee}
          closeModal={async () => {
            await getLicenseePersons(licensee?.id);
            toggleModal();
          }}
          setPerson={setPerson}
          nextStep={nextStep}
        />
      )
    },
  ]

  return (
    <Container>
      {
        isCreatePersonOpen && (
          <Modal open={isCreatePersonOpen}>
            {steps.map((step) => {
              if (step.step === formSteps) {
                return step.component;
              }
            })}
          </Modal>
        )
      }
      <EvaluationWrapper>
        {
          persons?.map((person) => {
            return (
              <PersonItem
                getUserById={getUserById}
                getPersonById={getPersonById}
                address_id={person.id}
                deletePerson={deletePerson}
                getLicenseePersons={getLicenseePersons}
                licensee={licensee} 
                person={person}
                editPerson={editPerson} 
                />
            )
          })
        }
      </EvaluationWrapper>
      <AbsoluteButton
        absolute
        onClick={() => {
          setIsCreatePersonOpen(true);
        }}
      />
    </Container>
  )
}

