import {
  RiLineChartLine,
  RiMenuLine,
  RiToolsLine,
  RiUserSettingsFill,
  RiUserStarFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { LinkItem, LinkTitle, RouteButton } from "../styles";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const AdminMenuList = ({ toggleMenu }: MenuListProps) => (
  <>
    <LinkItem onClick={toggleMenu}>
      <Link to="/admin/dashboard">
        <RouteButton>
          <RiLineChartLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("navMenu.reports")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/admin/orders">
        <RouteButton>
          <RiMenuLine size={26} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("navMenu.requests")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/users/licensees">
        <RouteButton>
          <RiUserStarFill size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.licensees")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/users/creators">
        <RouteButton>
          <RiUserSettingsFill size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.designers")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/configs">
        <RouteButton>
          <RiToolsLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.config")}`}</LinkTitle>
      </Link>
    </LinkItem>
  </>
);
