import { RiDownloadCloud2Line, RiFolder3Line, RiLineChartLine, RiMenuLine, RiTrophyLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useOnboard } from '../../../hooks/OnboardContext';
import { i18n } from '../../../localization/i18n';
import { RouteButton } from '../styles';

interface MenuListProps {
  extendedMode: boolean;
}

export const LicenseeMenuList = ({extendedMode} : MenuListProps) => {
  
  const { licensee } = useOnboard()
  
  return(
    <>
      <Link to="/dashboard">
        {" "}
        {/* Go to Dashboard */} {/* 6 because of 12px margin */}
        <RouteButton extendedMode={extendedMode}>
          <RiLineChartLine size={28} />

          {extendedMode && (
            <span>{`${i18n.t("sidebar.reports")}`}</span>
          )}
        </RouteButton>
      </Link>

      <Link to="/solutions">
        {" "}
        {/* Go to Solutions */}
        <RouteButton extendedMode={extendedMode}>
          <RiDownloadCloud2Line size={28} />
          {extendedMode && (
            <span>{`${i18n.t("sidebar.solutions")}`}</span>
          )}
        </RouteButton>
      </Link>

      <Link to="/orders">
        {" "}
        {/* Go to Orders */}
        <RouteButton extendedMode={extendedMode}>
          <RiMenuLine size={26} />
          {extendedMode && (
            <span>{`${i18n.t("sidebar.requests")}`}</span>
          )}
        </RouteButton>
      </Link>
      { licensee?.signature &&
        <>
          <a
            href="https://drive.google.com/drive/folders/12ouIWR70G6D1YOIiq4e8nstEZZ_tirVf?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {/* Go to Drive Folder */}
            <RouteButton extendedMode={extendedMode}>
              <RiFolder3Line size={26} />
              {extendedMode && (
                <span>{`${i18n.t("sidebar.files")}`}</span>
              )}
            </RouteButton>
          </a>

          <Link to="/success">
            {" "}
            {/* Go to Success */}
            <RouteButton extendedMode={extendedMode}>
              <RiTrophyLine size={28}/>
              {extendedMode && (
                <span>{`${i18n.t("sidebar.success")}`}</span>
              )}
            </RouteButton>
          </Link>
        </>
      }
    </>
)}