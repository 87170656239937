import {  useState } from "react";
import { useNavigate } from "react-router-dom";

import { i18n } from "../../../localization/i18n";
import logoImg from "../../../assets/logo.svg";

import { SolidButton } from "../../../components/SolidButton";
import { OutlinedInput } from "../../../components/OutlinedInput";

import {
  Form,
  FormContent,
  PassRecoveryButton,
  Footer,
  InputContainer,
  CreateAccountLine,
} from "../styles";

interface ILoginProps {
  validateForm: (event: any) => void;
  setEmail: (event: any) => void;
  setPassword: (event: any) => void;
  email: string;
  password: string;
  nextStep: () => void;
}

export default function Login({validateForm, setEmail, nextStep, setPassword, email, password, }: ILoginProps) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({} as any);

  return (
    <Form onSubmit={validateForm}>
          <img className="logo" src={logoImg} alt="FixIt logo" />
  
          <h1>{`${i18n.t('login.login')}`}</h1>
          <FormContent>
            <InputContainer errorMargin={!!errors.email}>
              <OutlinedInput
                label={`${i18n.t('login.email')}`}
                inputName="email"
                type="email"
                value={email}
                handleChange={(event) => {
                  setEmail(event);
                  setErrors((prevState: any) => ({
                    ...prevState,
                    email: "",
                  }));
                }}
                error={errors.email}
                marginOnError={false}
              />
            </InputContainer>
  
            <InputContainer errorMargin={!!errors.password}>
              <OutlinedInput
                label={`${i18n.t('login.password')}`}
                inputName="password"
                type="password"
                value={password}
                handleChange={(event) => {
                  setPassword(event);
                  setErrors((prevState: any) => ({
                    ...prevState,
                    password: "",
                  }));
                }}
                error={errors.password}
                marginOnError={false}
              />
            </InputContainer>
  
            <PassRecoveryButton onClick={nextStep}>
              {`${i18n.t('login.forgotPassword')}`}
            </PassRecoveryButton>
  
            <Footer>
              <SolidButton type="submit" text={i18n.t('login.submit')} />
            </Footer>
  
            <CreateAccountLine>
            {`${i18n.t('login.dontHaveAccount')}`}{" "}
              <span onClick={() => navigate("/registration")}>
              {`${i18n.t('login.register')}`}
              </span>{" "}
            </CreateAccountLine>
          </FormContent>
        </Form>
  );
}
