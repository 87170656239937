type LanguageObject = {
  en: "en";
  pt: "pt-BR";
  es: "es";
};

type IAppLanguage = "en" | "es" | "pt-BR";

export type I18NTextLanguageObject = {
  ["pt-BR"]: string;
  en: string;
  es: string;
};

export function getActiveLanguage(): IAppLanguage {
  const appLanguages: LanguageObject = {
    en: "en",
    pt: "pt-BR",
    es: "es",
  };
  const appStoragedLanguage = localStorage.getItem(
    "i18nextLng"
  ) as IAppLanguage | null;
  const languages = Object.entries(appLanguages);

  if (appStoragedLanguage) {
    let language: IAppLanguage = "en";
    languages.forEach((lang) => {
      if (appStoragedLanguage?.startsWith(lang[0])) language = lang[1];
    });

    return language;
  }
  
  return "en";
}
