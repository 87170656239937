import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { CloseButton, Container, ModalContent, Footer } from "./styles";
import api from "../../services/api";

interface IDoc {
  name: string;
  version: string;
  link: string;
  open?: boolean;
  closeModal: () => any;
}

export function ShowDocModal({
  name,
  version,
  link,
  open,
  closeModal
}: IDoc) {
  const [terms, setTerms] = useState<string>();

  useEffect(() => {
    if (open) {
      fetch(`${process.env.REACT_APP_STORAGE_BASE_URL}${link}`)
        .then(response => response.text())
        .then(html => {
          setTerms(html);
          // var parser = new DOMParser();
          // var doc = parser.parseFromString(html, "text/html");
        })
        .catch(err => {
          // writer.abort({reason: 'Problema na impressão'});
          console.error(err);
        })
    }
  }, [open]);

  return ReactDOM.createPortal(
    <Container open={!!open}>
      <div className="modal_wrapper">
        <ModalContent showTerms={!!terms}>
          <div className="header">
            <div className="title">{name} - {version}</div>
          </div>

          {!!terms && (              
            <iframe srcDoc={terms} width="100%" id="docFrame" scrolling="no" style={{overflow: 'hidden'}}
              onLoad={() => {
                var obj = document.getElementById( 'docFrame' ) as HTMLIFrameElement;
                if (obj && obj.contentWindow) {
                  obj.style.height = (obj.contentWindow.document.body.scrollHeight + 60) + 'px';
                }
              }}></iframe>
          )}

          <Footer>
            <CloseButton
              type="button"
              onClick={() => closeModal()}
            >
              Fechar
            </CloseButton>
          </Footer>
        </ModalContent>
      </div>
    </Container>,
    document.body
  );
}
