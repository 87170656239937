import { i18n } from "../../../../../../../../localization/i18n";

import { OutlinedInput } from "../../../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../../../components/OutlinedSelect";
import DropZone from "../../../../../../../../components/DropZone";

import { TipData, LanguageTipData, LanguageValues } from "../../dtos";

import { TipPageOptions, TipRoleOptions, TipTypesOptions } from "../../mocks";

import { RiDeleteBin7Line } from "react-icons/ri";

import { File, InputContainer, InputLine } from "../../styles";
import { useEffect, useState } from "react";

type TipLanguageFormProps = {
  tipData: TipData;
  languageTipData: LanguageTipData;
  language: keyof LanguageTipData;
  errors: any;
  editingTip: boolean;
  changeTipData: (data: Partial<TipData>) => void;
  changeLanguageTipData: (
    data: Partial<LanguageValues>,
    language: keyof LanguageTipData
  ) => void;
};

export function TipLanguageForm({
  tipData,
  errors,
  changeTipData,
  changeLanguageTipData,
  language,
  languageTipData,
  editingTip,
}: TipLanguageFormProps) {
  function removeFile(fileIndex: number) {
    const tmpFiles = tipData.files.filter((_, index) => index !== fileIndex);
    changeTipData({ files: [...tmpFiles] });
  }

  const [dataType, setDataType] = useState<string | undefined>('');

  function getTipData () {
    if (tipData.files[0]) {
      setDataType(tipData.files[0].type);
    } else {
      setDataType('');
    }
  }

  useEffect(() => {
    if(tipData.files) {
      getTipData();
    }
  }, [tipData.files])

  return (
    <>
      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="name"
            label={i18n.t("tips.name")}
            handleChange={(event) => changeTipData({ name: event })}
            value={tipData.name}
            error={errors.name}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedSelect
            label={i18n.t("tips.type")}
            options={TipTypesOptions}
            handleSelect={(selected) =>
              changeTipData({
                type: selected,
                role: {
                  optionText: "",
                  value: "",
                },
                page: {
                  optionText: "",
                  value: "",
                },
              })
            }
            selectedOption={tipData?.type?.optionText}
            error={errors.type}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedSelect
            label={i18n.t("tips.users")}
            disabled={tipData.type.optionText !== "Página"}
            options={TipRoleOptions}
            handleSelect={(selected) =>
              changeTipData({
                role: selected,
                page: {
                  optionText: "",
                  value: "",
                },
              })
            }
            selectedOption={tipData?.role?.optionText}
            error={errors.role}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedSelect
            label={i18n.t("tips.page")}
            disabled={tipData.role.value === "ALL" || !tipData.role.value}
            options={TipPageOptions[tipData.role.value.toString()]}
            handleSelect={(selected) => {
              changeTipData({
                page: selected,
                specificUrl: "",
              });
            }}
            selectedOption={tipData?.page?.optionText}
            error={errors.page}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="specificUrl"
            disabled={
              !(tipData.page.value as string).includes(":") &&
              !tipData.page.optionText.includes("/")
            }
            label={i18n.t("tips.specificUrl")}
            handleChange={(event) => changeTipData({ specificUrl: event })}
            value={tipData.specificUrl}
            error={
              (tipData.page.value as string).includes(":") && errors.specificUrl
            }
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="description"
            label={i18n.t("tips.description")}
            textArea
            handleChange={(event) =>
              changeLanguageTipData({ description: event }, language)
            }
            value={languageTipData[language].description}
            error={errors[language]?.description}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <DropZone
          accept={{'image/*': [".svg", ".jpeg",".jpg",".png"]}}
          fullWidth={true}
          onUpload={(files: File[]) => {
            const formattedFiles = files.map((item) => {
              return {
                name: item.name,
                size: item.size,
                type: item.type,
                file_data: item,
              };
            });
            changeTipData({ files: [...tipData.files, ...formattedFiles] });
          }}
        />
      </InputLine>

      { dataType?.indexOf('gif') !== -1 ? 
      <InputLine>
        <InputContainer>
          <OutlinedInput
              inputName="gif_time"
              label={'Duração do GIF (ms)'}
              handleChange={(event) =>
                changeTipData({ gif_time: event })
              }
              value={tipData.gif_time || ''}
              error={errors.gif_time}
            />
        </InputContainer>
      </InputLine>
      : null
      }

      {tipData?.files?.map((file, index) => (
        <File>
          <span>{file?.name}</span>
          <RiDeleteBin7Line
            onClick={() => {
              removeFile(index);
            }}
            size={20}
            color={"var(--fixit)"}
          />
        </File>
      ))}
    </>
  );
}
