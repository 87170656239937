import { useRoutes } from "react-router-dom";
import FinancialDashboard from "../../modules/Financial/pages/Dashboard";
import NotFound from "./NotFound";
import { Prices } from "../../modules/Financial/pages/Prices";
import { Plans } from "../../modules/Financial/pages/Plans";
import { Orders } from "../../modules/Orders";
import Layout from "../../components/Layout";
import ShowOrder from "../../modules/Orders/ShowOrder";
import { LicenseesList } from "../../modules/Licensee";
import CreateLicensee from "../../modules/Licensee/pages/CreateLicensee";
import EditLicensee from "../../modules/Licensee/pages/EditLicensee";
import Bills from "../../modules/Financial/pages/Bills";

function FinancialRoutes() {
  const financialRoutes = useRoutes([
    {
      path: "/financial/dashboard",
      element: (
        <Layout>
          <FinancialDashboard />
        </Layout>
      ),
    },
    {
      path: "/financial/orders",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/financial/orders/show",
      element: (
        <Layout>
          <ShowOrder />
        </Layout>
      ),
    },
    {
      path: "/financial/licensees",
      element: (
        <Layout>
          <LicenseesList />
        </Layout>
      ),
    },
    {
      path: "/financial/prices",
      element: (
        <Layout>
          <Prices />
        </Layout>
      ),
    },
    {
      path: "/financial/create/licensee",
      element: (
        <Layout>
          <CreateLicensee />
        </Layout>
      ),
    },
    {
      path: "/financial/edit/licensee",
      element: (
        <Layout>
          <EditLicensee />
        </Layout>
      ),
    },
    {
      path: "/financial/plans",
      element: (
        <Layout>
          <Plans />
        </Layout>
      ),
    },
    {
      path: "/financial/bills",
      element: (
        <Layout>
          <Bills />
        </Layout>
      ),
    },
    { path: "/financial/*", element: <NotFound /> },
  ]);

  return financialRoutes;
}

export default FinancialRoutes;
