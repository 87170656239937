import styled from "styled-components";
import global from "../../../../global";

interface ILegend {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 26px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    gap: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 30px;
  gap: 10px;

  display: flex;
  align-items: center;
  justify-content: end;

  .title {
    color: var(--dark-gray);
    font-size: 30px;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: fit-content;
    margin-top: 0px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 0px;
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

export const Legend = styled.div<ILegend>`
  width: 140px;
  padding: 1rem 0;
  background: white;
  flex-wrap: wrap;

  position: absolute;
  top: 35px;
  right: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 10;
  cursor: pointer;

  border-radius: 20px;

  visibility: ${(ILegend) => (ILegend.active ? "visible" : "hidden")};
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    top: 32px;
    right: 100px;
  }
`;

export const ContentContainer = styled.div`
  margin: 2px;
  width: 70%;
  background: white;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: center;
  gap: 10px;
  color: var(--fixit);

  span {
    font-size: 12px;
    color: var(--dark-gray);
  }
`;

export const PaidColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--active-green);

  border-radius: 50px;
`;

export const WaitingColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--warning);

  border-radius: 50px;
`;

export const CanceledColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--error);

  border-radius: 50px;
`;

export const ReversalColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--development);

  border-radius: 50px;
`;
