import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
`;

export const StepTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 20px;
`;

export const StepDescription = styled.div`
  font-size: 16px;
  color: var(--dark-gray);
  text-align: center;
  margin-top: 10px;
`;

export const PlansWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;

  @media (max-width: 980px) {
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const IllustrationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 400px;
    margin-top: 20px;
  }

  @media (max-width: 980px) {
    img {
      width: 100%;
    }
  }
`;

export const SAFLinkButton = styled.a`
  text-decoration: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid var(--fixit);
  background: var(--white);

  color: var(--fixit);
  font-size: 16px;

  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: var(--fixit);
    color: var(--white);
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;
