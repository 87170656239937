import { useEffect, useState } from "react";
import api from "../../../../../../services/api";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { showToast } from "../../../../../../components/CustomToast";
import { Checkbox } from "../../../../../../components/Checkbox";

import DropZone from "../DropZone";

import validateFormFields from "../../../../../../utils/validateFormFields";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import { planTypes } from "../../../../../../utils/constants";

import { IPlan, ISolutions, IOption, ILanguagesProps } from "../../dtos";

import { languages } from "./mockOptions";

import {
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Line,
  RiSaveLine,
} from "react-icons/ri";

import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import { ScrollContainer } from "../CreatePlan/styles";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  SaveText,
  SolutionsContainer,
  SelectedSolutions,
  Label,
  Tag,
  NextStep,
  BackText,
  UploadFilesContainer,
  DescriptionContainer,
  FlexContainerButton,
  LinkButton,
  TextArea,
  FlexContainer,
  CheckboxContainer,
  DropzoneContainer,
  File,
} from "./styles";

interface IFormData {
  pricePerMonth?: number;
  OrdersPerMonth?: number | string;
  avaliableSolutions: ISolutions[];
  solutionsType: IOption;
  trial_period?: number;
  numberOfSolutions: number | string;
  plan_name?: string;
  link_image?: string;
  description?: string;
  backDescription?: ILanguagesProps;
  currency: IOption;
  plan_type: IOption;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}
interface IEditPlan {
  closeModal: (plan?: IPlan) => void;
  plan: IPlan;
}

export function EditPlan({ plan, closeModal }: IEditPlan) {
  const solutionTypesOptions = [
    { value: "all", optionText: "Todas" },
    { value: "standard", optionText: "Padronizadas" },
    { value: "custom", optionText: "Personalizadas" },
  ];

  const formattedPlanTypes = Object.entries(planTypes).map((item) => ({
    optionText: i18n.t(`financial.prices.plan_type.${item[0]}`),
    value: item[1],
  }));

  const [formData, setFormData] = useState<IFormData>({
    pricePerMonth: plan.price,
    OrdersPerMonth: plan.orders_quantity,
    numberOfSolutions: plan.solutions_quantity,
    link_image: "",
    avaliableSolutions: plan.avaliable_solutions,
    trial_period: plan.trial_period,
    plan_name: plan.plan_name,
    description: "",
    solutionsType: getSolutionsType(),
    plan_type: formattedPlanTypes.find(
      (item) => item.value === plan.plan_type
    ) ?? { optionText: "", value: "" },
    currency: {
      optionText: plan.currency === "USD" ? "Dólar" : "Real",
      value: plan.currency === "USD" ? "USD" : "BRL",
    },
  });

  const editDescription = JSON.parse(plan.description);

  const [solutions, setSolutions] = useState<any[]>([]);
  const [availableSolutions, setAvailableSolutions] = useState<IOption[]>([]);
  const [errors, setErrors] = useState({} as any);
  const [file, setFile] = useState({} as IFile);

  const [formSteps, setFormSteps] = useState(1);
  const [ptBr, setIsptBr] = useState(true);
  const [Es, setIsEs] = useState(false);
  const [En, setIsEn] = useState(false);

  const [saveBr, setSaveBr] = useState(false);
  const [saveEn, setSaveEn] = useState(false);
  const [saveEs, setSaveEs] = useState(false);

  function getSolutionsType() {
    if (plan.custom_orders && plan.standard_orders) {
      return { value: "all", optionText: "Todas" };
    } else if (plan.custom_orders) {
      return { value: "custom", optionText: "Personalizadas" };
    } else {
      return { value: "standard", optionText: "Padronizadas" };
    }
  }

  function handleLanguageBr() {
    setIsEs(false);
    setIsEn(false);
    setIsptBr(!ptBr);
  }

  function handleLanguageEs() {
    setIsEs(!Es);
    setIsptBr(false);
    setIsEn(false);
  }

  function handleLanguageEn() {
    setIsEn(!En);
    setIsEs(false);
    setIsptBr(false);
  }

  const completeFormStep = () => {
    setFormSteps((step) => step + 1);
  };

  const backFormStep = () => {
    setFormSteps((step) => step - 1);
  };

  const [descriptionBr, setDescriptionBr] = useState(
    editDescription.ptBr.description
  );
  const [advantagesBr, setAdvantagesBr] = useState(
    editDescription.ptBr.advantages
  );

  const [descriptionEs, setDescriptionEs] = useState(
    editDescription.Es.description
  );
  const [advantagesEs, setAdvantagesEs] = useState(
    editDescription.Es.advantages
  );

  const [descriptionEn, setDescriptionEn] = useState(
    editDescription.En.description
  );
  const [advantagesEn, setAdvantagesEn] = useState(
    editDescription.En.advantages
  );

  const currencyOptions = [
    { value: "BRL", optionText: "Real" },
    { value: "USD", optionText: "Dólar" },
  ];

  function handleAddNewAdvantage() {
    setAdvantagesBr([...advantagesBr, ""]);
  }

  function handleAddNewEsAdvantage() {
    setAdvantagesEs([...advantagesEs, ""]);
  }

  function handleAddNewEnAdvantage() {
    setAdvantagesEn([...advantagesEn, ""]);
  }

  function handleSetDescriptionBrLanguage() {
    languages.ptBr.description = descriptionBr;
  }

  function handleSetDescriptionEsLanguage() {
    languages.Es.description = descriptionEs;
  }

  function handleSetDescriptionEnLanguage() {
    languages.En.description = descriptionEn;
  }

  async function handleSaveBrLanguage() {
    advantagesBr.map((advantage: string, index: number) => {
      return (languages.ptBr.advantages[index] = advantage);
    });
  }

  async function handleSaveEsLanguage() {
    advantagesEs.map((advantage: string, index: number) => {
      return (languages.Es.advantages[index] = advantage);
    });
  }

  async function handleSaveEnLanguage() {
    advantagesEn.map((advantage: string, index: number) => {
      return (languages.En.advantages[index] = advantage);
    });
  }

  /*   function handleSetAdvantagesBrLanguage(key: number, event: React.ChangeEvent<HTMLTextAreaElement>){
    setAdvantagesBr((prevState : any) => {
      const newState = prevState.map((advantage : IAdvantages) => {
        if(advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          }
        }
        return advantage;
      });
      return newState;
    })
    setSaveBr(false);
  }

  function handleSetAdvantagesEsLanguage(key: number, event: React.ChangeEvent<HTMLTextAreaElement>){
    setAdvantagesEs((prevState : any) => {
      const newState = prevState.map((advantage : IAdvantages) => {
        if(advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          }
        }
        return advantage;
      });
      return newState;
    })
    setSaveEs(false);
  }

  function handleSetAdvantagesEnLanguage(key: number, event: React.ChangeEvent<HTMLTextAreaElement>){
    setAdvantagesEn((prevState : any) => {
      const newState = prevState.map((advantage : IAdvantages) => {
        if(advantage.key === key) {
          return {
            ...advantage,
            [event.target.name]: event.target.value,
          }
        }
        return advantage;
      });
      return newState;
    })
    setSaveEn(false);
  } */

  async function handleUpdatePlan(body: any) {
    try {
      const { data } = await api.patch(`/plans/${plan.id}`, body);
      closeModal(data);
      showToast({
        type: "success",
        message: "O plano foi atualizado com sucesso",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro  ao tentar atualizar o plano.",
      });
    } finally {
      closeModal();
    }
  }

  async function getSolutions() {
    try {
      const response = await api.get("/solutions");

      const formattedSolutions = response.data.map((solution: any) => ({
        value: solution.id,
        optionText: solution.name,
      }));

      setSolutions(response.data);
      setAvailableSolutions(formattedSolutions);

      const availableSolutions: any[] = [];

      formData?.avaliableSolutions?.forEach((item) => {
        const solutionSelected = response.data.find(
          (solution: any) => solution.id === item
        );

        if (solutionSelected)
          availableSolutions.push({
            id: solutionSelected.id,
            optionText: solutionSelected.name,
          });
      });

      setFormData((prevState) => ({
        ...prevState,
        avaliableSolutions: availableSolutions,
      }));
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar atualizar as soluções disponíveis",
      });
    }
  }

  async function validateForm(event: any) {
    event.preventDefault();

    const body = {
      ...formData,
      price: formData?.pricePerMonth,
      orders_quantity:
        Number(formData?.OrdersPerMonth) === 0 ||
        formData?.OrdersPerMonth === ""
          ? null
          : formData?.OrdersPerMonth,
      solutions_quantity:
        formData?.numberOfSolutions === 0 ? null : formData?.numberOfSolutions,
      plan_type: formData?.plan_type.value,
      link_image: formData?.link_image,
      avaliable_solutions: formData?.avaliableSolutions?.length
        ? formData.avaliableSolutions.map((item) => item.id)
        : null,
      custom_orders:
        (formData?.solutionsType?.value === "all" ||
          formData?.solutionsType?.value === "custom") &&
        true,
      standard_orders:
        (formData?.solutionsType?.value === "all" ||
          formData?.solutionsType?.value === "standard") &&
        true,
      trial_period: formData?.trial_period,
      plan_name: formData?.plan_name,
      description: JSON.stringify(languages),
      currency: formData.currency.value,
    };

    const validationSchema = Yup.object().shape({
      price: Yup.number().positive().min(0).required(typesErrors.required),
      trial_period: Yup.number().positive().required(typesErrors.required),
      plan_name: Yup.string().required(typesErrors.required),
      plan_type: Yup.string().required(typesErrors.required),
      description: Yup.string(),
    });

    const validation = await validateFormFields(body, validationSchema, {
      plan_name: "",
      trial_period: "",
      price: "",
      description: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: "Verifique os campos com erro.",
      });

      return;
    }

    handleUpdatePlan(body);
  }

  useEffect(() => {
    if (formData.solutionsType.value === "all") {
      const formattedSolutions = solutions.map((solution: any) => ({
        value: solution.id,
        optionText: solution.name,
      }));
      setAvailableSolutions([...formattedSolutions]);
    } else if (formData.solutionsType.value === "custom") {
      const filteredSolutions: any = solutions
        .filter((solution) => solution.is_custom)
        .map((solution: any) => ({
          value: solution.id,
          optionText: solution.name,
        }));

      setAvailableSolutions([...filteredSolutions]);
    } else if (formData.solutionsType.value === "standard") {
      const filteredSolutions: any = solutions
        .filter((solution) => solution.is_standard)
        .map((solution: any) => ({
          value: solution.id,
          optionText: solution.name,
        }));

      setAvailableSolutions([...filteredSolutions]);
    }

    setFormData((prevState) => ({
      ...prevState,
      avaliableSolutions: [],
    }));
  }, [formData.solutionsType.value]);

  useEffect(() => {
    setFormSteps(1);
  }, [closeModal]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      pricePerMonth: plan.price,
      OrdersPerMonth: plan.orders_quantity,
      numberOfSolutions: plan.solutions_quantity,
      link_image: "",
      avaliableSolutions: plan.avaliable_solutions,
      trial_period: plan.trial_period,
      plan_name: plan.plan_name,
      description: "",
      solutionsType: getSolutionsType(),
      plan_type: formattedPlanTypes.find(
        (item) => item.value === plan.plan_type
      ) ?? { optionText: "", value: "" },
      currency: {
        optionText: plan.currency === "USD" ? "Dólar" : "Real",
        value: plan.currency === "USD" ? "USD" : "BRL",
      },
    }));
  }, [plan]);

  useEffect(() => {
    getSolutions();
  }, []);

  function handleRemoveTaskById(id: string | number) {
    const deletedTag = formData.avaliableSolutions?.filter(
      (as) => as.id !== id
    );
    setFormData((prevState) => ({
      ...prevState,
      avaliableSolutions: [...deletedTag],
    }));
  }

  if (formSteps === 1) {
    return (
      <Container>
        <RiCloseFill
          size={24}
          color="var(--fixit)"
          onClick={() => {
            closeModal();
          }}
        />

        <Header>
          <span>Editar plano</span>
        </Header>

        <FormContainer>
          <InputContainer>
            <OutlinedInput
              label={`${i18n.t("financial.prices.field.plan_name")}`}
              inputName="plan_name"
              type="text"
              handleChange={(event) => {
                setFormData((prevState) => ({
                  ...prevState,
                  plan_name: event,
                }));
              }}
              value={formData?.plan_name || ""}
              autoComplete="off"
            />
          </InputContainer>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Tipo do plano"
                options={formattedPlanTypes}
                handleSelect={(selected) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    plan_type: selected,
                  }));
                }}
                selectedOption={formData?.plan_type?.optionText}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("financial.prices.field.file_price")}`}
                inputName="file_price"
                type="number"
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    pricePerMonth: event,
                  }));
                }}
                value={formData?.pricePerMonth || ""}
                min={0}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedSelect
                label={"Moeda"}
                options={currencyOptions}
                selectedOption={formData?.currency?.optionText || ""}
                handleSelect={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    currency: {
                      value: event.value.toString(),
                      optionText: event.optionText,
                    },
                  }));
                }}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    OrdersPerMonth: event,
                  }));
                }}
                type="number"
                inputName={"plans_ordersPerMonth"}
                label={"Pedidos por mês"}
                value={formData?.OrdersPerMonth || ""}
                min={0}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("financial.prices.field.trial_period")}`}
                inputName="file_price"
                type="number"
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    trial_period: event,
                  }));
                }}
                value={formData?.trial_period || ""}
                min={0}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Tipo de soluções"
                options={solutionTypesOptions}
                handleSelect={(selected) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    solutionsType: selected,
                  }));
                }}
                selectedOption={formData?.solutionsType?.optionText}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                handleChange={(event) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    numberOfSolutions: event,
                  }));
                }}
                type="number"
                inputName={"plans_numbersOfSolutions"}
                label={`${i18n.t("financial.prices.field.numberOfSolutions")}`}
                value={formData?.numberOfSolutions || ""}
                min={0}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label={"Soluções disponíveis"}
                options={availableSolutions}
                handleSelect={(event) => {
                  const solutionsLimit =
                    formData.numberOfSolutions >
                    formData.avaliableSolutions.length;
                  const solutionAlreadySelected =
                    formData.avaliableSolutions.find(
                      (item) => item.id === event.value
                    );
                  if (!solutionAlreadySelected && solutionsLimit) {
                    return setFormData((prevState) => ({
                      ...prevState,
                      avaliableSolutions: [
                        ...prevState.avaliableSolutions,
                        {
                          id: event.value,
                          optionText: event.optionText,
                        },
                      ],
                    }));
                  }
                }}
                selectedOption={""}
              />
            </InputContainer>
          </InputLine>
        </FormContainer>
        <SolutionsContainer>
          <Label>Soluções selecionadas</Label>
          <SelectedSolutions>
            {formData.avaliableSolutions &&
              formData.avaliableSolutions.map((solution) => {
                return (
                  <Tag key={solution.id}>
                    <span>{solution.optionText}</span>
                    <RiCloseFill
                      size={14}
                      onClick={() => {
                        handleRemoveTaskById(solution.id);
                      }}
                    />
                  </Tag>
                );
              })}
          </SelectedSolutions>
        </SolutionsContainer>
        <Footer>
          <NextStep onClick={completeFormStep}>
            Próximo
            <FiArrowRight size="1rem" />
          </NextStep>
        </Footer>
      </Container>
    );
  }
  if (formSteps === 2) {
    return (
      <Container>
        <RiCloseFill
          size={24}
          color="var(--fixit)"
          onClick={() => {
            closeModal();
          }}
        />

        <Header>
          <span>Criar novo plano</span>
        </Header>
        <FlexContainer>
          <CheckboxContainer>
            <Checkbox checked={ptBr} handleChange={() => handleLanguageBr()} />
            <span>pt-BR</span>
          </CheckboxContainer>

          <CheckboxContainer>
            <Checkbox checked={Es} handleChange={() => handleLanguageEs()} />
            <span>ES</span>
          </CheckboxContainer>

          <CheckboxContainer>
            <Checkbox checked={En} handleChange={() => handleLanguageEn()} />
            <span>EN</span>
          </CheckboxContainer>
        </FlexContainer>

        {ptBr && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Português</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionBr(event.target.value);
                  setSaveBr(false);
                }}
                defaultValue={descriptionBr}
                placeholder="Escreva uma descrição do plano"
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesBr.map((advantage: string, index: number) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Português</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        (advantagesBr[index] = event.target.value)
                      }
                      defaultValue={advantage}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveBr ? (
                <LinkButton
                  onClick={() => {
                    setSaveBr(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionBrLanguage();
                    handleSaveBrLanguage();
                    setSaveBr(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}
        {Es && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Espanhol</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionEs(event.target.value);
                  setSaveEs(false);
                }}
                defaultValue={descriptionEs}
                placeholder="Escribe una descripción del plan"
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesEs.map((advantage: string, index: number) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Espanhol</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        (advantagesEs[index] = event.target.value)
                      }
                      defaultValue={advantage}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewEsAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveEs ? (
                <LinkButton
                  onClick={() => {
                    setSaveEs(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionEsLanguage();
                    handleSaveEsLanguage();
                    setSaveEs(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}

        {En && (
          <>
            <DescriptionContainer>
              <span>Descrição do Plano - Inglês</span>
              <TextArea
                id="description"
                name="description"
                onChange={(event: any) => {
                  setDescriptionEn(event.target.value);
                  setSaveEn(false);
                }}
                defaultValue={descriptionEn}
                placeholder="Write a description for the Plan"
              />
            </DescriptionContainer>
            <ScrollContainer>
              {advantagesEn.map((advantage: string, index: number) => {
                return (
                  <DescriptionContainer>
                    <span>Vantagens do Plano - Inglês</span>
                    <TextArea
                      id="advantages"
                      name="advantages"
                      onChange={(event) =>
                        (advantagesEn[index] = event.target.value)
                      }
                      defaultValue={advantage}
                    />
                  </DescriptionContainer>
                );
              })}
            </ScrollContainer>
            <FlexContainerButton>
              <LinkButton
                onClick={() => {
                  handleAddNewEnAdvantage();
                }}
              >
                Nova Vantagem
              </LinkButton>
              {saveEn ? (
                <LinkButton
                  onClick={() => {
                    setSaveEn(false);
                  }}
                >
                  Salvo
                  <RiCheckFill size={16} color="var(--finished)" />
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    handleSetDescriptionEnLanguage();
                    handleSaveEnLanguage();
                    setSaveEn(true);
                  }}
                >
                  Salvar
                  <RiSaveLine size={16} />
                </LinkButton>
              )}
            </FlexContainerButton>
          </>
        )}

        <UploadFilesContainer>
          <DropzoneContainer>
            <span>Adicione uma foto</span>
            <DropZone
              accept={{'image/*': [".gif", ".jpeg",".jpg",".png"]}}
              onUpload={(file) => {
                const formattedFile = {
                  name: file[0].name,
                  size: file[0].size,
                  type: file[0].type,
                  file_data: file[0],
                };
                setFile(formattedFile);
              }}
            />
          </DropzoneContainer>

          {file.name && (
            <File>
              <span>{file.name}</span>
              <RiDeleteBin7Line
                onClick={() => setFile({} as IFile)}
                size={20}
                color="var(--fixit)"
              />
            </File>
          )}
        </UploadFilesContainer>
        <Footer>
          <BackText onClick={backFormStep}>
            <FiArrowLeft size="1rem" />
            Voltar
          </BackText>
          <SaveText
            onClick={(event: any) => {
              saveBr && saveEs && saveEn
                ? validateForm(event)
                : showToast({
                    type: "error",
                    message: "Confira se todos os campos estão salvos!",
                  });
            }}
          >
            Editar plano
          </SaveText>
        </Footer>
      </Container>
    );
  } else return null;
}
