export const TipTypesOptions = [
  {
    optionText: "Global",
    value: "global",
  },
  {
    optionText: "Página",
    value: "specific",
  },
  {
    optionText: "Impressão",
    value: "print",
  },
];

export const TipRoleOptions = [
  {
    optionText: "Licenciado",
    value: "LICENSEE",
  },
  {
    optionText: "Fixiter",
    value: "FIXITER",
  },
  {
    optionText:  "Designers de Produtos",
    value: "CREATOR",
  },
  {
    optionText: "Administrador",
    value: "ADMIN",
  },
  {
    optionText: "Financeiro",
    value: "FINANCIAL",
  },
  {
    optionText: "Atendimento (SAF)",
    value: "SAF",
  }
]

export const TipPageOptions : any = {
    CREATOR : [
      {
        optionText: "Órteses",
        value: "creator/solutions",
      },
      {
        optionText: "Criação de órteses",
        value: "creator/solutions/create",
      },
      {
        optionText: "Edição de órteses",
        value: "creator/solutions/edit",
      },
      {
        optionText: "Pedidos",
        value: "creator/orders",
      },
      {
        optionText: "Visualização de pedidos",
        value: "creator/orders/show",
      },
      {
        optionText: "Confecção de pedido",
        value: "creator/orders/make",
      },
      {
        optionText: "Edição de padronizadas",
        value: "creator/solutions/:solution_key/standards",
      },
      {
        optionText: "Configuração de padronizadas",
        value: "creator/solutions/:solution_key/standards/upload",
      },
      {
        optionText: "Confecção de pedido",
        value: "creator/orders/make",
      },
    ],
  FINANCIAL: [
      {
        optionText: "Pedidos",
        value: "financial/orders",
      },
      {
        optionText: "Detalhes do pedido",
        value: "financial/orders/show",
      },
      {
        optionText: "Lista de licenciados",
        value: "financial/licensees",
      },
      {
        optionText: "Preços",
        value: "financial/prices",
      },
      {
        optionText: "Criação de licenciado",
        value: "financial/create/licensee",
      },
      {
        optionText: "Edição de licenciado",
        value: "financial/edit/licensee",
      },
      {
        optionText: "Planos",
        value: "financial/plans",
      },
    ],
    LICENSEE: [
      {
        optionText: "Tipo de soluções",
        value: "/solutions",
      },
      {
        optionText: "Escolha de categoria",
        value: "/categories/:field_key",
      },
      {
        optionText: "Escolha da órtese",
        value: "/solutions/:category_key",
      },
      {
        optionText: "Criação de pedido",
        value: "/order/:solution_key/:solution_type",
      },
      {
        optionText: "Pedidos",
        value: "/orders",
      },
      {
        optionText: "Pedidos como provedor",
        value: "/orders/:as_provider",
      },
      {
        optionText: "Perfil",
        value: "/profile",
      },
      {
        optionText: "Escolha de categoria",
        value: "/categories/:field_key",
      },
      {
        optionText: "Relatar problema",
        value: "/problem",
      },
      {
        optionText: "Detalhes do pedido",
        value: "/evaluation/:order_id",
      },
      {
        optionText: "Endereço",
        value: "/licensee/address",
      },
      {
        optionText: "Em desenvolvimento",
        value: "/development",
      },
    ],
    ADMIN: [
      {
        optionText: "Pedidos",
        value: "orders",
      },
      {
        optionText: "Detalhes do pedido",
        value: "admin/orders/show",
      },
      {
        optionText: "Lista de licenciados",
        value: "users/licensees",
      },
      {
        optionText: "Criação de licenciado",
        value: "users/create/licensee",
      },
      {
        optionText: "Lista de creators",
        value: "users/creators",
      },
      {
        optionText: "Criação de creators",
        value: "users/create/creator",
      },
      {
        optionText: "Edição de creators",
        value: "users/edit/creator",
      },
      {
        optionText: "Edição de licenciado",
        value: "user/edit/licensee",
      },
      {
        optionText: "Configurações",
        value: "configs",
      },
      {
        optionText: "Configuração de categorias",
        value: "configs/fields",
      },
      {
        optionText: "Criação de categorias",
        value: "configs/fields/create",
      },
      {
        optionText: "Configuração de impressoras",
        value: "configs/printers",
      },
      {
        optionText: "Configuração de users",
        value: "configs/users",
      },
      {
        optionText: "Configuração de tips",
        value: "configs/tips",
      },
    ],
    SAF: [
      {
        optionText: "Pedidos",
        value: "saf/orders",
      },
      {
        optionText: "Detalhes do pedido",
        value: "saf/orders/show",
      },
      {
        optionText: "Lista de licenciados",
        value: "saf/licensees",
      },
      {
        optionText: "Edição de licenciado",
        value: "saf/edit/licensee",
      },
    ],
    FIXITER: [
      {
        optionText: "Tipo de soluções",
        value: "fixiter/solutions",
      },
      {
        optionText: "Escolha de categoria",
        value: "fixiter/categories/:field_key",
      },
      {
        optionText: "Escolha da órtese",
        value: "fixiter/solutions/:category_key",
      },
      {
        optionText: "Criação de pedido",
        value: "fixiter/order/:solution_key/:solution_type",
      },
      {
        optionText: "Pedidos",
        value: "fixiter/orders",
      },
      {
        optionText: "Detalhes do pedido",
        value: "fixiter/evaluation/:order_id",
      },
      {
        optionText: "Cursos",
        value: "fixiter/courses",
      },
      {
        optionText: "Escolha de categoria",
        value: "fixiter/categories/:field_key",
      },
      {
        optionText: "Endereços",
        value: "fixiter/address",
      },
    ],
    ALL: [
      {
        optionText: "Popup de impressão",
        value: "/printing"
      },
      {
        optionText: "Dashboard",
        value: "/dashboard"
      }
    ]
}
