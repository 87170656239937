import { useEffect, useState } from "react";

import { useAuth } from "../../../../hooks/AuthContext";

import { i18n } from "../../../../localization/i18n";

import AbsoluteButton from "../../../../components/AbsoluteButton";
import { showToast } from "../../../../components/CustomToast";
import { Modal } from "../../../../components/Modal";

import { DeleteConfirmation } from "./components/DeleteConfirmation";
import { CreatePlan } from "./components/CreatePlan";
import { EditPlan } from "./components/EditPlan";
import { PlanItem } from "./components/PlanItem";

import api from "../../../../services/api";

import { IPlan } from "./dtos";

import { Container, ListContainer } from "./styles";

export function Plans() {
  const [createNewPlan, setCreateNewPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<IPlan>();
  const [planInfoModal, setPlanInfoModal] = useState(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const { setNavigationAction } = useAuth();

  async function getPlans() {
    try {
      const response = await api.get(`/plans`);

      setPlans(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os planos",
      });
    }
  }

  let isActiveFirst = (
    <>
      {plans.map((plan) => {
        return plan.is_active ? (
          <PlanItem
            onUpdate={(updatedPlan) => {
              setPlans((prevState) => {
                return prevState.map((p) => {
                  if (p.id === updatedPlan.id) {
                    return updatedPlan;
                  }
                  return p;
                });
              });
            }}
            key={plan.id}
            plan={plan}
            handleRender={getPlans}
            onDeleteIconClick={() => {
              setSelectedPlan(plan);
              toggleConfirmation();
            }}
            onEditIconClick={() => {
              setSelectedPlan(plan);
              setPlanInfoModal(true);
            }}
          ></PlanItem>
        ) : null;
      })}
    </>
  );

  async function deletePlan(id: any) {
    try {
      await api.delete(`/plans/${id}`);
      showToast({
        type: "success",
        message: "O plano foi deletado com sucesso",
      });
      getPlans();
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel deletar o plano selecionado",
      });
    }
  }

  function toggleModal() {
    setCreateNewPlan(!createNewPlan);
  }

  function toggleConfirmation() {
    setDeleteConfirmation(!deleteConfirmation);
  }

  useEffect(() => {
    getPlans();

    setNavigationAction({
      hasButton: false,
      description: i18n.t("sidebar.plans"),
    });
  }, []);

  return (
    <Container>
      {selectedPlan && (
        <Modal inheritOverflow open={planInfoModal} modalWidth={450}>
          <EditPlan
            plan={selectedPlan}
            closeModal={(updatedPlan?: IPlan) => {
              if (updatedPlan) {
                setPlans((prevState) => {
                  return prevState.map((p) => {
                    if (p.id === updatedPlan.id) {
                      return updatedPlan;
                    }
                    return p;
                  });
                });
              }
              setSelectedPlan(undefined);
              setPlanInfoModal(false);
            }}
          />
        </Modal>
      )}
      <Modal inheritOverflow open={createNewPlan} modalWidth={450}>
        <CreatePlan
          closeModal={() => {
            toggleModal();
          }}
          onCreatePlan={getPlans}
        />
      </Modal>

      <Modal inheritOverflow open={deleteConfirmation}>
        <DeleteConfirmation
          onRequestClose={() => toggleConfirmation()}
          onRequestDelete={() => {
            deletePlan(selectedPlan?.id);
            toggleConfirmation();
          }}
        />
      </Modal>

      <ListContainer>
        {isActiveFirst}
        {plans.map((plan) => {
          return (
            !plan.is_active && (
              <PlanItem
                onUpdate={(updatedPlan) => {
                  setPlans((prevState) => {
                    return prevState.map((p) => {
                      if (p.id === updatedPlan.id) {
                        return updatedPlan;
                      }
                      return p;
                    });
                  });
                }}
                key={plan.id}
                plan={plan}
                handleRender={getPlans}
                onDeleteIconClick={() => {
                  setSelectedPlan(plan);
                  toggleConfirmation();
                }}
                onEditIconClick={() => {
                  setSelectedPlan(plan);
                  setPlanInfoModal(true);
                }}
              />
            )
          );
        })}
      </ListContainer>
      <AbsoluteButton absolute={false} onClick={() => toggleModal()} />
    </Container>
  );
}
