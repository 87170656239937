import { useEffect, useState } from "react";
import {
  Container,
  EvaluationWrapper,
  Section,
  Field,
  ButtonIcon,
  TopRightPosition,
  ImageContainer,
  Image,
  Header,
} from "./styles";
import api from "../../../../services/api";
import { useNavigate, useParams } from "react-router";
import { RiPencilLine } from "react-icons/ri";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import AbsoluteButton from "../../../../components/AbsoluteButton";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

export interface ICategory {
  id: string;
  name: I18NTextLanguageObject;
  field_key: string;
  name_key: string;
  image_link: string;
  description: I18NTextLanguageObject;
}

interface ISolutionField {
  id: string;
  name: I18NTextLanguageObject;
  name_key: string;
  image_link: string;
  description: I18NTextLanguageObject;
}

export default function Categories() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();
  const [field, setField] = useState<ISolutionField>({} as ISolutionField);
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  const language = i18n.language;

  let { field_key } = useParams<keyof ICategory>();

  useEffect(() => {
    if (field_key) {
      api.get(`/categories/key/${field_key}`).then((response) => {
        setField(response.data);
      });
    }
  }, [field_key]);

  useEffect(() => {
    if (field.id) {
      api.get(`/categories/field/${field.id}`).then((response) => {
        setCategories(response.data);
      });
    }
  }, [field]);

  useEffect(() => {
    if(field.name !== undefined){

      setNavigationAction({
        action: "back",
        hasButton: true,
        description: `${i18n.t("global.configs.solutionCategories")} ${
          field ? field.name[language as keyof I18NTextLanguageObject] : i18n.t("global.solutions")
        }`,
      });
    }
  }, [field]);

  return (
    <>
      <Header>
        <AbsoluteButton
          text="Novo"
          absolute={false}
          onClick={() =>
            navigate(`/configs/fields/${field.name_key}/create`)
          }
        />
      </Header>
      <Container>
        <EvaluationWrapper>
          {categories.map((category) => {
            return (
              <Section>
                <TopRightPosition>
                  <ButtonIcon
                    onClick={() =>
                      navigate(
                        `/configs/fields/${field.name_key}/${category.name_key}/edit`
                      )
                    }
                  >
                    <RiPencilLine size={24} color="var(--fixit)" />
                  </ButtonIcon>
                </TopRightPosition>
                <h2>{category.name[language as keyof I18NTextLanguageObject]}</h2>
                {!!category.image_link && (
                  <ImageContainer>
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_BASE_URL}${category.image_link}`}
                    />
                  </ImageContainer>
                )}
                <Field>
                  <strong>Descrição:</strong> {category.description[language as keyof I18NTextLanguageObject]}
                </Field>
                <Field>
                  <strong>Chave de URL:</strong> {category.name_key}
                </Field>
              </Section>
            );
          })}
        </EvaluationWrapper>
      </Container>
    </>
  );
}
