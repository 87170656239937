import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBin7Line } from "react-icons/ri";
import DropZone from "../../../../components/DropZone";

import Head from "../../../../components/Head";
import { Checkbox } from "../../../../components/Checkbox";
import { useNavigate, useParams } from "react-router";

import {
  Container,
  SectionLabel,
  Section,
  Flex,
  SubContainer,
  Input,
  InputLabel,
  InputContainer,
  CheckboxFlex,
  Submit,
  Cancel,
  Delete,
  FlexButtons,
  UploadFilesContainer,
  DropzoneContainer,
  File,
  Image,
  ImageContainer,
  DeleteButton,
  TextArea,
  TextAreaContainer,
  InputPrefixBox,
  Prefix,
} from "./styles";

import api from "../../../../services/api";
import { showToast } from "../../../../components/CustomToast";
import { AxiosError } from "axios";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import { Tabs } from "../../../../components/Tabs";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

interface ISolutionField {
  id: string;
  name_key: string;
  image_link?: string;
  number: number;
  section_name: string;
  field_key: string;
  is_active: boolean;
  autoopen: boolean;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}

interface IParams {
  field_key: string;
}

export default function EditField() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();
  const { register, handleSubmit } = useForm({ mode: "all" });

  let { field_key } = useParams<keyof IParams>();

  const [field, setField] = useState<ISolutionField>({} as ISolutionField);
  const [file, setFile] = useState({} as IFile);

  const [languageDescriptionContent, setLanguageDescriptionContent] = useState<I18NTextLanguageObject>({
    "pt-BR": "",
    en: "",
    es: ""
  });

  const [languageNameContent, setLanguageNameContent] = useState<I18NTextLanguageObject>({
    "pt-BR": "",
    en: "",
    es: ""
  });

  const tabsComponents = [
    {
      tabName: "PT-BR",
      component: (
        <>
          <InputContainer>
            <InputLabel>Nome</InputLabel>
            <Input
              type="text"
              defaultValue={languageNameContent["pt-BR"]}
              onChange={(event: any) =>
                setLanguageNameContent((prevState) => ({
                  ...prevState,
                  "pt-BR": event.target.value,
                }))
              }
              inputWidth="380px"
              ref={register({
                required: {
                  value: true,
                  message: "Escreva o nome da área da solução!",
                },
              })}
            />
          </InputContainer>
          <TextAreaContainer>
            <InputLabel>Descrição</InputLabel>
            <TextArea
              value={languageDescriptionContent["pt-BR"]}
              onChange={(event: any) =>
                setLanguageDescriptionContent((prevState) => ({
                  ...prevState,
                  "pt-BR": event.target.value
                }))
              }
              ref={register({
                required: {
                  value: true,
                  message: "Escreva a descrição!",
                },
              })}
            />
          </TextAreaContainer>
        </>
      ),
    },
    {
      tabName: "EN",
      component: (
        <>
          <InputContainer>
            <InputLabel>Nome</InputLabel>
            <Input
              type="text"
              defaultValue={languageNameContent.en}
              onChange={(event: any) =>
                setLanguageNameContent((prevState) => ({
                  ...prevState,
                  en: event.target.value,
                }))
              }
              inputWidth="380px"
              ref={register({
                required: {
                  value: true,
                  message: "Escreva o nome da área da solução!",
                },
              })}
            />
          </InputContainer>
          <TextAreaContainer>
            <InputLabel>Descrição</InputLabel>
            <TextArea
              defaultValue={languageDescriptionContent.en}
              onChange={(event) =>
                setLanguageDescriptionContent((prevState) => ({
                  ...prevState,
                  en:event.target.value,
                }))
              }
              ref={register({
                required: {
                  value: true,
                  message: "Escreva a descrição!",
                },
              })}
            />
          </TextAreaContainer>
        </>
      ),
    },
    {
      tabName: "ES",
      component: (
        <>
          <InputContainer>
            <InputLabel>Nome</InputLabel>
            <Input
              type="text"
              defaultValue={languageNameContent.es}
              onChange={(event: any) =>
                setLanguageNameContent((prevState) => ({
                  ...prevState,
                  es: event.target.value,
                }))
              }
              inputWidth="380px"
              ref={register({
                required: {
                  value: true,
                  message: "Escreva o nome da área da solução!",
                },
              })}
            />
          </InputContainer>
          <TextAreaContainer>
            <InputLabel>Descrição</InputLabel>
            <TextArea
              value={languageDescriptionContent.es}
              onChange={(event: any) =>
                setLanguageDescriptionContent((prevState) => ({
                  ...prevState,
                  es: event.target.value,
                }))
              }
              ref={register({
                required: {
                  value: true,
                  message: "Escreva a descrição!",
                },
              })}
            />
          </TextAreaContainer>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (field_key) {
      api.get(`/categories/key/${field_key}`).then((response) => {
        setField(response.data);
        setLanguageDescriptionContent(response.data.description);
        setLanguageNameContent(response.data.name);
      })
    }
  }, [field_key]);

  const [mouseOverImage, setMouseOverImage] = useState<boolean>(false);

  const submitForm = (values: any): void => {
    if (field) {
      var formData = new FormData();
      formData.append("name_key", values.name_key);
      formData.append("number", values.number);
      formData.append("section_name", values.section_name);
      formData.append("is_active", `${field.is_active}`);
      formData.append("autoopen", `${field.autoopen}`);
      if (field.image_link) {
        formData.append("image_link", field.image_link);
      }
      if (file) {
        formData.append("file", file.file_data);
      }
      api
        .put(`/categories/${field.id}`, {
          ...formData,
          name:languageNameContent,
          description: languageDescriptionContent
        })
        .then(() => {
          showToast({
            type: "success",
            message: "Área de solução atualizada com sucesso!",
          });
          navigate(-1);
        })
        .catch((error: AxiosError) => {
          showToast({
            type: "error",
            message: `Erro ao atualizar a área de solução. Detalhes: ${error.message}`,
          });
        })
        .finally(() => clearModalData);
    }
  };

  const deleteField = () => {
    if (
      !!field &&
      window.confirm("Realmente deseja excluir essa área de solução?")
    ) {
      api
        .delete(`/categories/${field.id}`)
        .then(() => {
          showToast({
            type: "success",
            message: "Área de solução deletada com sucesso!",
          });
          navigate(-1);
        })
        .catch((error: AxiosError) => {
          showToast({
            type: "error",
            message: `Erro ao deletar a área de solução. Detalhes: ${error.message}`,
          });
        });
    }
  };

  function clearModalData() {
    setLanguageDescriptionContent({
      "pt-BR": "",
      en: "",
      es: "",
    });
    setLanguageNameContent({
      "pt-BR": "",
      en: "",
      es: "",
    });
  }

  const backPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: `${i18n.t("global.configs.createSolutionField")} ${
        field ? languageNameContent[i18n.language as keyof I18NTextLanguageObject] : "área"
      }`,
    });
  }, []);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(submitForm)}>
          <Flex>
            <Section>
              <SectionLabel>Infomações gerais</SectionLabel>
              <SubContainer>
                <CheckboxFlex>
                  <Checkbox
                    handleChange={() => {
                      setField((prevState) => ({
                        ...prevState,
                        is_active: !prevState.is_active,
                      }));
                    }}
                    checked={field.is_active}
                  />
                  <InputLabel>Átivo</InputLabel>
                </CheckboxFlex>

                <CheckboxFlex>
                  <Checkbox
                    handleChange={() => {
                      setField((prevState) => ({
                        ...prevState,
                        autoopen: !prevState.autoopen,
                      }));
                    }}
                    checked={field.autoopen}
                  />
                  <InputLabel>AutoOpen (Solução)</InputLabel>
                </CheckboxFlex>
              </SubContainer>

              <SubContainer>
                <Tabs tabs={tabsComponents} />
              </SubContainer>

              <SubContainer>
                <InputContainer>
                  <InputLabel>Número</InputLabel>
                  <Input
                    type="number"
                    min={0}
                    step="1"
                    id="number"
                    name="number"
                    inputWidth="60px"
                    defaultValue={field.number}
                    ref={register({
                      required: {
                        value: true,
                        message: "Escreva o número de ordem",
                      },
                    })}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Nome da seção</InputLabel>
                  <Input
                    type="text"
                    id="section_name"
                    name="section_name"
                    inputWidth="130px"
                    defaultValue={field.section_name}
                    ref={register({
                      required: {
                        value: true,
                        message:
                          "Escreva o nome da seção! Que vai ficar visível no caminho do usuário: Soluções > Imobilizar > *MMIS*",
                      },
                    })}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Nome URL</InputLabel>
                  <InputPrefixBox>
                    <Prefix>/</Prefix>
                    <Input
                      type="text"
                      id="name_key"
                      name="name_key"
                      inputWidth="120px"
                      defaultValue={field.name_key}
                      ref={register({
                        required: {
                          value: true,
                          message: "Escreva o nome da URL!",
                        },
                      })}
                    />
                  </InputPrefixBox>
                </InputContainer>
              </SubContainer>
            </Section>

            {!!field.image_link && (
              <Section>
                <SubContainer>
                  <ImageContainer
                    onMouseOver={() => {
                      setMouseOverImage(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOverImage(false);
                    }}
                  >
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_BASE_URL}${field.image_link}`}
                    />
                    {mouseOverImage && (
                      <DeleteButton
                        type="button"
                        onClick={() => {
                          setField((prevState) => ({
                            ...prevState,
                            image_link: undefined,
                          }));
                        }}
                      >
                        Excluir
                      </DeleteButton>
                    )}
                  </ImageContainer>
                </SubContainer>
              </Section>
            )}

            {!field.image_link && (
              <Section>
                <SubContainer>
                  <UploadFilesContainer>
                    <label>Imagem de exibição</label>
                    <DropzoneContainer>
                      <DropZone
                        accept={{
                          "image/*": [".svg", ".jpeg", ".jpg", ".png"],
                        }}
                        onUpload={(file) => {
                          const formattedFile = {
                            name: file[0].name,
                            size: file[0].size,
                            type: file[0].type,
                            file_data: file[0],
                          };
                          setFile(formattedFile);
                        }}
                      />
                    </DropzoneContainer>

                    {file.name && (
                      <File>
                        <span>{file.name}</span>
                        <RiDeleteBin7Line
                          onClick={() => setFile({} as IFile)}
                          size={20}
                          color="var(--fixit)"
                        />
                      </File>
                    )}
                  </UploadFilesContainer>
                </SubContainer>
              </Section>
            )}

            <FlexButtons>
              <div>
                <Cancel onClick={backPage} type="button">
                  Voltar
                </Cancel>
                <Submit type="submit">Atualizar</Submit>
              </div>
              <Delete onClick={deleteField} type="button">
                <RiDeleteBin7Line size={24} />
              </Delete>
            </FlexButtons>
          </Flex>
        </form>
      </Container>
    </>
  );
}
