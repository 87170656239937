import { useEffect } from "react";
import { FiEye } from "react-icons/fi";
import { IoIosArrowBack } from 'react-icons/io';
import { BsBook, BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { BiUserPin, BiMoney } from 'react-icons/bi';
import { MdPublishedWithChanges, MdOutlineAssignmentTurnedIn, MdOutlineWorkOutline, MdOutlineHomeWork } from "react-icons/md";
import {
  MobileActions,
  MobileActiveOption,
  MobileOption,
  SignatureActions,
  StatusActions,
} from "../pages/LicenseesList/components/LicenseeItem/styles";
import { IPlans, ISignature, Licensee } from "../dtos";
import { customerSituations, planTypes } from '../../../utils/constants';
import { FaExchangeAlt } from "react-icons/fa";
import { GoEyeClosed } from "react-icons/go";

export interface SAFProps {
  licensee: Licensee;
  licenseeSignature?: ISignature;
  activePlans: IPlans[];
  signatureOptions: boolean;
  statusOptions: boolean;
  handleActivateSignature: (id: string) => void;
  handleChangeLicenseeSituation: (id: string, situation: string) => void;
  handleGetLicenseeSignature: (id: string) => void;
  getPlans: (currency: string, is_active: boolean) => void;
  toggleStatusOptions: () => void;
  togglePlansOptions: () => void;
  onDetailsIconClick?: () => void;
  handleRender: () => void;
}

export const SAFActions = ({
  licensee,
  licenseeSignature,
  activePlans,
  signatureOptions,
  statusOptions,
  handleChangeLicenseeSituation = () => {},
  handleGetLicenseeSignature = () => {},
  toggleStatusOptions = () => {},
  togglePlansOptions = () => {},
  getPlans = () => {},
  onDetailsIconClick = () => {},
}: SAFProps) => {
  const licenseeStatus = [
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.onboarding
            );
          }}
        >
          <BiUserPin />
          <span>Onboarding</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BiUserPin />
          <span>Onboarding</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "onboarding",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.effected
            );
          }}
        >
          <BiMoney />
          <span>Venda Efetivada</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BiMoney />
          <span>Venda Efetivada</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "effected",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.training
            );
          }}
        >
          <BsBook />
          <span>Em Treinamento</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <BsBook />
          <span>Em Treinamento</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "training",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.implementation
            );
          }}
        >
          <MdOutlineHomeWork />
          <span>Em Implantação</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <MdOutlineHomeWork />
          <span>Em Implantação</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "implementation",
    },
    {
      defaultComponent: (
        <MobileOption
          onClick={(event) => {
            handleChangeLicenseeSituation(
              licensee.id,
              customerSituations.operational
            );
          }}
        >
          <MdOutlineWorkOutline />
          <span>Em Operação</span>
        </MobileOption>
      ),
      checkedComponent: (
        <MobileOption>
          <MdOutlineWorkOutline />
          <span>Em Operação</span>
          <RiCheckboxCircleLine className="checked" />
        </MobileOption>
      ),
      value: "operational",
    },
  ];

  useEffect(() => {
    if (Boolean(licensee?.signature?.is_activated)) {
      handleGetLicenseeSignature(licensee.id);
    }
  }, [signatureOptions]);

  useEffect(() => {
    if (licensee.country === "BRA") {
      getPlans("BRL", false);
    } else {
      getPlans("USD", false);
    }
  }, []);

  function renderSignatureOptions() {
    return (
      <SignatureActions>
        {activePlans.map((plan) => {
            if (plan.plan_type === planTypes.demo) {
              return (
                <MobileActiveOption
                  is_active={plan.is_active}
                >
                  <BsStar />
                  <span>Plano {plan.plan_name}</span>
                  {licenseeSignature?.plan_name === plan.plan_name ? <RiCheckboxCircleLine className="checked"/> : null}
                </MobileActiveOption>
              )
            }
            if (plan.plan_type === planTypes.basic) {
              return (
                <MobileActiveOption
                  is_active={plan.is_active}
                >
                  <BsStarHalf />
                  <span>Plano {plan.plan_name}</span>
                  {licenseeSignature?.plan_name === plan.plan_name ? <RiCheckboxCircleLine className="checked"/> : null}
                </MobileActiveOption>
              )
            }
            if (plan.plan_type === planTypes.advanced) {
              return (
                <MobileActiveOption
                  is_active={plan.is_active}
                >
                  <BsStarFill />
                  <span>Plano {plan.plan_name}</span>
                  {licenseeSignature?.plan_name === plan.plan_name ? <RiCheckboxCircleLine className="checked"/> : null}
                </MobileActiveOption>
              )
            }
            else {
              return (
                <MobileActiveOption
                  is_active={plan.is_active}
                >
                  <GoEyeClosed className="not_visible"/>
                  <span>Plano {plan.plan_name}</span>
                  {licenseeSignature?.plan_name === plan.plan_name ? <RiCheckboxCircleLine className="checked"/> : null}
                </MobileActiveOption>
              )
            }
          })
        }
      </SignatureActions>
    );
  }

  function renderStatusOptions() {
    return (
      <StatusActions>
        {licenseeStatus.map((l) => {
          if (licensee.situation === l.value) {
            return l.checkedComponent;
          }
          return l.defaultComponent;
        })}
      </StatusActions>
    );
  }

  return (
    <>
      {signatureOptions && renderSignatureOptions()}
      {statusOptions && renderStatusOptions()}
      <MobileActions>
        <MobileOption
          onClick={(event: any) => {
            event.stopPropagation();
            toggleStatusOptions();
          }}
        >
          {" "}
          {statusOptions ? <IoIosArrowBack /> : <MdPublishedWithChanges />}
          <span>Modificar Status</span>
        </MobileOption>

        {Boolean(licensee?.signature?.is_activated) ? (
          <MobileOption
            onClick={(event: any) => {
              event.stopPropagation();
              togglePlansOptions();
            }}
          >
            {signatureOptions ? (
              <IoIosArrowBack />
            ) : (
              <MdOutlineAssignmentTurnedIn />
            )}
            <span>Verificar Assinatura</span>
          </MobileOption>
        ) : null}

        <MobileOption
          onClick={(event: any) => {
            event.stopPropagation();
            onDetailsIconClick();
          }}
        >
          <FiEye />
          <span>Ver Detalhes</span>
        </MobileOption>
      </MobileActions>
    </>
  );
};
