import React, { ReactNode, useState } from 'react';

import NavBtn from '../NavBtn';
import NavMenu from '../NavMenu';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';

import { PageContainer } from "./styles";

const LayoutIframe: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return(
    <>
      <NavMenu toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <NavBtn toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <Sidebar />
      <PageContainer isMenuOpen={isMenuOpen}>
      <Topbar />
      <div className="content-area">
        {children}
      </div>
      </PageContainer>
    </>
  )
};

export default LayoutIframe;
