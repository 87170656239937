import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/AuthContext";

import { i18n } from "../../localization/i18n";

import * as Yup from "yup";
import CustomToast, { showToast } from "../../components/CustomToast";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../utils/validateFormFields";

import printImg from "../../assets/3dprint.jpg";

import {
  Container,
  Branding,
} from "./styles";
import api from "../../services/api";
import Login from "./Login";
import SendCode from "./SendCode";
import ValidateCode from "./ValidateCode";
import ChangePassword from "./ChangePassword";

export default function Logon() {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [code, setCode] = useState<any>("");
  const [expiredCode, setExpiredCode] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [reCaptcha, setReCaptcha] = useState<any>();

  const [activeStep, setActiveStep] = useState(1);

  function nextStep () {
    setActiveStep((prevState: any) => prevState + 1);
  }

  const steps = [
    {
      step: 1,
      component: (
        <Login
          validateForm={validateForm}
          setEmail={setEmail}
          email={email}
          nextStep={nextStep}
          password={password}
          setPassword={setPassword} />
      )
    },
    {
      step: 2,
      component: (
        <SendCode
          email={email}
          setEmail={setEmail}
          validateSendCodeStep={validateSendCodeStep} />
      )
    },
    {
      step: 3,
      component: (
        <ValidateCode
          loading={loading}
          validateCodeStep={validateCodeStep}
          handleDeleteCode={handleDeleteCode}
          resendCode={resendCode}
          setExpiredCode={setExpiredCode}
          setCode={setCode} />
      )
    },
    {
      step: 4,
      component: (
        <ChangePassword
          confirmNewPassword={confirmNewPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          validateNewPasswordStep={validateNewPasswordStep} />
      )
    },
  ];

  async function handleDeleteCode() {
    try {
      setLoading(false);

      const body = {
        email: email,
      };

      await api.post("/users/delete-temporary-user", body);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function resendCode() {
    try {
      setLoading(true);

      const body = {
        email: email,
      };
      await api.post("/users/resend-code", body);
      showToast({
        type: "success",
        message: i18n.t('userRegistration.sendedCode')
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function sendCode() {
    try {
      setLoading(true);

      const body = {
        email: email,
      };

      await api.post("/users/validate", body);
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Não foi possível fazer o envio do código. Favor contatar o SAF.',
      })
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = useCallback(async () => {
    try {
      await signIn({ email, password });
    } catch (error) {
      showToast({
        type: "error",
        message: "Tente novamente com email e senha válidos.",
      });
    }
  }, [email, password, signIn, navigate]);

  async function validateForm(event: any) {
    event.preventDefault();

    const body = {
      email,
      password,
    };

    const loginSchema = Yup.object().shape({
      email: Yup.string().required(typesErrors.required),
      password: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, loginSchema, {
      email: "",
      password: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t('form_errors.alert')
      });

      return;
    }

    handleSubmit();
  }

  async function validateSendCodeStep(event: any) {
    event.preventDefault();

    const body = {
      email,
    };

    const loginSchema = Yup.object().shape({
      email: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, loginSchema, {
      email: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t('form_errors.alert')
      });

      return;
    }
    sendCode();
    nextStep();
  }

  async function validateCodeStep(event?: any) {
    event.preventDefault();
    if (!expiredCode) {
      const body = {
        code,
      };
  
      const loginSchema = Yup.object().shape({
        code: Yup.string().required(typesErrors.required),
      });
  
      const validation = await validateFormFields(body, loginSchema, {
        code: "",
      });
  
      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t('form_errors.alert')
        });
        
        return;
      }
  
      handleSubmitCode();
    } else {
      showToast({
        type: 'error',
        message: i18n.t("userRegistration.codeExpired"),
      })
    }

    
  }

  async function handleSubmitCode() {
    try {
      setLoading(true);
      
      const body = {
        email: email,
        code: code,
      };

      const response =  await api.post("/users/check", body);
      setToken(response.data);
      nextStep();

    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.validCode"),
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitNewPassword() {
    if (newPassword === confirmNewPassword) {
      try {
        setLoading(true);

        const body = {
          email: email,
          newPassword: newPassword,
        }

        await api.post('/users/forgot', body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        showToast({
          type: "success",
          message: 'Senha modificada com sucesso!',
        });

        setTimeout(() => {
          setActiveStep(1);
        }, 4000)
      
      } catch (error) {
        showToast({
          type: "error",
          message: i18n.t("login.password_changed"),
        });
      
      } finally {
        setLoading(false);
      }
    } else {
      showToast({
        type: 'error',
        message: 'Senhas não coincidem!',
      })
    }
  }

  async function validateNewPasswordStep(event: any) {
    event.preventDefault();

    const body = {
      newPassword,
      confirmNewPassword,
    };

    const loginSchema = Yup.object().shape({
      newPassword: Yup.string().required(typesErrors.required),
      confirmNewPassword: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, loginSchema, {
      newPassword: "",
      confirmNewPassword: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t('form_errors.alert')
      });

      return;
    }

    await handleSubmitNewPassword();
  }

  return (
    <Container>
      <CustomToast />
      <Branding>
        <img className="ilustration" src={printImg} alt="3D Print" />
      </Branding>

    {
      steps.map((step) => {
        if(step.step === activeStep) {
          return step.component;
        }
      })
    }
    </Container>
  );
}
