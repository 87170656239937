import styled, { css } from 'styled-components'

interface IContainerProps {
  isDragActive: boolean
  isDragReject: boolean
}

interface IUploadMessageProps {
  type?: string
}

const dragActive = css`
  border-color: var(--fixit);
`

const dragReject = css`
  // border-color: var(--red);
  border-color: #FA0A0A;
`

export const Container = styled.div<IContainerProps>`
  border: 2px dashed var(--gray);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}
`

interface IObjectKeys {
  [key: string]: string 
}

interface ImessageColors extends IObjectKeys {
  default: string
  error: string
  success: string
}

const messageColors = {
  default: "var(--light-gray)",
  error: "#FA0A0A",
  success: "var(--fixit)"
} as ImessageColors

export const UploadMessage = styled.div<IUploadMessageProps>`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
`
