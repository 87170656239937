import React, { useState } from "react";

import {
  Container,
  Separator,
  Language,
  SideBarCard,
  Header,
  LinksContainer,
  Body
} from "./styles";

import Symbol from "../../assets/symbol-purple.svg";
import { useAuth } from "../../hooks/AuthContext";
import { LicenseeMenuList } from "./MenuList/LicenseeMenuList";
import { AdminMenuList } from "./MenuList/AdminMenuList";
import { CreatorMenuList } from "./MenuList/CreatorMenuList";
import { SafMenuList } from "./MenuList/SafMenuList";
import { FixiterMenuList } from "./MenuList/FixiterMenuList";
import { FinancialMenuList } from "./MenuList/FinancialMenuList";
import { roles } from "../../utils/constants";
import { EmployeeMenuList } from "./MenuList/EmployeeMenuList";
import { getActiveLanguage } from "../../utils/getActiveLanguage";

const Sidebar = () => {
  const [extendedMode, setExtendedMode] = useState(true);
  const { user } = useAuth();

  function handleLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    window.localStorage.setItem("i18nextLng", e.target.value);
    window.location = window.location;
  }

  return (
    <Container extendedMode={extendedMode}>
      <SideBarCard>
        <Header>
          <img
            src={Symbol}
            width="60px"
            height="60px"
            style={{ userSelect: "none" }}
            alt="Fix it"
          />
          <Separator />
        </Header>

        <Body>
          <LinksContainer>
            {
              user.role === roles.licensee && <LicenseeMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.creator && <CreatorMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.admin && <AdminMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.financial && <FinancialMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.saf && <SafMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.fixiter && <FixiterMenuList extendedMode={extendedMode} />
            }
            {
              user.role === roles.employee && <EmployeeMenuList extendedMode={extendedMode} />
            }
          </LinksContainer>

          <Language
            onChange={(e) => handleLanguageChange(e)}
            value={getActiveLanguage()}
          >
            <option value="pt-BR">PT 🇧🇷</option>
            <option value="es">ES 🇪🇸</option>
            <option value="en">EN 🇬🇧</option>
          </Language>

        </Body>
      </SideBarCard>
    </Container>
  );
};

export default Sidebar;
