import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { i18n } from "../../../localization/i18n";

import Popover from "../../../components/Popover";
import { ProvisionerList } from "../components/ProvisionerList";
import { showToast } from "../../../components/CustomToast";
import { ActionsPopup } from "../components/ActionsPopup";
import { ConfirmOrderUrgencyModal } from "../components/ConfirmOrderUrgencyModal";

import api from "../../../services/api";

import { orderStatus, orderUrgencyTypes } from "../../../utils/constants";

import { IOrder } from "../dtos";

import { FiGift } from "react-icons/fi";
import {
  RiAlarmWarningFill,
  RiEyeLine,
  RiMoneyDollarCircleLine,
  RiPrinterLine,
} from "react-icons/ri";
import {
  MdOutlinePersonPinCircle,
  MdAttachMoney,
  MdOutlineMoneyOff,
} from "react-icons/md";

import {
  ActionItem,
  ActionsContainer,
  IconButtonContainer,
  ProvisionerContainer,
} from "../components/OrderItem/styles";

interface ActionsProps {
  order: IOrder;
  getOrders: (reload: boolean) => Promise<void>;
  setOrderPrintManagement: (
    value: React.SetStateAction<IOrder | undefined>
  ) => void;
}

export const SAFActions = ({
  order,
  setOrderPrintManagement,
  getOrders,
}: ActionsProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [providerSelected, setProviderSelected] = useState(false);
  const [confirmOrderUrgencyModal, setConfirmOrderUrgencyModal] =
    useState(false);

  const [financialStatusModal, setFinancialStatusModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(order?.financial_status);

  function handleToggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  const paymentStatusActions = [
    {
      text: i18n.t("orders.financial_status.paid"),
      icon: <MdAttachMoney />,
      active: paymentStatus === "paid",
      action: () => {
        handleChangeLicenseeStatus("paid");
      },
    },
    {
      text: i18n.t("orders.financial_status.not_paid"),
      icon: <MdOutlineMoneyOff />,
      active: paymentStatus === "not_paid",
      action: () => {
        handleChangeLicenseeStatus("not_paid");
      },
    },
    {
      text: i18n.t("orders.financial_status.courtesy"),
      icon: <FiGift />,
      active: paymentStatus === "courtesy",
      action: () => {
        handleChangeLicenseeStatus("courtesy");
      },
    },
    {
      text: i18n.t(`topbar.coinFix.end`),
      icon: <RiMoneyDollarCircleLine />,
      active: paymentStatus === "fixcoin_paid",
      action: () => {
        handleChangeLicenseeStatus("fixcoin_paid");
      },
    },
  ];

  async function handleChangeLicenseeStatus(
    status: IOrder["financial_status"]
  ) {
    try {
      await api.put(
        `/orders/${order.id}/financial_status?financial_status=${status}`
      );

      setPaymentStatus(status);

      showToast({
        type: "success",
        message: i18n.t("orders.financial_status.success"),
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("orders.financial_status.error"),
      });
    }
  }

  function getFinancialStatusInfos() {
    switch (paymentStatus) {
      case "courtesy":
        return {
          label: i18n.t("orders.financial_status.courtesy"),
          icon: <FiGift size={18} />,
        };
      case "paid":
        return {
          label: i18n.t("orders.financial_status.paid"),
          icon: <MdAttachMoney size={18} />,
        };
      case "not_paid":
        return {
          label: i18n.t("orders.financial_status.not_paid"),
          icon: <MdOutlineMoneyOff size={18} />,
        };
      case "fixcoin_paid":
        return {
          label: i18n.t(`topbar.coinFix.end`),
          icon: <RiMoneyDollarCircleLine size={18} />,
        };
    }
  }

  return (
    <>
      <ProvisionerList
        handleProviderSelected={() => getOrders(true)}
        trigger={isModalOpen}
        orderIdentification={order.id}
        onClose={() => {
          handleToggleModal();
        }}
        order={order}
      />

      {order.urgency_type === orderUrgencyTypes.requested && (
        <ConfirmOrderUrgencyModal
          order={order}
          open={confirmOrderUrgencyModal}
          getOrders={getOrders}
          handleCloseModal={() => setConfirmOrderUrgencyModal(false)}
        />
      )}

      <ActionsContainer>
        {order.urgency_type === orderUrgencyTypes.requested &&
          order.urgency_reason && (
            <ActionItem>
              <Popover
                position="left"
                label={`${i18n.t("orders.urgency.popoverRequested")}`}
              >
                <RiAlarmWarningFill
                  size={18}
                  color="var(--error)"
                  onClick={() => setConfirmOrderUrgencyModal(true)}
                />
              </Popover>
            </ActionItem>
          )}

        {order.urgency_type === orderUrgencyTypes.requested &&
          !order.urgency_reason && (
            <ActionItem>
              <Popover
                position="left"
                label={`${i18n.t("orders.urgency.popoverNonJustified")}`}
              >
                <RiAlarmWarningFill size={18} color="var(--error)" />
              </Popover>
            </ActionItem>
          )}

        {order.urgency_type === orderUrgencyTypes.approved && (
          <ActionItem>
            <Popover
              position="left"
              label={`${i18n.t("orders.urgency.popoverApproved")}`}
            >
              <RiAlarmWarningFill size={18} color="var(--error)" />
            </Popover>
          </ActionItem>
        )}

        {order.solution_type === "standard" && (
          <ActionItem className="print">
            <IconButtonContainer
              disabled={order.status !== orderStatus.completed}
            >
              <RiPrinterLine
                size={18}
                onClick={() => {
                  order.status === orderStatus.completed &&
                    setOrderPrintManagement(order);
                }}
              />
            </IconButtonContainer>
          </ActionItem>
        )}
        <ActionItem>
          <Popover position="left" label={`${i18n.t("orders.view")}`}>
            <IconButtonContainer>
              <RiEyeLine
                size={18}
                onClick={() => {
                  navigate(
                    "/saf/orders/show", {
                    state: {
                      order_id: order.id,
                    },
                  });
                }}
              />
            </IconButtonContainer>
          </Popover>
        </ActionItem>

        {!order.printer && (
          <ActionItem>
            <Popover position="left" label={"FixPoint"}>
              <ProvisionerContainer
                providerSelected={!!order?.provider?.id ?? providerSelected}
              >
                <MdOutlinePersonPinCircle
                  size={18}
                  onClick={() => {
                    handleToggleModal();
                  }}
                />
              </ProvisionerContainer>
            </Popover>
          </ActionItem>
        )}

        <ActionItem>
          <IconButtonContainer onClick={() => setFinancialStatusModal(true)}>
            {getFinancialStatusInfos().icon}
            {financialStatusModal && (
              <ActionsPopup
                options={paymentStatusActions}
                onClickAway={() => setFinancialStatusModal(false)}
              />
            )}
          </IconButtonContainer>
        </ActionItem>
      </ActionsContainer>
    </>
  );
};