import React from "react";
import { Link } from "react-router-dom";

import {
  RiLineChartLine,
  RiUserStarFill,
  RiUserSettingsFill,
  RiFileListLine,
  RiToolsLine,
} from "react-icons/ri";

import {
  Container,
  RouteButton,
  LinkList,
  LinkItem,
  LinkTitle,
  Separator,
  Overlay,
} from "./styles";

import logo from "../../../../../assets/logo-white.svg";

interface INavMenuProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
  isMenuOpen: boolean;
}

const NavMenu = ({ toggleMenu, isMenuOpen }: INavMenuProps) => {
  return (
    <Container isMenuOpen={isMenuOpen}>
      <img className="logo" src={logo} width="50%" alt="Fix it logo" />
      <Separator />
      <LinkList>
        <LinkItem>
          <Link to="/admin/dashboard" onClick={toggleMenu}>
            {" "}
            {/* Go to Home */}
            <RouteButton>
              <RiLineChartLine size={28} color="var(--white)" />
            </RouteButton>
            <LinkTitle>Relatórios</LinkTitle>
          </Link>
        </LinkItem>

        <LinkItem>
          <Link to="/users/licensees" onClick={toggleMenu}>
            <RouteButton>
              <RiUserStarFill size={28} color="var(--white)" />
            </RouteButton>
            <LinkTitle>Licenciados</LinkTitle>
          </Link>
        </LinkItem>

        <LinkItem>
          <Link to="/users/creators" onClick={toggleMenu}>
            <RouteButton>
              <RiUserSettingsFill size={28} color="var(--white)" />
            </RouteButton>
            <LinkTitle>Designers</LinkTitle>
          </Link>
        </LinkItem>

        <LinkItem>
          <Link to="/configs" onClick={toggleMenu}>
            <RouteButton>
              <RiToolsLine size={28} color="var(--white)" />
            </RouteButton>
            <LinkTitle>Configurações</LinkTitle>
          </Link>
        </LinkItem>
      </LinkList>
      <Overlay onClick={toggleMenu} isMenuOpen={isMenuOpen} />
    </Container>
  );
};

export default NavMenu;
