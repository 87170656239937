import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
  ButtonIcon,
} from "./styles";
import api from "../../../../services/api";
import { RiEye2Line, RiEyeLine, RiPagesLine, RiPriceTag2Line, RiPrinterCloudLine } from "react-icons/ri";
import { ShowDocModal } from "../../../../components/ShowDocModal";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { ConfigItem } from "../../../../components/ConfigItem";
import { ActionItem, IconButtonContainer } from "../../../../components/ConfigItem/styles";
import Popover from "../../../../components/Popover";
import { useParams } from "react-router-dom";
import { DocItem } from "./components/DocItem";
import { Modal } from "../../../../components/Modal";
import { CreateDoc } from "./components/CreateDoc";
import AbsoluteButton from "../../../../components/AbsoluteButton";


export interface IDoc {
  id: string;
  name: string;
  locale: string;
  version: string;
  document_link: string;
}

export interface IContract {
  contract_version: string;
}

export default function Docs() {
  let { contract_version} = useParams<keyof IContract>();
  const { setNavigationAction } = useAuth();
  const [ docs, setDocs ] = useState<IDoc[]>();
  const [ isCreateModalOpen, setIsCreateModalOpen ] = useState(false);

  const getDocs = () => {
    api.get(`/user_terms/${contract_version}`).then(response => {
      setDocs(response.data);
    }).catch((error) => {
      console.log(error)
    });
  }

  useEffect(() => {
    if (contract_version) {
      getDocs();
    }

    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.docs.header", { contract: contract_version })
    });

  }, [contract_version]);

  return (
    <Container>
      {
        isCreateModalOpen && (
          <Modal open={isCreateModalOpen}>
            <CreateDoc 
              closeModal={() => {
                getDocs();
                setIsCreateModalOpen(false)
              }}
            />
          </Modal>
        )
      }
      <EvaluationWrapper>
        {
          docs?.map((doc) => {
            return (
              <DocItem 
                doc={doc} 
                />
            )
          })
        }
      </EvaluationWrapper>
      <AbsoluteButton
        absolute
        onClick={() => {
          setIsCreateModalOpen(true);
        }}
      />
    </Container>
    )
  }

