import { useEffect, useState } from "react";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { showToast } from "../../../../../../components/CustomToast";
import { RiCloseFill, RiDeleteBin7Line } from "react-icons/ri";
import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  CheckboxContainer,
  CheckboxLine,
  Footer,
  SaveText,
  UploadFilesContainer,
  File,
  DropzoneContainer,
  Form,
} from "./styles";
import { i18n } from "../../../../../../localization/i18n";
import api from "../../../../../../services/api";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../hooks/AuthContext";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import DropZone from "../../../../../../components/DropZone";

interface PriceInfosProps {
  closeModal: () => void;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}

interface IFormData {
  version?: string;
  contract?: {
    value: string | number;
    optionText: string;
  };
  type?: {
    value: string | number;
    optionText: string;
  };
  locale?: {
    value: string | number;
    optionText: string;
  };
}

interface IParams {
  contract_version: string;
}

export function CreateDoc({ closeModal }: PriceInfosProps) {
  const { setNavigationAction } = useAuth();
  
  const contractOptions = [
    { optionText: "Licença Anual - 1.0", value: "1.0" },
    { optionText: "Licença Mensal - 2.0", value: "2.0" },
  ];

  const typeOptions = [
    { optionText: "Política de Privacidade", value: "privacy_policy" },
    { optionText: "Termos de Uso", value: "user_terms" },
  ];

  const localeOptions = [
    { optionText: "Português", value: "pt-BR" },
    { optionText: "Inglês", value: "en" },
    { optionText: "Espanhol", value: "es" },
  ];

  let { contract_version } = useParams<keyof IParams>();

  const [file, setFile] = useState<IFile>();
  const [data, setData] = useState<IFormData>();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  useEffect(() => {
    setData({
      contract: contractOptions.find((c) => c.value === contract_version),
    });
  }, [contract_version]);

  function handleDeleteFile() {
    setFile(undefined);
  }

  const submitForm = async (e: any): Promise<void> => {
    if (!data?.version || !data?.type || !data?.locale || !data?.contract) {
      showToast({
        type: "error",
        message: `Dados não informados`,
      });
      return;
    }

    if (!file) {
      showToast({
        type: "error",
        message: `Nenhum documento foi adicionado`,
      });
      return;
    }

    var formData = new FormData();

    formData.append("file", file.file_data);
    formData.append("name", data.type.optionText);
    formData.append("version", data.version);
    formData.append("type", data.type.value.toString());
    formData.append("locale", data.locale.value.toString());
    formData.append("contract", data.contract.value.toString());

    api
      .post(`/user_terms`, formData)
      .then(() => {
        showToast({
          type: "success",
          message: "Documento adicionado com sucesso!",
        });
        closeModal();
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao carregar dados do termo de uso`,
        });
      });

    return;
  };

  return (
    <Container>
      <RiCloseFill
        size={24}
        color="var(--fixit)"
        onClick={() => closeModal()}
      />

      <Header>
        <span>{`${i18n.t("global.configs.createUserTerms")}`}</span>
      </Header>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submitForm)();
        }}
      >
        <FormContainer>
          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Tipo"
                options={typeOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    type: selected,
                  }));
                }}
                selectedOption={data?.type?.optionText || ""}
              />
            </InputContainer>
            <InputContainer fieldWidth="250px">
              <OutlinedInput
                label="Versão"
                inputName="version"
                type="text"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    version: event,
                  }));
                }}
                value={data?.version || ""}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                label="Contrato"
                options={contractOptions}
                readOnly={true}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    contract: selected,
                  }));
                }}
                selectedOption={data?.contract?.optionText || ""}
              />
            </InputContainer>
            <InputContainer fieldWidth="250px">
              <OutlinedSelect
                label="Lingua"
                options={localeOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    locale: selected,
                  }));
                }}
                selectedOption={data?.locale?.optionText || ""}
              />
            </InputContainer>
          </InputLine>
          <InputLine>
            <UploadFilesContainer>
              <DropzoneContainer>
                <DropZone
                  onUpload={(file) => {
                    const formattedFile = {
                      name: file[0].name,
                      size: file[0].size,
                      type: file[0].type,
                      file_data: file[0],
                    };
                    setFile(formattedFile);
                  }}
                />
              </DropzoneContainer>

              {file && (
                <File>
                  <span>{file.name}</span>
                  <RiDeleteBin7Line
                    onClick={() => handleDeleteFile()}
                    size={20}
                    color="var(--fixit)"
                  />
                </File>
              )}
            </UploadFilesContainer>
          </InputLine>
        </FormContainer>

        <Footer>
          <SaveText type="submit">Cadastrar documento</SaveText>
        </Footer>
      </Form>
    </Container>
  );
}
