import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";

import {
  Container,
  Form,
  FormContainer,
  FormRow,
  InputContainer,
  Footer,
} from "./styles";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";

export default function CreateCreator() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  async function handleCreateCreator() {
    try {
      if (data.password !== data.password_confirmation) {
        showToast({ type: "error", message: "As senhas não conhecidem" });
        return;
      }

      const body = {
        name: data.name,
        email: data.email,
        password: data.password,
        role: "CREATOR",
      };

      await api.post("/users", body);
      showToast({ type: "success", message: "Designer criado com sucesso" });
      navigate(-1);
    } catch (err) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro ao tentar criar um designer, tente novamente.",
      });
    }
  }

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("global.designers.header"),
    });
  }, []);

  return (
    <Container>
      <Form>
        <FormContainer>
          <div className="form_title">Informações do Designer</div>

          <FormRow>
            <InputContainer>
              <label>Nome</label>
              <Input
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    name: event,
                  }));
                }}
                value={data.name}
              />
            </InputContainer>

            <InputContainer>
              <label>E-mail</label>
              <Input
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    email: event,
                  }));
                }}
                value={data.email}
              />
            </InputContainer>
          </FormRow>

          <FormRow>
            <InputContainer>
              <label>Senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    password: event,
                  }));
                }}
                value={data.password}
              />
            </InputContainer>

            <InputContainer>
              <label>Confirmação da senha</label>
              <Input
                type="password"
                handleChange={(event) => {
                  setData((prevState) => ({
                    ...prevState,
                    password_confirmation: event,
                  }));
                }}
                value={data.password_confirmation}
              />
            </InputContainer>
          </FormRow>

          <Footer>
            <button type="button" onClick={handleCreateCreator}>
              Salvar
            </button>
          </Footer>
        </FormContainer>
      </Form>
    </Container>
  );
}
