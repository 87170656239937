import styled, { css } from "styled-components";
import global from "../../global";

interface SidebarProps {
  extendedMode: boolean;
}

export const Container = styled.div<SidebarProps>`
  background-color: var(--background);

  padding: 14px 5px;
  z-index: 10;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 71px;

  transition: all 0.4s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }

  ${({ extendedMode }) =>
    extendedMode &&
    css`
      width: 100px;
    `}
`;


export const SideBarCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  border-radius: 14px;
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  padding: 16px 10px 10px 10px;

  height: 100%;

  a {
    text-decoration: none;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
`

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
`;

export const RouteButton = styled.button<SidebarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 48px;
  height: 48px;
  border-radius: 50%;

  background-color: var(--background);

  cursor: ${(props => props.disabled ? 'default' : 'pointer')};

  transition: border-radius 0.2s, background-color 0.2s;

  svg {
    color: ${(props => props.disabled ? 'var(--light-gray)' : 'var(--gray)')};
  }

  svg.is_over {
    position: absolute;
    right: 30px;
    color: var(--gray);
  }

  span {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 500;
    color: ${(props => props.disabled ? 'var(--light-gray)' : 'var(--gray)')};
  }
  
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        svg {
          color: var(--fixit)
        }
    
        span {
          color: var(--fixit)
        }
      }
    `}

  ${({ extendedMode }) =>
    extendedMode &&
    css`
      width: 76px;
      height: 50px;
      flex-direction: column;
      border-radius: 8px;
      border-bottom: none;
    `}
    
`;

export const ChangeMode = styled.div<SidebarProps>`
  margin-top: 20px;
  cursor: pointer;

  svg {
    transition: all 0.4s;
  }

  ${({ extendedMode }) =>
    extendedMode &&
    css`
      svg {
        transform: rotateZ(180deg);
      }
    `}
`;

export const Language = styled.select`
  width: fit-content;
  cursor: pointer;
  color: var(--gray);
  font-size: 12px;
  font-weight: 500;
  background-color: var(--background);
  border: 1px solid var(--gray);
  border-radius: 15px;
  padding: 2px 5px 2px 5px;

  option {
    color: var(--dark-gray);
    font-size: 12px;
    width: fit-content;
  }

  &:hover {
    color: var(--fixit);
    border: 1px solid var(--fixit);
  }
`;