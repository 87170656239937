import {
  RiLineChartLine,
  RiMenuLine,
  RiToolsLine,
  RiUserSettingsFill,
  RiUserStarFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { RouteButton } from "../styles";

interface MenuListProps {
  extendedMode: boolean;
}

export const AdminMenuList = ({ extendedMode }: MenuListProps) => (
  <>
    <Link to="/admin/dashboard">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiLineChartLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.reports")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/admin/orders">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiMenuLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.requests")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/users/licensees">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiUserStarFill size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.licensees")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/users/creators">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiUserSettingsFill size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.designers")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/configs">
      {" "}
      {/* Go to Dashboard */} {/* 6 because of 12px margin */}
      <RouteButton extendedMode={extendedMode}>
        <RiToolsLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.config")}`}</span>}
      </RouteButton>
    </Link>
  </>
);
